import React, { useContext } from 'react';
import { Col, Divider, Row, Timeline } from 'antd';
import { SHOW_PROTOCOL } from '@config/env';
import { ShadowContainer } from '@styles/components/wrapper';
import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import { ApprovalContext } from '../context/context';
import { Text } from '@styles/components/text';
import '@extensions/number';
import moment from 'moment';
import { translate } from '@components/i18n';
import { useSelector } from 'react-redux';
import { orderItemsSelector } from '@redux/slices/order';

export const OrderInfo: React.FC = () => {
    const context = useContext(ApprovalContext);
    const {moedas} = useSelector(orderItemsSelector)
    const moedaSelected = moedas.find(item => item.codigo === context?.order?.codigoMoeda)

    const orderValues = !context.order
        ? []
        : [
              {
                  label: translate('columns.order'),
                  value: context.order.key,
              },
              {
                  label: translate('columns.client'),
                  break: true,
                  value: `${context.order.customer.code} - ${context.order.customer.name}`,
              },
              {
                  label: translate('columns.customerCity'),
                  break: true,
                  value: `${context.order.customer.city}`,
              },
              {
                  label: translate('columns.clientType'),
                  value: `${context.order.customer.type} - ${context.order.customer.typeId}`,
              },
              {
                  label: translate('columns.seller'),
                  value: context.order.userName,
              },
              {
                  label: translate('columns.orderDate'),
                  value: moment(context.order.date).format('DD/MM/YYYY'),
              },
              {
                  label: translate('columns.condition'),
                  value: context.order.paymentCondition.description,
              },
          ];

    const orderStatus = !context.order
        ? []
        : [
              context.order.deliveryDate && {
                  label: translate('columns.deliveryDate'),
                  value: moment(context.order.deliveryDate).format('DD/MM/YYYY'),
              },
              context.order.order && {
                  label: translate('columns.buyOrder'),
                  value: context.order.order,
              },
              {
                  label: translate('columns.status'),
                  value: context.order.status ? translate(`columns.${context.order.status}`) : context.order.status,
              },
              {
                  label: translate('columns.division'),
                  value: context.order.division,
              },
              {
                  label: translate('columns.branch'),
                  value: context.order.orderType.platform.description,
              },
              SHOW_PROTOCOL && {
                  label: translate('columns.protocol'),
                  value: context.order.protocolo,
              },
              {
                  label: translate('columns.triangulation'),
                  break: true,
                  value: context.order.addCustomerSelected
                      ? `${translate('general.yes')} - ${translate(
                            'columns.clientCode',
                        )}: ${context.order.addCustomerSelected.id} - ${
                            context.order.addCustomerSelected.name
                        }`
                      : translate('general.no'),
              },
              {
                label: translate('columns.moneyType'),
                value: moedaSelected ? `${moedaSelected.codigo} - ${moedaSelected.descricao}` : ''
              }
          ];

    return (
        <ShadowContainer style={{ marginTop: 30, marginLeft: 20, padding: 20 }}>
            <Row gutter={10}>
                <Col span={24} lg={{span: 8}}>
                    {orderValues.map((c) => (
                        <Row style={{ marginTop: 10 }}>
                            <Text fontWeight={'bold'} blue marginRight={'4px'}>
                                {c.label}:
                            </Text>
                            <Text inline={!c.break} title={c.value}>
                                {c.value}
                            </Text>
                        </Row>
                    ))}
                </Col>
                <Col span={24} lg={{span: 6}}>
                    {orderStatus
                        .filter((c) => c)
                        .map((c) => (
                            <Row style={{ marginTop: 10 }}>
                                <Text
                                    fontWeight={'bold'}
                                    blue
                                    marginRight={'4px'}
                                >
                                    {c.label}:
                                </Text>
                                <Text inline={!c.break} title={c.value}>
                                    {c.value}
                                </Text>
                            </Row>
                        ))}
                </Col>
                <Col span={10}>
                    <Row>
                        <Text fontWeight={'bold'} blue fontSize={'1.2em'}>
                            {translate('general.approvers')}:
                        </Text>
                    </Row>
                    <Timeline style={{ marginTop: 10 }}>
                        {context.order?.approvers.map((c) => (
                            <Timeline.Item
                                color={
                                    c.status === 'PENDENTE'
                                        ? 'gray'
                                        : c.status === 'APROVADO'
                                        ? 'green'
                                        : 'red'
                                }
                                dot={
                                    c.status === 'PENDENTE' ? (
                                        <ClockCircleOutlined
                                            style={{ fontSize: '16px' }}
                                        />
                                    ) : c.status === 'APROVADO' ? (
                                        <CheckOutlined
                                            style={{ fontSize: '16px' }}
                                        />
                                    ) : (
                                        <CloseOutlined
                                            style={{ fontSize: '16px' }}
                                        />
                                    )
                                }
                            >
                                <Row
                                    align="middle"
                                    justify={'space-between'}
                                    wrap={false}
                                >
                                    <span
                                        style={{
                                            maxWidth: 150,
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        title={
                                            `${c.userName}` ??
                                            translate('general.notInformed')
                                        }
                                    >
                                        {c.userName ??
                                            translate('general.notInformed')}
                                    </span>{' '}
                                    <Col>
                                        <Row align={'middle'}>
                                            <Divider type="vertical" />{' '}
                                            <span
                                                style={{
                                                    opacity: '0.7',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {c.status !== 'PENDENTE'
                                                    ? moment(c.date).format(
                                                          'HH:mm DD/MM/YYYY',
                                                      )
                                                    : translate(
                                                          'general.wating',
                                                      )}
                                            </span>
                                        </Row>
                                    </Col>
                                </Row>
                            </Timeline.Item>
                        ))}
                    </Timeline>
                </Col>
            </Row>
            <Divider style={{ margin: '10px 0px' }} />
            <Row justify="space-between">
                <Col>
                    <Text fontWeight={'bold'} blue>
                        {' '}
                        {translate('general.orderType')}:
                    </Text>{' '}
                    {context.order?.orderType.type.description}
                </Col>
                {context.order?.orderType.type.id !== 'V2' &&
                    context.bonificationOrder &&
                    context.bonificationOrder?.ponderedDiscount < 0 && (
                        <Col>
                            <Text fontWeight={'bold'} blue>
                                {translate('general.weightedDiscount')}:
                            </Text>{' '}
                            {context.bonificationOrder
                                ? Number(
                                      context.bonificationOrder
                                          ?.ponderedDiscount,
                                  ).toFixed(2) ?? translate('general.wating')
                                : Number(
                                      context.order?.ponderedDiscount,
                                  ).toFixed(2) ?? translate('general.wating')}
                            %
                        </Col>
                    )}
                {context.order?.orderType.type.id !== 'V2' &&
                    !context.bonificationOrder &&
                    context.order?.ponderedDiscount < 0 && (
                        <Col>
                            <Text fontWeight={'bold'} blue>
                                {translate('general.weightedDiscount')}:
                            </Text>{' '}
                            {Number(context.order.ponderedDiscount).toFixed(2)}%
                        </Col>
                    )}
            </Row>
        </ShadowContainer>
    );
};
