import React, { useEffect, useState } from 'react';

import { Input, Page } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import moment from 'moment';
import { Title } from '@styles/components/text';
import { columns, filterTypes, filterTypesStatus } from './constants';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { toQueryParameters } from 'src/extensions/object';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
    exportOrdersHistory,
    fetchOrdersHistory,
    orderHistorySelector,
} from '@redux/slices/reports';
import { ExportButton } from '@styles/components/button';
import { getFilterOptions } from '@utils/filter';
import { OrderHistoryResponse } from '@models/report';
import { translate } from '@components/i18n';
import { fetchAvailableTypes, fetchMoedasSimbolos, orderTypeSelector } from '@redux/slices/order';
import { InputRange } from '@components/input';

export const OrdersHistoryReport: React.FC = () => {
    const [queryState, setQueryState] = useState<string>('');
    const orderHistoryState = useSelector(orderHistorySelector);
    const orderTypeState = useSelector(orderTypeSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [optionsComparisonFilter, setOptionsComparisonFilter] =
        useState(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        if (values['datas'] && values['datas'].length > 0) {
            const range = values['datas'];
            values = {
                ...values,
                dataInicio: range[0].format('DD/MM/YYYY'),
                dataFim: range[1].format('DD/MM/YYYY'),
            };
        } else {
            values = {
                ...values,
                dataInicio: undefined,
                dataFim: undefined,
            };
        }

        delete values.datas

        if (changedValues.filterBy) {
            setOptionsComparisonFilter(
                getFilterOptions(filterTypes, changedValues.filterBy),
            );
        }

        dispatch(
            fetchOrdersHistory({
                ...orderHistoryState.request,
                ...values,
                filterValue: queryState,
            }),
        );
    };

    const onSearch = (value: string): void => {
        setQueryState(value);
    };

    const onExport = (): void => {
        dispatch(
            exportOrdersHistory({
                ...orderHistoryState.request,
                filterValue: queryState,
            }),
        );
    };

    useEffect(() => {
        history.replace(
            '/reports/orders-history?' +
                toQueryParameters(orderHistoryState.request),
        );
    }, [orderHistoryState.request]);

    useEffect(() => {
        dispatch(fetchAvailableTypes())
        dispatch(fetchMoedasSimbolos())
        dispatch(fetchOrdersHistory(orderHistoryState.request));
        setOptionsComparisonFilter(getFilterOptions(filterTypes, 'pedido'));
    }, []);

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchOrdersHistory({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    const search = (): void => {
        dispatch(
            fetchOrdersHistory({
                ...orderHistoryState.request,
                filterValue: queryState,
            }),
        );
    };

    useEffect(() => {
        const filters = Object.fromEntries(
            new URLSearchParams(location.search),
        );

        const datas = []

        if(filters.dataInicio){
            datas.push(moment(filters.dataInicio, 'DD/MM/YYYY'))
        }
        if(filters.dataFim){
            datas.push(moment(filters.dataFim, 'DD/MM/YYYY'))
        }

        const orderTypes = []

        if(filters.tipo_pedido){
            filters.tipo_pedido
                .split(",")
                .forEach(item => orderTypes.push(item))
        }
    
        form.setFieldsValue({
            ...filters,
            datas,
            status: filters.status ? filters.status.split(",") : undefined,
            filterBy: filters.filterBy || undefined,
            comparisonType: filters.comparisonType || undefined,
            tipo_pedido: orderTypes,
        });
    }, []);

    const onSelect = (rowKey: OrderHistoryResponse): void => {
        history.push(`/approval-order/${rowKey.order}?readOnly=true`);
    };

    return (
        <Page
            title={translate('pages.historyOfOrders')}
            greyBackground
            withPadding
        >
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.historyOfOrders')}
                        </Title>
                    </Row>
                    <ShadowContainer>
                        <Row justify="center">
                            <Col span={23}>
                                <Table
                                    customKey={`${orderHistoryState.request.current}-page`}
                                    columns={columns.filter(c => !!c)}
                                    form={form}
                                    pointer
                                    onRowClick={onSelect}
                                    dataSource={orderHistoryState.ordersHistory}
                                    pagination={orderHistoryState.request}
                                    isLoading={orderHistoryState.isFetching}
                                    onChange={loadData}
                                    onFilterChange={onFiltersChange}
                                    filters={[
                                        <FormItem
                                            label={translate(
                                                'forms.labels.searchFor',
                                            )}
                                            name={'filterBy'}
                                        >
                                            <Select
                                                placeholder="Todos"
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                            >
                                                {filterTypes
                                                .filter(c => !!c)
                                                .map((f) => {
                                                    return (
                                                        <Option
                                                            key={f.name}
                                                            value={f.name}
                                                        >
                                                            {f.description}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FormItem>,
                                        <FormItem
                                            label={translate(
                                                'forms.labels.searchType',
                                            )}
                                            name={'comparisonType'}
                                        >
                                            <Select
                                                placeholder="Todos"
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                            >
                                                {optionsComparisonFilter &&
                                                    optionsComparisonFilter.map(
                                                        (f) => {
                                                            return (
                                                                <Option
                                                                    key={f.name}
                                                                    value={
                                                                        f.name
                                                                    }
                                                                >
                                                                    {
                                                                        f.description
                                                                    }
                                                                </Option>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormItem>,
                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Input
                                                placeholder={translate(
                                                    'forms.placeholders.search',
                                                )}
                                                allowClear
                                                suffix={
                                                    <div
                                                        onClick={search}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <SearchOutlined />
                                                    </div>
                                                }
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.code === `Enter` ||
                                                        e.keyCode === 13
                                                    ) {
                                                        search();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    onSearch(e.target.value)
                                                }
                                            />
                                        </Form.Item>,
                                         <FormItem
                                         label={translate('forms.labels.date')}
                                         name={'datas'}
                                     >
                                         <InputRange
                                             format="DD-MM-YYYY"
                                             placeholder={[
                                                 translate(
                                                     'forms.placeholders.startDate',
                                                 ),
                                                 translate('forms.placeholders.endDate'),
                                             ]}
                                             light
                                         />
                                     </FormItem>,
                                    <FormItem
                                        label={translate('forms.labels.status')}
                                        name={'status'}
                                        wrapperCol={{style: {minWidth: 120}}}
                                    >
                                        <Select
                                            placeholder={translate('general.all')}
                                            dropdownMatchSelectWidth={false}
                                            allowClear
                                            mode='multiple'
                                        >
                                            {filterTypesStatus.map((c) => (
                                                <Option value={c.name}>
                                                    {c.description}
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>,
                                    <FormItem
                                        label={translate('forms.labels.orderType')}
                                        name={'tipo_pedido'}
                                        wrapperCol={{style: {minWidth: 120}}}
                                    >
                                        <Select
                                            placeholder={translate('general.all')}
                                            dropdownMatchSelectWidth={false}
                                            allowClear
                                            mode='multiple'
                                        >
                                            {orderTypeState.availableTypes.map((c) => (
                                                <Option value={c.description}>
                                                    {c.description}
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormItem>,
                                    ]}
                                    actions={[
                                        <ExportButton
                                            onClick={() => {
                                                onExport();
                                            }}
                                        >
                                            {translate('general.export')}{' '}
                                            <DownloadOutlined />
                                        </ExportButton>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ShadowContainer>
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
