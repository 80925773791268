import React, { useEffect } from 'react';
import { Col } from 'antd';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from './components/header';
import { Body } from './components/body';
import { CardTable } from '@components/table/styled';
import { translate } from '@components/i18n';
import { fetchTransferItems, fetchTransferItemsByUser } from '@redux/slices/transfer-item';
import { fetchAllAvailablePlatforms } from '@redux/slices/order';
import { fetchTransferClients } from '@redux/slices/transfer-clients';
import { fetchDivisions } from '@redux/slices/divisions';
import { fetchEnabledItems } from '@redux/slices/campaign';
import { fetchMoneySymbols, fetchMoneyTypes, moneyTypeSelector } from '@redux/slices/money-type';
import { authSelector } from '@redux/slices/auth';

export const TransferItem: React.FC = () => {
    const dispatch = useDispatch();
    const moneyType = useSelector(moneyTypeSelector)
    const authState = useSelector(authSelector);

    useEffect(() => {
        if (authState.isAdmin) {
            dispatch(fetchTransferItems());
        } else {
            dispatch(fetchTransferItemsByUser({ size: 20, page: 0 }))
        }

        dispatch(fetchDivisions())
        dispatch(fetchAllAvailablePlatforms());
        dispatch(fetchTransferClients({ size: 999, page: 0 }));
        dispatch(fetchEnabledItems())

        if (!moneyType?.isSuccessSymbols) {
            dispatch(fetchMoneySymbols())
        }

        if (!moneyType?.isSuccess) {
            dispatch(fetchMoneyTypes())
        }

    }, []);

    return (
        <Page
            title={translate('pages.transferItems')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Col span={22} style={{ alignSelf: 'center' }}>
                <h2 style={{ marginTop: '15px' }}>
                    {translate('titlePages.transferItems')}
                </h2>
                <CardTable>
                    <Header />
                    <Body />
                </CardTable>
            </Col>
        </Page>
    );
};
