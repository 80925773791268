import { translate } from '@components/i18n';

export const columns = [
    {
        title: translate('columns.order'),
        dataIndex: 'id',
        sorter: false,
        width: '8%',
    },
    {
        title: translate('columns.client'),
        dataIndex: 'name',
        width: '25%',
    },
    {
        title: translate('columns.customerCity'),
        dataIndex: 'customerCity',
        width: '10%',
    },
    {
        title: translate('columns.condition'),
        dataIndex: 'condition',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.protocol'),
        dataIndex: 'protocol',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.date'),
        dataIndex: 'draftRef',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'type',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.status'),
        dataIndex: 'status',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.hasBonification'),
        dataIndex: 'hasBonification',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.total'),
        dataIndex: 'total',
        sorter: false,
        width: '20%',
    },
];
