import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { UserResponse } from '@models/users';
import { forgotPasswordFields } from '@pages/users/constants';
import { FormBuilder } from '@components/form';
import { resetPassword } from '@redux/slices/users';
import { authSelector } from '@redux/slices/auth';
import { translate } from '@components/i18n';

export const ForgotPasswordModal: React.FC = () => {
    const dispatch = useDispatch();
    const { currentUser } = useSelector(authSelector);
    const [form] = Form.useForm<UserResponse>();
    const [editUserState, setEditUserState] =
        useState<UserResponse>(currentUser);
    const modalContext = useContext(ModalContext);

    const onOk = (values): void => {
        handleUpdateUser(values);
    };

    const handleUpdateUser = (values): void => {
        console.debug(values);
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.verifyingAccess'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            resetPassword({
                data: {
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    useEffect(() => {
        if (currentUser) {
            setEditUserState(currentUser);
            form.setFieldsValue(currentUser);
        } else {
            form.resetFields();
        }
    }, [currentUser]);

    const onChangeValues = (
        changedValues: Partial<UserResponse>,
        allValues: UserResponse,
    ): void => {
        setEditUserState({
            ...allValues,
            ...changedValues,
        });
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ ...editUserState }}
                    onFinish={onOk}
                    form={form}
                    fields={forgotPasswordFields()}
                    withDelete={false}
                    style={{ width: '100%' }}
                />
            </Col>
            <Col span={1} />
        </Row>
    );
};
