export interface ApproverResponse {
    id: number;
    approverId: number;
    name: string;
    limit: number;
    division: string;
}

export class ApproverResponseApi {
    id: number;
    divisao: string;
    aprovadorId: number;
    aprovadorNome: string;
    limite: number;

    toApproverResponse(): ApproverResponse {
        return {
            approverId: this.aprovadorId,
            name: this.aprovadorNome,
            division: this.divisao,
            id: this.id,
            limit: this.limite,
        };
    }
}

export interface ApproverOrderResponse {
    userId: number;
    userName: number;
    orderId: number;
    date: string;
    status: string;
    order: number;
}

export interface ApproverOrderDiscount {
    usuarioId: number;
    usuarioNome: number;
    role: string;
    dataHoraConfirmacao: string;
    statusAprovacao: string;
    ordem: number;
}

export class ApproverOrderResponseApi {
    usuarioId: number;
    usuarioNome: number;
    pedidoId: number;
    dataHoraConfirmacao: string;
    statusAprovacao: string;
    ordem: number;

    toApproverOrderResponse(): ApproverOrderResponse {
        return {
            userId: this.usuarioId,
            userName: this.usuarioNome,
            orderId: this.pedidoId,
            date: this.dataHoraConfirmacao,
            status: this.statusAprovacao,
            order: this.ordem,
        };
    }
}
