import { RouterPath } from '@definitions/routes';
import LoginPage from '@pages/auth/login';

const routes: RouterPath[] = [
    {
        path: '/login',
        render: () => <LoginPage />,
        allowedRoles: [],
    },
];

export default routes;
