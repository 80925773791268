import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import '@extensions/string';
import {
    MinOrderRequest,
    MinOrderRequestAPI,
    MinOrderResponse,
    MinOrderResponseApi,
    MinOrderResponseOrder,
    TipoTransporteResponse,
} from '@models/min-order';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification } from '../notification';
import { RootState } from '@redux/reducers';
import { PaginatedResponseApi } from '@models/order/items/response';
import { translate } from '@components/i18n';

export const fetchMinValueForOrder = createAsyncThunk<MinOrderResponseOrder[]>(
    'pedidoMinimo/fetchMinValueForOrder',
    async (request, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;

            const params = JSON.parse(
                JSON.stringify({
                    codigoFilial: state.selectItems.order.orderType.platform.id,
                    codigoCliente: state.selectItems.order.customer.code,
                    tipoCliente: state.selectItems.order.customer.typeId,
                    zonaCliente: state.selectItems.order.customer.state,
                    zonaClienteEntrega:
                        state.selectItems.order.addCustomerSelected?.state,
                    codigosTipoTransporte: state.selectItems.availableItems
                        .filter((c) => c.quantity > 0)
                        .map((c) => c.codigoTipoTransporte)
                        .filter((c) => !!c),
                }),
            );
            if (params.codigosTipoTransporte.length === 0) return undefined;

            const response = await axios.post<MinOrderResponseOrder[]>(
                '/pedidominimo/validacao',
                params,
            );

            const { data, status } = response;
            if (status === 200 && data) {
                return data;
            } else {
                return undefined;
            }
        } catch (e) {
            return undefined;
        }
    },
);

export const fetchMinimumOrder = createAsyncThunk<MinOrderResponse[]>(
    'pedidoMinimo',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<
                PaginatedResponseApi<MinOrderResponseApi>
            >('/pedidominimo', {
                params: {
                    page: 0,
                    size: 999,
                },
            });

            const { data, status } = response;

            if (status === 200) {
                const result = data.content.map((u) =>
                    Object.assign(new MinOrderResponseApi(), u),
                );

                return result.map((u) => u.toMinOrderResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(
                translate('general.errorListingMinOrders'),
            );
        }
    },
);

export const fetchTransportTypes = createAsyncThunk<TipoTransporteResponse[]>(
    'pedidoMinimo/fetchTransportTypes',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<TipoTransporteResponse[]>(
                '/tipotransporte/all',
            );

            const { data, status } = response;

            if (status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(
                translate('general.errorListingMinOrders'),
            );
        }
    },
);

export const fetchMinimumOrderById = async (
    groupId,
): Promise<MinOrderRequestAPI> => {
    try {
        const response = await axios.get<MinOrderResponseApi>(
            '/pedidominimo/' + groupId,
        );
        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        const { response } = e;
    }
};

export const createMinimumOrder = createAsyncThunk<
    MinOrderResponse,
    ThunkCallback<MinOrderRequest>
>('pedidoMinimo/createMinimumOrder', async (request, thunkAPI) => {
    try {
        const response = await axios.post<MinOrderResponseApi>(
            '/pedidominimo',
            new MinOrderRequestAPI(request.data),
        );

        const { data, status } = response;
        if (status === 201 && data) {
            const instance: MinOrderResponseApi = Object.assign(
                new MinOrderResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.minOrderRegistered'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );
            thunkAPI.dispatch(fetchMinimumOrder());
            return instance.toMinOrderResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        console.debug('Error', e);
        if (e.response) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: e.response.data[0].msgUsuario,
                    title: translate('general.errorT'),
                    notificationKey: request.notificationKey,
                }),
            );
        }
        return thunkAPI.rejectWithValue('');
    }
});

export const removeMinimumOrder = createAsyncThunk<number, number>(
    'pedidoMinimo/removeMinimumOrder',
    async (groupId, thunkAPI) => {
        try {
            const response = await axios.delete('/pedidominimo/' + groupId);
            if (response.status === 200) {
                thunkAPI.dispatch(
                    addNotification({
                        type: 'success',
                        message: translate('general.minOrderRemovedSuccess'),
                        title: translate('general.success'),
                    }),
                );
                thunkAPI.dispatch(fetchMinimumOrder());
                return Promise.resolve(groupId);
            }
            return 0;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;
            return thunkAPI.rejectWithValue(message);
        }
    },
);

interface IMinimumOrderState {
    availableMinOrders: MinOrderResponse[];
    availableTransportTypes: TipoTransporteResponse[];
    availableCampaignForOrder: MinOrderResponse[];
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
    minOrderValue?: MinOrderResponseOrder[];
}

const initialState: IMinimumOrderState = {
    availableMinOrders: [],
    availableTransportTypes: [],
    availableCampaignForOrder: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const minimumOrderSlice = createSlice({
    name: 'minimumOrderSlice',
    initialState,
    reducers: {
        clearMinOrderState: (state) => {
            state = { ...initialState };

            return state;
        },
        filterCampaign: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
            return state;
        },
    },
    extraReducers: {
        [createMinimumOrder.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<MinOrderResponse>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            // state.availableMinOrders = [...state., payload];
            return state;
        },
        [createMinimumOrder.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [createMinimumOrder.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchMinimumOrder.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchMinimumOrder.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchMinimumOrder.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<MinOrderResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableMinOrders = payload;
            return state;
        },
        [fetchMinValueForOrder.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchMinValueForOrder.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchMinValueForOrder.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<MinOrderResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableCampaignForOrder = payload;
            return state;
        },
        [fetchTransportTypes.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<TipoTransporteResponse[]>,
        ) => {
            state.availableTransportTypes = payload;
            return state;
        },
        [fetchMinValueForOrder.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<MinOrderResponseOrder[]>,
        ) => {
            state.minOrderValue = payload;
            return state;
        },
    },
});

export const updateMinOrder = createAsyncThunk<
    MinOrderResponse,
    ThunkCallback<MinOrderResponse>
>('minimumOrderSlice/updateMinOrder', async (request, thunkAPI) => {
    try {
        const response = await axios.put<MinOrderResponseApi>(
            '/pedidominimo/' + request.data.id,
            new MinOrderRequestAPI(request.data),
        );

        const { data, status } = response;

        if (status === 200) {
            const instance: MinOrderResponseApi = Object.assign(
                new MinOrderResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.minOrderUpdated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            thunkAPI.dispatch(fetchMinimumOrder());

            return instance.toMinOrderResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        if (e.response) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'error',
                    message: e.response.data[0].msgUsuario,
                    title: translate('general.errorT'),
                    notificationKey: request.notificationKey,
                }),
            );
        }
        return thunkAPI.rejectWithValue('');
    }
});

export const minimumOrderSelector = (state: RootState): IMinimumOrderState =>
    state.minOrder;

export const { clearMinOrderState } = minimumOrderSlice.actions;

export default minimumOrderSlice.reducer;
