import { translate } from '@components/i18n';
import { ApproverOrderResponseApi } from '@models/approval/response';
import { DraftsResponse, initialItemsState } from '@redux/slices/order';

export class DraftResponseApi {
    id: number;
    ultimaAtualizacao: string;
    codigoTipoPedido: string;
    descricaoTipoPedido: string;
    codigoMoeda: string
    usuarioId: number;
    usuarioNome: string;
    emailCopia: string;
    fromPedidoTransfer: boolean
    mensagemCopia: string;
    codigoPrazo: string;
    descricaoPrazo: string;
    dataPedido: string;
    dataProgramada: string;
    dataEntrega: string;
    valorTotal: number;
    valorTotalComIpi: number;
    status: string;
    protocolo: string;
    divisao: string;
    codigoCliente: number;
    nomeCliente: string;
    cnpjCliente: string;
    tipoCliente: string;
    tipoClienteDescricao: string;
    cidadeCliente: string;
    zonaCliente: string;
    codigoFilial: string;
    descricaoFilial: string;
    codigoClienteEntrega: number;
    nomeClienteEntrega: string;
    cnpjClienteEntrega: string;
    ordemCompra: null;
    bonificacao: boolean;
    pedidoBonificacaoId: null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    itens: any[];
    itensBonificados: ItensBonificado[];
    aprovacoes: ApproverOrderResponseApi[];
    semaforoCor: string;
    semaforoNome: string;
    descontoPonderado: number;
    aprovacaoPendenteUsuario?: string;
    pedidosBonificados?: number[];
    campanhaIds?: number[];
    envioProximoPedidoVenda?: boolean;

    toOrderState(): Partial<DraftsResponse> {
        return {
            ...initialItemsState,
            draftRef: this.id.toString(),
            lastUpdate: this.ultimaAtualizacao,
            order: {
                ...initialItemsState.order,
                key: this.id.toString(),
                campanhaIds: this.campanhaIds ? this.campanhaIds : [],
                codigoMoeda: this.codigoMoeda,
                paymentCondition: {
                    id: this.codigoPrazo,
                    description: this.descricaoPrazo,
                },
                protocolo: this.protocolo ?? translate('general.notInformed'),
                userName: this.usuarioNome,
                valorPedido: this.valorTotal,
                fromPedidoTransfer: !!this.fromPedidoTransfer,
                semaforoPedido: this.semaforoCor,
                semaforoNomePedido: this.semaforoNome,
                addCustomer: !!this.nomeClienteEntrega,
                date: this.dataPedido,
                division: this.divisao,
                status: this.status,
                ponderedDiscount: this.descontoPonderado,
                aprovacaoPendenteUsuario: this.aprovacaoPendenteUsuario,
                deliveryDate: this.dataEntrega,
                approvers: !this.aprovacoes
                    ? []
                    : this.aprovacoes.map((a) => {
                          const instance = Object.assign(
                              new ApproverOrderResponseApi(),
                              a,
                          );
                          return instance.toApproverOrderResponse();
                      }),
                addCustomerSelected: this.nomeClienteEntrega
                    ? {
                          id: this.codigoClienteEntrega,
                          name: this.nomeClienteEntrega,
                          cnpj: this.cnpjClienteEntrega,
                      }
                    : undefined,
                sendCopy: {
                    email: this.emailCopia,
                    note: this.mensagemCopia,
                    envioProximoPedidoVenda: this.envioProximoPedidoVenda,
                },
                order: this.ordemCompra,
                items: undefined,
                customer: {
                    code: this.codigoCliente,
                    name: this.nomeCliente,
                    cnpj: this.cnpjCliente,
                    type: this.tipoCliente,
                    city: this.cidadeCliente,
                    state: this.zonaCliente,
                    address: undefined,
                    alternativeAddress: undefined,
                    company: undefined,
                    group: undefined,
                    id: undefined,
                    isentIpi: false, // TODO Adicionar
                    suframa: undefined,
                    trade: undefined,
                    typeId: this.tipoClienteDescricao,
                },
                orderType: {
                    date: this.dataProgramada,
                    platform: {
                        description: this.descricaoFilial,
                        id: this.codigoFilial,
                    },
                    type: {
                        description: this.descricaoTipoPedido,
                        id: this.codigoTipoPedido,
                    },
                },
                tempItems: this.itens.map((i) => ({
                    discount: i.desconto,
                    description: i.descricaoItem,
                    ipi: i.ipi,
                    quantityPresentation: i.multiplo,
                    quantity: i.quantidade,
                    number: i.numeroItem,
                    code: i.numeroItem,
                    total: i.precoTotal,
                    order: i.ordem,
                    totalQuantity: i.quantidadeTotal,
                    promotion: i.promocoes,
                    costModifiable: i.precoUnitario,
                    valor: i.precoUnitario,
                    semaforo: i.semaforoCor,
                    semaforoNome: i.semaforoNome,
                    defaultKey: this.id,
                    campanhaId: i.campanhaId,
                })),
                bonificationId: this.pedidoBonificacaoId,
                bonificationItems: this.itensBonificados.map((i) => ({
                    discount: i.desconto,
                    description: i.descricaoItem,
                    ipi: i.ipi,
                    quantityPresentation: i.multiplo,
                    quantity: i.quantidade,
                    number: i.numeroItem,
                    code: i.numeroItem,
                    total: i.precoTotal,
                    order: i.ordem,
                    totalQuantity: i.quantidadeTotal,
                    promotion: i.promocoes,
                    costModifiable: i.precoUnitario,
                    valor: i.precoUnitario,
                    semaforo: i.semaforoCor,
                    semaforoNome: i.semaforoNome,
                    defaultKey: this.id,
                    campanhaId: i.campanhaId,
                })),
            },
        };
    }
}

export interface ItensBonificado {
    numeroItem: string;
    descricaoItem: string;
    quantidade: number;
    precoUnitario: number;
    precoTotal: number;
    multiplo: number;
    quantidadeTotal: number;
    desconto: number;
    promocoes: number;
    valorTrade: number;
    ipi: boolean;
    valorIpi: number;
    valorSuframa: number;
    ordem: null;
    semaforoCor: string;
    semaforoNome: string;
    campanhaId?: number;
}
