import { useContext, useState } from 'react';
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { authSelector } from '@redux/slices/auth';
import { FlexBox, MenuItem, SubMenuBox } from '../styled';
import { PageContext } from '../context/producer';
import { COUNTRY } from '@config/env';
import { ActionItemMenuDrawer } from './action-item-menu.drawer';
import { Menu } from '@models/menu';

type TItemMenuDrawer = {
  data: Menu.Item
}

export const ItemMenuDrawer: React.FC<TItemMenuDrawer> = ({ data }) => {
  const { t } = useTranslation();
  const pageContext = useContext(PageContext);
  const authState = useSelector(authSelector);

  const [open, setOpen] = useState(false);

  const toggleMenu = (): void => {
    if (data?.subMenus) setOpen((prev) => !prev);
    else {
      pageContext.toggleDrawer();
    }
  };

  // admin cannot see this menu. Only see the menu inside the Registers subMenu
  if (authState.isAdmin && data.title === 'sideMenu.transfers.self') {
    return null
  }

  return (
    <Link
      to={!data?.subMenus && data.path}
      key={`side-menu-${t(data.title)}-link`}
    >
      <MenuItem
        key={`side-menu-${t(data.title)}`}
        className={`${!data?.subMenus && 'has-hover'}`}
      >
        <FlexBox onClick={toggleMenu}>
          <ReactSVG src={data.icon} />
          <Typography.Text>
            {t(data.title)}
          </Typography.Text>
        </FlexBox>
        {data?.subMenus && (
          <SubMenuBox className={`${open && 'open'}`}>
            {data?.subMenus?.filter((m) => authState.currentUser.isAdmin || m.allowedRoles?.some((r) =>
              authState.currentUser.roles.some(
                (c) => c === r,
              ),
            ),
            )
              .filter(sb => (sb.onlyCountries?.includes("ALL") || sb.onlyCountries?.includes(COUNTRY)))
              .map((submenu) => {
                if (submenu.subMenus && submenu.subMenus.length > 0) {
                  return <ItemMenuDrawer key={submenu.title} data={submenu} />
                }
                return <ActionItemMenuDrawer data={submenu} key={submenu.title} />
              })}
          </SubMenuBox>
        )}
      </MenuItem>
    </Link>
  )
}