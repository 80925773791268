import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { MinOrderResponse } from '@models/min-order';
import { divisionValues } from '@redux/slices/divisions';
import { minimumOrderSelector } from '@redux/slices/min-order';
import { customerSelector } from '@redux/slices/order';
import { ExportButton } from '@styles/components/button';
import { FormItem, Select, Option } from '@styles/components/form';
import { Col, Row } from 'antd';
import React, { memo, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { minOrderColumns } from '../constants';
import { EditCampaignModal } from './edit-modal';

interface MinOrderFiltersProps {
    division?: string;
    transportId?: string;
}

export const Body: React.FC = memo(() => {
    const campaigns = useSelector(minimumOrderSelector);
    const modalContext = useContext(ModalContext);
    const { availableTransportTypes } = useSelector(minimumOrderSelector);
    const divisions = useSelector(divisionValues);
    const { isFetchingAllCustomers, isSuccessAllCustomers } =
        useSelector(customerSelector);
    const [filters, setFilters] = useState<MinOrderFiltersProps>({
        division: '',
        transportId: '',
    });

    const editUser = (minOrder: MinOrderResponse): void => {
        modalContext.setIsOpen(
            true,
            <EditCampaignModal
                minOrder={minOrder}
                key={`editing-min-order-${minOrder.id}`}
            />,
            {
                title: `${translate('general.editMinOrder')} - ${minOrder.id}`,
                width: '70%',
            },
        );
    };

    const onFiltersChange = (
        _: Partial<MinOrderFiltersProps>,
        values: MinOrderFiltersProps,
    ): void => {
        setFilters(values);
    };

    const addUser = (): void => {
        modalContext.setIsOpen(true, <EditCampaignModal />, {
            title: translate('general.addNewMinOrder'),
            width: '70%',
        });
    };

    return (
        <Col>
            <Table
                columns={minOrderColumns}
                rowKey={(record: MinOrderResponse) => `${record.id}`}
                dataSource={campaigns.availableMinOrders
                    .map((m) => ({
                        ...m,
                        transportType: campaigns.availableTransportTypes.find(
                            (t) =>
                                `${t.codigo}` === `${m.codigoTipoTransporte}`,
                        )?.descricao,
                    }))
                    .filter((f) => {
                        let response = true;
                        if (filters.division) {
                            response = `${f.divisao}` === `${filters.division}`;
                        }
                        if (filters.transportId) {
                            response =
                                response &&
                                f.codigoTipoTransporte === filters.transportId;
                        }
                        return response;
                    })}
                pagination={undefined}
                pointer
                onFilterChange={onFiltersChange}
                width={'100%'}
                filters={[
                    <Row style={{ width: '100%' }} justify="space-between">
                        <Col span={10}>
                            <Row>
                                <FormItem
                                    label={translate('forms.labels.division')}
                                    name={'division'}
                                >
                                    <Select
                                        placeholder={translate('general.all')}
                                        dropdownMatchSelectWidth={false}
                                        allowClear
                                    >
                                        {divisions.map((c) => {
                                            return (
                                                <Option value={c.nome}>
                                                    {c.descricao}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>
                                ,
                                <FormItem
                                    label={translate(
                                        'forms.labels.transportCode',
                                    )}
                                    name={'transportId'}
                                    style={{ minWidth: 150 }}
                                >
                                    <Select
                                        placeholder={translate('general.all')}
                                        dropdownMatchSelectWidth={false}
                                        allowClear
                                    >
                                        {availableTransportTypes.map((c) => {
                                            return (
                                                <Option value={c.codigo}>
                                                    {c.descricao}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row justify="end">
                                <ExportButton
                                    loading={
                                        isFetchingAllCustomers
                                    }
                                    onClick={addUser}
                                >
                                    {translate('forms.buttons.addNew')}
                                </ExportButton>
                            </Row>
                        </Col>
                    </Row>,
                ]}
                onRowClick={editUser}
                scroll={{ y: 'calc(100vh - 450px)' }}
            />
        </Col>
    );
});
