export interface PaymentConditionResponse {
    id: string;
    description: string;
    division: string;
    ICMSCredit: boolean;
    minOrderValue: number;
}

export class PaymentConditionResponseApi {
    codigo: string;
    descricao: string;
    divisao: string;
    creditoIcms: boolean;
    pedidoMinimo: number;

    toPaymentConditionResponse(): PaymentConditionResponse {
        return {
            id: this.codigo,
            description: this.descricao,
            ICMSCredit: this.creditoIcms,
            division: this.divisao,
            minOrderValue: this.pedidoMinimo,
        };
    }
}
