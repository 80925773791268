import React, { useEffect, useState } from 'react';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { SelectItemsHeader } from './components/select-items-header';
import { SelectItemsBody } from './components/select-items-body';
import { SelectItemsActions } from './components/select-items-actions';
import { UserBonification } from './components/user-bonification-modal';
import {
    customerSelector,
    draftsSelector,
    fetchAvailableItems,
    fetchAvailablePaymentConditions,
    fetchConvenio,
    fetchCustos,
    fetchMoedas,
    fetchMoedasSimbolos,
    fetchPaymentCondition,
    fetchPdiscqt,
    fetchSemaphores,
    orderItemsSelector,
    orderTypeSelector,
    resetBonification,
    updateMoeda,
    updateOrder,
    updateOrderState,
} from '@redux/slices/order';
import { useQuery } from '@hooks/use-query';
import { useDispatch, useSelector } from 'react-redux';
import { scheduledSelector } from '@redux/slices/order/scheduled';
import axios from '@axios';
import { OrderResponseApi } from '@models/order/response';
import { approvalMarketingSelector } from '@redux/slices/approval-marketing';
import { ApproverResponseApi } from '@models/approval/response';
import { OrderContextProvider } from './context/provider';
import { clearMinOrderState } from '@redux/slices/min-order';
import { translate } from '@components/i18n';
import { AppDispatch } from '@redux/store';
import { message } from 'antd';
import { COUNTRY } from '@config/env';
import { COUNTRIES } from 'src/enums/countries';
import { SelectItemsHeaderMarketingBonification } from './components/select-items-header-marketing-bonification';

export const MarketingBonitication: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [canFetchItems, setCanFetchItems] = useState<boolean>(false)
    const query = useQuery();
    const dispatch = useDispatch<AppDispatch>();
    const { customer } = useSelector(customerSelector);
    const { orderType } = useSelector(orderTypeSelector);
    const { drafts } = useSelector(draftsSelector);
    const { scheduled } = useSelector(scheduledSelector);

    const { availableApprovalMarketing } = useSelector(
        approvalMarketingSelector,
    );

    const {
        semaphores,
        custos,
        convenios,
        pdiscqt,
        order,
        paymentCondition,
        selectedMoeda,
        moedaSimbolos,
        moedas,
        ...selectItemsState
    } = useSelector(orderItemsSelector);

    const isDraft = query.get('draft');

    useEffect(() => {
        dispatch(fetchMoedas())
        dispatch(fetchMoedasSimbolos())
    }, [])

    const fetchPreData = async (): Promise<void> => {
        const arrayDispatch = []

        if (isDraft === 'true' && moedas.length > 0 && moedaSimbolos.length > 0) {
            const moeda = moedas.find(item => item.codigo === order?.codigoMoeda)
            const simbolo = moedaSimbolos.find(item => item.codigoMoeda === order?.codigoMoeda)

            if (moeda) {
                dispatch(updateMoeda({
                    ...moeda,
                    simbolo: simbolo?.simbolo
                }))

                arrayDispatch.push(dispatch(fetchCustos({ moeda: moeda.codigo })).unwrap())
            }
        } else if ((!isDraft || isDraft === 'false') && selectedMoeda?.codigo) {
            arrayDispatch.push(dispatch(fetchCustos({ moeda: selectedMoeda.codigo })).unwrap())
        }

        await Promise.all([
            dispatch(fetchConvenio()).unwrap(),
            dispatch(fetchPdiscqt()).unwrap(),
            dispatch(fetchSemaphores()).unwrap(),
            dispatch(fetchPaymentCondition({ data: order.customer.code })).unwrap(),
            dispatch(fetchAvailablePaymentConditions()).unwrap(),
            dispatch(resetBonification()),
            dispatch(clearMinOrderState()),
        ])
    }

    useEffect(() => {
        if (order?.customer?.code && moedas?.length && moedaSimbolos?.length) {
            fetchPreData().then(res => {
                setCanFetchItems(true)
            })
        }
    }, [order?.customer?.code, moedas?.length, moedaSimbolos?.length])


    useEffect(() => {
        if (canFetchItems) {
            getItems()
        }
    }, [canFetchItems]);


    const getItems = async (): Promise<void> => {
        const isDraft = query.get('draft');

        if (
            paymentCondition && paymentCondition.id &&
            selectItemsState.availablePaymentConditions.length > 0 &&
            !isDraft
        ) {

            if (!selectItemsState.draftRef) {
                dispatch(
                    fetchAvailableItems({
                        paymentConditionId: COUNTRY === COUNTRIES.BR ? '100' : `${paymentCondition.id}`,
                    }),
                );
            }
        } else if (
            paymentCondition && paymentCondition.id &&
            !selectItemsState.request.paymentConditionId &&
            !isDraft
        ) {
            message.info({
                content: translate('general.suggestionNotFound'),
                key: 'suggestion-not-found',
            });
            dispatch(
                fetchAvailableItems({
                    paymentConditionId: COUNTRY === COUNTRIES.BR ? '100' : `${paymentCondition.id}`,
                }),
            );
        }
    }

    useEffect(() => {


        const isDraft = query.get('draft');
        const scheduledOrder = query.get('scheduled');

        if (isDraft) {
            const ref = query.get('ref');
            const order = scheduledOrder
                ? scheduled.find((d) => `${d.draftRef}` === ref)
                : drafts.find((d) => `${d.draftRef}` === ref);

            axios
                .get<OrderResponseApi>('/pedido/' + ref)
                .then((r) => {
                    const result = Object.assign(
                        new OrderResponseApi(),
                        r.data,
                    );

                    const bonificationUser = Object.assign(
                        new ApproverResponseApi(),
                        availableApprovalMarketing.find(
                            (w) =>
                                `${w.aprovadorId}` ===
                                `${result.aprovadorMarketingUsuarioId}`,
                        ),
                    );

                    console.debug(result);
                    console.debug({
                        ...order.order,
                        ...result.toOrderRequest(),
                        userBonification:
                            bonificationUser?.toApproverResponse(),
                        tempItems: result.toOrderRequest().tempItems,
                    });

                    dispatch(
                        updateOrderState({
                            ...order,
                            order: {
                                ...order.order,
                                ...result.toOrderRequest(),
                                userBonification:
                                    bonificationUser?.toApproverResponse(),
                                tempItems: result.toOrderRequest().tempItems,
                            },
                        }),
                    );
                    setLoading(false);
                })
                .catch(console.error);
        } else {
            dispatch(updateOrder({ customer, orderType }));
            setLoading(false);
        }
    }, []);

    return (
        <OrderContextProvider>
            <Page
                title={translate('pages.newOrderBonificatoinMarketing')}
                greyBackground
                onClickGoBack
                menuIcon={<LeftOutlined />}
                loading={loading}
            >
                <UserBonification />
                <SelectItemsHeaderMarketingBonification />
                <SelectItemsBody bonification marketing />
                <SelectItemsActions bonification marketing />
            </Page>
        </OrderContextProvider>
    );
};
