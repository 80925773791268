import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import '@extensions/string';
import {
    DelegationRequest,
    DelegationRequestAPI,
    DelegationResponse,
    DelegationResponseApi,
} from '@models/delegation';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification } from '../notification';
import { RootState } from '@redux/reducers';
import { translate } from '@components/i18n';

export const fetchDelegation = createAsyncThunk<DelegationResponse[]>(
    'delegacao/fetchDelegation',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<DelegationResponseApi[]>(
                '/delegacao/all',
                {
                    params: {
                        query: '',
                    },
                },
            );
            const { data, status } = response;

            if (status === 200) {
                const result = data.map((u) =>
                    Object.assign(new DelegationResponseApi(), u),
                );

                return result.map((u) => u.toMarketingApprovalResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.errorListApprovalGroup'),
            );
        }
    },
);

export const fetchDelegationById = async (
    groupId,
): Promise<DelegationRequestAPI> => {
    try {
        const response = await axios.get<DelegationResponseApi>(
            '/delegacao/' + groupId,
        );
        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        const { response } = e;
    }
};

export const createDelegation = createAsyncThunk<
    DelegationResponse,
    ThunkCallback<DelegationRequest>
>('delegacao/createDelegation', async (request, thunkAPI) => {
    try {
        const response = await axios.post<DelegationResponseApi>(
            '/delegacao',
            new DelegationRequestAPI(request.data),
        );

        const { data, status } = response;
        if (status === 201 && data) {
            const instance: DelegationResponseApi = Object.assign(
                new DelegationResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.approvalGroupRegistered'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );
            thunkAPI.dispatch(fetchDelegation());
            return instance.toMarketingApprovalResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        console.debug('Error', e);

        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: translate('general.errorRegisteringUser'),
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});

export const removeDelegation = createAsyncThunk<number, number>(
    'delegacao/removeDelegation',
    async (groupId, thunkAPI) => {
        try {
            const response = await axios.delete('/delegacao/' + groupId);
            if (response.status === 200) {
                thunkAPI.dispatch(removeDelegationState(groupId));
                return Promise.resolve(groupId);
            }
            return 0;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;
            return thunkAPI.rejectWithValue(message);
        }
    },
);

interface IDelegationState {
    availableDelegation: DelegationResponse[];
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: IDelegationState = {
    availableDelegation: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const delegationSlice = createSlice({
    name: 'approvalMarketingSlice',
    initialState,
    reducers: {
        clearStateDelegation: (state) => {
            state = { ...initialState };

            return state;
        },
        filterDelegation: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
            return state;
        },
        removeDelegationState: (state, action: PayloadAction<number>) => {
            state.availableDelegation = state.availableDelegation.filter(
                (u) => u.id !== action.payload,
            );
            return state;
        },
    },
    extraReducers: {
        [createDelegation.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<DelegationResponse>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableDelegation = [...state.availableDelegation, payload];
            return state;
        },
        [createDelegation.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [createDelegation.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchDelegation.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchDelegation.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchDelegation.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<DelegationResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableDelegation = payload;
            return state;
        },
    },
});

export const updateDelegation = createAsyncThunk<
    DelegationResponse,
    ThunkCallback<DelegationResponse>
>('approvalMarketing/createDelegation', async (request, thunkAPI) => {
    try {
        const response = await axios.put<DelegationResponseApi>(
            '/delegacao/' + request.data.id,
            new DelegationRequestAPI(request.data),
        );

        const { data, status } = response;

        if (status === 200) {
            const instance: DelegationResponseApi = Object.assign(
                new DelegationResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.userUpdated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            thunkAPI.dispatch(fetchDelegation());

            return instance.toMarketingApprovalResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const delegationSelector = (state: RootState): IDelegationState =>
    state.delegation;

export const delegationValues = ({
    delegation,
}: RootState): DelegationResponse[] =>
    delegation.availableDelegation.filter(
        (u) =>
            u.descricao?.compare(delegation.filter) ||
            u.delegacaoMotivo?.compare(delegation.filter),
    );

export const { clearStateDelegation, filterDelegation, removeDelegationState } =
    delegationSlice.actions;

export default delegationSlice.reducer;
