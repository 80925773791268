import { Description } from '@components/texts';
import { CustomerResponse } from '@models/customer';
import { orderItemsSelector } from '@redux/slices/order';
import { Col } from 'antd';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Collapsable, SelectCustomer } from './styled';
import { useQuery } from '@hooks/use-query';
import { translate } from '@components/i18n';

const { Panel } = Collapsable;

interface ICustomerCollapseProps {
    customer: CustomerResponse;
    deliveryCustomer?: boolean;
}

export const CustomerCollapse: React.FC<ICustomerCollapseProps> = memo(
    ({ customer, deliveryCustomer = false }) => {
        const { order } = useSelector(orderItemsSelector);
        const query = useQuery();

        return (
            <Collapsable
                expandIconPosition="right"
                collapsible={!deliveryCustomer ? 'header' : 'disabled'}
                expandIcon={!deliveryCustomer ? undefined : () => <></>}
            >
                <Panel
                    header={
                        <>
                            <Col>
                                {query.get('ref') && (
                                    <Description
                                        text={`${translate('columns.order')}:`}
                                        description={query.get('ref')}
                                    />
                                )}
                                <Description
                                    text={`${translate('columns.client')}:`}
                                    description={customer?.name}
                                />
                                <Description
                                    text={`${translate('columns.cnpj')}:`}
                                    description={customer?.cnpj}
                                />
                            </Col>
                            {deliveryCustomer && (
                                <SelectCustomer>
                                    {translate('general.deliveryClient')}
                                </SelectCustomer>
                            )}
                        </>
                    }
                    key={'header'}
                >
                    <Col>
                        <Description
                            text={`${translate('columns.clientType')}:`}
                            description={order.orderType?.type.description}
                        />
                        <Description
                            text={`${translate('columns.branch')}:`}
                            description={`${order.orderType?.platform.description}`}
                        />
                        <Description
                            text={`${translate('columns.address')}:`}
                            description={customer?.address}
                        />
                    </Col>
                </Panel>
            </Collapsable>
        );
    },
);
