import { RouterPath } from '@definitions/routes';
import { TransferOrderDrafts } from '@pages/transfer-order/drafts';
import { TransferOrderHistory } from '@pages/transfer-order/history';
import { HistoryShow } from '@pages/transfer-order/history-show';
import { SelectClient } from '@pages/transfer-order/select-client';
import { SelectDistributor } from '@pages/transfer-order/select-distributor';
import { SelectItems } from '@pages/transfer-order/select-items';
import { SelectType } from '@pages/transfer-order/select-type/select-type';

const routes: RouterPath[] = [
  {
    path: '/create-transfer-order/select-distributor',
    auth: true,
    render: () => <SelectDistributor />,
    allowedRoles: ['PROMOTOR'],
  },
  {
    path: '/create-transfer-order/select-client',
    auth: true,
    render: () => <SelectClient />,
    allowedRoles: ['PROMOTOR'],
  },
  {
    path: '/create-transfer-order/select-type',
    auth: true,
    render: () => <SelectType />,
    allowedRoles: ['PROMOTOR'],
  },
  {
    path: '/create-transfer-order/select-items',
    auth: true,
    render: () => <SelectItems />,
    allowedRoles: ['PROMOTOR'],
  },
  {
    path: '/transfer-order/drafts',
    auth: true,
    render: () => <TransferOrderDrafts />,
    allowedRoles: ['PROMOTOR'],
  },
  {
    path: '/transfer-order/history',
    auth: true,
    render: () => <TransferOrderHistory />,
    allowedRoles: ['PROMOTOR'],
  },
  {
    path: '/transfer-order/show',
    auth: true,
    render: () => <HistoryShow />,
    allowedRoles: ['PROMOTOR'],
  },
];

export default routes;
