
import { orderItemsSelector, } from '@redux/slices/order';
import { Col, Row } from 'antd';
import React, { memo } from 'react';
import { CustomerCollapse } from './customer-collapse';
import { SelectItemsFilters } from './select-items-filters';
import { SolidBackground } from '@styles/components/wrapper';
import { useSelector } from 'react-redux';
import { UserInfoCollapse } from './user-info-collapse';
import '@extensions/number';
import { SelectItemsHeaderFooter } from './select-items-header-footer';

interface SelectItemsHeaderProps {
    readOnly?: boolean;
    marketing?: boolean;
    bonification?: boolean;
}

export const SelectItemsHeader: React.FC<SelectItemsHeaderProps> = memo(
    ({ readOnly = false, marketing = false, bonification = false }) => {
        const selectItemsState = useSelector(orderItemsSelector);

        return (
            <>
                <Col>
                    <SolidBackground isWhite>
                        <Row style={{ padding: 30 }}>
                            <Col span={24}>
                                <Row justify="space-between" align="top">
                                    <Col span={readOnly ? 24 : 10}>
                                        <Row gutter={20}>
                                            <Col
                                                span={
                                                    readOnly
                                                        ? 6
                                                        : selectItemsState.order
                                                            ?.addCustomerSelected
                                                            ? 11
                                                            : 14
                                                }
                                            >
                                                <CustomerCollapse
                                                    customer={
                                                        selectItemsState.order
                                                            .customer
                                                    }
                                                ></CustomerCollapse>
                                            </Col>
                                            <Col
                                                span={
                                                    readOnly
                                                        ? selectItemsState.order
                                                            ?.addCustomerSelected
                                                            ? 6
                                                            : 0
                                                        : selectItemsState.order
                                                            ?.addCustomerSelected
                                                            ? 13
                                                            : 10
                                                }
                                            >
                                                {selectItemsState.order
                                                    ?.addCustomerSelected && (
                                                        <CustomerCollapse
                                                            customer={
                                                                selectItemsState
                                                                    .order
                                                                    ?.addCustomerSelected
                                                            }
                                                            deliveryCustomer
                                                        ></CustomerCollapse>
                                                    )}
                                            </Col>
                                            {marketing &&
                                                selectItemsState.order
                                                    ?.userBonification &&
                                                selectItemsState.order
                                                    ?.userBonification.name && (
                                                    <Col span={5}>
                                                        <UserInfoCollapse
                                                            user={
                                                                selectItemsState
                                                                    .order
                                                                    ?.userBonification
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                        </Row>
                                    </Col>
                                    <Col span={14}>
                                        {!readOnly && <SelectItemsFilters />}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </SolidBackground>
                    <SelectItemsHeaderFooter bonification={bonification} />
                </Col>
            </>
        );
    },
);
