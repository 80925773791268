export const getLastYears = (count): any[] => {
    const months = [];
    const year = new Date().getFullYear();
    for (let index = 0; index < count; index++) {
        const newYear = year - index;
        months.push({
            name: newYear,
            description: newYear,
        });
    }
    return months;
};
