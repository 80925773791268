import React from 'react';
import { Typography } from 'antd';
import { Logo, Page } from '@components';
import { Card, Center, DefaultBackground } from '@styles/components/wrapper';
import { LoginForm } from './components/login-form';
import { translate } from '@components/i18n';

const Login: React.FC = () => {
    return (
        <Page title={translate('pages.login')} withHeader={false}>
            <DefaultBackground>
                <Center>
                    <Logo size={'s'} />
                    <Card
                        title={
                            <Typography.Text style={{ fontWeight: 300 }}>
                                {translate('titlePages.loginOrder')}{' '}
                                <Typography.Text strong>
                                    {translate('titlePages.loginEletronic')}
                                </Typography.Text>
                            </Typography.Text>
                        }
                    >
                        <LoginForm />
                    </Card>
                </Center>
            </DefaultBackground>
        </Page>
    );
};

export default Login;
