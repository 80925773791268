import { RouterPath } from '@definitions/routes';
import HomePage from '@pages';

const routes: RouterPath[] = [
    {
        path: '/',
        exact: true,
        render: HomePage,
        auth: true,
        allowedRoles: ['*'],
    },
];

export default routes;
