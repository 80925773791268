import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from '@axios';
import { RootState } from '@redux/reducers';
import { OrderType } from '@models/order/order-type';
import { PlatformRequest } from '@models/order/platform/request';
import { TypeResponse, TypeResponseApi } from '@models/order/type/response';
import { PlatformResponse } from '@models/order/platform/response';
import { toQueryParameters } from '@extensions/object';

export const fetchAvailableTypes = createAsyncThunk<TypeResponse[]>(
    'select-type/fetchAvailableTypes',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<TypeResponseApi[]>(
                '/tipopedido/all',
            );

            const result = response.data.map((u) =>
                Object.assign(new TypeResponseApi(), u),
            );

            return result.map((u) => u.toTypeResponse());
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchAvailablePlatforms = createAsyncThunk<
    PlatformResponse[],
    PlatformRequest
>('select-type/fetchAvailablePlatforms', async (req, thunkAPI) => {
    try {
        thunkAPI.dispatch(updateTypeRequest(req));

        const state = thunkAPI.getState() as RootState;

        const params = toQueryParameters({
            companhiaCodigo: req.companhiaCodigo
                ? req.companhiaCodigo
                : state.selectCustomer.customer.company,
        });

        const response = await axios.get<TypeResponseApi[]>(
            '/filial/byusuario?' + params,
        );

        const { status, data } = response;

        if (status === 200) {
            const result = response.data.map((u) =>
                Object.assign(new TypeResponseApi(), u),
            );

            return result.map((u) => u.toTypeResponse());
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchAllAvailablePlatforms = createAsyncThunk<PlatformResponse[]>(
    'select-type/fetchAllAvailablePlatforms',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<TypeResponseApi[]>('/filial/all');

            const { status, data } = response;

            if (status === 200) {
                const result = response.data.map((u) =>
                    Object.assign(new TypeResponseApi(), u),
                );

                return result.map((u) => u.toTypeResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchAvailablePlatformsByDivision = createAsyncThunk<
    PlatformResponse[],
    string
>('select-type/fetchAvailablePlatformsByDivision', async (req, thunkAPI) => {
    try {
        const response = await axios.get<TypeResponseApi[]>(
            '/filial/bydivisao?divisao=' + req.replace('undefined', ''),
        );

        const { status, data } = response;

        if (status === 200) {
            const result = response.data.map((u) =>
                Object.assign(new TypeResponseApi(), u),
            );

            return result.map((u) => u.toTypeResponse());
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

interface IOrderTypeSelectorState {
    orderType: OrderType;
    availableTypes: TypeResponse[];
    availablePlatforms: PlatformResponse[];
    allAvailablePlatforms: PlatformResponse[];
    availablePlatformsByDivision: PlatformResponse[];
    request: PlatformRequest;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;

    isFetchingPlatform: boolean;
    isSuccessPlatform: boolean;
    isErrorPlatform: boolean;
    errorMessagePlatform: string;
}

const initialState: IOrderTypeSelectorState = {
    allAvailablePlatforms: [],
    orderType: undefined,
    availableTypes: [],
    availablePlatforms: [],
    availablePlatformsByDivision: [],
    request: {},
    isFetching: true,
    isSuccess: false,
    isError: false,
    isFetchingPlatform: true,
    isSuccessPlatform: false,
    isErrorPlatform: false,
    errorMessage: '',
    errorMessagePlatform: '',
};

const selectTypeSlice = createSlice({
    name: 'selectTypeSlice',
    initialState,
    reducers: {
        selectType: (state, action: PayloadAction<PlatformRequest>) => {
            const date = action.payload.date;
            const platform = state.availablePlatforms.find(
                (i) => i.id === action.payload.platform,
            );
            const type = state.availableTypes.find(
                (i) => i.id === action.payload.type,
            );

            state.orderType = { date, platform, type };

            return state;
        },
        updateTypeRequest: (state, action: PayloadAction<PlatformRequest>) => {
            state.request = {
                ...action.payload,
                date: action.payload.date,
            };

            return state;
        },
        resetSelectTypeState: (state) => {
            state = { ...initialState };
            return state;
        },
        addAvailablePlatform: (state, action: PayloadAction<PlatformResponse>) => {
            state.availablePlatforms = [action.payload];
            state.isFetchingPlatform = false
            return state;
        },
    },
    extraReducers: {
        [fetchAvailableTypes.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<TypeResponse[]>,
        ) => {
            state.availableTypes = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAvailableTypes.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        [fetchAvailableTypes.pending.toString()]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },
        [fetchAllAvailablePlatforms.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<TypeResponse[]>,
        ) => {
            state.allAvailablePlatforms = payload;
            return state;
        },
        [fetchAvailablePlatforms.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PlatformResponse[]>,
        ) => {
            state.availablePlatforms = payload;
            state.isFetchingPlatform = false;
            state.isSuccessPlatform = true;
            return state;
        },
        [fetchAvailablePlatforms.rejected.toString()]: (state, action) => {
            state.isFetchingPlatform = false;
            state.isErrorPlatform = true;
            state.errorMessagePlatform = action.payload;
        },
        [fetchAvailablePlatforms.pending.toString()]: (state) => {
            state.isFetchingPlatform = true;
            state.isErrorPlatform = false;
        },
        [fetchAvailablePlatformsByDivision.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PlatformResponse[]>,
        ) => {
            state.availablePlatformsByDivision = payload;
            return state;
        },
    },
});

export const orderTypeSelector = (state: RootState): IOrderTypeSelectorState =>
    state.selectType;

export const { selectType, updateTypeRequest, resetSelectTypeState, addAvailablePlatform } =
    selectTypeSlice.actions;

export default selectTypeSlice.reducer;
