import { SearchGroup } from '@components/input';
import { IFilterSelectItems } from '@redux/slices/order';
import { Col, Row } from 'antd';
import React, { memo, useContext } from 'react';
import { ExportButton } from '@styles/components/button';
import { useDispatch } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { EditModal } from './edit-modal';
import { translate } from '@components/i18n';
import { fetchCost } from '@redux/slices/cost';

export const Header: React.FC = memo(() => {
    const dispatch = useDispatch();
    const modalContext = useContext(ModalContext);

    const filter = (filter: Partial<IFilterSelectItems>): void => {
        dispatch(
            fetchCost({
                current: 1,
                pageSize: 10,
                query: filter.filterValue,
            }),
        );
    };

    const add = (): void => {
        modalContext.setIsOpen(true, <EditModal />, {
            title: translate('general.registerItem'),
            width: '40%',
        });
    };

    return (
        <Col>
            <Row style={{ marginTop: 20 }} align="middle">
                <Col span={24}>
                    <Row justify={'space-between'} align="middle">
                        <Col span={10}>
                            <SearchGroup
                                allowClear
                                placeholder={translate(
                                    'forms.placeholders.search',
                                )}
                                onSearch={(e) => {
                                    filter({ filterValue: e });
                                }}
                            />
                        </Col>
                        <Col span={14}>
                            <Row justify={'end'}>
                                <Col span={12}></Col>
                                <Col span={5} style={{ marginRight: 10 }}></Col>
                                <Col span={5} style={{ marginRight: 10 }}>
                                    <ExportButton onClick={add}>
                                        {translate('forms.buttons.addNew')}
                                    </ExportButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
});
