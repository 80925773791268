import { ApproverModel } from '.';
import { ApprovalGroupRequest, ApprovalGroupRequestAPI } from './request';

export interface ApprovalGroupResponse extends ApprovalGroupRequest {
    id: number;
    descricao: string;
    codigoTipoPedido: string;
    divisao: string;
    aprovadores: ApproverModel[];
}

export class ApprovalGroupResponseApi implements ApprovalGroupRequestAPI {
    id: number;
    descricao: string;
    codigoTipoPedido: string;
    divisao: string;
    aprovadores?: ApproverModel[];

    toGroupApprovalResponse(): ApprovalGroupResponse {
        return {
            id: this.id,
            descricao: this.descricao,
            codigoTipoPedido: this.codigoTipoPedido,
            divisao: this.divisao,
            aprovadores: this.aprovadores,
        };
    }
}
