import React, { useContext, useState } from 'react';
import { Button, Col, Input, message, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { userValuesSelect } from '@redux/slices/users';
import { ParameterResponse } from '@models/parameter/response';
import { Title } from '@styles/components/text';
import { fetchParameterValues, updateParameter } from '@redux/slices/config';
import { translate } from '@components/i18n';

interface EditParameterModalProps {
    data?: ParameterResponse;
}

export const EditParameterModal: React.FC<EditParameterModalProps> = ({
    data,
}) => {
    const userList = useSelector(userValuesSelect);
    const dispatch = useDispatch();

    const [editParameterState, setEditParameterState] =
        useState<ParameterResponse>({
            ...data,
        });

    const modalContext = useContext(ModalContext);

    const handleUpdateParameter = (): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingParameter'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateParameter({
                data: {
                    id: data.id,
                    valor: editParameterState.valor,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    dispatch(fetchParameterValues());
                    modalContext.handleClose();
                },
            }),
        );
    };

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        setEditParameterState((prev) => ({ ...prev, valor: `${value}` }));
    };

    const changeSelectValue = (value: number): void => {
        setEditParameterState((prev) => ({ ...prev, valor: `${value}` }));
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <Title as={'h4'}>{data.descricao}</Title>
                {data.id === 'APROVADOR_ICMS' && (
                    <div>
                        <Select
                            style={{
                                width: '100%',
                            }}
                            placeholder={translate(
                                'forms.placeholders.chooseApprover',
                            )}
                            dropdownMatchSelectWidth={false}
                            showSearch
                            onChange={changeSelectValue}
                            value={Number(editParameterState.valor)}
                            filterOption={(input, option) => {
                                if (!option || !option.children) return true;
                                return (
                                    option?.children
                                        .toString()
                                        .toLowerCase()
                                        .indexOf(
                                            input.toString().toLowerCase(),
                                        ) >= 0
                                );
                            }}
                        >
                            {userList
                                ?.sort((a, b) =>
                                    a?.name?.localeCompare(b?.name),
                                )
                                .map((c) => (
                                    <Select.Option
                                        key={c.id + '' + c.name}
                                        value={c.id}
                                    >
                                        {c.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                )}
                {data.id !== 'APROVADOR_ICMS' && (
                    <Input
                        placeholder={translate('forms.placeholders.writeValue')}
                        allowClear
                        value={editParameterState.valor}
                        onChange={onChangeValue}
                        autoComplete="new-password"
                        type={data.id === 'EMAILS_BUILD' ? 'text' : 'number'}
                    />
                )}
                <Row justify="end" style={{ marginTop: 10 }}>
                    <Col>
                        <Button type="primary" onClick={handleUpdateParameter}>
                            {translate('forms.buttons.update')}
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col span={1} />
        </Row>
    );
};
