import React, { useEffect, useState } from 'react';

import { Input } from '@components';
import { InputRange } from '@components/input';
import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Col, Form, Row } from 'antd';
import {
    approvalSelector,
    ApprovalTable,
    approvalValuesDashboard,
    fetchDashboardApprovals,
} from '@redux/slices/order';
import { columns, filterComparison, filterTypes } from '../constants';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { userApprovalValuesSelect } from '@redux/slices/users';
import { authSelector } from '@redux/slices/auth';
import { translate } from '@components/i18n';
import { List } from '@components/list';

export const OrdersBody: React.FC = () => {
    const approvalTableValues = useSelector(approvalValuesDashboard);
    const { currentUser } = useSelector(authSelector);
    const approvalState = useSelector(approvalSelector);
    const approvers = useSelector(userApprovalValuesSelect);
    const [queryState, setQueryState] = useState<string>('');
    const history = useHistory();
    const dispatch = useDispatch();
    const [checkState, setCheckState] = useState<string[]>([]);
    const [form] = Form.useForm();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        let checkValues = { ...approvalState.request };

        if (changedValues['checkbox-group']) {
            const changeValue = changedValues['checkbox-group'].filter(
                (c) => !checkState.includes(c),
            );
            console.debug(changeValue);
            setCheckState(changeValue);
            form.setFieldsValue({ 'checkbox-group': changeValue });
            checkValues = {
                ...checkValues,
                waitingApproval: changeValue.includes('waitingApproval'),
                pending: changeValue.includes('pending'),
            };
        }

        if (values['datas']) {
            const range = values['datas'];
            checkValues = {
                ...checkValues,
                dataInicio: range[0].format('DD/MM/YYYY'),
                dataFim: range[1].format('DD/MM/YYYY'),
            };
        } else {
            checkValues = {
                ...checkValues,
                dataInicio: undefined,
                dataFim: undefined,
            };
        }

        dispatch(
            fetchDashboardApprovals({
                ...checkValues,
                ...values,
                filterValue: queryState,
            }),
        );
    };

    const search = (): void => {
        dispatch(
            fetchDashboardApprovals({
                ...approvalState.request,
                filterValue: queryState,
            }),
        );
    };

    useEffect(() => {
        dispatch(
            fetchDashboardApprovals({
                pageSize: 10,
                current: 1,
                filterValue: '',
                filterBy: '',
            }),
        );
    }, []);

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        _: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchDashboardApprovals({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    const onSearch = (value: string): void => {
        setQueryState(value);
    };

    const onSelect = (rowKey: ApprovalTable): void => {
        if (
            approvalState.request.waitingApproval ||
            approvalState.request.pending
        )
            history.push(`/approval-order/${rowKey.id}?readOnly=true`);
        else history.push(`/approval-order/${rowKey.id}`);
    };

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={0} lg={{span: 23}}>
                    <Table
                        columns={columns}
                        rowKey={(record: ApprovalTable) => `${record.id}`}
                        pointer
                        dataSource={approvalTableValues}
                        pagination={approvalState.request}
                        isLoading={false}
                        onRowClick={onSelect}
                        onChange={loadData}
                        onFilterChange={onFiltersChange}
                        width="100%"
                        form={form}
                        filters={[
                            <FormItem
                                label={translate('general.approvers')}
                                name={'aprovadorId'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {[
                                        {
                                            id: '',
                                            name: translate(
                                                'general.iAndMyDelegations',
                                            ),
                                        },
                                        {
                                            id: currentUser.id,
                                            name: translate('general.i'),
                                        },
                                        ...approvers.filter(
                                            (c) => c.id !== currentUser.id,
                                        ),
                                    ].map((f) => {
                                        return (
                                            <Option key={f.id} value={f.id}>
                                                {f.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.date')}
                                name={'datas'}
                            >
                                <InputRange
                                    format="DD-MM-YYYY"
                                    placeholder={[
                                        translate(
                                            'forms.placeholders.startDate',
                                        ),
                                        translate('forms.placeholders.endDate'),
                                    ]}
                                    light
                                />
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.selection')}
                                name={'filterBy'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {filterTypes.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.type')}
                                name={'comparisonType'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {filterComparison.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={search}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                    onChange={(e) => onSearch(e.target.value)}
                                />
                            </Form.Item>,
                            <FormItem
                                name="checkbox-group"
                                label={`${translate(
                                    'forms.labels.filterBySituation',
                                )}:`}
                            >
                                <Checkbox.Group
                                    style={{ width: '100%' }}
                                    value={checkState}
                                >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox
                                                value="pending"
                                                style={{ lineHeight: '32px' }}
                                            >
                                                {translate(
                                                    'general.myPendingApprovals',
                                                )}
                                            </Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox
                                                value="waitingApproval"
                                                style={{ lineHeight: '32px' }}
                                            >
                                                {translate(
                                                    'general.waitingApproval',
                                                )}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </FormItem>,
                        ]}
                    />
                </Col>
                <Col span={23} lg={{span: 0}}>
                    <List
                        columns={columns}
                        rowKey={(record: ApprovalTable) => `${record.id}`}
                        dataSource={approvalTableValues}
                        pagination={approvalState.request}
                        isLoading={false}
                        onClick={onSelect}
                        onChange={loadData}
                        onFilterChange={onFiltersChange}
                        width="100%"
                        form={form}
                        filters={[
                            <Form.Item
                                label={translate('general.approvers')}
                                name={'aprovadorId'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {[
                                        {
                                            id: '',
                                            name: translate(
                                                'general.iAndMyDelegations',
                                            ),
                                        },
                                        {
                                            id: currentUser.id,
                                            name: translate('general.i'),
                                        },
                                        ...approvers.filter(
                                            (c) => c.id !== currentUser.id,
                                        ),
                                    ].map((f) => {
                                        return (
                                            <Option key={f.id} value={f.id}>
                                                {f.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>,
                            <FormItem
                                label={translate('forms.labels.date')}
                                name={'datas'}
                            >
                                <InputRange
                                    format="DD-MM-YYYY"
                                    placeholder={[
                                        translate(
                                            'forms.placeholders.startDate',
                                        ),
                                        translate('forms.placeholders.endDate'),
                                    ]}
                                    light
                                />
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.selection')}
                                name={'filterBy'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {filterTypes.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.type')}
                                name={'comparisonType'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {filterComparison.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }} style={{maxWidth: '100%', marginTop: '1em'}}>
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={search}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                    onChange={(e) => onSearch(e.target.value)}
                                />
                            </Form.Item>,
                            <FormItem
                                name="checkbox-group"
                                label={`${translate(
                                    'forms.labels.filterBySituation',
                                )}:`}
                            >
                                <Checkbox.Group
                                    style={{ width: '100%' }}
                                    value={checkState}
                                >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox
                                                value="pending"
                                                style={{ lineHeight: '32px' }}
                                            >
                                                {translate(
                                                    'general.myPendingApprovals',
                                                )}
                                            </Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox
                                                value="waitingApproval"
                                                style={{ lineHeight: '32px' }}
                                            >
                                                {translate(
                                                    'general.waitingApproval',
                                                )}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </FormItem>,
                        ]}
                    />
                </Col>
            </Row>
        </ShadowContainer>
    );
};
