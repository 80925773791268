import React, { useEffect } from 'react';
import { ROLES_ENUM } from 'src/enums/user';
import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { CardTable } from '@components/table/styled';
import { translate } from '@components/i18n';
import { fetchUsersByRoles } from '@redux/slices/users';
import { authSelector } from '@redux/slices/auth';
import { Body } from './components/body';

export const TransferClients: React.FC = () => {
    const dispatch = useDispatch();
    const { isAdmin } = useSelector(authSelector)

    useEffect(() => {
        if (isAdmin) {
            dispatch(fetchUsersByRoles({
                data: {
                    roles: [ROLES_ENUM.PROMOTOR]
                }
            }))
        }
    }, []);

    return (
        <Page
            title={translate('pages.transferClients')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Col span={22} style={{ alignSelf: 'center' }}>
                <h2 style={{ marginTop: '15px' }}>
                    {translate('titlePages.transferClients')}
                </h2>
                <CardTable>
                    <Body />
                </CardTable>
            </Col>
        </Page>
    );
};
