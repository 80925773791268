enum Comparison {
    EQ = 'EQ',
    NEQ = 'NEQ',
    GT = 'GT',
    LT = 'LT',
    CONTAINS = 'CONTAINS',
    NOT_CONTAINS = 'NOT_CONTAINS',
    BEGINS_WITH = 'BEGINS_WITH',
}

export default Comparison;
