/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EditableField } from '@models/editable-field';
import { translate } from '@components/i18n';

export const columns = [
    {
        title: translate('columns.code'),
        dataIndex: 'codigoPrazo',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.numberItem'),
        dataIndex: 'numeroItem',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: true,
        width: '10%',
    },
];

export function editableFields(): EditableField[] {
    return [
        {
            name: 'codigoPrazo',
            label: translate('forms.labels.code'),
            type: 'text',
            span: 12,
        },
        {
            name: 'numeroItem',
            label: translate('columns.numberItem'),
            type: 'text',
            span: 12,
        },
        {
            name: 'divisao',
            label: translate('forms.labels.division'),
            type: 'text',
            span: 12,
        },
    ];
}
