//@ts-ignore
import orderReducer from './order';
import draftReducer from './draft';
import historyReducer from './history';

const reducers = {
    transferOrder: orderReducer,
    transferDraftOrder: draftReducer,
    transferHistoryOrder: historyReducer
};

export default reducers

