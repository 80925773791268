import axios from '@axios';
import { DraftResponseApi } from '@models/order/drafs/response';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FinancialDiscountContext, IFinancialDiscountContext } from './context';
import { CommentResponseApi } from '@models/comments/response';
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { useQuery as useQueryFn } from '@hooks/use-query';
import { CampaignResponse, CampaignResponseApi } from '@models/campaign';
import moment from 'moment';
import { RouteState } from '..';
import { IOrderItemsSelectorState } from '@redux/slices/order';
import { translate } from '@components/i18n';

interface Props {
    state: RouteState;
    children: React.ReactNode;
}

export const FinancialDiscountContextProvider: React.FC<Props> = ({
    state,
    children,
}) => {
    const queryClient = useQueryClient();
    const query = useQueryFn();
    const [internalState, setInternalState] = useState<RouteState>(state);
    const [campaigns, setCampaigns] = useState<CampaignResponse[]>([]);

    const {
        data,
        isFetched,
        isFetching,
        refetch: refetchOrder,
    } = useQuery('order', async () => {
        const orders: Partial<IOrderItemsSelectorState>[] = [];
        for (const order of state.pedidos) {
            const result = await axios.get<DraftResponseApi>(
                `/pedido/${order}`,
            );

            const data = Object.assign(new DraftResponseApi(), result.data);

            orders.push(data.toOrderState());
        }

        return orders;
    });

    useEffect(() => {
        if (data) {
            const campanhaIds = [];
            for (const order of data) {
                if (order.order.campanhaIds) {
                    campanhaIds.push(order.order.campanhaIds);
                }
            }

            if (campanhaIds.length > 0) {
                const set = new Set(campanhaIds);
                if (set.size > 0) loadCampaigns([...set]);
            }
        }
    }, [data]);

    const loadCampaigns = async (ids: number[]): Promise<void> => {
        const campanhas = [];
        for (const campaign of ids) {
            const campanha = await axios.get<CampaignResponseApi>(
                `/campanha/${campaign}`,
            );

            const { data } = campanha;
            const result = Object.assign(new CampaignResponseApi(), data);

            campanhas.push(result.toCampaignResponse());
        }

        console.debug(campanhas);
        setCampaigns(campanhas);
    };

    const refuseOrder = ({ text }): Promise<AxiosResponse<void>> => {
        return axios.post<void>('/descontofinanceiro/reprovacao', {
            descontoFinanceiroId: state.id,
            comentario: text,
        });
    };

    const { mutate: refuseMutation } = useMutation(refuseOrder, {
        onSuccess: (data) => {
            console.debug(data);
            message.success(translate('general.refusedOrder'));
            setTimeout(() => {
                location.replace('/');
            }, 1500);
        },
        onError: () => {
            message.error(translate('general.errorRefuseOrder'));
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        },
    });

    const acceptOrder = ({ text }): Promise<AxiosResponse<void>> => {
        return axios.post<void>('/descontofinanceiro/aprovacao', {
            descontoFinanceiroId: state.id,
            comentario: text,
        });
    };

    const { mutate: acceptMutation } = useMutation(acceptOrder, {
        onSuccess: (data) => {
            console.debug(data);
            message.success(translate('general.acceptOrder'));
            setTimeout(() => {
                location.replace('/');
            }, 1500);
        },
        onError: () => {
            message.error(translate('general.errorAcceptOrder'));
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        },
    });

    const requestDiscount = (): Promise<AxiosResponse<void>> => {
        return axios.post<void>('/descontofinanceiro', {
            codigoCliente: Number(internalState.codigoCliente),
            pedidosIds: internalState.pedidos.map(Number),
            desconto: Number(internalState.desconto),
            comentario: internalState.comentario,
        });
    };

    const { mutate: requestDiscountMutation } = useMutation(requestDiscount, {
        onSuccess: (data) => {
            console.debug(data);
            message.success(translate('general.solicitationDiscount'));
            setTimeout(() => {
                location.replace('/');
            }, 1500);
        },
        onError: () => {
            message.error(translate('general.errorSolicitationDiscount'));
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        },
    });

    useEffect(() => {
        refetchOrder();
    }, [state]);

    const setDiscount = async (discount: string): Promise<void> => {
        setInternalState((state) => ({
            ...state,
            desconto: discount,
        }));
    };

    const addComment = async (comment: string): Promise<void> => {
        setInternalState((state) => ({
            ...state,
            comentario: comment,
        }));
    };

    const removeComment = async (): Promise<void> => {
        setInternalState((state) => ({
            ...state,
            comentario: '',
        }));
    };

    const { data: comments } = useQuery('comments', async () => {
        const result = await axios.get<CommentResponseApi[]>(
            `/descontofinanceiro/${state.id}/comentario`,
        );

        const data = result.data.map((c) =>
            Object.assign(new CommentResponseApi(), c),
        );

        return data
            .map((c) => c.toCommentResponse())
            .sort((a, b) => moment(a.date).toDate().valueOf() - moment(b.date).toDate().valueOf());
    });

    const createCommentFn = ({ text, item }): Promise<AxiosResponse<void>> => {
        return axios.post<void>(`/descontofinanceiro/${state.id}/comentario`, {
            descontoFinanceiroId: state.id,
            texto: text,
            usuarioId: 1,
            numeroItem: item,
        });
    };

    const removeCommentFn = ({ commentId }): Promise<AxiosResponse<void>> => {
        return axios.delete<void>(
            `/descontofinanceiro/${state.id}/comentario/${commentId}`,
        );
    };

    const { mutate: createMutation } = useMutation(createCommentFn, {
        onSuccess: (data) => {
            console.debug(data);
            message.success(translate('general.commentSave'));
            return queryClient.invalidateQueries(['comments']);
        },
        onError: () => {
            message.error(translate('general.errorComment'));
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        },
    });

    const { mutate: removeMutation } = useMutation(removeCommentFn, {
        onSuccess: (data) => {
            console.debug(data);
            message.success(translate('general.deleteComment'));
            return queryClient.invalidateQueries(['comments']);
        },
        onError: () => {
            message.error(translate('general.errorComment'));
        },
        onSettled: () => {
            queryClient.invalidateQueries('create');
        },
    });

    const contextState: IFinancialDiscountContext = {
        orders: !data ? [] : data?.map((c) => c.order),
        loading: isFetching,
        success: isFetched,
        currentState: internalState,
        setDiscount: setDiscount,
        comment: internalState?.comentario,
        comments: comments,
        addCommentFn: createMutation,
        removeCommentFn: removeMutation,
        addComment: addComment,
        refuseOrder: refuseMutation,
        acceptOrder: acceptMutation,
        requestDiscount: requestDiscountMutation,
        readOnly: !!state?.id || !!query.get('readOnly'),
        removeComment: removeComment,
        campaigns: campaigns,
        approvers: !state?.aprovadores ? [] : state.aprovadores,
    };

    return (
        <FinancialDiscountContext.Provider value={contextState}>
            {children}
        </FinancialDiscountContext.Provider>
    );
};
