import React, { useContext } from 'react';

import { ShadowContainer } from '@styles/components/wrapper';
import { Avatar, Row, Divider } from 'antd';
import { Text } from '@styles/components/text';
import '@extensions/number';
import { MessageOutlined } from '@ant-design/icons';
import { FinancialDiscountContext } from '../context/context';
import TextArea from 'antd/lib/input/TextArea';
import { InternalComment } from './comments_approval';
import { translate } from '@components/i18n';

interface OrderInfoProps {
    comments: string;
    item?: string;
    orderId?: number;
    addComment?: (comment: string) => void;
    removeComment?: () => void;
    readOnly?: boolean;
}

export const Comments: React.FC<OrderInfoProps> = ({ comments }) => {
    const addCommentFn = useContext(FinancialDiscountContext).addComment;
    const readOnlyD = useContext(FinancialDiscountContext).readOnly;
    const history = useContext(FinancialDiscountContext).currentState?.history;

    return (
        <ShadowContainer style={{ marginTop: 30, marginLeft: 20, padding: 20 }}>
            <Row align={'middle'}>
                <Avatar
                    size="large"
                    style={{
                        backgroundColor: '#001B42',
                        verticalAlign: 'middle',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        marginRight: 10,
                    }}
                    icon={
                        <MessageOutlined
                            style={{ color: '#fff', fontSize: 16 }}
                        />
                    }
                />
                <Text fontWeight={'bold'} blue fontSize={'1.2em'}>
                    {translate('general.comentary')}
                </Text>
            </Row>
            <Divider style={{ margin: 0, marginBottom: 5, marginTop: 10 }} />
            {!readOnlyD && !history && (
                <TextArea
                    readOnly={readOnlyD}
                    defaultValue={comments}
                    onBlur={(e) => {
                        addCommentFn(e.target.value);
                    }}
                />
            )}
            {readOnlyD && <InternalComment />}
        </ShadowContainer>
    );
};
