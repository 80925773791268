import '@extensions/string';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { translate } from '@components/i18n';
import { RootState } from '@redux/reducers';
import { Distributor } from '@models/distributor';

export const fetchDistributors = 
    createAsyncThunk<Distributor.Response[], Distributor.Params | void>('distributor/list', async (request, thunkAPI) => {
    try {
        const response = await axios.get<Distributor.ResposeApi[]>(`/cliente/distribuidores`, {params: request});

        const { data, status } = response;

        if (status === 200) {
            return data?.map(item => Object.assign(new Distributor.ResposeApi(), item).fromJSON())
        } else {
            return thunkAPI.rejectWithValue('');
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(translate('general.erroListingDistributors'));
    }
});

export const fetchDistributor = 
    createAsyncThunk<Distributor.Response | null, number | void>('distributor/list', async (codigo, thunkAPI) => {
    try {
        const response = await axios.get<Distributor.ResposeApi[]>(`/cliente/distribuidores/${codigo}`);

        const { data, status } = response;

        if (status === 200) {
            if(!data){
                return null
            }
            return Object.assign(new Distributor.ResposeApi(), data).fromJSON()
        } else {
            return thunkAPI.rejectWithValue('');
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(translate('general.erroListingDistributors'));
    }
});


interface IDistributorState {
    available: Distributor.Response[];
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: IDistributorState = {
    available: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const distributorSlice = createSlice({
    name: 'distributor-slice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };
            return state;
        },
        deleteTransferItemFromState: (state, action: PayloadAction<number>) => {
            state.available = state.available.filter((u) => u.id !== action.payload);
            return state;
        },
    },
    extraReducers: {
        [fetchDistributors.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchDistributors.rejected.toString()]: (state, action) => {
            state.errorMessage = action.payload;
            state.isFetching = false;
            state.isError = true;
            return state;
        },
        [fetchDistributors.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Distributor.Response[]>,
        ) => {
            state.available = payload;
            state.isSuccess = true;
            state.isFetching = false;
            return state;
        },
    },
});

export const { clearState, deleteTransferItemFromState } = distributorSlice.actions;

export const distributorSelector = (state: RootState): IDistributorState => state.distributor;

export default distributorSlice.reducer;
