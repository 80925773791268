import { clearStateCampaign } from '@redux/slices/campaign';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OrderContext, IOrderContext } from './context';

const useStateWithCallback = (initialState) => {
    const [state, setState] = useState(initialState);
    const callbackRef = useRef(() => undefined);

    const setStateCB = (newState, callback) => {
        callbackRef.current = callback;
        setState(newState);
    };

    useEffect(() => {
        callbackRef.current?.();
    }, [state]);

    return [state, setStateCB];
};

export const OrderContextProvider: React.FC = ({ children }) => {
    const [canLeave, setCanLeave] = useStateWithCallback(false);
    const dispatch = useDispatch();

    const changeState = (callback) => {
        // dispatch(clearStateCampaign());
        setCanLeave(true, callback);
    };

    const state: IOrderContext = {
        canLeave,
        setCanLeave: changeState,
    };

    return (
        <OrderContext.Provider value={state}>{children}</OrderContext.Provider>
    );
};
