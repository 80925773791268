import { CampaignResponse, Conditions, Result } from '@models/campaign';

export interface ItemResponse {
    id: number;
    description: string;
    code: string;
    quantityPresentation: number;
    totalQuantity: number;
    cost: number;
    costModifiable: number;
    promotion: string | number;
    discount: number;
    total: number;
    quantity: number;
    order?: string;
    number: string;

    pais: string;

    ipi?: boolean;
    controlado: boolean;
    limitado: string;
    convenio100: boolean;
    ultimoValorFaturado: number;
    dataUltimoFaturado: string;
    precoBase: number;
    pTRADE: number;
    pDISC: number;
    pANTECI: number;
    pESC: number;
    pTAX: number;
    etrade: string;
    kit: string;
    compkit: number;
    promocao: unknown;
    valorIpi: number | string;
    subtotalipi?: number;
    custo?: number;
    codigoLinha?: number;
    codigoTipoTransporte?: string;

    suframa?: number;
    valorTrade?: number;
    valorsemdesconto?: number;
    valorSemPTAX?: number;
    valor?: number;
    valorexibicao?: number | string;
    subtotal?: number;
    subtotalsemipi?: number;

    semaforo?: string;
    semaforoNome?: string;

    defaultKey?: number;
    orderType?: string;
    apresentacao?: string;

    campaign?: CampaignResponse;
    campaignIndex?: number;
    disabled?: boolean;
    condition?: Conditions;
    result?: Result;
    min?: number;
    bonification?: boolean;

    precoUnitarioSemCampanha?: number;
    precoUnitarioSemCampanhaSemDesconto?: number;
    precoUnitarioSemDesconto?: number;
    campanhaId?: number;
}

export class ItemResponseApi {
    id: number;
    numero: string;
    descricao: string;
    divisao: string;
    apresentacao: string;
    codigoFilial: number;
    kit: string;
    compkit: number;
    ipi: boolean;
    etrade: string;
    multiplo: number;
    valorIpi: string;
    ativo: boolean;
    controlado: boolean;
    limitado: string;
    convenio100: boolean;
    ultimoValorFaturado: number;
    dataUltimoFaturado: string;
    pais: string;
    precoBase: number;
    pTRADE: number;
    pDISC: number;
    pANTECI: number;
    pPROMO: string;
    pESC: number;
    pTAX: number;
    codigoLinha?: number;
    codigoTipoTransporte?: string;

    toItemResponse(request?: Partial<ItemResponse>): ItemResponse {
        return {
            id: this.id,
            description: this.descricao,
            pais: this.pais,
            code: this.numero,
            quantityPresentation: this.multiplo ?? 1,
            totalQuantity: 0,
            cost: this.precoBase,
            costModifiable: this.precoBase,
            promotion: this.pPROMO,
            discount: request ? request.discount : 0,
            total: request ? request.totalQuantity : 0,
            quantity: request ? request.quantity : 0,
            order: request ? request.order : undefined,
            number: this.numero,
            controlado: this.controlado,
            limitado: this.limitado,
            convenio100: this.convenio100,
            ultimoValorFaturado: this.ultimoValorFaturado,
            dataUltimoFaturado: this.dataUltimoFaturado,
            precoBase: this.precoBase,
            pTRADE: this.pTRADE,
            pDISC: this.pDISC,
            pANTECI: this.pANTECI,
            pESC: this.pESC,
            pTAX: this.pTAX,
            etrade: this.etrade,
            kit: this.kit,
            compkit: this.compkit,
            promocao: this.pPROMO,
            ipi: this.ipi,
            valorIpi: this.valorIpi,
            apresentacao: this.apresentacao,
            codigoLinha: this.codigoLinha,
            codigoTipoTransporte: this.codigoTipoTransporte,
        };
    }
}

export interface PaginatedResponseApi<T> {
    content: T[];
    pageable: Pageable;
    totalPages: number;
    totalElements: number;
    last: boolean;
    numberOfElements: number;
    first: boolean;
    sort: Sort;
    size: number;
    number: number;
    empty: boolean;
}

export interface Pageable {
    sort: Sort;
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
}

export interface Sort {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
}

export interface OrderResponse {
    pedidoId: number;
}
