import React, { useEffect, useState } from 'react';

import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Popconfirm, Row } from 'antd';

import { columns } from '../constants';
import { Table } from '@components/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from '@styles/components/button';
import {
    fetchFinancialDiscount,
    FinancialDiscountTable,
    ordersToSelectSelector,
    ordersToSelectValues,
} from '@redux/slices/order/financial-discounts';
import { Title } from '@styles/components/text';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { orderItemsSelector } from '@redux/slices/order';

interface ParamsProps {
    customerId?: string;
}

export const SelectOrdersBody: React.FC = () => {
    const { customerId } = useParams<ParamsProps>();
    const history = useHistory();
    const historyTableState = useSelector(ordersToSelectValues);
    const historyState = useSelector(ordersToSelectSelector);
    const {selectedMoeda} = useSelector(orderItemsSelector);
    const [selectedRows, setSelectedRows] = useState<FinancialDiscountTable[]>(
        [],
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchFinancialDiscount({
                pageSize: 10,
                current: 1,
                filterValue: '',
                filterBy: '',
                company: customerId,
                categoria: ''
            }),
        );
    }, []);

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchFinancialDiscount({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    const send = (): void => {
        history.push(`/financial-discounts/request-discount-orders/`, {
            pedidos: selectedRows.map((r) => r.id),
            codigoCliente: customerId,
            desconto: 0,
        });
    };

    const rowSelection = {
        onChange: (_: React.Key[], selectedRows: FinancialDiscountTable[]) => {
            setSelectedRows(selectedRows);
        },
    };

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={23}>
                    <Table
                        columns={columns}
                        rowKey={(record: FinancialDiscountTable) =>
                            `${record.id}`
                        }
                        pointer
                        dataSource={historyTableState}
                        pagination={historyState.request}
                        isLoading={false}
                        rowSelection={{
                            ...rowSelection,
                            columnWidth: '20px',
                            fixed: true,
                        }}
                        onChange={loadData}
                        footerObject={
                            <div>
                                {selectedRows?.length === 0 && <div />}
                                {selectedRows?.length > 0 && (
                                    <Row
                                        justify={'end'}
                                        gutter={10}
                                        style={{ marginTop: 10 }}
                                    >
                                        <Title
                                            marginRight={'5px'}
                                            level={4}
                                            blue
                                        >
                                            {translate('general.orderAmout')}:{' '}
                                        </Title>
                                        <Title
                                            blue
                                            marginTop={'0px !important'}
                                            marginRight={'40px'}
                                            level={4}
                                        >
                                            <Row align="middle" gutter={10}>
                                                {formatMoney(
                                                    selectedMoeda?.simbolo,
                                                    selectedRows.reduce(
                                                        (acc, cur) =>
                                                            acc + cur.value,
                                                        0,
                                                    ),
                                                )}
                                            </Row>
                                        </Title>
                                    </Row>
                                )}
                            </div>
                        }
                    />
                </Col>
            </Row>
            {selectedRows.length > 0 && (
                <Row justify="center" style={{ paddingBottom: 20 }}>
                    <Col span={23}>
                        <Row justify={'end'} gutter={10}>
                            <Col>
                                {!selectedRows.every(
                                    (r) =>
                                        r.bonification ||
                                        r.hasBonification ===
                                            translate(
                                                'general.yes',
                                            ).toLocaleUpperCase(),
                                ) && (
                                    <Popconfirm
                                        title={translate(
                                            'general.selectingDiscountYouSure',
                                        )}
                                        okText={translate('general.yes')}
                                        cancelText={translate('general.no')}
                                        onConfirm={send}
                                    >
                                        <Button type="primary" height={40}>
                                            {translate(
                                                'general.requestDiscount',
                                            )}{' '}
                                            - {selectedRows.length}{' '}
                                            {translate('general.orders')}
                                        </Button>
                                    </Popconfirm>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </ShadowContainer>
    );
};
