import React from 'react';

import { Page } from '@components';
import { Col, Row } from 'antd';
import { Title } from '@styles/components/text';
import { RequestedDiscountBody } from './components/requested-discount-body';
import { translate } from '@components/i18n';

export const OrdersHistory: React.FC = () => {
    return (
        <Page
            title={translate('pages.historyOfDiscountSolicitations')}
            greyBackground
            withPadding
        >
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate(
                                'titlePages.historyOfDiscountSolicitations',
                            )}
                        </Title>
                    </Row>
                    <RequestedDiscountBody />
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
