import React from 'react';
import { Input, InputProps, Select } from 'antd';
import { SearchGroupWrapper } from '../styled';
import { SelectValue } from 'antd/lib/select';

const { Option } = Select;

interface SearchGroupProps extends InputProps {
    options?: {
        label: string;
        value: string;
    }[];
    defaultSelect?: string;
    onChangeSelect?: (value: string) => void;
    onSearch: (value: string) => void;
}

export const SearchGroup: React.FC<SearchGroupProps> = (props) => {
    const { options, onChangeSelect, defaultSelect, ...rest } = props;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onChange = (value: SelectValue, _option: unknown): void => {
        onChangeSelect(value as string);
    };

    return (
        <SearchGroupWrapper>
            {options && (
                <Select
                    defaultValue={
                        defaultSelect ? defaultSelect : options[0].value
                    }
                    onChange={onChange}
                >
                    {options.map((option) => (
                        <Option value={option.value}>{option.label}</Option>
                    ))}
                </Select>
            )}
            <Input.Search style={{ width: '60%' }} {...rest} />
        </SearchGroupWrapper>
    );
};
