import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { Description } from '@components/texts';
import { translate } from '@components/i18n';
import { Collapsable } from '../../order/components/styled';
import { transferOrderSelector } from '../../../redux/slices/transfer-order/order';

const { Panel } = Collapsable;


export const DistributorCollapse: React.FC = memo(() => {
    const transferOrder = useSelector(transferOrderSelector);

    return (
        <Collapsable
            expandIconPosition="right"
            collapsible="header"
        >
            <Panel
                header={
                    <>
                        <Col>
                            <Description
                                text={`${translate('columns.distributor')}:`}
                                description={transferOrder?.distributor ? `${transferOrder?.distributor?.codigo || ''} - ${transferOrder?.distributor?.nome}` : ''}
                            />
                            <Description
                                text={`${translate('columns.cnpj')}:`}
                                description={transferOrder?.distributor?.cnpj}
                            />
                        </Col>
                    </>
                }
                key='header'
            >
                <Col>
                    <Description
                        text={`${translate('columns.distributorType')}:`}
                        description={transferOrder?.distributor?.tipoDescricao}
                    />
                    <Description
                        text={`${translate('columns.branch')}:`}
                        description={transferOrder?.filial?.description}
                    />
                    <Description
                        text={`${translate('columns.address')}:`}
                        description={transferOrder?.distributor?.endereco1}
                    />
                    <Description
                        text={`${translate('columns.neighborhood')}:`}
                        description={transferOrder?.distributor?.bairro}
                    />
                    <Description
                        text={`${translate('columns.city')}:`}
                        description={transferOrder?.distributor?.cidade}
                    />
                    <Description
                        text={`${translate('columns.state')}:`}
                        description={transferOrder?.distributor?.zona}
                    />
                </Col>
            </Panel>
        </Collapsable>
    );
},
);
