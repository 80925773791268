import { translate } from '@components/i18n';
import { PendingOrderResponse } from '@models/report';
import { orderItemsSelector } from '@redux/slices/order';
import { formatMoney } from '@utils/money';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Comparison from 'src/enums/comparison';

export const columns = [
    {
        title: translate('columns.order'),
        dataIndex: 'order',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.seller'),
        dataIndex: 'seller',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.date'),
        dataIndex: 'date',
        sorter: true,
        width: '10%',
        render: (_, record: PendingOrderResponse): React.ReactNode => {
            return moment(record.date).format('DD/MM/YYYY')
        },
    },
    {
        title: translate('columns.division'),
        dataIndex: 'division',
        sorter: true,
        width: '5%',
    },
    {
        title: translate('columns.client'),
        dataIndex: 'customer',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.clientName'),
        dataIndex: 'customerName',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'type',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.deadline'),
        dataIndex: 'deadline',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.totalAmount'),
        dataIndex: 'totalAmount',
        sorter: true,
        width: '10%',
        render: (_, record: PendingOrderResponse): React.ReactNode => {
            const {moedaSimbolos} = useSelector(orderItemsSelector)
            const moeda = moedaSimbolos.find(item => item.codigoMoeda === record?.codigoMoeda)

            return (
                <span>{formatMoney(moeda?.simbolo, record.totalAmount)}</span>
            )
        },
    },
    {
        title: translate('columns.pendency'),
        dataIndex: 'pendency',
        sorter: false,
        width: '10%',
    },
];

export const filterTypes = [
    {
        name: 'pedido',
        description: translate('filters.order'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'valor_total',
        description: translate('filters.totalAmount'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'data_pedido',
        description: translate('filters.date'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'divisao',
        description: translate('filters.division'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'codigo_cliente',
        description: translate('filters.client'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'nome_cliente',
        description: translate('filters.clientName'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'tipo_pedido',
        description: translate('filters.orderType'),
        comparisonFilter: [Comparison.EQ, Comparison.NEQ],
    },
];
