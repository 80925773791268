import React, { memo, useContext } from 'react';
import { Col } from 'antd';
import { useSelector } from 'react-redux';
import { Table } from '@components/table';
import { ApprovalMarketingResponse } from '@models/approvalMarketing';
import { approvalMarketingValues } from '@redux/slices/approval-marketing';
import { ModalContext } from 'src/contexts/modal-context';
import { approvalMarketingColumns } from '../constants';
import { EditApprovalMarketingModal } from './edit-modal';
import { translate } from '@components/i18n';

export const Body: React.FC = memo(() => {
    const approvalGroups = useSelector(approvalMarketingValues);
    const modalContext = useContext(ModalContext);

    const editApprovalGroup = (data: ApprovalMarketingResponse): void => {
        modalContext.setIsOpen(
            true,
            <EditApprovalMarketingModal
                data={data}
                key={`editing-approval-marketing-${data.id}`}
            />,
            {
                title: `${translate('general.editMarketingApprover')} - ${
                    data.aprovadorNome
                }`,
                width: '40%',
            },
        );
    };

    return (
        <Col>
            <Table
                columns={approvalMarketingColumns}
                rowKey={(record: ApprovalMarketingResponse) => `${record.id}`}
                dataSource={approvalGroups}
                pagination={undefined}
                pointer
                onRowClick={editApprovalGroup}
                scroll={{ y: '50vh' }}
            />
        </Col>
    );
});
