declare global {
    interface String {
        removeSpacesAndLower(): string;
        cutString(maxLength: number): string;
        capitalizeTheFirstLetterOfEachWord(): string;
        compare(str: string): boolean;
        normalizeLink(): string;
    }
}

String.prototype.removeSpacesAndLower = function () {
    return `${this.split(' ')
        .join('')
        .replace(/[^\w\s]/gi, '')
        .toLowerCase()}`;
};

String.prototype.cutString = function (maxLength: number) {
    if (this.length < maxLength) return this;
    return `${this.substring(0, maxLength)}...`;
};

String.prototype.capitalizeTheFirstLetterOfEachWord = function () {
    const separateWord = this.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i++) {
        separateWord[i] =
            separateWord[i].charAt(0).toUpperCase() +
            separateWord[i].substring(1);
    }
    return separateWord.join(' ');
};

String.prototype.compare = function (str: string) {
    return !this
        ? !!str
        : this?.removeSpacesAndLower().indexOf(
              !str ? '' : str?.removeSpacesAndLower(),
          ) !== -1;
};

String.prototype.normalizeLink = function () {
    return this.removeSpacesAndLower()
        .replace(/[^\w\s]/gi, '')
        .replace(' BR', '');
};

export {};
