/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';
import { EditableField } from '@models/editable-field';
import { CustomButton } from '@pages/approval-groups/styled';
import { Button, Row } from 'antd';

export const minOrderColumns = [
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.transportType'),
        dataIndex: 'transportType',
        sorter: false,
        width: '40%',
    },
];

export const resultOptions = [
    {
        id: 'DESCONTO',
        name: 'Desconto',
    },
    {
        id: 'BONIFICACAO_FIXA',
        name: 'Bonificação fixa',
    },
    {
        id: 'BONIFICACAO_PROPORCIONAL',
        name: 'Bonificação proporcional',
    },
    {
        id: 'NOTIFICACAO',
        name: 'Notificação',
    },
];

export function minOrderEditableFields({
    handleAddField,
    divisionOptions,
    transportType,
    customerTypesOptions,
}): EditableField[] {
    const fields = [
        {
            name: 'divisao',
            label: translate('forms.labels.division'),
            type: 'select',
            multiple: false,
            span: 8,
            options: divisionOptions,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.division'),
                },
            ],
        },
        {
            name: 'codigoTipoTransporte',
            label: translate('forms.labels.transportType'),
            type: 'select',
            multiple: false,
            span: 8,
            options: transportType,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.transportType'),
                },
            ],
        },
        {
            title: translate('general.validations'),
            name: 'hr',
            label: 'hr',
        },
        {
            name: 'condicoes',
            label: translate('forms.labels.validation'),
            type: 'component',
            span: 24,
            Render: () => (
                <CustomButton>
                    <Button onClick={handleAddField} className="border-none">
                        <PlusCircleOutlined />
                        <span className="ml-2">
                            {translate('forms.buttons.addValidation')}
                        </span>
                    </Button>
                </CustomButton>
            ),
        },
        {
            title: translate('general.exceptions'),
            name: 'hr',
            label: 'hr',
        },
        {
            name: 'tiposCliente',
            label: translate('forms.labels.clientType'),
            type: 'select',
            span: 24,
            multiple: true,
            options: customerTypesOptions,
        },
    ];

    return fields;
}

export const conditionsJsonModel = ({
    handleRemove,
    indexFieldApprovers,
    customersOptions,
    platforms,
}): EditableField[] => {
    return [
        {
            name: `cliente.${indexFieldApprovers}`,
            label: translate('forms.labels.client'),
            type: 'select',
            span: 8,
            options: customersOptions,
            order: indexFieldApprovers,
            // rules: [
            //     ({ getFieldValue, getFieldsValue }) => ({
            //         validator(_, value) {
            //             const clientes = Object.entries(getFieldsValue())
            //                 .filter((c) => c[0].includes('cliente.'))
            //                 .filter(
            //                     (c) =>
            //                         c[0] !== `cliente.${indexFieldApprovers}`,
            //                 );

            //             for (const cliente of clientes) {
            //                 if (cliente[1] === value) {
            //                     const origem = getFieldValue(
            //                         `origem.${indexFieldApprovers}`,
            //                     );
            //                     const clienteIndex = cliente[0].split('.')[1];
            //                     const origemCliente = getFieldValue(
            //                         `origem.${clienteIndex}`,
            //                     );

            //                     if (origem === origemCliente) {
            //                         return Promise.reject(
            //                             'Cliente com esta mesma origem já na lista',
            //                         );
            //                     }
            //                 }
            //             }
            //             return Promise.resolve();
            //         },
            //     }),
            // ],
        },
        {
            name: `origem.${indexFieldApprovers}`,
            label: translate('forms.labels.origin'),
            type: 'select',
            span: 4,
            options: platforms,
            order: indexFieldApprovers,
            // rules: [
            //     ({ getFieldValue, getFieldsValue }) => ({
            //         validator(_, value) {
            //             const clientes = Object.entries(getFieldsValue())
            //                 .filter((c) => c[0].includes('cliente.'))
            //                 .filter(
            //                     (c) =>
            //                         c[0] !== `cliente.${indexFieldApprovers}`,
            //                 );

            //             const cli = getFieldValue(
            //                 `cliente.${indexFieldApprovers}`,
            //             );

            //             for (const cliente of clientes) {
            //                 if (cliente[1] === cli) {
            //                     const origem = getFieldValue(
            //                         `origem.${indexFieldApprovers}`,
            //                     );
            //                     const clienteIndex = cliente[0].split('.')[1];
            //                     const origemCliente = getFieldValue(
            //                         `origem.${clienteIndex}`,
            //                     );

            //                     const destino = getFieldValue(
            //                         `destino.${indexFieldApprovers}`,
            //                     );

            //                     const destinoCliente = getFieldValue(
            //                         `destino.${clienteIndex}`,
            //                     );
            //                     if (
            //                         origem === origemCliente &&
            //                         destino !== destinoCliente
            //                     ) {
            //                         return Promise.reject(
            //                             'Origem duplicada na lista',
            //                         );
            //                     }
            //                     if (destino === destinoCliente) {
            //                         return Promise.reject(
            //                             'Origem com este mesmo destino já na lista',
            //                         );
            //                     }
            //                 }
            //             }
            //             return Promise.resolve();
            //         },
            //     }),
            // ],
        },
        {
            name: `destino.${indexFieldApprovers}`,
            label: translate('forms.labels.destiny'),
            type: 'select',
            span: 4,
            options: brazilianStates.map((c) => ({ id: c, name: c })),
            order: indexFieldApprovers,
            // rules: [
            //     ({ getFieldValue, getFieldsValue }) => ({
            //         validator(_, value) {
            //             const clientes = Object.entries(getFieldsValue())
            //                 .filter((c) => c[0].includes('destino.'))
            //                 .filter(
            //                     (c) =>
            //                         c[0] !== `destino.${indexFieldApprovers}`,
            //                 );

            //             for (const cliente of clientes) {
            //                 if (cliente[1] === value) {
            //                     const origem = getFieldValue(
            //                         `origem.${indexFieldApprovers}`,
            //                     );
            //                     const clienteIndex = cliente[0].split('.')[1];
            //                     const origemCliente = getFieldValue(
            //                         `origem.${clienteIndex}`,
            //                     );

            //                     if (origem === origemCliente) {
            //                         return Promise.reject(
            //                             'Destino com a mesma origem já está na lista',
            //                         );
            //                     }
            //                 }
            //             }
            //             return Promise.resolve();
            //         },
            //     }),
            // ],
        },
        {
            name: `valorminimo.${indexFieldApprovers}`,
            label: translate('forms.labels.minValue'),
            type: 'number',
            span: 5,
            min: 0,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.value'),
                },
            ],
        },
        {
            name: `c.${indexFieldApprovers}`,
            label: 'hr',
            type: 'component',
            span: 3,
            Render: () => (
                <Row style={{ height: '100%' }} align={'middle'}>
                    <CustomButton style={{ marginBottom: 0 }}>
                        <Button
                            onClick={() => {
                                handleRemove(indexFieldApprovers);
                            }}
                            className="border-none"
                        >
                            <MinusCircleOutlined />
                        </Button>
                    </CustomButton>
                </Row>
            ),
        },
        {
            name: `validation.${indexFieldApprovers}`,
            label: 'hr',
            type: 'component',
            span: 24,
            title: '.',
            rules: [
                ({ getFieldValue, getFieldsValue }) => ({
                    validator(_, value) {
                        const rows = Object.entries(getFieldsValue());

                        const currentRow = {
                            cliente: rows.find(
                                (cc) =>
                                    cc[0] === `cliente.${indexFieldApprovers}`,
                            )[1],
                            origem: rows.find(
                                (cc) =>
                                    cc[0] === `origem.${indexFieldApprovers}`,
                            )[1],
                            destino: rows.find(
                                (cc) =>
                                    cc[0] === `destino.${indexFieldApprovers}`,
                            )[1],
                            valorMinimo: rows.find(
                                (cc) =>
                                    cc[0] ===
                                    `valorminimo.${indexFieldApprovers}`,
                            )[1],
                        };

                        const clientes = rows
                            .filter((c) => c[0].includes('destino.'))
                            .filter(
                                (c) =>
                                    !c[0].includes(`.${indexFieldApprovers}`),
                            )
                            .map((cl) => {
                                return {
                                    cliente: rows.find(
                                        (cc) =>
                                            cc[0] ===
                                            `cliente.${cl[0].split('.')[1]}`,
                                    )[1],
                                    origem: rows.find(
                                        (cc) =>
                                            cc[0] ===
                                            `origem.${cl[0].split('.')[1]}`,
                                    )[1],
                                    destino: rows.find(
                                        (cc) =>
                                            cc[0] ===
                                            `destino.${cl[0].split('.')[1]}`,
                                    )[1],
                                    valorMinimo: rows.find(
                                        (cc) =>
                                            cc[0] ===
                                            `valorminimo.${
                                                cl[0].split('.')[1]
                                            }`,
                                    )[1],
                                };
                            });

                        const containsRow = clientes.find(
                            (c) =>
                                c.cliente === currentRow.cliente &&
                                c.origem === currentRow.origem &&
                                c.destino === currentRow.destino,
                        );

                        if (containsRow)
                            return Promise.reject(
                                translate(
                                    'general.existValidationsWithThisConditions',
                                ),
                            );
                        return Promise.resolve();
                    },
                }),
            ],
        },
    ];
};

export const brazilianStates = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
];
