import moment from 'moment';
import axios from '@axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@redux/reducers';
import { IOrderItemsSelectorState } from '.';
import { PaginatedResponseApi } from '@models/order/items/response';
import { DraftResponseApi } from '@models/order/drafs/response';
import { toQueryParameters } from '@extensions/object';
import { Paginated } from '@models/paginated';
import { CustomerRequest } from '@models/customer';
import { OrderHistoryProps } from './history';
import { FinancialDiscountResponseApi } from '@models/financial-discount/response';
import { exportReport } from '@utils/file';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

export type FinancialDiscountResponse = IOrderItemsSelectorState;

export interface FinancialDiscountTable {
    id: string;
    draftRef: string;
    name: string;
    condition: string;
    type: string;
    total: string;
    lastUpdate: string;
    bonification?: boolean;
    status: string;
    typeId?: string;
    envio?: boolean;
    value: number;
    hasBonification?: string;
}

export interface OrderFinancialDiscountProps extends CustomerRequest {
    status?: string;
    dataInicio?: string;
    dataFim?: string;
}

export const fetchFinancialDiscount = createAsyncThunk<
    Paginated<Partial<FinancialDiscountResponse>>,
    OrderFinancialDiscountProps
>('ordersToSelect/fetchFinancialDiscount', async (request, thunkAPI) => {
    try {
        const params = toQueryParameters({
            codigoCliente: request.company,
            page: request.current - 1,
            size: request.pageSize < 10 ? 10 : request.pageSize,
            sort: 'dataPedido,desc',
        });

        const response = await axios.get<
            PaginatedResponseApi<DraftResponseApi>
        >(`/pedido/list/solicitacaodescontos?${params}`);

        const { data, status } = response;

        if (status === 200) {
            const result = response.data.content.map((u) =>
                Object.assign(new DraftResponseApi(), u),
            );

            return {
                items: result.map((d) => d.toOrderState()),
                current: request.current,
                total: response.data.totalElements,
                pageSize: request.pageSize < 10 ? 10 : request.pageSize,
            };
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

const fetchOrderDetails = async (
    orders: FinancialDiscountResponseApi[],
): Promise<FinancialDiscountResponseApi[]> => {
    const list = [];
    for (const iterator of orders) {
        const response = await axios.get<FinancialDiscountResponseApi>(
            `/descontofinanceiro/${iterator.id}`,
        );
        if (response.data) {
            list.push({ ...response.data, pendenteCom: iterator.pendenteCom });
        }
    }

    return list;
};

export const fetchFinancialDiscountToApproval = createAsyncThunk<
    Paginated<Partial<FinancialDiscountResponseApi>>,
    OrderFinancialDiscountProps
>(
    'ordersToSelect/fetchFinancialDiscountToApproval',
    async (request, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const { financialDiscounts } = state;
            const { requestApproval: oldRequest } = financialDiscounts;

            if (oldRequest) {
                const { status, dataFim, dataInicio } = oldRequest;
                if (
                    dataFim != request.dataFim ||
                    dataInicio != request.dataInicio ||
                    status != request.status
                )
                    request.current = 1;
            }

            thunkAPI.dispatch(updateApprovalsRequest(request));

            const params = toQueryParameters({
                dataInicio: request.dataInicio,
                dataFim: request.dataFim,
                filterType: request.filterBy?.toUpperCase()
                    ? request.filterBy?.toUpperCase()
                    : '',
                status: request.status?.toUpperCase()
                    ? request.status?.toUpperCase()
                    : '',
                page: request.current - 1,
                size: request.pageSize < 10 ? 10 : request.pageSize,
            });

            const response = await axios.get<
                PaginatedResponseApi<FinancialDiscountResponseApi>
            >(`/descontofinanceiro/list/aprovacao?${params}`);

            const { data, status } = response;

            if (status === 200) {
                const result = response.data.content.map((u) => u);
                const details = await fetchOrderDetails(result);

                return {
                    items: details,
                    current: request.current,
                    total: response.data.totalElements,
                    pageSize: request.pageSize < 10 ? 10 : request.pageSize,
                };
            } else {
                return {
                    items: [],
                    current: 0,
                    total: 0,
                    pageSize: request.pageSize < 10 ? 10 : request.pageSize,
                };
                // return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

export const fetchFinancialDiscountHistory = createAsyncThunk<
    Paginated<Partial<FinancialDiscountResponseApi>>,
    OrderFinancialDiscountProps
>('ordersToSelect/fetchFinancialDiscountHistory', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { financialDiscounts } = state;
        const { requestHistory: oldRequest } = financialDiscounts;

        if (oldRequest) {
            const { status, dataFim, dataInicio } = oldRequest;
            if (
                dataFim != request.dataFim ||
                dataInicio != request.dataInicio ||
                status != request.status
            )
                request.current = 1;
        }

        thunkAPI.dispatch(updateApprovalsHistoryRequest(request));

        const params = toQueryParameters({
            dataInicio: request.dataInicio,
            dataFim: request.dataFim,
            filterType: request.filterBy?.toUpperCase()
                ? request.filterBy?.toUpperCase()
                : '',
            status: request.status?.toUpperCase()
                ? request.status?.toUpperCase()
                : '',
            page: request.current - 1,
            size: request.pageSize < 10 ? 10 : request.pageSize,
        });

        const response = await axios.get<
            PaginatedResponseApi<FinancialDiscountResponseApi>
        >(`/descontofinanceiro/list/historico?${params}`);

        const { data, status } = response;

        if (status === 200) {
            const result = response.data.content.map((u) => u);
            const details = await fetchOrderDetails(result);

            return {
                items: details,
                current: request.current,
                total: response.data.totalElements,
                pageSize: request.pageSize < 10 ? 10 : request.pageSize,
            };
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchFinancialReport = createAsyncThunk<
    Paginated<Partial<FinancialDiscountResponseApi>>,
    OrderFinancialDiscountProps
>('ordersToSelect/fetchFinancialReport', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { financialDiscounts } = state;
        const { requestReportHistory: oldRequest } = financialDiscounts;

        if (oldRequest) {
            const { filterBy, filterValue, company } = oldRequest;
            if (
                filterBy != request.filterBy ||
                filterValue != request.filterValue ||
                company != request.company
            )
                request.current = 1;
        }

        thunkAPI.dispatch(updateApprovalsHistoryRequest(request));

        const params = toQueryParameters({
            filterValue: request.filterValue,
            filterType: request.filterBy?.toUpperCase()
                ? request.filterBy?.toUpperCase()
                : '',
            comparisonType: request.company?.toUpperCase()
                ? request.company?.toUpperCase()
                : '',
            page: request.current - 1,
            size: request.pageSize < 10 ? 10 : request.pageSize,
        });

        const response = await axios.get<
            PaginatedResponseApi<FinancialDiscountResponseApi>
        >(`/relatorio/descontosfinanceirosaprovados?${params}`);

        const { data, status } = response;

        if (status === 200) {
            const result = response.data.content.map((u) => u);
            const details = await fetchOrderDetails(result);

            return {
                items: details,
                current: request.current,
                total: response.data.totalElements,
                pageSize: request.pageSize < 10 ? 10 : request.pageSize,
            };
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const fetchFinancialReportExport = createAsyncThunk<
    Paginated<Partial<FinancialDiscountResponseApi>>,
    OrderFinancialDiscountProps
>('ordersToSelect/fetchFinancialReportExport', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { financialDiscounts } = state;
        const { requestReportHistory: oldRequest } = financialDiscounts;

        if (oldRequest) {
            const { filterBy, filterValue, company } = oldRequest;
            if (
                filterBy != request.filterBy ||
                filterValue != request.filterValue ||
                company != request.company
            )
                request.current = 1;
        }

        thunkAPI.dispatch(updateApprovalsHistoryRequest(request));

        const params = toQueryParameters({
            filterValue: request.filterValue,
            filterType: request.filterBy?.toUpperCase()
                ? request.filterBy?.toUpperCase()
                : '',
            comparisonType: request.company?.toUpperCase()
                ? request.company?.toUpperCase()
                : '',
            page: request.current - 1,
            size: request.pageSize < 10 ? 10 : request.pageSize,
        });

        const response = await axios.get(
            `/relatorio/descontosfinanceirosaprovados/export?${params}`,
            { responseType: 'blob' },
        );
        exportReport(
            response.data,
            `${translate('general.reportFinacialDiscountApproveds')}.xlsx`,
        );
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

interface IFinancialDiscountState {
    ordersToSelect: FinancialDiscountResponse[];
    ordersToApproval: FinancialDiscountResponseApi[];
    ordersHistory: FinancialDiscountResponseApi[];
    ordersReport: FinancialDiscountResponseApi[];
    request: OrderFinancialDiscountProps;
    requestApproval: OrderHistoryProps;
    requestHistory: OrderHistoryProps;
    requestReportHistory: OrderHistoryProps;
}

const initialState: IFinancialDiscountState = {
    ordersToSelect: [],
    ordersToApproval: [],
    ordersHistory: [],
    ordersReport: [],
    requestApproval: {
        pageSize: 10,
        current: 1,
        company: '',
        filterBy: '',
        filterValue: '',
        categoria: ''
    },
    requestHistory: {
        pageSize: 10,
        current: 1,
        company: '',
        filterBy: '',
        filterValue: '',
        categoria: ''
    },
    request: {
        pageSize: 10,
        current: 1,
        company: '',
        filterBy: '',
        filterValue: '',
        categoria: ''
    },
    requestReportHistory: {
        pageSize: 10,
        current: 1,
        company: '',
        filterBy: '',
        filterValue: '',
        categoria: ''
    },
};

const ordersToSelectSlice = createSlice({
    name: 'ordersToSelectSlice',
    initialState,
    reducers: {
        resetState: (state) => {
            state = { ...initialState };
            return state;
        },
        updateFinancialDiscountRequest: (
            state,
            action: PayloadAction<OrderFinancialDiscountProps>,
        ) => {
            state.request = action.payload;

            return state;
        },
        updateApprovalsRequest: (
            state,
            action: PayloadAction<OrderHistoryProps>,
        ) => {
            state.requestApproval = action.payload;

            return state;
        },
        updateApprovalsHistoryRequest: (
            state,
            action: PayloadAction<OrderHistoryProps>,
        ) => {
            state.requestHistory = action.payload;

            return state;
        },
    },
    extraReducers: {
        [fetchFinancialDiscount.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<FinancialDiscountResponse>>,
        ) => {
            state.ordersToSelect = payload.items;
            state.request = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            return state;
        },
        [fetchFinancialDiscountToApproval.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<FinancialDiscountResponseApi>>,
        ) => {
            state.ordersToApproval = payload.items;
            state.requestApproval = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            return state;
        },
        [fetchFinancialDiscountHistory.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<FinancialDiscountResponseApi>>,
        ) => {
            state.ordersHistory = payload.items;
            state.requestHistory = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            return state;
        },
        [fetchFinancialReport.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<FinancialDiscountResponseApi>>,
        ) => {
            state.ordersReport = payload.items;
            state.requestReportHistory = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            return state;
        },
    },
});

export const ordersToSelectSelector = (
    state: RootState,
): IFinancialDiscountState => state.financialDiscounts;

export const ordersToSelectValues = (
    state: RootState,
): FinancialDiscountTable[] =>
    state.financialDiscounts.ordersToSelect.map<FinancialDiscountTable>(
        (d) => ({
            id: `${d.draftRef}`,
            condition: d.order.paymentCondition
                ? d.order.paymentCondition.description
                : translate('general.notSelected'),
            draftRef: d.order.date
                ? moment(d.order.date).format('DD/MM/YYYY')
                : translate('general.notInformed'),
            lastUpdate: moment(d.lastUpdate).format("DD/MM/YYYY"),
            name: `${d.order.customer.code} - ${d.order.customer.name}`,
            total: formatMoney(state.selectItems?.selectedMoeda?.simbolo, d.order.valorPedido),
            customerCity: `${d.order.customer.city} - ${d.order.customer.state}`,
            type: d.order.orderType.type.description,
            typeId: `${d.order.orderType.type.id}`,
            envio: d.order.sendCopy.envioProximoPedidoVenda,
            bonification: d.order.orderType.type.id.toString() !== 'S1',
            hasBonification: d.order.bonificationId
                ? translate('general.yes').toLocaleUpperCase()
                : translate('general.no').toLocaleUpperCase(),
            status: d.order.status,
            protocol: d.order.protocolo,
            value: d.order.valorPedido,
        }),
    );

export const {
    updateFinancialDiscountRequest,
    updateApprovalsRequest,
    updateApprovalsHistoryRequest,
    resetState,
} = ordersToSelectSlice.actions;
export default ordersToSelectSlice.reducer;
