import { Card } from 'antd';
import styled from 'styled-components';
import {light} from 'src/definitions/styled-components'

export const CardTable = styled(Card)`
    width: 100%;
    display: flex;
    flex-direction: column;
    backgroud: '#fff';
    margin: 0 0 0 0;
    border-radius: 8px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
    margin-bottom: 100px;
    .ant-card-body {
        padding-top: 0px;
    }
`;


export const Container = styled.section`
    width: '100%';
`

export const CardLine = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    border-bottom: 1px solid ${light.colors.darkBlue};
`

export const CardHeader = styled.div`
    background-color: ${light.colors.darkBlue};
    padding: 1em;
    color: white;
    border: none;
    border-top-right-radius: 0.2em;
    border-top-left-radius: 0.2em;
    text-align: center;
`

export const CardLabel = styled.label`
    background-color: #ccc;
    padding: 4px 0.5em;
    //color: white;
    border: none;
`

export const CardValue = styled.div`
    
`