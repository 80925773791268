import { IModalProps, Modal } from '@components/modal';
import * as React from 'react';
import { useState } from 'react';

interface ModalContextInterface {
    isOpen?: boolean;
    content?: React.ReactNode;
    title?: Partial<IModalProps>;
    ref?: string;
    setIsOpen: (
        open: boolean,
        content: React.ReactNode,
        title: Partial<IModalProps>,
    ) => void;
    handleClose: () => void;
}

export const ModalContext = React.createContext<ModalContextInterface | null>(
    null,
);

interface ModalContextProviderProps {
    children: React.ReactNode;
}

export const ModalContextProvider: React.FC<ModalContextProviderProps> = ({
    children,
}) => {
    const [cstate, setState] = useState<Partial<ModalContextInterface>>({
        isOpen: false,
        content: null,
        title: null,
    });

    const setIsOpen = (
        isOpen: boolean,
        content: React.ReactNode,
        title: Partial<IModalProps>,
    ): void => {
        setState({
            isOpen,
            content,
            title,
            ref: new Date().valueOf().toString(),
        });
    };

    const handleClose = (): void => {
        setState({
            isOpen: false,
            content: null,
            title: null,
        });
    };

    return (
        <ModalContext.Provider value={{ ...cstate, setIsOpen, handleClose }}>
            {children}
        </ModalContext.Provider>
    );
};

export const ModalContextConsumer: React.FC = () => {
    const cstate = React.useContext(ModalContext);

    if (!cstate) {
        throw new Error(
            'ModalContextConsumer must be used within a ModalContextProvider',
        );
    }

    return (
        <Modal
            key={cstate.ref}
            title={''}
            {...cstate.title}
            onCancel={() => {
                cstate.handleClose();
            }}
            visible={cstate.isOpen}
            footer={false}
        >
            <>{cstate.content}</>
        </Modal>
    );
};
