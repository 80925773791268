import { TransferOrderRequestParams } from "./request";
import { TransferOrderResponse, TransferOrderResponseApi } from "./response";

export namespace TransferOrder {

  export type Response = TransferOrderResponse;
  export const Response = TransferOrderResponse;
  
  export type ResposeApi = TransferOrderResponseApi
  export const ResposeApi = TransferOrderResponseApi

  export type Params = TransferOrderRequestParams;
}