export interface PendingOrderResponse {
    order?: number;
    seller?: string;
    date?: string;
    division?: string;
    customer?: number;
    customerName?: string;
    type?: string;
    deadline?: string;
    totalAmount?: number;
    pendency?: string;
    codigoMoeda?: string
}

export class PendingOrderResponseApi {
    id?: number;
    usuario?: string;
    dataPedido?: string;
    divisao?: string;
    codigoCliente?: number;
    nomeCliente?: string;
    descricaoTipoPedido?: string;
    descricaoPrazo?: string;
    valorTotal?: number;
    pendencia?: string;
    codigoMoeda?: string

    toPendingOrderResponse(): PendingOrderResponse {
        return {
            order: this.id,
            seller: this.usuario,
            date: this.dataPedido,
            division: this.divisao,
            customer: this.codigoCliente,
            customerName: this.nomeCliente,
            type: this.descricaoTipoPedido,
            deadline: this.descricaoPrazo,
            totalAmount: this.valorTotal,
            pendency: this.pendencia,
            codigoMoeda: this.codigoMoeda
        };
    }
}

export interface ApprovedOrderResponse {
    order?: number;
    type?: string;
    protocol?: string;
    division?: string;
    customer?: number;
    customerName?: string;
    totalAmount?: number;
    deadline?: string;
    seller?: string;
    date?: string;
    codigoMoeda?: string
}

export class ApprovedOrderResponseApi {
    id?: number;
    descricaoTipoPedido?: string;
    protocolo?: string;
    divisao?: string;
    codigoCliente?: number;
    nomeCliente?: string;
    valorTotal?: number;
    descricaoPrazo?: string;
    usuario?: string;
    dataPedido?: string;
    codigoMoeda?: string

    toApprovedOrderResponse(): ApprovedOrderResponse {
        return {
            order: this.id,
            type: this.descricaoTipoPedido,
            protocol: this.protocolo,
            division: this.divisao,
            customer: this.codigoCliente,
            customerName: this.nomeCliente,
            totalAmount: this.valorTotal,
            deadline: this.descricaoPrazo,
            seller: this.usuario,
            date: this.dataPedido,
            codigoMoeda: this.codigoMoeda
        };
    }
}

export interface OrderHistoryResponse {
    order?: number;
    type?: string;
    protocol?: string;
    division?: string;
    customer?: number;
    customerName?: string;
    totalAmount?: number;
    deadline?: string;
    status?: string;
    date?: string;
    codigoMoeda?: string;
}

export class OrderHistoryResponseApi {
    id?: number;
    descricaoTipoPedido?: string;
    protocolo?: string;
    divisao?: string;
    codigoCliente?: number;
    nomeCliente?: string;
    valorTotal?: number;
    descricaoPrazo?: string;
    status?: string;
    dataPedido?: string;
    codigoMoeda?: string;

    toOrderHistoryResponse(): OrderHistoryResponse {
        return {
            order: this.id,
            type: this.descricaoTipoPedido,
            protocol: this.protocolo,
            division: this.divisao,
            customer: this.codigoCliente,
            customerName: this.nomeCliente,
            totalAmount: this.valorTotal,
            deadline: this.descricaoPrazo,
            status: this.status,
            date: this.dataPedido,
            codigoMoeda: this.codigoMoeda
        };
    }
}

export interface CommercialBonusSaleResponse {
    bonusOrder?: number;
    bonusProtocol?: string;
    customer?: number;
    customerName?: string;
    date?: string;
    bonusTotalAmount?: number;
    user?: string;
    division?: string;
    saleOrder?: string;
    saleProtocol?: string;
    saleTotalAmount?: number;
    status?: string;
    codigoMoeda?: string;
}

export class CommercialBonusSaleResponseApi {
    pedidoBonificacaoId?: number;
    pedidoBonificacaoProtocolo?: string;
    pedidoBonificacaoCodigoCliente?: number;
    pedidoBonificacaoNomeCliente?: string;
    pedidoBonificacaoDataPedido?: string;
    pedidoBonificacaoValorTotal?: number;
    pedidoBonificacaoUsuario?: string;
    pedidoBonificacaoDivisao?: string;
    pedidoVendaId?: string;
    pedidoVendaProtocolo?: string;
    pedidoVendaValorTotal?: number;
    pedidoBonificacaoStatus?: string;
    codigoMoeda?: string;

    toCommercialBonusSaleResponse(): CommercialBonusSaleResponse {
        return {
            bonusOrder: this.pedidoBonificacaoId,
            bonusProtocol: this.pedidoBonificacaoProtocolo,
            customer: this.pedidoBonificacaoCodigoCliente,
            customerName: this.pedidoBonificacaoNomeCliente,
            date: this.pedidoBonificacaoDataPedido,
            bonusTotalAmount: this.pedidoBonificacaoValorTotal,
            user: this.pedidoBonificacaoUsuario,
            division: this.pedidoBonificacaoDivisao,
            saleOrder: this.pedidoVendaId,
            saleProtocol: this.pedidoVendaProtocolo,
            saleTotalAmount: this.pedidoVendaValorTotal,
            status: this.pedidoBonificacaoStatus,
            codigoMoeda: this.codigoMoeda
        };
    }
}
