import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { RootState } from '@redux/reducers';
import {
    DivisionResponse,
    DivisionResponseApi,
    RowResponse,
} from '@models/division';
import '@extensions/string';
import { translate } from '@components/i18n';

export const fetchDivisions = createAsyncThunk<DivisionResponse[]>(
    'divisao/fetch',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<DivisionResponseApi[]>(
                '/divisao/all',
                {
                    params: {
                        query: '',
                    },
                },
            );

            const { data, status } = response;

            if (status === 200) {
                const result = data.map((u) =>
                    Object.assign(new DivisionResponseApi(), u),
                );

                return result.map((u) => u.toDivisionResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.erroListingUsers'),
            );
        }
    },
);

export const fetchLines = createAsyncThunk<RowResponse[]>(
    'divisao/fetchLines',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<RowResponse[]>('/linhaitem/all', {
                params: {
                    query: '',
                },
            });

            const { data, status } = response;

            if (status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            return thunkAPI.rejectWithValue(
                translate('general.erroListingUsers'),
            );
        }
    },
);

interface IDivisionssState {
    availableDivisions: DivisionResponse[];
    availableRows: RowResponse[];
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: IDivisionssState = {
    availableDivisions: [],
    availableRows: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const divisionSlice = createSlice({
    name: 'divisionSlice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };

            return state;
        },
        filterDivisionss: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
            return state;
        },
        removeDivisionsState: (state, action: PayloadAction<number>) => {
            state.availableDivisions = state.availableDivisions.filter(
                (u) => u.id !== action.payload,
            );
            return state;
        },
    },
    extraReducers: {
        [fetchDivisions.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchDivisions.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchDivisions.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<DivisionResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableDivisions = payload;
            return state;
        },
        [fetchLines.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<RowResponse[]>,
        ) => {
            state.availableRows = payload;
            return state;
        },
    },
});

export const isAuthenticated = (state: RootState): boolean =>
    state.login.isSuccess;

export const divisionSelector = (state: RootState): IDivisionssState =>
    state.divisions;

export const divisionValues = ({ divisions }: RootState): DivisionResponse[] =>
    divisions.availableDivisions.filter(
        (u) =>
            u.nome?.compare(divisions.filter) ||
            u.descricao?.compare(divisions.filter),
    );

export const { clearState, filterDivisionss, removeDivisionsState } =
    divisionSlice.actions;

export default divisionSlice.reducer;
