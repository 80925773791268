import React, { useEffect } from 'react';

import { Page } from '@components';
import { Col, Row } from 'antd';
import { Title } from '@styles/components/text';
import { DraftsBody } from './components/drafts-body';
import { useDispatch } from 'react-redux';
import { clearStateCampaign } from '@redux/slices/campaign';
import { translate } from '@components/i18n';
import { fetchMoedas, fetchMoedasSimbolos } from '@redux/slices/order';

export const Drafts: React.FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(clearStateCampaign());
        dispatch(fetchMoedas());
        dispatch(fetchMoedasSimbolos());
    }, []);

    return (
        <Page title={translate('pages.draft')} greyBackground withPadding>
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.draft')}
                        </Title>
                    </Row>
                    <DraftsBody />
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
