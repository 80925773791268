export const closestNumber = (value: number, array: number[]): number[] => {
    let result = [];
    array.some(function (a) {
        if (a > value) {
            return result.push(a);
        }
        result = [a];
        return a === value;
    });
    return result;
};

export const generatePercentageArray = (): number[] => {
    return Array.from({ length: 30 / 0.05 + 1 })
        .map((v, i) => (i === 0 ? [0.0] : [0.05 * i, -(0.05 * i)]))
        .flat(1)
        .map((v) => Number(v.toFixed(2)));
};
