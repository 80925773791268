import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { Footer } from '@components/footer';
import { Header, IHeaderProps } from '@components/header';
import {
    notificationsSelector,
    removeNotification,
} from '@redux/slices/notification';
import { Container, Layout } from '@styles/components/wrapper';
import {
    ModalContextConsumer,
    ModalContextProvider,
} from 'src/contexts/modal-context';
import { PageDrawer } from './components/menu-drawer';
import { PageProvider } from './context/provider';
import { translate } from '@components/i18n';

interface IPageProps extends IHeaderProps {
    children: React.ReactNode;
    title?: string;
    withHeader?: boolean;
    withFooter?: boolean;
    greyBackground?: boolean;
    withPadding?: boolean;
    loading?: boolean;
}

export const Page: React.FC<IPageProps> = ({
    title = 'pages.home',
    children,
    menuIcon,
    onMenuClick,
    withHeader = true,
    withFooter = true,
    greyBackground = false,
    withPadding = false,
    loading = false,
    onClickGoBack = false,
}) => {
    const notifications = useSelector(notificationsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = `Ceva - ${title}`;
    }, [title]);

    useEffect(() => {
        if (notifications.length > 0) {
            const notification = notifications[notifications.length - 1];

            setTimeout(() => {
                if (notification.type === 'success')
                    message.success({
                        content: notification.message,
                        key: notification.notificationKey,
                        duration: 3,
                        className: 'message-styled',
                        onClose: () => {
                            dispatch(removeNotification(notification));
                            if (notification.callback) notification.callback();
                        },
                    });
                else if (notification.type === 'error')
                    message.error({
                        content: notification.message,
                        key: notification.notificationKey,
                        duration: 3,
                        className: 'message-styled-error',
                        onClose: () => {
                            dispatch(removeNotification(notification));
                            if (notification.callback) notification.callback();
                        },
                    });
            }, 500);
        }
    }, [notifications]);

    return (
        <Layout>
            <PageProvider>
                <ModalContextProvider>
                    {withHeader && (
                        <Header
                            onMenuClick={onMenuClick}
                            menuIcon={menuIcon}
                            title={translate(title)}
                            onClickGoBack={onClickGoBack}
                        />
                    )}
                    <Container
                        greyBackground={greyBackground}
                        withPadding={withPadding}
                    >
                        {withHeader && <PageDrawer />}
                        {!loading && <>{children}</>}
                    </Container>
                    {withFooter && <Footer />}
                    <ModalContextConsumer />
                </ModalContextProvider>
            </PageProvider>
        </Layout>
    );
};
