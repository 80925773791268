import { translate } from '@components/i18n';
import Comparison from 'src/enums/comparison';

export const filterComparison = [
    {
        name: Comparison.EQ,
        description: translate('comparison.equalTo'),
    },
    {
        name: Comparison.NEQ,
        description: translate('comparison.diferentThen'),
    },
    {
        name: Comparison.GT,
        description: translate('comparison.biggerThen'),
    },
    {
        name: Comparison.LT,
        description: translate('comparison.lessThen'),
    },
    {
        name: Comparison.CONTAINS,
        description: translate('comparison.contains'),
    },
    {
        name: Comparison.NOT_CONTAINS,
        description: translate('comparison.noContains'),
    },
    {
        name: Comparison.BEGINS_WITH,
        description: translate('comparison.startWith'),
    },
];

export const filterMonths = [
    {
        name: '01',
        description: translate('months.jan'),
    },
    {
        name: '02',
        description: translate('months.feb'),
    },
    {
        name: '03',
        description: translate('months.mar'),
    },
    {
        name: '04',
        description: translate('months.abr'),
    },
    {
        name: '05',
        description: translate('months.mai'),
    },
    {
        name: '06',
        description: translate('months.jun'),
    },
    {
        name: '07',
        description: translate('months.jul'),
    },
    {
        name: '08',
        description: translate('months.aug'),
    },
    {
        name: '09',
        description: translate('months.sep'),
    },
    {
        name: '10',
        description: translate('months.oct'),
    },
    {
        name: '11',
        description: translate('months.nov'),
    },
    {
        name: '12',
        description: translate('months.dec'),
    },
];

export const colors = [
    '#673ab7',
    '#108ee9',
    '#87d068',
    '#2db7f5',
    '#ffc53d',
    '#d48806',
    '#3f51b5',
    '#00bcd4',
    '#009688',
    '#607d8b',
    '#795548',
];
