declare global {
    interface Array<T> {
        insert(index, item): Array<T>;
        removeDuplicatesById(): Array<T>;
    }
}

Array.prototype.insert = function (index, item) {
    return [...this.slice(0, index), item, ...this.slice(index)];
};

Array.prototype.removeDuplicatesById = function() {
    const ids = new Set();
    const result = [];

    for (const obj of this) {
        if (!ids.has(obj.id)) {
        ids.add(obj.id);
        result.push(obj);
        }
    }

    return result;
};

export {};
