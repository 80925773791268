import React, { useEffect, useState } from 'react';

import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import {
    fetchCompanies,
    fetchConvenio,
    fetchCustos,
    fetchMoedasSimbolos,
    fetchPdiscqt,
    fetchSemaphores,
} from '@redux/slices/order';
import { columns, filterTypes } from '../constants';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Input } from '@components/input';
import {
    fetchFinancialReport,
    fetchFinancialReportExport,
    ordersToSelectSelector,
} from '@redux/slices/order/financial-discounts';
import { FinancialDiscountResponseApi } from '@models/financial-discount/response';
import { ExportButton } from '@styles/components/button';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { getFilterOptions } from '@utils/filter';
import { translate } from '@components/i18n';

export const RequestedDiscountBody: React.FC = () => {
    const state = useSelector(ordersToSelectSelector);
    const [queryState, setQueryState] = useState<string>('');
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [optionsComparisonFilter, setOptionsComparisonFilter] =
        useState(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        if (changedValues.filterBy) {
            setOptionsComparisonFilter(
                getFilterOptions(filterTypes, changedValues.filterBy),
            );
        }

        dispatch(
            fetchFinancialReport({
                ...changedValues,
                ...values,
                filterValue: queryState,
            }),
        );
    };

    useEffect(() => {
        dispatch(
            fetchFinancialReport({
                pageSize: 10,
                current: 1,
                filterValue: '',
                filterBy: '',
                company: '',
                categoria: ''
            }),
        );
    }, []);

    const onSelect = (rowKey: any): void => {
        history.push(
            `/financial-discounts/request-discount-orders?readOnly=true`,
            {
                pedidos: rowKey.pedidos.map((p) => p.id),
                codigoCliente: rowKey.codigoCliente,
                desconto: rowKey.desconto,
                aprovadores: rowKey.aprovacoes,
                comentario: rowKey.comentario,
                id: rowKey.id,
                history: true,
            },
        );
    };

    useEffect(() => {
        dispatch(fetchMoedasSimbolos())
        dispatch(fetchCompanies());
        dispatch(fetchCustos());
        dispatch(fetchConvenio());
        dispatch(fetchPdiscqt());
        dispatch(fetchSemaphores());
    }, []);

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchFinancialReport({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    const search = (): void => {
        dispatch(
            fetchFinancialReport({
                ...state.requestHistory,
                filterValue: queryState,
            }),
        );
    };

    const onSearch = (value: string): void => {
        setQueryState(value);
    };

    const onExport = (): void => {
        dispatch(
            fetchFinancialReportExport({
                ...state.requestHistory,
                filterValue: queryState,
            }),
        );
    };

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={23}>
                    <Table
                        columns={columns}
                        rowKey={(record: FinancialDiscountResponseApi) =>
                            `${record.id}`
                        }
                        pointer
                        dataSource={state.ordersReport ?? []}
                        pagination={state.requestReportHistory}
                        isLoading={false}
                        onRowClick={onSelect}
                        onFilterChange={onFiltersChange}
                        onChange={loadData}
                        form={form}
                        filters={[
                            <FormItem
                                label={translate('forms.labels.searchFor')}
                                name={'filterBy'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {filterTypes.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.searchType')}
                                name={'company'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {optionsComparisonFilter &&
                                        optionsComparisonFilter.map((f) => {
                                            return (
                                                <Option
                                                    key={f.name}
                                                    value={f.name}
                                                >
                                                    {f.description}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            onClick={search}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                    onChange={(e) => onSearch(e.target.value)}
                                />
                            </Form.Item>,
                        ]}
                        actions={[
                            <ExportButton
                                onClick={() => {
                                    onExport();
                                }}
                            >
                                {translate('general.export')}{' '}
                                <DownloadOutlined />
                            </ExportButton>,
                        ]}
                    />
                </Col>
            </Row>
        </ShadowContainer>
    );
};
