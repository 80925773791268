import React, { useContext, useEffect, useRef } from 'react';
import { Comment, Tooltip, Divider, Popconfirm } from 'antd';
import '@extensions/number';
import moment from 'moment';
import Search from 'antd/lib/input/Search';
import { CommentBackground } from '../styled';
import { useSelector } from 'react-redux';
import { authSelector } from '@redux/slices/auth';
import { FinancialDiscountContext } from '../context/context';
import { ModalContext } from 'src/contexts/modal-context';
import { translate } from '@components/i18n';

export const InternalComment: React.FC = () => {
    const comments = useContext(FinancialDiscountContext).comments;
    const addCommentFn = useContext(FinancialDiscountContext).addCommentFn;

    const removeCommentFn = useContext(
        FinancialDiscountContext,
    ).removeCommentFn;

    const isReadOnly = useContext(FinancialDiscountContext).currentState
        .history;

    const { currentUser } = useSelector(authSelector);
    const messagesEndRef = useRef(null);
    const modalContext = useContext(ModalContext);

    const scrollToBottom = (): void => {
        messagesEndRef.current?.scrollTo({
            top: messagesEndRef.current?.scrollHeight,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollToBottom();
    }, [comments]);

    return (
        <div>
            <div
                ref={messagesEndRef}
                style={{
                    maxHeight: 180,
                    minHeight: 130,
                    marginTop: 20,
                    overflowY: 'auto',
                }}
            >
                {comments?.length === 0 && (
                    <div>{translate('general.noComment')}</div>
                )}
                {comments?.map((c) => (
                    <CommentBackground sender={currentUser.id === c.userId}>
                        <Comment
                            author={
                                <a>
                                    <b>
                                        {c.userName ||
                                            translate('general.notInformed')}
                                    </b>
                                </a>
                            }
                            actions={
                                currentUser.id === c.userId
                                    ? [
                                          <Popconfirm
                                              title={translate(
                                                  'general.lostCommentYouSure',
                                              )}
                                              okText={translate('general.yes')}
                                              cancelText={translate(
                                                  'general.no',
                                              )}
                                              onConfirm={() => {
                                                  removeCommentFn({
                                                      commentId: c.id,
                                                  });
                                                  if (modalContext.isOpen)
                                                      modalContext.handleClose();
                                              }}
                                          >
                                              <span
                                                  style={{
                                                      color: '#FF0000',
                                                  }}
                                              >
                                                  {translate(
                                                      'general.removeComment',
                                                  )}
                                              </span>
                                          </Popconfirm>,
                                      ]
                                    : []
                            }
                            content={<p>{c.comment}</p>}
                            datetime={
                                <Tooltip
                                    title={moment(c.date).format(
                                        'DD-MM-YYYY hh:MM:ss',
                                    )}
                                >
                                    <span>{moment(c.date).fromNow()}</span>
                                </Tooltip>
                            }
                        />
                        <div />
                    </CommentBackground>
                ))}
            </div>
            <Divider style={{ margin: 0, marginBottom: 5 }} />
            <Search
                key={`search-${comments?.length}`}
                placeholder={translate('forms.placeholders.comment')}
                disabled={isReadOnly}
                onSearch={(c) => {
                    addCommentFn({ text: c });
                    if (modalContext.isOpen) modalContext.handleClose();
                }}
                enterButton={translate('general.send')}
            />
        </div>
    );
};
