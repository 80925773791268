import React, { useEffect } from 'react';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Header } from './components/header';
import { Body } from './components/body';
import { CardTable } from '@components/table/styled';
import { Col } from 'antd';
import { translate } from '@components/i18n';
import { fetchSemaphore } from '@redux/slices/semaphore';

export const Semaphore: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSemaphore({ current: 1, pageSize: 10 }));
    }, []);

    return (
        <Page
            title={translate('pages.semaphore')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Col span={22} style={{ alignSelf: 'center' }}>
                <h2 style={{ marginTop: '15px' }}>
                    {translate('titlePages.semaphore')}
                </h2>
                <CardTable>
                    <Header />
                    <Body />
                </CardTable>
            </Col>
        </Page>
    );
};
