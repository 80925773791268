import React from 'react';
import { ModalProps } from 'antd';
import { ModalContainer } from './styled';
import { CloseCircleOutlined } from '@ant-design/icons';

export interface IModalProps extends ModalProps {
    title: string;
    visible: boolean;
    onOk?: () => void;
    onCancel?: () => void;
    children: React.ReactNode;
}

export const Modal: React.FC<IModalProps> = ({
    title,
    visible,
    onCancel,
    onOk,
    children,
    ...rest
}) => {
    return (
        <ModalContainer
            title={title}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            cancelText="Cancelar"
            cancelButtonProps={{ style: { borderRadius: '4px' } }}
            okButtonProps={{ style: { borderRadius: '4px' } }}
            closeIcon={<CloseCircleOutlined style={{ fontSize: '1.6em' }} />}
            {...rest}
        >
            {children}
        </ModalContainer>
    );
};
