export class TransferClientResponse {
    bairro: string;
    cidade: string;
    cnpj: string;
    codigoDistribuidor: number;
    idPromotor: number;
    email: string;
    comercial1: number;
    comercial2: number;
    comercial3: number;
    endereco1: string;
    contato: string;
    telefone: string;
    codigoPostal: string;
    razaoSocial: string;
    nomeFantasia: string;
    zona: string;
    id: number;
}