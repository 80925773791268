import React from 'react';
import { InputRangeDateWrapper } from '../styled';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import { isEsAr } from '@components/i18n';
import { isEsMx } from '@components/i18n';

type InputDateProps = RangePickerProps<Moment> & { light?: boolean };

export const InputRange: React.FC<InputDateProps> = ({ light, ...rest }) => {
    return <InputRangeDateWrapper locale={isEsAr() || isEsMx() ? localeEs : locale} {...rest} light={light} />;
};
