import { Excecoes, Validacoes } from './response';

export interface MinOrderRequest {
    id?: number;
    codigoTipoTransporte: string;
    divisao: string;
    excecoes: Excecoes[];
    validacoes: Validacoes[];
}

export class MinOrderRequestAPI {
    constructor(data?: MinOrderRequest) {
        if (data) {
            const { id, ...rest } = data;
            Object.assign(this, rest);
        }
    }

    codigoTipoTransporte: string;
    divisao: string;
    excecoes: Excecoes[];
    validacoes: Validacoes[];
}

export interface MinOrderRequestOrder {
    codigoCliente: number;
    codigoFilial: number;
    codigosTipoTransporte: string[];
    tipoCliente: string;
    zonaCliente: string;
    zonaClienteEntrega: string;
}
