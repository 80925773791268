import React, { memo } from 'react';
import { Col, Row } from 'antd';
import { SolidBackground } from '@styles/components/wrapper';
import { SelectItemsHeaderFooter } from './select-items-header-footer';
import { ClientCollapse } from './client-collapse';
import { DistributorCollapse } from './distributor-collapse';

export const TransferSelectItemsHeader: React.FC = memo(() => {
    return (
        <Col>
            <SolidBackground isWhite>
                <Row style={{ padding: 30 }}>
                    <Col
                        xs={24}
                        lg={12}
                        xxl={8}
                    >
                        <ClientCollapse />
                    </Col>
                    <Col
                        xs={24}
                        lg={12}
                        xxl={8}
                    >
                        <DistributorCollapse />
                    </Col>
                </Row>
            </SolidBackground>
            <SelectItemsHeaderFooter />
        </Col>
    );
},
);
