import React, { memo, useContext, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { editableFields } from '../constants';
import { FormBuilder } from '@components/form';
import { translate } from '@components/i18n';
import { SemaphoreResponse } from '@models/semaphore';
import {
    createSemaphore,
    removeSemaphore,
    updateSemaphore,
} from '@redux/slices/semaphore';
import moment from 'moment';

interface EditModalProps {
    data?: SemaphoreResponse;
}

export const EditModal: React.FC<EditModalProps> = memo(({ data }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm<SemaphoreResponse>();
    const [editState, setEditState] = useState<SemaphoreResponse>({ ...data });
    const modalContext = useContext(ModalContext);

    const onOk = (values): void => {
        if (!data) addNew(values);
        else handleUpdate(values);
    };

    const handleUpdate = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingItem'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateSemaphore({
                data: {
                    id: data.id,
                    ...values,
                    dataInicial: values.dataInicial
                        ? moment(values.dataInicial).format('YYYY-MM-DD')
                        : null,
                    dataFinal: values.dataFinal
                        ? moment(values.dataFinal).format('YYYY-MM-DD')
                        : null,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addNew = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringItem'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            createSemaphore({
                data: {
                    ...values,
                    dataInicial: values.dataInicial
                        ? moment(values.dataInicial).format('YYYY-MM-DD')
                        : null,
                    dataFinal: values.dataFinal
                        ? moment(values.dataFinal).format('YYYY-MM-DD')
                        : null,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const onChangeValues = (
        changedValues: Partial<SemaphoreResponse>,
        allValues: SemaphoreResponse,
    ): void => {
        setEditState({
            ...allValues,
            ...changedValues,
        });
    };

    const handleDeleteUser = (): void => {
        dispatch(removeSemaphore(data.id));
        modalContext.handleClose();
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{
                        ...editState,
                        dataInicial: editState?.dataInicial
                            ? moment(editState?.dataInicial)
                            : undefined,
                        dataFinal: editState?.dataFinal
                            ? moment(editState?.dataFinal)
                            : undefined,
                    }}
                    onFinish={onOk}
                    form={form}
                    fields={editableFields()}
                    withDelete={!!data}
                    onDelete={handleDeleteUser}
                />
            </Col>
            <Col span={1} />
        </Row>
    );
});
