import { CampaignResponse } from '@models/campaign';
import { CommentResponse } from '@models/comments/response';
import { OrderRequest } from '@models/order/items/request';
import { AxiosResponse } from 'axios';
import React from 'react';
import { UseMutateFunction } from 'react-query';

export interface IApprovalContext {
    isSavingComment?: boolean;
    readOnly?: boolean;
    addComment: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        {
            text: any;
            item: any;
            orderId?: any;
        },
        unknown
    >;
    removeComment: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        {
            commentId?: any;
            orderId?: any;
        },
        unknown
    >;
    refuseOrder: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        {
            text: any;
        },
        unknown
    >;
    acceptOrder: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        unknown,
        unknown
    >;
    comments: CommentResponse[];
    loadingComments?: boolean;
    successComments: boolean;
    loading?: boolean;
    success?: boolean;
    order: Partial<OrderRequest>;
    bonificationOrder?: Partial<OrderRequest>;
    allBonificationOrders?: Partial<OrderRequest>[];
    campaigns?: CampaignResponse[];
}

export const ApprovalContext = React.createContext<IApprovalContext | null>(
    null,
);
