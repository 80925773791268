import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from '@axios';
import { RootState } from '@redux/reducers';
import { Paginated } from '@models/paginated';
import { toQueryParameters } from 'src/extensions/object';
import { PaginatedResponseApi } from '@models/order/items/response';
import { approvedOrdersReportColumnsTranslations } from '@utils/translation';
import {
    ApprovedOrderResponse,
    ApprovedOrderResponseApi,
    ReportRequest,
} from '@models/report';
import { exportReport } from '@utils/file';
import { translate } from '@components/i18n';

export const fetchApprovedOrders = createAsyncThunk<
    Paginated<ApprovedOrderResponse>,
    ReportRequest
>('approved-orders/fetchApprovedOrders', async (request, thunkAPI) => {
    try {
        const state = thunkAPI.getState() as RootState;
        const { approvedOrders } = state;
        const { request: oldRequest } = approvedOrders;

        thunkAPI.dispatch(updateRequestApprovedOrder(request));

        const params = generateParams(request, oldRequest);

        const response = await axios.get<
            PaginatedResponseApi<ApprovedOrderResponseApi>
        >(`/relatorio/pedidosaprovados?${params}`);
        const { data: dataResponse, status: statusResponse } = response;

        if (!dataResponse) {
            return {
                items: [],
                current: 0,
                total: 0,
                pageSize: 0,
            };
        } else {
            const result = dataResponse.content.map((u) =>
                Object.assign(new ApprovedOrderResponseApi(), u),
            );

            if (statusResponse === 200) {
                return {
                    items: result.map((u) => u.toApprovedOrderResponse()),
                    current: request.current,
                    total: response.data.totalElements,
                    pageSize: request.pageSize,
                };
            } else {
                return thunkAPI.rejectWithValue('');
            }
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const exportApprovedOrders = createAsyncThunk(
    'approved-orders/exportApprovedOrders',
    async (request: ReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const { approvedOrders } = state;
            const { request: oldRequest } = approvedOrders;

            const params = generateParams(request, oldRequest);

            const response = await axios.get(
                `/relatorio/pedidosaprovados/export?${params}`,
                { responseType: 'blob' },
            );
            exportReport(
                response.data,
                `${translate('general.reportApprovedOrders')}.xlsx`,
            );
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;

            return thunkAPI.rejectWithValue(message);
        }
    },
);

const generateParams = (request: ReportRequest, oldRequest: ReportRequest) => {
    const { filterValue, filterBy } = oldRequest;

    if (filterValue != request.filterValue || filterBy != request.filterBy)
        request.current = 1;

    let parameters: any = {
        page: request.current - 1,
        size: request.pageSize,
        sort: request.field
            ? `${
                  approvedOrdersReportColumnsTranslations[request.field] || ''
              },${request.order === 'ascend' ? 'asc' : 'desc'}`
            : '',
    };

    parameters =
        request.filterValue && request.filterBy && request.comparisonType
            ? {
                  ...parameters,
                  filterValue: request.filterValue,
                  filterType: request.filterBy?.toUpperCase(),
                  comparisonType: request.comparisonType?.toUpperCase(),
              }
            : parameters;
    parameters = request.ano ? { ...parameters, ano: request.ano } : parameters;
    parameters = request.mes ? { ...parameters, mes: request.mes } : parameters;

    return toQueryParameters(parameters);
};

interface IApprovedOrderSelectorState {
    approvedOrder: ApprovedOrderResponse;
    approvedOrders: ApprovedOrderResponse[];
    request: ReportRequest;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;
}

const initialState: IApprovedOrderSelectorState = {
    approvedOrder: undefined,
    approvedOrders: [],
    request: {
        filterBy: '',
        filterValue: '',
        comparisonType: '',
        status: '',
        mes: '01',
        ano: `${new Date().getFullYear()}`,
        pageSize: 10,
        current: 1,
        total: 0,
    },
    isFetching: true,
    isSuccess: false,
    isError: false,
    errorMessage: '',
};

const approvedOrdersSlice = createSlice({
    name: 'approvedOrdersSlice',
    initialState,
    reducers: {
        approvedOrders: (
            state,
            action: PayloadAction<ApprovedOrderResponse>,
        ) => {
            state.approvedOrder = action.payload;

            return state;
        },
        updateRequestApprovedOrder: (
            state,
            action: PayloadAction<ReportRequest>,
        ) => {
            state.request = action.payload;

            return state;
        },
        resetApprovedOrderState: (state) => {
            state = { ...initialState };
            return state;
        },
    },
    extraReducers: {
        [fetchApprovedOrders.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<ApprovedOrderResponse>>,
        ) => {
            state.approvedOrders = [...payload.items];
            state.request = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
            };
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchApprovedOrders.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        [fetchApprovedOrders.pending.toString()]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },
    },
});

export const approvedOrderSelector = (
    state: RootState,
): IApprovedOrderSelectorState => state.approvedOrders;

export const {
    approvedOrders,
    updateRequestApprovedOrder,
    resetApprovedOrderState,
} = approvedOrdersSlice.actions;

export default approvedOrdersSlice.reducer;
