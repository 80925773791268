import React from 'react';
import { Page } from '@components';
import { Col, Row } from 'antd';
import { ApprovalContextProvider } from './context/provider';
import { OrderDetails } from './components/order-details';
import { useQuery } from '@hooks/use-query';
import { translate } from '@components/i18n';

export const DetailsOrders: React.FC = () => {
    const query = useQuery();

    return (
        <Page
            title={
                query.get('readOnly')
                    ? translate('pages.historyOfOrders')
                    : translate('pages.orderApprovals')
            }
            greyBackground
            withPadding
        >
            <ApprovalContextProvider>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <OrderDetails />
                    </Col>
                    <Col span={1} />
                </Row>
            </ApprovalContextProvider>
        </Page>
    );
};
