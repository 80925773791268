import React, { memo } from 'react';
import { Col } from 'antd';
import { useSelector } from 'react-redux';
import { Table } from '@components/table';
import { transferOrderSelector } from '@redux/slices/transfer-order/order';
import { TransferItem } from '@models/transfer-item';
import { transferItemsColumns } from '../select-items/constants';
import { SelectItemsFooter } from './select-items-footer';

export const SelectItemsBody: React.FC = memo(() => {
    const transferOrder = useSelector(transferOrderSelector);

    const filteredItems = (i: TransferItem.Response): boolean => {
        const value = i[transferOrder.filterItems?.filterType];
        if (
            !value &&
            (i.descricao.compare(transferOrder.filterItems?.filterString) ||
                `${i.numero}`.compare(transferOrder.filterItems?.filterString))
        )
            return true;
        else if (
            value &&
            `${value}`.compare(transferOrder.filterItems?.filterString)
        )
            return true;
        return false;
    };

    return (
        <Col>
            <Table
                columns={transferItemsColumns}
                rowKey={(record: TransferItem.Response) => `${record.id}`}
                dataSource={transferOrder?.availableItems?.filter(filteredItems) || []}
                isLoading={transferOrder.isFetchingItems}
                pagination={undefined}
                scroll={{ y: '40vh' }}
                footerObject={<SelectItemsFooter />}
            />
        </Col>
    );
},
);