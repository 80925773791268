import React, { useEffect } from 'react';

import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import {
    fetchCompanies,
    fetchConvenio,
    fetchCustos,
    fetchPdiscqt,
    fetchSemaphores,
} from '@redux/slices/order';
import { columns, filterTypesStatus } from '../constants';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { InputRange } from '@components/input';
import {
    fetchFinancialDiscountHistory,
    ordersToSelectSelector,
} from '@redux/slices/order/financial-discounts';
import { FinancialDiscountResponseApi } from '@models/financial-discount/response';
import { translate } from '@components/i18n';

export const RequestedDiscountBody: React.FC = () => {
    const state = useSelector(ordersToSelectSelector);
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (_: any, values: any): void => {
        let checkValues = { ...state.request };

        if (values['datas']) {
            const range = values['datas'];
            checkValues = {
                ...checkValues,
                dataInicio: range[0].format('DD/MM/YYYY'),
                dataFim: range[1].format('DD/MM/YYYY'),
            };
        } else {
            checkValues = {
                ...checkValues,
                dataInicio: undefined,
                dataFim: undefined,
            };
        }

        dispatch(
            fetchFinancialDiscountHistory({
                ...checkValues,
                ...values,
                filterValue: '',
            }),
        );
    };

    useEffect(() => {
        dispatch(
            fetchFinancialDiscountHistory({
                pageSize: 10,
                current: 1,
                filterValue: '',
                filterBy: '',
                company: '',
                categoria: ''
            }),
        );
    }, []);

    const onSelect = (rowKey: any): void => {
        history.push(
            `/financial-discounts/request-discount-orders?readOnly=true`,
            {
                pedidos: rowKey.pedidos.map((p) => p.id),
                codigoCliente: rowKey.codigoCliente,
                desconto: rowKey.desconto,
                aprovadores: rowKey.aprovacoes,
                comentario: rowKey.comentario,
                id: rowKey.id,
                history: true,
            },
        );
    };

    useEffect(() => {
        dispatch(fetchCompanies());
        dispatch(fetchCustos());
        dispatch(fetchConvenio());
        dispatch(fetchPdiscqt());
        dispatch(fetchSemaphores());
    }, []);

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchFinancialDiscountHistory({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={23}>
                    <Table
                        columns={columns}
                        rowKey={(record: FinancialDiscountResponseApi) =>
                            `${record.id}`
                        }
                        pointer
                        dataSource={state.ordersHistory ?? []}
                        pagination={state.requestHistory}
                        isLoading={false}
                        onRowClick={onSelect}
                        onFilterChange={onFiltersChange}
                        onChange={loadData}
                        form={form}
                        filters={[
                            <FormItem
                                label={translate('forms.labels.date')}
                                name={'datas'}
                            >
                                <InputRange
                                    format="DD-MM-YYYY"
                                    placeholder={[
                                        translate(
                                            'forms.placeholders.startDate',
                                        ),
                                        translate('forms.placeholders.endDate'),
                                    ]}
                                    light
                                />
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.status')}
                                name={'status'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {filterTypesStatus.map((c) => (
                                        <Option value={c.name}>
                                            {c.description}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>,
                        ]}
                    />
                </Col>
            </Row>
        </ShadowContainer>
    );
};
