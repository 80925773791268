import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { DelegationResponse } from '@models/delegation';
import { delegationValues } from '@redux/slices/delegation';
import { allUsers } from '@redux/slices/users';
import { Col } from 'antd';
import React, { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { approvalMarketingColumns } from '../constants';
import { EditDelegationModal } from './edit-modal';

export const Body: React.FC = memo(() => {
    const delegations = useSelector(delegationValues);
    const users = useSelector(allUsers);
    const modalContext = useContext(ModalContext);

    const editDelegation = (data: DelegationResponse): void => {
        modalContext.setIsOpen(
            true,
            <EditDelegationModal
                data={data}
                key={`editing-delegation-${data.id}`}
            />,
            {
                title: `${translate('general.editDelegation')} - ${
                    data.delegacaoMotivo
                }`,
                width: '40%',
            },
        );
    };

    return (
        <Col>
            <Table
                columns={approvalMarketingColumns({ optionsUser: users })}
                rowKey={(record: DelegationResponse) => `${record.id}`}
                dataSource={delegations}
                pagination={undefined}
                pointer
                onRowClick={editDelegation}
                scroll={{ y: '50vh' }}
            />
        </Col>
    );
});
