import { TransferItemResponse, TransferItemResponseApi } from "@models/transfer-item";
import moment from "moment";


export class ResponseOrderItem extends TransferItemResponse {
  quantity: number;
  totalQuantity: number;
  totalValue: number;
}

export class ResponseOrderItemApi extends TransferItemResponseApi {
  quantity: number;
  totalQuantity: number;
  totalValue: number;

  public fromJSON(): ResponseOrderItem {
    const data = {
        id: this.id,
        cliente: this.cliente,
        descricao: this.descricao,
        divisao: this.divisao,
        enabled: this.enabled,
        filial: this.filial,
        multiplo: this.multiplo,
        numero: this.numero,
        preco: this.preco,
        vigencia: this.vigencia ? moment(this.vigencia) : undefined,
        quantity: this.quantity || 0,
        totalQuantity: this.totalQuantity || 0,
        totalValue: this.totalValue || 0
    }

    return Object.assign(new ResponseOrderItem(), data);
}
}

export namespace OrderItem {
  export type Request = {
    note?: string,
    email?: string,
    isDraft: boolean
  };

  export type Response = ResponseOrderItem;
  export const Response = ResponseOrderItem;
  
  export type ResposeApi = ResponseOrderItemApi
  export const ResposeApi = ResponseOrderItemApi
}