import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import {
    fontSize,
    FontSizeProps,
    FontWeightProps,
    fontWeight,
    opacity,
    OpacityProps,
    margin,
    MarginProps,
} from 'styled-system';

interface ITextProps
    extends FontSizeProps,
        FontWeightProps,
        OpacityProps,
        MarginProps {
    blue?: boolean;
    white?: boolean;
    inline?: boolean;
    danger?: boolean;
}

export const Text = styled(Typography.Text)<ITextProps>`
    ${fontSize};
    ${fontWeight};
    ${opacity};
    ${margin};

    ${({ blue }) =>
        blue &&
        css`
            color: ${({ theme }) => theme.colors.darkBlue} !important;
        `}

    ${({ danger }) =>
        danger &&
        css`
            color: ${({ theme }) => theme.colors.danger} !important;
        `}
        
    ${({ white }) =>
        white &&
        css`
            color: ${({ theme }) => theme.colors.white} !important;
        `}

    @media (min-width: 992px){
        ${({ inline }) =>
            inline &&
            css`
                display: block;
                white-space: nowrap;
                overflow: hidden;
                max-width: 70%;
                text-overflow: ellipsis;
            `}
    }
`;

export const Title = styled(Typography.Title)<ITextProps>`
    ${fontSize};
    ${fontWeight};
    ${opacity};
    ${margin};

    ${({ blue }) =>
        blue &&
        css`
            color: ${({ theme }) => theme.colors.darkBlue} !important;
        `}

    ${({ white }) =>
        white &&
        css`
            color: ${({ theme }) => theme.colors.white} !important;
        `}
`;
