import '@extensions/string';
import {message as Message} from 'antd'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification } from '../notification';
import { translate } from '@components/i18n';
import { PaginatedResponseApi } from '@models/order/items/response';
import { TransferItem } from '@models/transfer-item';
import { RootState } from '@redux/reducers';

export const fetchTransferItems = 
    createAsyncThunk<PaginatedResponseApi<TransferItem.Response>, TransferItem.Params | void>('transfer-items/list-items', async (request, thunkAPI) => {
    try {
        const response = await axios.get<PaginatedResponseApi<TransferItem.ResposeApi>>(`/itemtransfer`, {params: request});

        const { data, status } = response;

        if (status === 200) {
            return {
                ...data,
                content: data.content.map(item => {
                    const temp = Object.assign(new TransferItem.ResposeApi(), item)
                    
                    return temp.fromJSON()
                })
            }
        } else {
            return thunkAPI.rejectWithValue('');
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(translate('general.erroListingItems'));
    }
});

export const fetchTransferItemsByUser = 
    createAsyncThunk<PaginatedResponseApi<TransferItem.Response>, TransferItem.Params | void>('transfer-items/list-items', async (request, thunkAPI) => {
    try {
        const response = await axios.get<PaginatedResponseApi<TransferItem.ResposeApi>>(`/itemtransfer/byusuario/page`, {params: request});

        const { data, status } = response;

        if (status === 200) {
            return {
                ...data,
                content: data.content.map(item => {
                    const temp = Object.assign(new TransferItem.ResposeApi(), item)
                    
                    return temp.fromJSON()
                })
            }
        } else {
            return thunkAPI.rejectWithValue('');
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(translate('general.erroListingItems'));
    }
});

export const addTransferItem = createAsyncThunk<
    TransferItem.Response,
    ThunkCallback<TransferItem.Request>
>('transfer-items/add-item', async (request, thunkAPI) => {
    try {
        const response = await axios.post<TransferItem.Response>('/itemtransfer', request.data.toJSON());

        const { data, status } = response;

        if (status === 200) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.registered'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        console.error(e)
        Message.destroy(request.notificationKey)
        
        let message
        if(e.response.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
            message = e.response.data[0].msgUsuario
        }else{
            message = translate('general.error')
        }

        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: message,
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );
        return thunkAPI.rejectWithValue(e);
    }
});

export const updateTransferItem = createAsyncThunk<
    TransferItem.Response,
    ThunkCallback<TransferItem.RequestUpdate>
>('transfer-items/update-item', async (request, thunkAPI) => {
    try {
        const { id } = request.data
        const response = await axios.put<TransferItem.Response>(
            '/itemtransfer/' + id, request.data.toJSON(),
        );

        const { data, status } = response;

        if (status === 200) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.itemUpdated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        Message.destroy(request.notificationKey)
        
        let message
        if(e.response.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
            message = e.response.data[0].msgUsuario
        }else{
            message = translate('general.error')
        }

        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: message,
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );
        return thunkAPI.rejectWithValue(e);
    }
});

export const deleteTransferItem = createAsyncThunk<number, number>(
    'transfer-items/delete-item',
    async (itemId, thunkAPI) => {
        try {
            const response = await axios.delete('/itemtransfer/' + itemId);
            if (response.status === 204) {
                thunkAPI.dispatch(deleteTransferItemFromState(itemId));
                return Promise.resolve(itemId);
            }
            return 0;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;
            return thunkAPI.rejectWithValue(message);
        }
    },
);

interface ITransferItemsState {
    available: PaginatedResponseApi<TransferItem.Response>;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: ITransferItemsState = {
    available: undefined,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const transferItemsSlice = createSlice({
    name: 'transfer-item-slice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };
            return state;
        },
        deleteTransferItemFromState: (state, action: PayloadAction<number>) => {
            state.available.content = state.available.content.filter((u) => u.id !== action.payload);
            return state;
        },
    },
    extraReducers: {
        [fetchTransferItems.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchTransferItems.rejected.toString()]: (state, action) => {
            state.errorMessage = action.payload;
            state.isFetching = false;
            state.isError = true;
            return state;
        },
        [fetchTransferItems.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PaginatedResponseApi<TransferItem.Response>>,
        ) => {
            state.available = payload;
            state.isSuccess = true;
            state.isFetching = false;
            return state;
        },
        [fetchTransferItemsByUser.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchTransferItemsByUser.rejected.toString()]: (state, action) => {
            state.errorMessage = action.payload;
            state.isFetching = false;
            state.isError = true;
            return state;
        },
        [fetchTransferItemsByUser.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PaginatedResponseApi<TransferItem.Response>>,
        ) => {
            state.available = payload;
            state.isSuccess = true;
            state.isFetching = false;
            return state;
        },
    },
});

export const { clearState, deleteTransferItemFromState } = transferItemsSlice.actions;

export const transferItemsSelector = (state: RootState): ITransferItemsState => state.transferItems;

export default transferItemsSlice.reducer;
