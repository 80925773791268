import { RouterPath } from '@definitions/routes';
import { Campaign } from '@pages/campaign';

const routes: RouterPath[] = [
    {
        path: '/campaign',
        auth: true,
        render: () => <Campaign />,
        allowedRoles: ['CONTROLADORIA'],
    },
];

export default routes;
