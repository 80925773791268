import pendingOrdersReducer from './pending-orders';
import approvedOrdersReducer from './approved-orders';
import ordersHistoryReducer from './orders-history';
import commercialBonusesSalesReducer from './commercial-bonuses-sales';
export * from './pending-orders';
export * from './approved-orders';
export * from './orders-history';
export * from './commercial-bonuses-sales';

export default {
    pendingOrders: pendingOrdersReducer,
    approvedOrders: approvedOrdersReducer,
    ordersHistory: ordersHistoryReducer,
    commercialBonusesSales: commercialBonusesSalesReducer,
};
