import { RouterPath } from '@definitions/routes';
import { RequestFinancialDiscounts } from '@pages/financial-discounts/request-discount-orders';
import { OrdersToApproval } from '@pages/financial-discounts/requested-discounts';
import { OrdersHistory } from '@pages/financial-discounts/requested-discounts-history';
import { SelectCustomer } from '@pages/financial-discounts/select-customer/select-client';
import { SelectOrders } from '@pages/financial-discounts/select-orders';

const routes: RouterPath[] = [
    {
        path: '/financial-discounts/select-customer',
        auth: true,
        render: () => <SelectCustomer />,
        allowedRoles: ['REPRESENTANTE', 'APROVADOR'],
    },
    {
        path: '/financial-discounts/select-orders/:customerId',
        auth: true,
        render: () => <SelectOrders />,
        allowedRoles: ['REPRESENTANTE', 'APROVADOR'],
    },
    {
        path: '/financial-discounts/request-discount-orders',
        auth: true,
        render: () => <RequestFinancialDiscounts />,
        allowedRoles: ['REPRESENTANTE', 'APROVADOR', "CONTROLADORIA"],
    },
    {
        path: '/financial-discounts/requested-discount-orders',
        auth: true,
        render: () => <OrdersToApproval />,
        allowedRoles: ['CONTROLADORIA', 'APROVADOR'],
    },
    {
        path: '/financial-discounts/requested-discount-orders-history',
        auth: true,
        render: () => <OrdersHistory />,
        allowedRoles: ['REPRESENTANTE', 'APROVADOR'],
    },
];

export default routes;
