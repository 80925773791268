import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import { PageContext } from '@components/page/context/producer';
import { Col, Row } from 'antd';
import React, { memo, useContext } from 'react';
import { IHeaderProps } from '..';
import { HeaderMenu, HeaderMenuTitle } from '../styled';

export const Menu: React.FC<IHeaderProps> = memo(
    ({ title, onMenuClick, menuIcon }) => {
        const pageContext = useContext(PageContext);

        return (
            <Col span={6}>
                <Row align="middle" gutter={10}>
                    <Col>
                        <HeaderMenu
                            onClick={
                                onMenuClick
                                    ? onMenuClick
                                    : pageContext.toggleDrawer
                            }
                        >
                            {menuIcon}
                            {!menuIcon && <MenuOutlined />}
                        </HeaderMenu>
                    </Col>
                    <Col>
                        <HeaderMenuTitle>{title}</HeaderMenuTitle>
                    </Col>
                </Row>
            </Col>
        );
    },
);
