import React, { useContext, useEffect, useState } from 'react';
import { Col, Divider, Form, Radio, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { ModalContext } from 'src/contexts/modal-context';
import { Input } from '@components/input';
import { Button } from '@styles/components/button';
import { ItemResponse } from '@models/order/items/response';
import { Text } from '@styles/components/text';
import '@extensions/number';
import { closestNumber, generatePercentageArray } from '@utils/number';
import '@extensions/number';
import { SelectList } from '@components/select-list/styled';
import { useSelector } from 'react-redux';
import { calculateItemCost } from '@redux/functions/order';
import { RootState } from '@redux/reducers';
import { ItemHistory } from '@models/item';
import { toQueryParameters } from '@extensions/object';
import '@extensions/number';
import axios from '@axios';
import moment from 'moment';
import { translate } from '@components/i18n';
import { formatMoney, getMoneyNotation } from '@utils/money';
import { orderItemsSelector } from '@redux/slices/order';

interface IChangePriceModal {
    item: ItemResponse;
    onOk: (v: number) => void;
    customerCode?: number;
    platformId?: number | string;
}

export const ChangePriceModal: React.FC<IChangePriceModal> = ({
    item,
    customerCode,
    platformId,
    onOk,
}) => {
    const [form] = Form.useForm<ItemResponse>();
    const modalContext = useContext(ModalContext);
    const [availableOptions] = useState<number[]>(() =>
        generatePercentageArray(),
    );
    const [options, setOptions] = useState<number[]>([]);
    const [selectedOption, setSelectedOption] = useState<number>(undefined);
    const [currentValue, setCurrentValue] = useState<ItemResponse>(undefined);
    const [history, setHistory] = useState<ItemHistory[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);
    const orderState = useSelector(orderItemsSelector);

    const onFinish = (): void => {
        modalContext.handleClose();
        onOk(selectedOption);
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const loadHistory = async () => {
        if (history.length > 0 || alreadyLoaded) return;
        setLoading(true);

        const params = toQueryParameters({
            codigoFilial: platformId,
            codigoCliente: customerCode,
            numeroItem: item.number,
        });

        try {
            const response = await axios.get<ItemHistory[]>(
                `/item/historico/?${params}`,
            );
            setHistory(response.data);
            setLoading(false);
            setAlreadyLoaded(true);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (item) {
            form.setFieldsValue(item);
            updateOptions(item.costModifiable, item.discount);
            loadHistory();
        }
    }, [item]);

    useEffect(() => {
        if (selectedOption) {
            form.setFieldsValue(currentValue);
        }
    }, [currentValue]);

    const onValuesChange = (v: Partial<ItemResponse>): void => {
        updateOptions(v.costModifiable);
    };

    const updateOptions = (value: number, selectedDiscount?: number): void => {
        setOptions(
            closestNumber(
                ((item.cost - value) / item.cost) * 100 * -1,
                availableOptions.sort((a, b) => a - b),
            ),
        );

        if (selectedOption !== undefined) {
            setSelectedOption(undefined);
        }

        if (selectedDiscount) {
            const discount = Number(Number(selectedDiscount).toFixed(2));
            setSelectedOption(discount);
        }
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <Form<ItemResponse>
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    form={form}
                >
                    <Text>
                        {translate('general.lasPriceWas')}:{' '}
                        <Text fontWeight={'bold'} blue>
                            {loading ? (
                                <span>{translate('general.loading')}</span>
                            ) : (
                                <>
                                    {alreadyLoaded && history.length === 0 && (
                                        <p>
                                            {translate(
                                                'general.noHistoryForThisItem',
                                            )}
                                        </p>
                                    )}
                                    {alreadyLoaded && history.length > 0 && (
                                        <>
                                            {formatMoney(
                                                orderState?.selectedMoeda?.simbolo,
                                                history[0].precoUnitario,
                                            )}{' '}
                                            {translate('general.in')}{' '}
                                            {moment(history[0].dataPedido).format('DD/MM/YYYY')}
                                        </>
                                    )}
                                </>
                            )}
                        </Text>
                    </Text>
                    <Divider style={{ margin: '10px 0px' }} />
                    <FormItem
                        label={
                            <Row>
                                <Col>{translate('general.newPrice')}</Col>
                            </Row>
                        }
                        name={'costModifiable'}
                    >
                        <Input
                            placeholder={'Ex: 0'}
                            type={'number'}
                            addonBefore={getMoneyNotation(orderState?.selectedMoeda?.simbolo)}
                            step={0.01}
                            min={(item.cost - item.cost * 0.3).toFixed(2)}
                            max={(item.cost + item.cost * 0.3).toFixed(2)}
                        />
                    </FormItem>
                    {options.length > 0 && (
                        <>
                            <SelectList
                                value={selectedOption}
                                buttonStyle="solid"
                                size="large"
                                onChange={(e) => {
                                    setSelectedOption(Number(e.target.value));

                                    setCurrentValue({
                                        ...item,
                                        costModifiable: Number(
                                            (
                                                item.cost *
                                                    (Number(e.target.value) /
                                                        100) +
                                                item.cost
                                            ).toFixed(2),
                                        ),
                                    });
                                }}
                            >
                                {options.map((option) => (
                                    <Radio.Button value={option}>
                                        <span>
                                            {option > 0 ? '+' : ''}
                                            {option}%
                                        </span>
                                        <span>
                                            {translate('general.newValue')}{' '}
                                            <b>
                                                <ValueWrapper
                                                    value={option}
                                                    item={item}
                                                />
                                            </b>
                                        </span>
                                    </Radio.Button>
                                ))}
                            </SelectList>
                        </>
                    )}
                    <Divider style={{ margin: '10px 0px' }} />
                    <Button
                        type="primary"
                        className={'full-btn'}
                        htmlType="submit"
                        disabled={selectedOption === undefined}
                    >
                        {translate('general.ok')}
                    </Button>
                </Form>
            </Col>
            <Col span={1} />
        </Row>
    );
};

interface IValue {
    value: number;
    item: ItemResponse;
}

const ValueWrapper: React.FC<IValue> = ({ value, item }) => {
    const [defaultValue, setValue] = useState<number>(value);
    const state = useSelector((s) => s) as RootState;
    const orderState = useSelector(orderItemsSelector);

    useEffect(() => {
        console.debug(value);
        const itemValue = { ...item, discount: value, quantity: 1 };
        const [itemV] = calculateItemCost(itemValue, false, state);
        console.debug(itemValue, itemV);
        setValue(itemV.valor);
    }, [value, item]);

    return <>{formatMoney(orderState?.selectedMoeda?.simbolo, defaultValue)}</>;
};
