import { ApproverOrder } from '@redux/slices/order';
import { Col, Row, Timeline } from 'antd';
import { memo } from 'react';
import { Text } from '@styles/components/text';
import { ClockCircleOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';

interface ApproverListProps {
    approvers: ApproverOrder[];
}

export const ApproversListTimeline: React.FC<ApproverListProps> = memo(
    ({ approvers }) => {
        return (
            <Col span={24}>
                <Row>
                    <Text fontWeight={'bold'} blue fontSize={'1.2em'}>
                        Aprovadores:
                    </Text>
                </Row>
                <Timeline style={{ marginTop: 10 }}>
                    {approvers.map((c) => (
                        <Timeline.Item
                            color={'gray'}
                            dot={
                                <ClockCircleOutlined
                                    style={{ fontSize: '16px' }}
                                />
                            }
                        >
                            <Row
                                align="middle"
                                justify={'space-between'}
                                wrap={false}
                            >
                                <span
                                    style={{
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: 200,
                                    }}
                                    title={
                                        `${c.usuarioNome}` ??
                                        translate('general.notInformed')
                                    }
                                >
                                    {c.usuarioNome ??
                                        translate('general.notInformed')}
                                </span>{' '}
                            </Row>
                        </Timeline.Item>
                    ))}
                </Timeline>
            </Col>
        );
    },
);
