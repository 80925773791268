/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useContext, useEffect, useState } from 'react';
import { Col, Input, Row, Tooltip } from 'antd';
import { orderItemsSelector, updateItemApi } from '@redux/slices/order';
import { Dot } from '@styles/components/button';
import { useDispatch, useSelector } from 'react-redux';
import {
    ArrowButton,
    EmptyItemMessage,
    CampaignIndicator,
} from './components/styled';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { ChangeDiscountContext } from 'src/contexts/change-discount-modal-context';
import { ModalContext } from 'src/contexts/modal-context';
import { ChangePriceModal } from './components/change-price-modal';
import { CustomerResponse } from '@models/customer';
import { ItemResponse } from '@models/order/items/response';
import { HistoryItem } from './components/item-history';
import { colors } from '@utils/constants';
import { selectedCampaignsSelector } from '@redux/slices/campaign';
import { translate } from '@components/i18n';
import { formatMoney, getMoneyNotation } from '@utils/money';
import { SHOW_COUNTRY } from '@config/env';

export const columns = [
    {
        title: translate('columns.client'),
        dataIndex: 'name',
        sorter: true,
        width: '25%',
        render: (_, record: CustomerResponse): React.ReactNode => {
            const addresses = []

            if (record?.address?.trim()) {
                addresses.push(record.address)
            } else if (record?.alternativeAddress?.trim()) {
                addresses.push(record.alternativeAddress)
            }

            return (
                <>
                    <span
                        style={{
                            display: 'flex',
                            columnGap: '5px',
                            userSelect: 'none',
                            fontWeight: 'bold'
                        }}
                    >
                        {record.name}
                    </span>
                    <span
                        style={{
                            fontStyle: 'italic',
                            fontSize: '0.850em'
                        }}>
                        {translate("columns.address")}: {addresses.join(" / ")}
                    </span>
                </>
            );
        },
    },
    {
        title: translate('columns.code'),
        dataIndex: 'code',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.cia'),
        dataIndex: 'company',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.type'),
        dataIndex: 'type',
        sorter: true,
        width: '10%',
        render: (_, record: CustomerResponse): React.ReactNode => {
            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {record.typeId} - {record.type}
                </span>
            );
        },
    },
    {
        title: translate('columns.city'),
        dataIndex: 'city',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.state'),
        dataIndex: 'state',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.country'),
        dataIndex: 'country',
        sorter: true,
        width: '10%',
        noShow: !SHOW_COUNTRY
    },
    {
        title: translate('columns.cnpj'),
        dataIndex: 'cnpj',
        sorter: true,
        width: '10%',
    },
];

export const draftsColumns = [
    {
        title: translate('columns.client'),
        dataIndex: 'name',
        sorter: true,
        width: '25%',
    },
    {
        title: translate('columns.condition'),
        dataIndex: 'condition',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.date'),
        dataIndex: 'draftRef',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'type',
        sorter: false,
        width: '20%',
    },
    {
        title: translate('columns.hasBonification'),
        dataIndex: 'hasBonification',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.total'),
        dataIndex: 'total',
        sorter: false,
        width: '20%',
        render: (text, record): React.ReactNode => {
            const { moedaSimbolos } = useSelector(orderItemsSelector)

            const moeda = moedaSimbolos.find(item => item.codigoMoeda === record?.codigoMoeda)

            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(moeda?.simbolo, text)}
                </span>
            );
        },
    },
];

export const sendedOrdersColumns = [
    {
        title: translate('columns.client'),
        dataIndex: 'name',
        sorter: true,
        width: '35%',
    },
    {
        title: translate('columns.condition'),
        dataIndex: 'condition',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.date'),
        dataIndex: 'draftRef',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'type',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.total'),
        dataIndex: 'total',
        sorter: false,
        width: '20%',
        render: (text, record): React.ReactNode => {
            const { moedaSimbolos } = useSelector(orderItemsSelector)

            const moeda = moedaSimbolos.find(item => item.codigoMoeda === record?.codigoMoeda)

            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(moeda?.simbolo, text)}
                </span>
            );
        },
    },
];

export const userBonificationColumns = [
    {
        title: 'Nome',
        dataIndex: 'name',
        sorter: false,
        width: '40%',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sorter: false,
        width: '30%',
    },
    {
        title: 'Divisão',
        dataIndex: 'division',
        sorter: false,
        width: '30%',
    },
];

export const orderBonificationColumns = [
    {
        title: 'Cliente',
        dataIndex: 'name',
        sorter: false,
        width: '25%',
    },
    {
        title: 'Código',
        dataIndex: 'code',
        sorter: false,
        width: '20%',
    },
    {
        title: 'Pedido',
        dataIndex: 'order',
        sorter: false,
        width: '25%',
    },
    {
        title: 'Total Pedido',
        dataIndex: 'total',
        sorter: false,
        width: '25%',
        render: (text): React.ReactNode => {
            const { selectedMoeda } = useSelector(orderItemsSelector)

            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(selectedMoeda?.simbolo, text)}
                </span>
            );
        },
    },
];

export const bonificationItemsColumns = [
    {
        title: translate('columns.item'),
        dataIndex: 'description',
        width: '22%',
        sorter: (a, b) => sort(a.description, b.description),
        render: (text, record) => {
            const campaigns = useSelector(selectedCampaignsSelector);
            const color =
                colors[
                campaigns.findIndex((c) => c.id === record.campaign?.id)
                ];

            return (
                <Row wrap={false}>
                    {record.campaign && (
                        <Tooltip
                            title={`${record.campaign.nome} - ${record.condition
                                ? translate('columns.condition')
                                : translate('general.cannotBeEdited')
                                }`}
                        >
                            <CampaignIndicator color={color}>
                                {record.condition ? 'C' : 'R'}
                            </CampaignIndicator>
                        </Tooltip>
                    )}
                    {text}
                </Row>
            );
        },
    },
    {
        title: translate('columns.code'),
        dataIndex: 'code',
        width: '10%',
        sorter: (a, b) => sort(a.code, b.code),
    },
    {
        title: translate('columns.qtd'),
        dataIndex: 'quantity',
        sorter: (a, b) => sort(a.quantity, b.quantity),
        width: '7%',
        render: (_, record: ItemResponse): React.ReactNode => {
            const [defaultValue, setDefaultValue] = useState<number>(
                record.quantity,
            );

            const [value, setValue] = useState<number | string>(
                record.quantity,
            );

            const dispatch = useDispatch();
            const updateValue = (value: number): void => {
                dispatch(
                    updateItemApi({
                        id: record.id,
                        quantity: value,
                        bonification: true,
                    }),
                );
            };

            useEffect(() => {
                setDefaultValue(record.quantity);
                if (record.quantity != defaultValue) {
                    setDefaultValue(record.quantity);
                    setValue(record.quantity);
                }
            }, [record.quantity]);

            return (
                <>
                    {(!record.precoBase || record.custo === -1) && (
                        <EmptyItemMessage>
                            {translate("general.noBaseOfPrice")}
                        </EmptyItemMessage>
                    )}
                    {((record.orderType === 'V2' &&
                        !(!record.precoBase || record.custo === -1)) ||
                        (record.custo > 0 && record.precoBase > 0)) && (
                            <span>
                                <Input
                                    key={`default-value-${record.id}-${defaultValue}`}
                                    placeholder={'Ex: 0'}
                                    defaultValue={defaultValue}
                                    value={value}
                                    type={'number'}
                                    min={record.campaign ? record.result?.qtd : '0'}
                                    onFocus={() => {
                                        if (record.quantity === 0) {
                                            setValue('');
                                        }
                                    }}
                                    onChange={(e) => {
                                        setValue(Number(e.target.value));
                                    }}
                                    onBlur={(e) => {
                                        updateValue(Number(e.target.value));
                                        if (!Number(e.target.value)) {
                                            setValue(0);
                                        }
                                    }}
                                />
                            </span>
                        )}
                </>
            );
        },
    },
    {
        title: translate('columns.presentationQtd'),
        dataIndex: 'quantityPresentation',
        sorter: (a, b) => sort(a.quantityPresentation, b.quantityPresentation),
        width: '10%',
        render: (text, record): React.ReactNode => {
            return (
                <>
                    {((record.orderType === 'V2' &&
                        !(!record.precoBase || record.custo === -1)) ||
                        (record.custo > 0 && record.precoBase > 0)) && (
                            <span>{text}</span>
                        )}
                </>
            );
        },
    },
    {
        title: translate('columns.amountQtd'),
        dataIndex: 'totalQuantity',
        sorter: (a, b) => sort(a.totalQuantity, b.totalQuantity),
        width: '10%',
        render: (text, record): React.ReactNode => {
            return (
                <>
                    {((record.orderType === 'V2' &&
                        !(!record.precoBase || record.custo === -1)) ||
                        (record.custo > 0 && record.precoBase > 0)) && (
                            <span>{text}</span>
                        )}
                </>
            );
        },
    },
    {
        title: translate('columns.price'),
        dataIndex: 'costModifiable',
        sorter: (a, b) => sort(a.costModifiable, b.costModifiable),
        width: '12%',
        render: (text, record): React.ReactNode => {
            const { selectedMoeda } = useSelector(orderItemsSelector)
            return (
                <>
                    {((record.orderType === 'V2' &&
                        !(!record.precoBase || record.custo === -1)) ||
                        (record.custo > 0 && record.precoBase > 0)) && (
                            <span
                                style={{
                                    display: 'flex',
                                    columnGap: '5px',
                                    userSelect: 'none',
                                }}
                            >
                                {formatMoney(selectedMoeda?.simbolo, text)}
                            </span>
                        )}
                </>
            );
        },
    },
    {
        title: translate('columns.subTotal'),
        dataIndex: 'total',
        sorter: (a, b) => sort(a.total, b.total),
        width: '15%',
        render: (text, record: ItemResponse): React.ReactNode => {
            const { selectedMoeda } = useSelector(orderItemsSelector)
            return (
                <>
                    {((record.orderType === 'V2' &&
                        !(!record.precoBase || record.custo === -1)) ||
                        (record.custo > 0 && record.precoBase > 0)) && (
                            <span
                                style={{
                                    display: 'flex',
                                    columnGap: '5px',
                                    userSelect: 'none',
                                }}
                            >
                                {formatMoney(selectedMoeda?.simbolo, text)}
                                {/* {record.semaforo && (
                                <Dot background={record.semaforo} />
                            )} */}
                            </span>
                        )}
                </>
            );
        },
    },
];

function sort(a: string, b: string): number {
    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

export const exportItemsColumns = (items: ItemResponse[]) => {
    return items.map((item) => {
        return {
            Item: item.description,
            Cod: item.code,
            Quantity: item.custo > 0 && item.precoBase > 0 ? item.quantity : '',
            Apresentacao: item.apresentacao ? item.apresentacao : '',
            QtdApres:
                item.custo > 0 && item.precoBase > 0
                    ? item.quantityPresentation
                    : '',
            Promo:
                item.custo > 0 && item.precoBase > 0
                    ? Number(item.promotion) === 0
                        ? translate('general.no').toLocaleUpperCase()
                        : `${Number(item.promotion.toString()).toFixed(2)}`
                    : '',
            QtdTotal:
                item.custo > 0 && item.precoBase > 0 ? item.totalQuantity : '',
            Desconto:
                item.custo > 0 && item.precoBase > 0
                    ? `${item.discount.toFixed(2)}`
                    : '',
            Preco:
                item.custo > 0 && item.precoBase > 0 ? item.costModifiable : '',
            Subtotal: item.custo > 0 && item.precoBase > 0 ? item.total : '',
            Ordem: item.custo > 0 && item.precoBase > 0 ? item.order : '',
        };
    });
};

export const itemsColumns = [
    {
        title: translate('columns.item'),
        dataIndex: 'description',
        width: '15%',
        sorter: (a, b) => sort(a.description, b.description),
        render: (text, record: ItemResponse): React.ReactNode => {
            const campaigns = useSelector(selectedCampaignsSelector);
            const color =
                colors[
                campaigns.findIndex((c) => c.id === record.campaign?.id)
                ];

            return (
                <Row wrap={false}>
                    {record.campaign && (
                        <Tooltip
                            title={`${record.campaign.nome} - ${record.condition
                                ? translate('columns.condition')
                                : translate('general.cannotBeEdited')
                                }`}
                        >
                            <CampaignIndicator color={color}>
                                {record.condition && record.result
                                    ? 'CR'
                                    : record.condition
                                        ? 'C'
                                        : 'R'}
                            </CampaignIndicator>
                        </Tooltip>
                    )}
                    {text}
                </Row>
            );
        },
    },
    {
        title: translate('columns.code'),
        dataIndex: 'code',
        width: '8%',
        sorter: (a, b) => sort(a.code, b.code),
    },
    {
        title: translate('columns.transportCode'),
        dataIndex: 'codigoTipoTransporte',
        // sorter: true,
        width: '7%',
        sorter: (a, b) => sort(a.code, b.code),
    },
    {
        title: translate('columns.qtd'),
        dataIndex: 'quantity',
        // sorter: true,
        width: '10%',
        sorter: (a, b) => sort(a.quantity, b.quantity),
        render: (_, record): React.ReactNode => {
            const [value, setValue] = useState<number | string>(
                record.quantity,
            );
            const [defaultValue, setDefaultValue] = useState<number>(0);
            const dispatch = useDispatch();
            const updateValue = (value: number): void => {
                dispatch(
                    updateItemApi({
                        id: record.id,
                        quantity: value,
                    }),
                );
            };

            useEffect(() => {
                if (record.quantity != defaultValue) {
                    setDefaultValue(record.quantity);
                    setValue(record.quantity);
                }
            }, [record.quantity]);

            return (
                <>
                    {(!record.precoBase || record.custo === -1) && (
                        <EmptyItemMessage>
                            {translate("general.noBaseOfPrice")}
                        </EmptyItemMessage>
                    )}
                    {record.custo > 0 && record.precoBase > 0 && (
                        <span>
                            <Input
                                key={`default-value-${record.id}-${defaultValue}`}
                                placeholder={'Ex: 0'}
                                defaultValue={record.quantity}
                                value={value}
                                disabled={
                                    record.disabled ||
                                    (record.condition && record.result)
                                }
                                type={'number'}
                                onFocus={() => {
                                    if (record.quantity === 0) {
                                        setValue('');
                                    }
                                }}
                                onChange={(e) => {
                                    setValue(Number(e.target.value));
                                }}
                                min={record.min ? record.min : '0'}
                                onBlur={(e) => {
                                    updateValue(Number(e.target.value));
                                    if (!Number(e.target.value)) {
                                        setValue(0);
                                    }
                                }}
                            />
                        </span>
                    )}
                </>
            );
        },
    },
    {
        title: translate('columns.presentationQtd'),
        dataIndex: 'quantityPresentation',
        sorter: (a, b) => sort(a.quantityPresentation, b.quantityPresentation),
        width: '7%',
        render: (text, record): React.ReactNode => {
            return (
                <>
                    {record.custo > 0 && record.precoBase > 0 && (
                        <span>{text}</span>
                    )}
                </>
            );
        },
    },
    {
        title: translate('columns.amountQtd'),
        dataIndex: 'totalQuantity',
        sorter: (a, b) => sort(a.totalQuantity, b.totalQuantity),
        width: '7%',
        render: (text, record): React.ReactNode => {
            return (
                <>
                    {record.custo > 0 && record.precoBase > 0 && (
                        <span>{text}</span>
                    )}
                </>
            );
        },
    },
    {
        title: translate('columns.price'),
        dataIndex: 'cost',
        sorter: (a, b) => sort(a.cost, b.cost),
        width: '16%',
        render: (_, record): React.ReactNode => {
            const dispatch = useDispatch();
            const order = useSelector(orderItemsSelector);
            const modalContext = useContext(ModalContext);

            const updateValue = (value: number): void => {
                dispatch(
                    updateItemApi({
                        id: record.id,
                        discount: Number(value.toFixed(2)),
                    }),
                );
            };

            return (
                <>
                    {record.custo > 0 && record.precoBase > 0 && (
                        <Row wrap={false} align={'middle'}>
                            <Input
                                key={`coast-${record.costModifiable}`}
                                placeholder={'Ex: 0'}
                                defaultValue={record.costModifiable}
                                type={'number'}
                                addonBefore={getMoneyNotation(order.selectedMoeda?.simbolo)}
                                readOnly
                                disabled={record.disabled || record.condition}
                                onClick={
                                    record.disabled || record.condition
                                        ? undefined
                                        : () => {
                                            modalContext.setIsOpen(
                                                true,
                                                <ChangePriceModal
                                                    item={record}
                                                    onOk={updateValue}
                                                    customerCode={
                                                        order.order.customer
                                                            .code
                                                    }
                                                    platformId={
                                                        order.order.orderType
                                                            .platform.id
                                                    }
                                                />,
                                                {
                                                    title: translate(
                                                        'general.changePrice',
                                                    ),
                                                    width: '25%',
                                                },
                                            );
                                        }
                                }
                            />
                            <HistoryItem
                                itemNumber={record.number}
                                clientCode={order.order.customer.code}
                                platformId={order.order.orderType.platform.id}
                            />
                        </Row>
                    )}
                </>
            );
        },
    },
    {
        title: translate('columns.promo'),
        dataIndex: 'promotion',
        sorter: (a, b) => sort(a.promotion, b.promotion),
        width: '7%',
        render: (text, record): React.ReactNode => {
            return (
                <>
                    {record.custo > 0 && record.precoBase > 0 && (
                        <span>
                            {isNaN(text) ? text : `${Number(text).toFixed(2)}%`}
                        </span>
                    )}
                </>
            );
        },
    },
    {
        title: translate('columns.discount'),
        dataIndex: 'discount',
        sorter: (a, b) => sort(a.discount, b.discount),
        width: '12%',
        render: (text, record): React.ReactNode => {
            const dispatch = useDispatch();
            const changeDiscountContext = useContext(ChangeDiscountContext);

            const updateValue = (value: number): void => {
                if (value > 30 || value < -30) return;
                dispatch(
                    updateItemApi({
                        id: record.id,
                        discount: Number(value.toFixed(2)),
                    }),
                );
            };

            return (
                <>
                    {record.custo > 0 && record.precoBase > 0 && (
                        <Row justify="space-between" align="middle" gutter={10}>
                            <Col span={19}>
                                <Input
                                    placeholder={'0'}
                                    step={0.05}
                                    value={record.discount}
                                    defaultValue={record.discount}
                                    readOnly
                                    addonAfter={'%'}
                                    disabled={
                                        record.disabled || record.condition
                                    }
                                    onClick={
                                        record.disabled || record.condition
                                            ? undefined
                                            : () => {
                                                changeDiscountContext.setIsOpen(
                                                    true,
                                                    record,
                                                );
                                            }
                                    }
                                />
                            </Col>
                            <Col span={4}>
                                <div>
                                    <ArrowButton
                                        onClick={() => {
                                            updateValue(
                                                Number(record.discount) + 0.05,
                                            );
                                        }}
                                    >
                                        <Tooltip
                                            title={translate(
                                                'columns.addition',
                                            )}
                                            color={'#219653'}
                                        >
                                            <CaretUpOutlined />
                                        </Tooltip>
                                    </ArrowButton>

                                    <ArrowButton
                                        danger
                                        onClick={() => {
                                            updateValue(
                                                Number(record.discount) - 0.05,
                                            );
                                        }}
                                    >
                                        <Tooltip
                                            title={translate(
                                                'columns.discount',
                                            )}
                                            placement="bottom"
                                            color={'#c10b0b'}
                                        >
                                            <CaretDownOutlined />
                                        </Tooltip>
                                    </ArrowButton>
                                </div>
                            </Col>
                        </Row>
                    )}
                </>
            );
        },
    },
    {
        title: translate('columns.subTotal'),
        dataIndex: 'total',
        sorter: (a, b) => sort(a.total, b.total),
        width: '12%',
        render: (text, record): React.ReactNode => {
            const { selectedMoeda } = useSelector(orderItemsSelector);
            return (
                <>
                    {record.custo > 0 && record.precoBase > 0 && (
                        <span
                            style={{
                                display: 'flex',
                                columnGap: '5px',
                                userSelect: 'none',
                            }}
                        >
                            {formatMoney(selectedMoeda?.simbolo, text)}
                            {record.semaforo && (
                                <Dot background={record.semaforo} />
                            )}
                        </span>
                    )}
                </>
            );
        },
    },
];

export const itemsColumnsReadOnly = [
    {
        title: translate('columns.item'),
        dataIndex: 'description',
        sorter: true,
        width: '22%',
    },
    {
        title: translate('columns.code'),
        dataIndex: 'code',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.qtd'),
        dataIndex: 'quantity',
        sorter: true,
        width: '7%',
        render: (_, record): React.ReactNode => {
            return (
                <span>
                    <Input
                        placeholder={'Ex: 0'}
                        defaultValue={record.quantity}
                        type={'number'}
                        min={'0'}
                        readOnly
                        disabled
                    />
                </span>
            );
        },
    },
    {
        title: translate('columns.presentationQtd'),
        dataIndex: 'quantityPresentation',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.amountQtd'),
        dataIndex: 'totalQuantity',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.price'),
        dataIndex: 'cost',
        sorter: true,
        width: '12%',
        render: (_, record): React.ReactNode => {
            const { selectedMoeda } = useSelector(orderItemsSelector);
            return (
                <span>
                    <Input
                        key={`coast-${record.costModifiable}`}
                        placeholder={'Ex: 0'}
                        defaultValue={record.costModifiable}
                        type={'number'}
                        addonBefore={getMoneyNotation(selectedMoeda?.simbolo)}
                        readOnly
                        disabled
                    />
                </span>
            );
        },
    },
    {
        title: translate('columns.promo'),
        dataIndex: 'promotion',
        sorter: false,
        width: '6%',
        render: (text): React.ReactNode => {
            return (
                <span>
                    {isNaN(text) ? text : `${Number(text).toFixed(2)}%`}
                </span>
            );
        },
    },
    {
        title: translate('columns.discount'),
        dataIndex: 'discount',
        sorter: true,
        width: '10%',
        render: (text, record): React.ReactNode => {
            return (
                <Row justify="space-between" align="middle" gutter={10}>
                    <Input
                        placeholder={'0'}
                        value={record.discount}
                        defaultValue={record.discount}
                        addonAfter={'%'}
                        readOnly
                        disabled
                    />
                </Row>
            );
        },
    },
    {
        title: translate('columns.subTotal'),
        dataIndex: 'total',
        sorter: true,
        width: '15%',
        render: (text, record): React.ReactNode => {
            const { selectedMoeda } = useSelector(orderItemsSelector);
            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {formatMoney(selectedMoeda?.simbolo, text)}
                    {(record.semaforo || record.semaforoCor) && (
                        <Dot
                            background={
                                record.semaforo
                                    ? record.semaforo
                                    : record.semaforoCor
                            }
                        />
                    )}
                </span>
            );
        },
    },
];

export const orderColumn = {
    title: 'Ordem',
    dataIndex: 'order',
    sorter: false,
    width: '8%',
    render: (text, record): React.ReactNode => {
        const dispatch = useDispatch();
        return (
            <span>
                <Input
                    placeholder={'0'}
                    step={1}
                    defaultValue={record.order}
                    type={'text'}
                    min={'0'}
                    onBlur={(e) => {
                        dispatch(
                            updateItemApi({
                                id: record.id,
                                order: e.target.value,
                            }),
                        );
                    }}
                />
            </span>
        );
    },
};

export const orderColumnReadOnly = {
    title: translate('columns.sequence'),
    dataIndex: 'order',
    sorter: false,
    width: '8%',
    render: (text, record): React.ReactNode => {
        return (
            <span>
                <Input
                    placeholder={'0'}
                    step={1}
                    defaultValue={record.order}
                    type={'number'}
                    min={'0'}
                    readOnly
                    disabled
                />
            </span>
        );
    },
};

export const filterTypes = [
    {
        name: 'todos',
        description: translate('filters.all'),
    },
    {
        name: 'cnpj',
        description: translate('filters.cnpj'),
    },
    {
        name: 'nome',
        description: translate('filters.name'),
    },
    {
        name: 'codigo',
        description: translate('filters.clientCode'),
    },
    {
        name: 'cidade',
        description: translate('filters.city'),
    },
    {
        name: 'estado',
        description: translate('filters.state'),
    },
    {
        name: 'tipo',
        description: translate('filters.type'),
    },
];

export const filterTypesOrder = [
    {
        name: 'CNPJ_CLIENTE',
        description: translate('filters.cnpj'),
    },
    {
        name: 'NOME_CLIENTE',
        description: translate('filters.name'),
    },
    {
        name: 'CODIGO_CLIENTE',
        description: translate('filters.clientCode'),
    },
    {
        name: 'CIDADE_CLIENTE',
        description: translate('filters.city'),
    },
    {
        name: 'ZONA_CLIENTE',
        description: translate('filters.state'),
    },
    {
        name: 'TIPO_PEDIDO',
        description: translate('filters.orderType'),
    },
];

export const filterTypesOrderHistory = [
    {
        name: 'CNPJ_CLIENTE',
        description: 'CNPJ',
    },
    {
        name: 'NOME_CLIENTE',
        description: 'Nome',
    },
    {
        name: 'PEDIDO',
        description: 'Pedido',
    },
    {
        name: 'CODIGO_CLIENTE',
        description: 'Código do Cliente',
    },
    {
        name: 'CIDADE_CLIENTE',
        description: 'Cidade',
    },
    {
        name: 'ZONA_CLIENTE',
        description: 'Estado',
    },
    {
        name: 'TIPO_PEDIDO',
        description: 'Tipo de Pedido',
    },
];

export const filterOptions = [
    { value: 'all', label: 'Todos' },
    { value: 'name', label: 'Nome' },
    { value: 'code', label: 'Código' },
];

export const filterOptionsTranspo = [
    { value: 'all', label: translate('filters.all') },
    { value: 'name', label: translate('filters.name') },
    { value: 'codigoTipoTransporte', label: translate('filters.transport') },
];

export const filterModalSelectOptions = [
    { value: 'todos', label: translate('filters.all') },
    { value: 'nome', label: translate('filters.name') },
    { value: 'codigo', label: translate('filters.code') },
];
