import { Row } from "antd"
import { useDispatch, useSelector } from "react-redux";
import { authSelector, fetchUserApprovalGroup } from "@redux/slices/auth";
import { COUNTRY } from "@config/env";
import { CardHome } from "./card-home"
import meta from '../../meta.json'
import { AdminListCardsHome } from "./profile/admin";
import { ROLES_ENUM } from "src/enums/user";
import { PromotorListCardsHome } from "./profile/promotor";
import { useEffect } from "react";
import { fetchTransferDraftOrders } from "@redux/slices/transfer-order/draft";
import { fetchApprovals, fetchDrafts, resetOrderStates } from "@redux/slices/order";
import { fetchScheduled } from "@redux/slices/order/scheduled";

export const ListCardsHome: React.FC = () => {
  const authState = useSelector(authSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTransferDraftOrders({ page: 0, size: 20 }));
    dispatch(fetchDrafts());
    dispatch(resetOrderStates());
    dispatch(fetchScheduled());
    dispatch(fetchApprovals({ current: 1, pageSize: 9999 }));
    dispatch(fetchUserApprovalGroup());
  }, []);

  if (authState.isPromoter && authState.currentUser.roles.length === 1) {
    return <PromotorListCardsHome />
  }

  return <AdminListCardsHome />
}