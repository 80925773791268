/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { Upload } from './styled';

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

interface ImageUploadProps {
    defaultImage: string;
    placeholder?: string;
    onChange: (img: string) => void;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
    defaultImage,
    onChange,
    placeholder,
}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [imgUrl, setImgUrl] = useState<string>('');

    const handleChange = (
        info: UploadChangeParam<UploadFile<unknown>>,
    ): void => {
        // if (info.file.status === 'uploading') {
        //     setLoading(true);
        //     return;
        // }
        // if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, (imageUrl) => {
            setImgUrl(imageUrl);
            onChange(imageUrl);
            setLoading(false);
        });
        // }
    };

    const uploadButton = (
        <div>
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>
                {placeholder ? placeholder : 'Foto de Perfil'}
            </div>
        </div>
    );

    useEffect(() => {
        setImgUrl(defaultImage);
    }, [defaultImage]);

    return (
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
            {imgUrl ? (
                <img src={imgUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
                uploadButton
            )}
        </Upload>
    );
};
