import { DistributorRequest, DistributorRequestApi, DistributorRequestParams } from './request';
import { DistributorResponse, DistributorResponseApi } from './response';

export * from './response';
export * from './request';

export * from './response';
export * from './request';


export namespace Distributor {
  export type Request = DistributorRequest;
  export const Request = DistributorRequest;
  export const RequestApi = DistributorRequestApi;

  export type Response = DistributorResponse;
  export const Response = DistributorResponse;
  
  export type ResposeApi = DistributorResponseApi
  export const ResposeApi = DistributorResponseApi

  export type Params = DistributorRequestParams;
}