import { translate } from '@components/i18n';
import { SHOW_PROTOCOL } from '@config/env';
import { OrderHistoryResponse } from '@models/report';
import { orderItemsSelector } from '@redux/slices/order';
import { formatMoney } from '@utils/money';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Comparison from 'src/enums/comparison';

export const columns = [
    {
        title: translate('columns.order'),
        dataIndex: 'order',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'type',
        sorter: true,
        width: '10%',
    },
    SHOW_PROTOCOL && {
        title: translate('columns.protocol'),
        dataIndex: 'protocol',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'division',
        sorter: true,
        width: '5%',
    },
    {
        title: translate('columns.client'),
        dataIndex: 'customer',
        sorter: true,
        width: '1%',
    },
    {
        title: translate('columns.clientName'),
        dataIndex: 'customerName',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.totalAmount'),
        dataIndex: 'totalAmount',
        sorter: true,
        width: '10%',
        render: (_, record: OrderHistoryResponse): React.ReactNode => {
            const {moedaSimbolos} = useSelector(orderItemsSelector)
            const moeda = moedaSimbolos.find(item => item.codigoMoeda === record?.codigoMoeda)

            return (
                <span>{formatMoney(moeda?.simbolo, record.totalAmount)}</span>
            )
        },
    },
    {
        title: translate('columns.deadline'),
        dataIndex: 'deadline',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.status'),
        dataIndex: 'status',
        sorter: true,
        width: '10%',
        render: (_, record: OrderHistoryResponse): React.ReactNode => {
            return translate(`columns.${record.status}`);
        },
    },
    {
        title: translate('columns.date'),
        dataIndex: 'date',
        sorter: true,
        width: '10%',
        render: (_, record: OrderHistoryResponse): React.ReactNode => {
            return moment(record.date).format('DD/MM/YYYY')
        },
    },
];

export const filterTypes = [
    {
        name: 'pedido',
        description: translate('filters.order'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    {
        name: 'codigo_cliente',
        description: translate('filters.client'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
    SHOW_PROTOCOL && {
        name: 'protocolo',
        description: translate('filters.protocol'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'divisao',
        description: translate('filters.division'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'tipo_pedido',
        description: translate('filters.orderType'),
        comparisonFilter: [Comparison.EQ, Comparison.NEQ],
    },
    {
        name: 'nome_cliente',
        description: translate('filters.clientName'),
        comparisonFilter: [
            Comparison.CONTAINS,
            Comparison.NOT_CONTAINS,
            Comparison.BEGINS_WITH,
            Comparison.EQ,
            Comparison.NEQ,
        ],
    },
    {
        name: 'valor_total',
        description: translate('filters.totalAmount'),
        comparisonFilter: [
            Comparison.EQ,
            Comparison.NEQ,
            Comparison.GT,
            Comparison.LT,
        ],
    },
];

export const filterTypesStatus = [
    {
        name: 'APROVADO',
        description: translate('columns.APROVADO'),
    },
    {
        name: 'PENDENTE',
        description: translate('columns.PENDENTE'),
    },
    {
        name: 'RECUSADO',
        description: translate('columns.RECUSADO'),
    },
    {
        name: 'REPROVADO',
        description: translate('columns.REPROVADO'),
    },
    {
        name: 'RASCUNHO',
        description: translate('columns.RASCUNHO'),
    },
    {
        name: 'PROGRAMADO',
        description: translate('columns.PROGRAMADO'),
    },
    {
        name: 'INTEGRADO',
        description: translate('columns.INTEGRADO'),
    },
    {
        name: 'FATURADO',
        description: translate('columns.FATURADO'),
    },
    {
        name: 'ENTREGUE',
        description: translate('columns.ENTREGUE'),
    },
];