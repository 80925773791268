import React, { useContext } from 'react';
import { ApprovalContext } from '../context/context';
import { translate } from '@components/i18n';

export const FromTransfer: React.FC = () => {
  const { order } = useContext(ApprovalContext);

  if (!order?.fromPedidoTransfer) return null;

  return (
    <div style={{
      background: 'green',
      textAlign: 'center',
      color: '#FFF',
      padding: '10px',
      borderBottomRightRadius: '1rem',
      borderBottomLeftRadius: '1rem'
    }}>
      {translate("general.fromTransferOrder")}
    </div>
  );
};
