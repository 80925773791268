import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Page } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { customerSelector, fetchAvailablePlatforms, fetchAvailableTypes, fetchCategories, fetchCompanies, } from '@redux/slices/order';
import { Title } from '@styles/components/text';
import { columns, filterTypes } from './constants';
import { SorterResult } from 'antd/lib/table/interface';
import { CustomerResponse } from '@models/customer';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { LeftOutlined, SearchOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';
import { Distributor } from '@models/distributor';
import { distributorSelector, fetchDistributors } from '@redux/slices/distributor';
import { updateTransferDistributor } from '@redux/slices/transfer-order/order';
import { CATEGORY_WITH_VALUE_AR, SHOW_CATEGORY } from '@config/env';

const defaultValues = {
    category: 'Sold'
}

export const SelectDistributor: React.FC = () => {
    const selectDistributorState = useSelector(distributorSelector);
    const selectCustomerState = useSelector(customerSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();

    const loadData = (
        pagination: any,
        _,
        sorter: SorterResult<unknown>,
    ): void => {

        const params: Distributor.Params = {
            page: pagination.current - 1,
            size: pagination.pageSize,
            filterType: form.getFieldValue('filterType'),
            filterString: form.getFieldValue("filterString"),
            categoria: form.getFieldValue('category'),
            companhiaCodigo: form.getFieldValue("companhiaCodigo")
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order.substring(0, 3)}`
            })
        }

        dispatch(fetchDistributors(params));
    };

    const onSelect = (rowKey: Distributor.Response): void => {
        dispatch(fetchAvailableTypes());
        dispatch(fetchAvailablePlatforms({}))

        dispatch(updateTransferDistributor({ distributor: rowKey }));
        history.push('/create-transfer-order/select-type');
    };

    const search = (): void => {
        dispatch(
            fetchDistributors({
                page: 0,
                size: 20,
                filterType: form.getFieldValue("filterType") || "TODOS",
                filterString: form.getFieldValue("filterString"),
                categoria: form.getFieldValue('category'),
                companhiaCodigo: form.getFieldValue("companhiaCodigo")
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchCompanies())

        if (SHOW_CATEGORY) {
            dispatch(fetchCategories())
        }
    }, []);

    useEffect(() => {
        if (SHOW_CATEGORY) {
            if (selectCustomerState.isSuccessCategories) {
                if (selectCustomerState.categories.length > 0) {
                    const hasSold = selectCustomerState.categories.find(category => category.nome === 'Sold')
                    if (hasSold) {
                        dispatch(fetchDistributors({
                            page: 0,
                            size: 20,
                            categoria: defaultValues.category,
                        }))
                    }
                } else {
                    dispatch(fetchDistributors({
                        page: 0,
                        size: 20,
                    }))
                }

            }
        } else {
            dispatch(fetchDistributors({
                page: 0,
                size: 20,
            }))
        }
    }, [selectCustomerState.isSuccessCategories])

    const getInitialValues = (): any => {
        const initialValues = {
            filterType: 'TODOS'
        }
        if (SHOW_CATEGORY && CATEGORY_WITH_VALUE_AR) {
            Object.assign(initialValues, { category: defaultValues.category })
        }
        return initialValues
    }

    return (
        <Page
            title={translate('pages.searchDistributor')}
            greyBackground
            withPadding
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.searchDistributor')}
                        </Title>
                    </Row>
                    <ShadowContainer>
                        <Row justify="center">
                            <Col span={23}>
                                <Table
                                    columns={columns}
                                    form={form}
                                    pointer
                                    rowKey={(record: CustomerResponse) =>
                                        `${record.id}-${record.company}`
                                    }
                                    dataSource={selectDistributorState?.available || []}
                                    onRowClick={onSelect}
                                    isLoading={selectDistributorState?.isFetching}
                                    onChange={loadData}
                                    onFilterChange={search}
                                    initialValues={getInitialValues()}
                                    filters={[
                                        <FormItem
                                            label={translate('forms.labels.searchFor')}
                                            name="filterType"
                                        >
                                            <Select
                                                placeholder={translate('general.all')}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                                onChange={e => form.setFieldsValue({ filterType: e })}
                                            >
                                                {filterTypes.map((f) => {
                                                    return (
                                                        <Option
                                                            key={f.name}
                                                            value={f.name}
                                                        >
                                                            {f.description}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FormItem>,
                                        <FormItem
                                            label={translate('forms.labels.cia')}
                                            name="companhiaCodigo"
                                            style={{ minWidth: 150 }}
                                        >
                                            <Select
                                                placeholder={translate('general.all')}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                            >
                                                {selectCustomerState.availableCompanies.map(
                                                    (c) => (
                                                        <Option value={c.id}>
                                                            {c.id} - {c.name}
                                                        </Option>
                                                    ),
                                                )}
                                            </Select>
                                        </FormItem>,
                                        !!SHOW_CATEGORY && (
                                            <FormItem
                                                label={translate('forms.labels.category')}
                                                name={'category'}
                                                style={{ minWidth: 150 }}
                                            >
                                                <Select
                                                    placeholder={translate('general.all')}
                                                    dropdownMatchSelectWidth={false}
                                                    loading={selectCustomerState.isFetchingCategories}
                                                    allowClear
                                                >
                                                    {selectCustomerState?.categories.map(
                                                        (c) => (
                                                            <Option value={c.nome}>
                                                                {c.nome}
                                                            </Option>
                                                        )
                                                    )}
                                                </Select>
                                            </FormItem>),
                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Input
                                                placeholder={translate('forms.placeholders.search')}
                                                allowClear
                                                suffix={
                                                    <div
                                                        onClick={search}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <SearchOutlined />
                                                    </div>
                                                }
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.code === `Enter` ||
                                                        e.keyCode === 13
                                                    ) {
                                                        search();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    form.setFieldsValue({ filterString: e.target.value })
                                                }
                                            />
                                        </Form.Item>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ShadowContainer>
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
