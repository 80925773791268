import { translate } from '@components/i18n';
import { CampaignResponse } from '@models/campaign';
import { selectCampaign } from '@redux/slices/campaign';
import { Dispatch } from '@reduxjs/toolkit';
import { Button, FormInstance, notification, Row } from 'antd';

export const getCurrentChanged = (
    campaigns: CampaignResponse[],
    changedValue: any,
): CampaignResponse => {
    let campaign = undefined;
    Object.entries(changedValue).map((v) => {
        const [label, value] = v;

        if (!value) return;

        const id = Number(label.replace('camp', ''));
        campaign = campaigns.find((c) => c.id === id);
    });
    return campaign;
};

interface CampaignExclusive {
    exclusiveId: number;
    isExclusive: boolean;
}

export const getCurrentExclusive = (
    campaigns: CampaignResponse[],
    allValues: any,
): CampaignExclusive => {
    let isExclusive = false;
    let exclusiveId = 0;

    Object.entries(allValues).map((v) => {
        const [label, value] = v;

        if (!value) return;

        const id = Number(label.replace('camp', ''));
        const campaign = campaigns.find((c) => c.id === id);

        if (campaign && campaign.exclusivo) {
            isExclusive = campaign.exclusivo;
            exclusiveId = campaign.id;
        }
    });

    return { isExclusive, exclusiveId };
};

export const showExclusiveModal = (
    form: FormInstance,
    selectedCampaigns: CampaignResponse[],
    exclusiveCampaign: CampaignExclusive,
    dispatch: Dispatch<any>,
): void => {
    // const dispatch = useDispatch();
    const key = `open${Date.now()}`;
    const btn = (
        <Row>
            <Button
                type="primary"
                size="small"
                onClick={() => {
                    const obj = {
                        [`camp${exclusiveCampaign.exclusiveId}`]: false,
                    };

                    form.setFieldsValue({ ...obj });
                    notification.close(key);
                }}
            >
                Não
            </Button>
            <Button
                type="primary"
                size="small"
                style={{ marginLeft: 5 }}
                danger
                onClick={() => {
                    form.setFieldsValue(
                        Object.assign(
                            {},
                            ...selectedCampaigns.map((c) => {
                                const o = {};
                                if (
                                    `${exclusiveCampaign.exclusiveId}` ===
                                    `${c.id}`
                                ) {
                                    o[`camp${c.id}`] = true;
                                } else o[`camp${c.id}`] = false;
                                return o;
                            }),
                        ),
                    );
                    dispatch(selectCampaign([exclusiveCampaign.exclusiveId]));
                    notification.close(key);
                }}
            >
                Sim
            </Button>
        </Row>
    );

    notification.open({
        message: translate("columns.exclusiveCampain"),
        description: translate("general.youChoosedAExclusiveCampain"),
        btn,
        key,
        // onClose: close,
    });
};

export const showConflictExclusive = (
    form: FormInstance,
    selectedCampaigns: CampaignResponse[],
    exclusiveCampaign: CampaignExclusive,
    currentCampaign: CampaignResponse,
    dispatch: Dispatch<any>,
): void => {
    const key = `open${Date.now()}`;
    const btn = (
        <Row>
            <Button
                type="primary"
                size="small"
                onClick={() => {
                    const obj = {
                        [`camp${currentCampaign.id}`]: false,
                    };

                    form.setFieldsValue({ ...obj });
                    notification.close(key);
                }}
            >
                Não
            </Button>
            <Button
                type="primary"
                size="small"
                style={{ marginLeft: 5 }}
                danger
                onClick={() => {
                    form.setFieldsValue(
                        Object.assign(
                            {},
                            ...selectedCampaigns.map((c) => {
                                const o = {};
                                if (
                                    `${exclusiveCampaign.exclusiveId}` ===
                                    `${c.id}`
                                ) {
                                    o[`camp${c.id}`] = false;
                                } else o[`camp${c.id}`] = false;
                                return o;
                            }),
                        ),
                    );
                    dispatch(selectCampaign([currentCampaign.id]));
                    notification.close(key);
                }}
            >
                Sim
            </Button>
        </Row>
    );

    notification.open({
        message: translate("general.choosedExclusiveCampain"),
        description: translate("general.youChoosedAExclusiveCampainThatWasChoosedBefore"),
        btn,
        key,
        closeIcon: <div></div>,
        // onClose: close,
    });
};

export const verifyConflictItems = (
    form: FormInstance,
    selectedCampaigns: CampaignResponse[],
    currentCampaign: CampaignResponse,
    dispatch: Dispatch<any>,
    values: number[],
): void => {
    if (selectedCampaigns.length === 0) dispatch(selectCampaign(values));
    let conflictItem = undefined;

    for (const campaign of selectedCampaigns) {
        if (!currentCampaign || campaign.id === currentCampaign.id) continue;
        const conflictCondition = currentCampaign.condicoes
            .filter((c) => c.campo === 'QUANTIDADE_ITEM')
            .find((c) =>
                campaign.condicoes
                    .filter((c) => c.campo === 'QUANTIDADE_ITEM')
                    .some((cc) => cc.numeroItem === c.numeroItem),
            );

        if (conflictCondition) {
            conflictItem = {
                name: campaign.nome,
                where: 'condições',
                itemName: conflictCondition.numeroItem,
            };

            console.warn(conflictCondition, 'Condition conflict');

            break;
        }

        const conflictResult = currentCampaign.resultados.find((c) =>
            campaign.resultados.some((cc) => cc.numeroItem === c.numeroItem),
        );

        if (conflictResult) {
            conflictItem = {
                name: campaign.nome,
                where: 'resultados',
                itemName: conflictResult.numeroItem,
            };
            break;
        }
    }

    if (conflictItem) {
        const key = `open${Date.now()}`;
        const obj = {
            [`camp${currentCampaign.id}`]: false,
        };

        form.setFieldsValue({ ...obj });
        notification.close(key);
        notification.open({
            message: translate("general.conflictCampain"),
            description: translate("general.youChoosedAConflictCampain")
                .replaceAll('#NAME', conflictItem.name) 
                .replaceAll("#ITEM", conflictItem.itemName)
                .replaceAll("#WHERE", conflictItem.where),
            key,
        });
    } else {
        dispatch(selectCampaign(values));
    }
};
