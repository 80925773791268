import { Card } from 'antd';
import styled from 'styled-components';

export const CardTable = styled(Card)`
    width: 100%;
    display: flex;
    flex-direction: column;
    backgroud: '#fff';
    margin: 0 0 0 0;
    border-radius: 8px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
    margin-bottom: 100px;
    .ant-card-body {
        padding-top: 0px;
    }
`;
