import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
    @media only screen and (max-width: 991px){
        width: 95% !important;
    }
    
    .ant-modal-header {
        background: #f0eff0;
        border-radius: 8px 8px 0px 0px;
    }

    .ant-modal-content {
        border-radius: 8px;
    }

    .bold-title .ant-modal-title {
        font-size: 1.6em;
        font-weight: bold;
        margin-top: 15px;
    }

    .full-btn {
        width: 100%;
        font-weight: bold;
        height: 40px;
    }

    .half-btn {
        width: 48%;
        font-weight: bold;
        height: 40px;
    }

    .full-btn.auto-margin {
        margin-left: -10px !important;
    }
`;
