import {
    CampaignResponse,
    CampaignResponseApi,
    Conditions,
} from '@models/campaign';
import {
    brazilianStates,
    operators,
    resultOptions,
} from '@pages/campaign/constants';
import {
    campaignSelector,
    fetchCampaignById,
    oldSeletedCampain,
    selectCampaign,
    selectedCampaignsSelector,
} from '@redux/slices/campaign';
import { divisionValues, fetchDivisions } from '@redux/slices/divisions';
import {
    customerSelector,
    fetchAllAvailablePlatforms,
    fetchAvailablePaymentConditions,
    fetchCompanies,
    orderItemsSelector,
    orderTypeSelector,
} from '@redux/slices/order';
import { ExportButton } from '@styles/components/button';
import { FormItem } from '@styles/components/form';
import {
    Badge,
    Button,
    Col,
    Descriptions,
    Form,
    notification,
    Row,
    Switch,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { CSSProperties } from 'styled-components';
import { CampaignCardWrapper } from './styled';
import { Collapse } from 'antd';
import {
    getCurrentChanged,
    getCurrentExclusive,
    showConflictExclusive,
    showExclusiveModal,
    verifyConflictItems,
} from './functions';
import { translate } from '@components/i18n';
import { ENABLE_PAYMENT_CONDITION } from '@config/env';

const { Panel } = Collapse;

interface SelectCampaignProps {
    bonification?: boolean;
}

export const SelectCampaign: React.FC<SelectCampaignProps> = ({ bonification }) => {
    const { availableCampaignForOrder } = useSelector(campaignSelector);
    const modalContext = useContext(ModalContext);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDivisions());
        dispatch(fetchCompanies());
        dispatch(fetchAvailablePaymentConditions());
        dispatch(fetchAllAvailablePlatforms());
    }, []);

    const openCampaign = (): void => {
        modalContext.setIsOpen(true, <AvailableCampaignsModal bonification={bonification} />, {
            width: '60%',
            title: translate('general.campainsAvailableToOrder'),
        });
    };

    return (
        <>
            {availableCampaignForOrder.length > 0 && (
                <Col>
                    <Badge count={availableCampaignForOrder.length}>
                        <ExportButton
                            onClick={openCampaign}
                            style={{ marginLeft: 0 }}
                        >
                            {translate('forms.buttons.campaigns')}
                        </ExportButton>
                    </Badge>
                </Col>
            )}
        </>
    );
};

interface AvailableCampaignsModalProps {
    bonification?: boolean,
}

const AvailableCampaignsModal: React.FC<AvailableCampaignsModalProps> = ({ bonification }) => {
    const { availableCampaignForOrder } = useSelector(campaignSelector);
    const selectedCampaigns = useSelector(selectedCampaignsSelector);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onChangeValues = (changedValues: any, allValues: any): void => {
        if (!!bonification) return;

        const currentValue = Object.entries(changedValues)

        if (!(currentValue[0][1])) {// disabled campain
            const value = currentValue[0][0].toString().replace('camp', '')
            dispatch(oldSeletedCampain(parseInt(value, 10)))
        }

        const { isExclusive, exclusiveId } = getCurrentExclusive(
            availableCampaignForOrder,
            allValues,
        );

        const selectedCampaign = getCurrentChanged(
            availableCampaignForOrder,
            changedValues,
        );

        const values = Object.entries(allValues).map((v) => {
            const [label, value] = v;

            if (value) return Number(label.replace('camp', ''));
            return null;
        });

        if (
            selectedCampaign?.exclusivo &&
            values.filter((v) => v !== null).length > 1 &&
            isExclusive
        )
            showExclusiveModal(
                form,
                selectedCampaigns,
                {
                    isExclusive,
                    exclusiveId,
                },
                dispatch,
            );
        else if (
            !selectedCampaign?.exclusivo &&
            isExclusive &&
            values.filter((v) => v !== null).length > 1
        )
            showConflictExclusive(
                form,
                selectedCampaigns,
                {
                    isExclusive,
                    exclusiveId,
                },
                selectedCampaign,
                dispatch,
            );
        else
            verifyConflictItems(
                form,
                selectedCampaigns,
                selectedCampaign,
                dispatch,
                values,
            );
    };

    return (
        <>
            <Col span={24} style={{ maxHeight: '60vh', overflow: 'auto' }}>
                <Form
                    form={form}
                    onValuesChange={onChangeValues}
                    initialValues={Object.assign(
                        {},
                        ...selectedCampaigns.map((c) => {
                            const o = {};
                            o[`camp${c.id}`] = true;
                            return o;
                        }),
                    )}
                >
                    {availableCampaignForOrder.map((campaign) => (
                        <CampaignCard campaign={campaign} bonification={bonification} />
                    ))}
                </Form>
            </Col>
        </>
    );
};

interface CampaignCardProps {
    campaign: CampaignResponse;
    bonification?: boolean;
}

interface ErrorState {
    error: boolean;
    item: string;
}

export const CampaignCard: React.FC<CampaignCardProps> = ({ campaign, bonification }) => {
    const { allAvailablePlatforms } = useSelector(orderTypeSelector);
    const divisions = useSelector(divisionValues);
    const { availablePaymentConditions, availableItems } =
        useSelector(orderItemsSelector);
    const { availableCompanies, availableCustomersTypeClassification } =
        useSelector(customerSelector);
    const [canBeSelected, setCanBeSelected] = useState<ErrorState>({
        error: false,
        item: '',
    });
    const [campaignState, setCampaignState] = useState<CampaignResponse>({
        ...campaign,
    });

    const startConditions = (camp: CampaignResponse): any => {
        const condicoes = [];
        for (const condition of camp.condicoes) {
            const cond = conditionsFields().find(
                (c) => c.id === condition.campo,
            );

            const newCondition: Conditions = {
                ...condition,
                campo: cond.name,
                operador: operators.find(
                    (o) => `${o.id}` === `${condition.operador}`,
                )?.name,
                valor:
                    condition.campo === 'QUANTIDADE_ITEM'
                        ? condition.valor
                        : cond.options
                            .map((c) => ({ id: `${c.id}`, name: c.name }))
                            .filter((o) =>
                                condition.valor
                                    .split(';')
                                    .some((v) => o.id === v),
                            )
                            .map((c) => c.name)
                            .join(', '),
            };

            condicoes.push(newCondition);
        }

        return { condicoes };
    };

    const startResult = (camp: CampaignResponse) => {
        const result = resultOptions.find((c) => `${c.id}` === `${camp.tipo}`);

        return { tipo: result.name, resultados: camp.resultados };
    };

    useEffect(() => {
        if (campaign?.id) {
            (async () => {
                const result = await fetchCampaignById(campaign.id);
                const data = Object.assign(new CampaignResponseApi(), result);

                setCampaignState({
                    ...campaign,
                    ...data.toCampaignResponse(),
                    dataInicio: data.dataInicio,
                    dataFim: data.dataFim,
                    ...startConditions(data.toCampaignResponse()),
                    ...startResult(data.toCampaignResponse()),
                });
            })();
        }
    }, [campaign]);

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const conditionsFields = () => {
        return [
            {
                id: 'QUANTIDADE_ITEM',
                name: translate('columns.itemQtd'),
                options: [],
            },
            {
                id: 'CONDICAO_PAGAMENTO',
                name: translate('columns.paymentCondition'),
                options: availablePaymentConditions.map((c) => ({
                    id: c.id,
                    name: c.description,
                })),
                disabled: !ENABLE_PAYMENT_CONDITION,
            },
            {
                id: 'FILIAL',
                name: translate('columns.branch'),
                options: allAvailablePlatforms.map((c) => ({
                    id: c.id,
                    name: `${c.id} - ${c.description}`,
                })),
            },
            {
                id: 'DIVISAO',
                name: translate('columns.division'),
                options: divisions.map((c) => ({
                    id: c.id,
                    name: c.nome,
                })),
            },
            {
                id: 'CLASSIFICACAO_CLIENTE',
                name: translate('columns.clientClassification'),
                options: availableCustomersTypeClassification.map((c) => ({
                    id: c.tipo,
                    name: `${c.tipo}`,
                    rm: true,
                })),
            },
            {
                id: 'TIPO_CLIENTE',
                name: translate('columns.clientType'),
                options: availableCompanies.map((c) => ({
                    id: c.id,
                    name: c.name,
                })),
            },
            {
                id: 'ESTADO_CLIENTE',
                name: translate('columns.stateOfClient'),
                options: brazilianStates.map((c) => ({
                    id: c,
                    name: c,
                })),
            },
        ];
    };

    useEffect(() => {
        const error: ErrorState = {
            error: false,
            item: '',
        };

        for (const iterator of campaignState.condicoes) {
            if (iterator.campo !== translate('columns.itemQtd')) continue;
            const item = availableItems.find(
                (i) => i.number === iterator.numeroItem,
            );
            if (!item) {
                error.error = true;
                error.item = iterator.numeroItem;
                break;
            }
        }
        if (!error.error)
            for (const iterator of campaignState.resultados) {
                if (!iterator.numeroItem) continue;
                const item = availableItems.find(
                    (i) => i.number === iterator.numeroItem,
                );
                if (!item) {
                    error.error = true;
                    error.item = iterator.numeroItem;
                    break;
                }
            }

        setCanBeSelected(error);
    }, [availableItems, campaignState]);

    return (
        <CampaignCardWrapper>
            <Collapse defaultActiveKey={['2']} ghost>
                <Panel
                    header={`${campaign.nome}${campaign.exclusivo
                            ? ` (${translate('columns.exclusiveCampain')})`
                            : ''
                        }${canBeSelected.error
                            ? ` - ${translate(
                                'general.noHasNecessaryItems',
                            )} (${canBeSelected.item})`
                            : ''
                        }`}
                    key="1"
                    extra={
                        <Col>
                            <FormItem
                                style={{ marginBottom: 0 }}
                                name={`camp${campaign.id}`}
                                marginBottom={0}
                                valuePropName="checked"
                            >
                                <Switch disabled={canBeSelected.error || !!bonification} />
                            </FormItem>
                        </Col>
                    }
                >
                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item
                            label={translate('columns.startDate')}
                        >
                            {campaign.dataInicio}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={translate('columns.endDate')}
                        >
                            {campaign.dataFim}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={translate('forms.labels.exclusive')}
                        >
                            {campaign.exclusivo
                                ? translate('general.yes').toLocaleUpperCase()
                                : translate('general.no').toLocaleUpperCase()}
                        </Descriptions.Item>
                        <Descriptions.Item label="Condições" span={24}>
                            {campaignState.condicoes.map((c) => (
                                <Row
                                    gutter={20}
                                    style={{
                                        padding: 5,
                                        background: '#f5f5f5',
                                        border: '1px solid rgba(0, 0, 0, .1)',
                                        marginBottom: 5,
                                        borderRadius: 5,
                                    }}
                                >
                                    <Col>
                                        <b>
                                            {translate('forms.labels.field')}:{' '}
                                        </b>
                                        {c.campo}
                                    </Col>
                                    {c.numeroItem && (
                                        <Col>
                                            <b>
                                                {translate(
                                                    'forms.labels.numberItem',
                                                )}
                                                :{' '}
                                            </b>
                                            {c.numeroItem}
                                        </Col>
                                    )}
                                    {c.operador && (
                                        <Col>
                                            <b>
                                                {translate(
                                                    'forms.labels.operator',
                                                )}
                                                :{' '}
                                            </b>
                                            {c.operador}
                                        </Col>
                                    )}
                                    <Col>
                                        <b>
                                            {translate('forms.labels.value')}:{' '}
                                        </b>
                                        {c.valor}
                                    </Col>
                                </Row>
                            ))}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={translate('forms.labels.type')}
                            span={24}
                        >
                            {campaignState.tipo}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={translate('forms.labels.results')}
                            span={24}
                        >
                            {campaignState.resultados.map((c) => (
                                <Row
                                    gutter={20}
                                    style={{
                                        padding: 5,
                                        background: '#f9f9f9',
                                        border: '1px solid rgba(0, 0, 0, .1)',
                                        marginBottom: 5,
                                        borderRadius: 5,
                                    }}
                                >
                                    {c.email && (
                                        <Col>
                                            <b>
                                                {translate(
                                                    'forms.labels.email',
                                                )}
                                                :{' '}
                                            </b>
                                            {c.email}
                                        </Col>
                                    )}
                                    {c.numeroItem && c.numeroItem !== 'string' && (
                                        <Col>
                                            <b>
                                                {translate(
                                                    'forms.labels.numberItem',
                                                )}
                                                :{' '}
                                            </b>
                                            {c.numeroItem}
                                        </Col>
                                    )}
                                    {c.qtd && (
                                        <Col>
                                            <b>
                                                {translate('forms.labels.qtd')}:{' '}
                                            </b>
                                            {c.qtd}
                                        </Col>
                                    )}
                                    {c.desconto && (
                                        <Col>
                                            <b>
                                                {translate('general.discount')}:{' '}
                                            </b>
                                            {c.desconto}%
                                        </Col>
                                    )}
                                </Row>
                            ))}
                        </Descriptions.Item>
                    </Descriptions>
                </Panel>
            </Collapse>
        </CampaignCardWrapper>
    );
};
