/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EditableField } from '@models/editable-field';
import { translate } from '@components/i18n';

export const columns = [
    {
        title: translate('columns.number'),
        dataIndex: 'numero',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.description'),
        dataIndex: 'descricao',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.branch'),
        dataIndex: 'codigoFilial',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.active'),
        dataIndex: 'ativo',
        sorter: false,
        width: '12%',
        render: (_, record): React.ReactNode => {
            return (
                <span>
                    {record?.ativo
                        ? translate('general.yes')
                        : translate('general.no')}
                </span>
            );
        },
    },
];

export function editableFields({ withRow = false,optionsDivisions, optionsAllAvailablePlatforms }): EditableField[] {
    return [
        {
            name: 'numero',
            label: translate('forms.labels.number'),
            type: 'string',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'descricao',
            label: translate('forms.labels.description'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'divisao',
            label: translate('forms.labels.division'),
            type: 'select',
            span: 12,
            placeholder:translate('forms.labels.division'),
            options: optionsDivisions,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.division'),
                },
            ],
        },
        {
            name: 'apresentacao',
            label: translate('forms.labels.presentation'),
            type: 'number',
            span: 12,
        },
        {
            name: 'codigoFilial',
            label: translate('forms.labels.branch'),
            type: 'select',
            placeholder: translate('forms.labels.branch'),
            options: optionsAllAvailablePlatforms,
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'codigoTipoTransporte',
            label: translate('forms.labels.transportCode'),
            type: 'text',
            span: 12,
        },
        {
            name: 'codigoLinha',
            label: translate('forms.labels.line'),
            type: 'number',
            removed: !withRow,
            span: 12,
            placeholder: translate('forms.placeholders.line'),
        },
        {
            name: 'compkit',
            label: translate('forms.labels.compkit'),
            type: 'number',
            span: 12,
        },
        {
            name: 'etrade',
            label: translate('forms.labels.etrade'),
            type: 'string',
            span: 12,
        },
        {
            name: 'kit',
            label: translate('forms.labels.kit'),
            type: 'string',
            span: 12,
        },
        {
            name: 'limitado',
            label: translate('forms.labels.limited'),
            type: 'string',
            span: 12,
        },
        {
            name: 'multiplo',
            label: translate('forms.labels.multiply'),
            type: 'number',
            span: 12,
        },
        {
            name: 'controlado',
            label: translate('forms.labels.controlled'),
            type: 'string',
            span: 12,
            rules: [
                {
                    max: 10,
                    message: translate('forms.rules.len10'),
                },
            ],
        },
        {
            name: 'ativo',
            label: translate('forms.labels.active'),
            type: 'boolean',
            span: 12,
            defaultValue: false,
        },
    ];
}
