import React from 'react';
import { InputDateWrapper } from '../styled';
import { DatePickerProps } from 'antd';

// interface InputDateProps extends DatePickerProps {
//     light?: boolean;
// }
type InputDateProps = DatePickerProps & { light?: boolean };

export const InputDate: React.FC<InputDateProps> = ({ light, ...rest }) => {
    return <InputDateWrapper {...rest} light={light} />;
};
