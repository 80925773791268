import { RouterPath } from '@definitions/routes';
import { authSelector } from '@redux/slices/auth';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import authRoute from './auth';

interface PrivateRouteProps extends RouterPath {
    role?: string[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const RoutePrivate: React.FC<PrivateRouteProps> = (props) => {
    const authState = useSelector(authSelector);

    return authState.currentUser ? (
        props.role ? (
            authState.currentUser.isAdmin ||
            props.role?.some((r) => r === '*') ||
            props.role?.some((r) => authState.currentUser.roles.includes(r)) ? (
                <Route {...props} component={props.render} />
            ) : (
                <Redirect to={'/'} />
            )
        ) : (
            <Route {...props} component={props.render} />
        )
    ) : (
        <Redirect to={authRoute[0].path} />
    );
};

export default RoutePrivate;
