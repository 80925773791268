import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { FormBuilder } from '@components/form';
import {
    customerSelector,
    fetchAvailablePlatformsByDivision,
    fetchCustomerTypes,
    orderTypeSelector,
} from '@redux/slices/order';
import { EditableField } from '@models/editable-field';
import { divisionValues } from '@redux/slices/divisions';
import { conditionsJsonModel, minOrderEditableFields } from '../constants';
import {
    createMinimumOrder,
    fetchMinimumOrderById,
    removeMinimumOrder,
    updateMinOrder,
} from '@redux/slices/min-order';
import { MinOrderResponse, MinOrderResponseApi } from '@models/min-order';

import { minimumOrderSelector } from '@redux/slices/min-order';
import { Loading } from '@styles/components/loader';
import { translate } from '@components/i18n';

interface EditMinOrderModalProps {
    minOrder?: MinOrderResponse;
}

export const EditCampaignModal: React.FC<EditMinOrderModalProps> = ({
    minOrder,
}) => {
    const dispatch = useDispatch();
    const modalContext = useContext(ModalContext);
    const divisions = useSelector(divisionValues);
    const platforms = useSelector(orderTypeSelector);
    const { availableTransportTypes } = useSelector(minimumOrderSelector);
    const { availableCustomersType, allCustomers } =
        useSelector(customerSelector);
    const [form] = Form.useForm<MinOrderResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [internalKey, setInternalKey] = useState<string>('');
    const [started, setStarted] = useState(false);

    const handleAddField = (): void => {
        setFieldState((prev): EditableField[] => {
            const refItem = prev.findIndex((i) => i.name === 'condicoes');
            let lastCondition = 0;

            try {
                lastCondition = Number(
                    prev
                        .filter((i) => i.name.includes('cliente.'))
                        .sort(
                            (a, b) =>
                                Number(b.name.split('.')[1]) -
                                Number(a.name.split('.')[1]),
                        )[0]
                        ?.name?.split('.')[1],
                );
            } catch (e) {
                console.error(e);
            }

            const indexFieldApprovers = Number(lastCondition) + 1 || 1;

            return [
                ...prev.slice(0, refItem),
                ...conditionsJsonModel({
                    indexFieldApprovers,
                    handleRemove: handleRemoveField,
                    customersOptions: allCustomers.map((c) => ({
                        id: c.id,
                        name: `${c.code} - ${c.name}`,
                    })),
                    platforms: platforms.availablePlatformsByDivision.map(
                        (c) => ({
                            id: c.id,
                            name: `${c.id} - ${c.description}`,
                        }),
                    ),
                }),
                ...prev.slice(refItem, prev.length),
            ];
        });

        form.validateFields();
    };

    const handleRemoveField = (index: number): void => {
        setFieldState((prev): EditableField[] => {
            form.setFieldsValue({
                [`cliente.${index}`]: undefined,
                [`origem.${index}`]: undefined,
                [`destino.${index}`]: undefined,
                [`valorminimo.${index}`]: undefined,
            });

            return prev.filter(
                (i) =>
                    !i.name.includes('.') ||
                    !i.name.split('.')[1]?.includes(`${index}`),
            );
        });
        form.validateFields();
    };

    const [fieldState, setFieldState] = useState<EditableField[]>(
        minOrderEditableFields({
            handleAddField,
            divisionOptions: divisions.map((c) => ({
                id: c.id,
                name: c.nome,
            })),
            customerTypesOptions: availableCustomersType.map((c) => ({
                id: Number(c.classificacao),
                name: `${c.classificacao} - ${c.tipo}`,
                rm: true,
            })),
            transportType: availableTransportTypes.map((c) => ({
                id: c.codigo,
                name: c.descricao,
            })),
        }),
    );

    useEffect(() => {
        if (!fieldState.some((e) => e.name.includes('cliente.')) && !minOrder)
            handleAddField();
    }, [fieldState]);

    const startConditions = (approversList) => {
        const validacoes = {};

        approversList.validacoes.forEach((element, i) => {
            (validacoes[`cliente.${i + 1}`] = element.codigoCliente),
                (validacoes[`origem.${i + 1}`] = element.origem),
                (validacoes[`destino.${i + 1}`] = element.destino),
                (validacoes[`valorminimo.${i + 1}`] = element.valorminimo);
        });

        return validacoes;
    };

    const [editCampaignState, setEditCampaignState] =
        useState<MinOrderResponse>({
            ...minOrder,
        });

    const onOk = (values: any): void => {
        const refactorValues = {
            divisao: values.divisao.replace('undefined', ''),
            codigoTipoTransporte: values.codigoTipoTransporte,
            validacoes: Object.keys(values)
                .filter((item) => item.includes('.'))
                .map((item) => {
                    const index = item.split('.')[1];
                    return {
                        codigoCliente: values['cliente.' + index],
                        origem: values['origem.' + index],
                        destino: values['destino.' + index],
                        valorminimo: values['valorminimo.' + index],
                    };
                }),
            excecoes: !values.tiposCliente
                ? []
                : values.tiposCliente.map((i) => ({ tipocliente: i })),
        };

        refactorValues.validacoes = refactorValues.validacoes.filter(
            (value, index, array) => {
                const _value = JSON.stringify(value);
                return (
                    index ===
                    array.findIndex((obj) => {
                        return JSON.stringify(obj) === _value;
                    })
                );
            },
        );

        console.debug(refactorValues, 'refactor values');

        if (!minOrder) addMinOrder(refactorValues);
        else handleUpdateMinOrder(refactorValues);
    };

    const handleUpdateMinOrder = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingNewMinOrder'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateMinOrder({
                data: {
                    id: minOrder.id,
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addMinOrder = (values): void => {
        const key = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringNewMinOrder'),
            key: key,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            createMinimumOrder({
                data: {
                    ...values,
                },
                notificationKey: key,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    useEffect(() => {
        if (minOrder?.id) {
            (async () => {
                setLoading(true);
                const result = await fetchMinimumOrderById(minOrder.id);
                const data = Object.assign(new MinOrderResponseApi(), result);
                setEditCampaignState({
                    ...minOrder,
                    ...data.toMinOrderResponse(),
                    ...startConditions(data.toMinOrderResponse()),
                    tiposCliente: data
                        .toMinOrderResponse()
                        .excecoes.map((p) => p.tipocliente),
                });

                form.setFieldsValue({
                    ...minOrder,
                    ...data.toMinOrderResponse(),
                    ...startConditions(data.toMinOrderResponse()),
                    tiposCliente: data
                        .toMinOrderResponse()
                        .excecoes.map((p) => p.tipocliente),
                });

                if (data.validacoes)
                    data.validacoes.forEach(() => handleAddField());

                dispatch(fetchCustomerTypes({ divisao: data.divisao }));
                dispatch(fetchAvailablePlatformsByDivision(data.divisao));
                setLoading(false);
                setStarted(true);
            })();
        } else {
            setStarted(true);
            setInternalKey(`${new Date().valueOf()}`);
        }
    }, [minOrder]);

    useEffect(() => {
        if (started && minOrder?.id) {
            onStart(editCampaignState);
        }
    }, [started]);

    const onStart = (values: any): void => {
        let fieldsStateClone = [...fieldState];
        for (const obj of Object.entries(values)) {
            if (obj[0].includes('cliente.')) {
                const index = obj[0].split('.')[1];
                const defaultValueItem = fieldsStateClone.find(
                    (c) => c.name === `destino.${index}`,
                );

                if (obj[1]) {
                    defaultValueItem.disabled = true;
                    form.setFieldsValue({ [defaultValueItem.name]: '' });
                } else {
                    defaultValueItem.disabled = false;
                }

                const updatedFieldState = fieldsStateClone.map((item) => {
                    if (item.name === defaultValueItem.name)
                        return defaultValueItem;
                    if (item.name === defaultValueItem.name)
                        return defaultValueItem;
                    return item;
                });

                fieldsStateClone = [...updatedFieldState];
            } else if (obj[0].includes('destino.')) {
                const index = obj[0].split('.')[1];
                const defaultValueItem = fieldsStateClone.find(
                    (c) => c.name === `cliente.${index}`,
                );

                if (obj[1]) {
                    defaultValueItem.disabled = true;
                    form.setFieldsValue({ [defaultValueItem.name]: '' });
                } else {
                    defaultValueItem.disabled = false;
                }
                const updatedFieldState = fieldsStateClone.map((item) => {
                    if (item.name === defaultValueItem.name)
                        return defaultValueItem;
                    if (item.name === defaultValueItem.name)
                        return defaultValueItem;
                    return item;
                });

                fieldsStateClone = [...updatedFieldState];
            }
        }

        setFieldState(fieldsStateClone);
        setInternalKey(`${new Date().valueOf()}`);
        form.validateFields();
    };

    const onChangeValues = (
        changedValues: Partial<MinOrderResponse>,
        allValues: MinOrderResponse,
    ): void => {
        setEditCampaignState({
            ...allValues,
            ...changedValues,
        });
        form.validateFields();
        if (changedValues.divisao) {
            dispatch(fetchCustomerTypes({ divisao: changedValues.divisao }));
            dispatch(fetchAvailablePlatformsByDivision(changedValues.divisao));

            setFieldState((prev) =>
                prev.map((item) => {
                    if (item.name.includes('origem.')) {
                        form.setFieldsValue({
                            [item.name]: undefined,
                        });
                    }
                    return item;
                }),
            );

            form.setFieldsValue({
                tiposCliente: undefined,
            });
        }

        if (Object.keys(changedValues).some((c) => c.includes('cliente.'))) {
            const index = Object.keys(changedValues)[0].split('.')[1];
            const defaultValueItem = fieldState.find(
                (c) => c.name === `destino.${index}`,
            );

            if (Object.values(changedValues)[0]) {
                defaultValueItem.disabled = true;
                form.setFieldsValue({ [defaultValueItem.name]: '' });
            } else {
                defaultValueItem.disabled = false;
            }
        } else if (
            Object.keys(changedValues).some((c) => c.includes('destino.'))
        ) {
            const index = Object.keys(changedValues)[0].split('.')[1];
            const defaultValueItem = fieldState.find(
                (c) => c.name === `cliente.${index}`,
            );

            if (Object.values(changedValues)[0]) {
                defaultValueItem.disabled = true;
                form.setFieldsValue({ [defaultValueItem.name]: '' });
            } else {
                defaultValueItem.disabled = false;
            }
        }
    };

    useEffect(() => {
        setFieldState((prev) =>
            prev.map((item) => {
                if (item.name === 'tiposCliente') {
                    item.options = availableCustomersType.map((c) => ({
                        id: c.classificacao,
                        name: `${c.classificacao} - ${c.tipo}`,
                    }));
                }
                return item;
            }),
        );
    }, [availableCustomersType]);

    useEffect(() => {
        setFieldState((prev) =>
            prev.map((item) => {
                if (item.name.includes('origem.')) {
                    item.options = platforms.availablePlatformsByDivision.map(
                        (c) => ({
                            id: c.id,
                            name: `${c.id} - ${c.description}`,
                        }),
                    );
                }
                return item;
            }),
        );
    }, [platforms.availablePlatformsByDivision]);

    const handleDeleteMinOrder = (): void => {
        dispatch(removeMinimumOrder(minOrder.id));

        modalContext.handleClose();
    };

    if (loading || !started || !internalKey) return <Loading />;

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{
                        ...editCampaignState,
                    }}
                    onFinish={onOk}
                    form={form}
                    fields={fieldState}
                    withDelete={!!minOrder}
                    onDelete={handleDeleteMinOrder}
                />
            </Col>
            <Col span={1} />
        </Row>
    );
};
