import React, { useEffect } from 'react';
import { Col } from 'antd';
import { useDispatch } from 'react-redux';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { Header } from './components/header';
import { Body } from './components/body';
import { fetchApprovalGroups } from '@redux/slices/approval-groups';
import { fetchAvailableTypes } from '@redux/slices/order';
import { fetchDivisions } from '@redux/slices/divisions';
import { fetchUsersByRoles } from '@redux/slices/users';
import { CardTable } from '@components/table/styled';
import { translate } from '@components/i18n';

export const ApprovalGroups: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchApprovalGroups());
        dispatch(fetchAvailableTypes());
        dispatch(fetchDivisions());
        dispatch(fetchUsersByRoles({
            data: {
                roles: ["CONTROLADORIA", "APROVADOR"]
            }
        }));
    }, []);

    return (
        <Page
            title={translate('pages.approvalGroup')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Col span={22} style={{ alignSelf: 'center' }}>
                <h2 style={{ marginTop: '15px' }}>
                    {translate('titlePages.approvalGroup')}
                </h2>
                <CardTable>
                    <Header />
                    <Body />
                </CardTable>
            </Col>
        </Page>
    );
};
