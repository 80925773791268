declare global {
    interface Number {
        toMoney(): string;
    }
}

Number.prototype.toMoney = function () {
    return this.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
    });
};

export {};
