import React, { memo } from 'react';
import { Divider, Row } from 'antd';
import { SolidBackground } from '@styles/components/wrapper';
import { Title } from '@styles/components/text';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { useSelector } from 'react-redux';
import { transferOrderMoneySymbolSelector, transferOrderSelector } from '@redux/slices/transfer-order/order';

export const HistoryShowFooter: React.FC = memo(() => {
  const transferOrder = useSelector(transferOrderSelector);
  const symbol = useSelector(transferOrderMoneySymbolSelector)

  return (
    <SolidBackground isWhite paddingBottom={10}>
      <Row style={{ width: '100%', marginTop: 10 }}>
        <Divider type="horizontal" style={{ margin: '0px' }} />
        <Row
          justify="end"
          style={{ width: '100%', marginTop: 10 }}
          align="top"
        >
          <Title marginRight={'5px'} level={4} blue>
            {translate('columns.orderAmount')}:
          </Title>
          <Title
            blue
            marginTop={'0px !important'}
            marginRight={'40px'}
            level={4}
          >
            {formatMoney(symbol, transferOrder?.order?.valorTotal ?? 0)}
          </Title>
        </Row>
      </Row>
    </SolidBackground>
  );
});
