import { IS_ARG } from "@config/env";

const parameterList: string[] = [
    'APROVADOR_ICMS',
    'EMAILS_BUILD',
    'VALOR_MINIMO_PEDIDO',
];

if(IS_ARG){
    parameterList.push('DIA_FATURAMENTO')
}

export { parameterList };
