import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { ItemDeadlineResponse } from '@models/item-deadline';
import {
    fetchItemDeadline,
    itemDeadlineSelector,
} from '@redux/slices/item-deadline';
import { Col } from 'antd';
import { SorterResult, FilterValue } from 'antd/lib/table/interface';
import React, { memo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { columns } from '../constants';
import { EditModal } from './edit-modal';

export const Body: React.FC = memo(() => {
    const dispatch = useDispatch();
    const itemData = useSelector(itemDeadlineSelector);
    const modalContext = useContext(ModalContext);

    const edit = (
        data: ItemDeadlineResponse,
        _: number,
        event: React.MouseEvent<HTMLElement, MouseEvent>,
    ): void => {
        if (
            !`${event.target}`.includes('SVG') &&
            !`${event.target}`.includes('Button') &&
            !`${event.target}`.includes('Span')
        )
            modalContext.setIsOpen(
                true,
                <EditModal
                    data={data}
                    key={`editing-item-${data.numeroItem}`}
                />,
                {
                    title: `${translate('general.editItem')} - ${
                        data.numeroItem
                    }`,
                    width: '40%',
                },
            );
    };

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        _: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchItemDeadline({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    return (
        <Col>
            <Table
                columns={columns}
                rowKey={(record: ItemDeadlineResponse) => `${record.id}`}
                dataSource={itemData.list}
                pagination={itemData.request}
                pointer
                onRowClick={edit}
                scroll={{ y: 'calc(100vh - 380px)' }}
                onChange={loadData}
                isLoading={itemData.isFetching}
            />
        </Col>
    );
});
