import styled, { css } from 'styled-components';

interface CommentBackgroundProps {
    sender?: boolean;
}

export const CommentBackground = styled.div<CommentBackgroundProps>`
    background-color: #f0eff0;
    padding: 0px 10px;
    margin-bottom: 4px;
    border-radius: 8px;

    ${({ sender }) =>
        sender &&
        css`
            background-color: #dbf6ff;

            .ant-comment-content-author {
                flex-direction: row-reverse;
            }
        `}
`;
