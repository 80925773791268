import React, { useEffect, useState } from 'react';

import { Input } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, message, Popconfirm, Row } from 'antd';
import {
    customerSelector,
    draftsDashboardValues,
    draftsSelector,
    DraftsTable,
    fetchCompanies,
    fetchConvenio,
    fetchCustos,
    fetchDraftsDashboard,
    fetchPdiscqt,
    fetchSemaphores,
    removeDrafts,
    sendOrder,
} from '@redux/slices/order';
import { draftsColumns, filterTypesOrder } from '../constants';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from '@styles/components/button';
import { fetchApprovalMarketing } from '@redux/slices/approval-marketing';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { translate } from '@components/i18n';

export const DraftsBody: React.FC = () => {
    const draftsState = useSelector(draftsDashboardValues);
    const draftsStateSelector = useSelector(draftsSelector);
    const [queryState, setQueryState] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<DraftsTable[]>([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const selectCustomerState = useSelector(customerSelector);
    const [form] = Form.useForm();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        dispatch(
            fetchDraftsDashboard({
                ...draftsStateSelector.request,
                ...values,
                filterValue: queryState,
            }),
        );
    };

    const search = (): void => {
        dispatch(fetchDraftsDashboard({
            ...draftsStateSelector.request,
            filterValue: queryState,
        }));
    };

    useEffect(() => {
        dispatch(fetchDraftsDashboard({
            pageSize: 10,
            current: 1,
            filterValue: '',
            filterBy: '',
            company: '',
            categoria: ''
        }));
    }, []);

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        _: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchDraftsDashboard({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    const onSearch = (value: string): void => {
        setQueryState(value);
    };

    const onSelect = (rowKey: DraftsTable): void => {
        if (rowKey.bonification) {
            history.push(
                '/order/marketing-bonification?draft=true&marketing=true&ref=' +
                rowKey.id,
            );
        } else {
            history.push(
                `/create-order/select-items?draft=true&ref=${rowKey.id}`,
            );
        }

    };

    const onChange = (_: React.Key[], selectedRows: DraftsTable[]): void => {
        setSelectedRows(selectedRows);
    };

    const deleteDrafts = (): void => {
        const draftRef = `${new Date().valueOf()}`;

        message.loading({
            content: translate('general.deletingOrders'),
            key: draftRef,
            duration: 2,
            className: 'message-styled',
        });

        dispatch(removeDrafts({
            notificationKey: draftRef,
            onSuccess: () => {
                setSelectedRows([])
                dispatch(fetchDraftsDashboard(draftsStateSelector.request));
            },
            data: selectedRows,
        }));
    };

    useEffect(() => {
        dispatch(fetchCompanies());
        dispatch(fetchCustos());
        dispatch(fetchConvenio());
        dispatch(fetchPdiscqt());
        dispatch(fetchSemaphores());
        dispatch(fetchApprovalMarketing());
    }, []);

    const send = (): void => {
        const draftRef = `${new Date().valueOf()}`;

        message.loading({
            content: translate('general.sendingOrders'),
            key: draftRef,
            duration: 2,
            className: 'message-styled',
        });

        dispatch(sendOrder({
            notificationKey: draftRef,
            onSuccess: () => {
                setSelectedRows([])
                dispatch(fetchDraftsDashboard(draftsStateSelector.request));
            },
            data: selectedRows.map((c) => Number(c.id)),
        }));
    };

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={23}>
                    <Table
                        columns={draftsColumns}
                        rowKey={(record: DraftsTable) => `${record.id}`}
                        pointer
                        rowSelection={{
                            selectedRowKeys: selectedRows.map((c) => Number(c.id)),
                            onChange,
                            fixed: true,
                        }}
                        dataSource={draftsState}
                        pagination={draftsStateSelector.request}
                        onChange={loadData}
                        isLoading={false}
                        onRowClick={onSelect}
                        onFilterChange={onFiltersChange}
                        scroll={{ y: '40vh' }}
                        form={form}
                        filters={[
                            <FormItem
                                label={translate('forms.labels.searchFor')}
                                name={'filterBy'}
                            >
                                <Select
                                    placeholder={translate(
                                        'forms.placeholders.chooseFilter',
                                    )}
                                    allowClear
                                >
                                    {filterTypesOrder.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.cia')}
                                name={'company'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                >
                                    {selectCustomerState.availableCompanies.map(
                                        (c) => (
                                            <Option value={c.id}>
                                                {c.id} - {c.name}
                                            </Option>
                                        ),
                                    )}
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={search}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                    onChange={(e) => onSearch(e.target.value)}
                                />
                            </Form.Item>,
                        ]}
                    />
                </Col>
            </Row>
            {selectedRows.length > 0 && (
                <Row justify="center" style={{ paddingBottom: 20 }}>
                    <Col span={23}>
                        <Row justify={'end'} gutter={10}>
                            <Col>
                                <Popconfirm
                                    title={
                                        <span>
                                            {translate(
                                                'general.orderSelectedModalFirstPart',
                                            )}
                                            <br />{' '}
                                            {translate(
                                                'general.orderSelectedModalSecondPart',
                                            )}
                                        </span>
                                    }
                                    style={{ whiteSpace: 'break-spaces' }}
                                    okText={translate('general.yes')}
                                    cancelText={translate('general.no')}
                                    onConfirm={deleteDrafts}
                                >
                                    <Button danger type="primary" height={40}>
                                        {translate('general.delete')}
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col>
                                {!selectedRows.every(
                                    (r) =>
                                        r.bonification ||
                                        r.hasBonification ===
                                        translate(
                                            'general.yes',
                                        ).toLocaleUpperCase(),
                                ) && (
                                        <Popconfirm
                                            title={translate(
                                                'general.sendOrderWithoutBonification',
                                            )}
                                            okText={translate('general.yes')}
                                            cancelText={translate('general.no')}
                                            onConfirm={send}
                                        >
                                            <Button type="primary" height={40}>
                                                {translate('general.send')}
                                            </Button>
                                        </Popconfirm>
                                    )}
                                {selectedRows.every(
                                    (r) =>
                                        r.bonification ||
                                        r.hasBonification ===
                                        translate(
                                            'general.yes',
                                        ).toLocaleUpperCase(),
                                ) && (
                                        <Button
                                            type="primary"
                                            height={40}
                                            onClick={send}
                                        >
                                            {translate('general.send')}
                                        </Button>
                                    )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </ShadowContainer>
    );
};
