import { translate } from '@components/i18n';
import { ParameterResponse } from '@models/parameter/response';
import { HeaderItem } from '@pages/delegation/constants';

export const parametersColumns = (users): HeaderItem[] => {
    return [
        {
            title: translate('columns.name'),
            dataIndex: 'nome',
            sorter: false,
            width: '20%',
        },
        {
            title: translate('columns.description'),
            dataIndex: 'descricao',
            sorter: false,
            width: '60%',
        },
        {
            title: translate('columns.value'),
            dataIndex: 'valor',
            sorter: false,
            width: '20%',
            render: (valor: string, record: ParameterResponse) => {
                if (record.id === 'APROVADOR_ICMS') {
                    return (
                        <span>
                            {users.find((c) => `${c.id}` === `${valor}`)?.name}
                        </span>
                    );
                }

                return <span>{valor}</span>;
            },
        },
    ];
};
