import React, { memo, useContext } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { SearchGroup } from '@components/input';
import { IFilterSelectItems } from '@redux/slices/order';
import { ExportButton } from '@styles/components/button';
import { ModalContext } from 'src/contexts/modal-context';
import { filterCampaign } from '@redux/slices/campaign';
import { EditCampaignModal } from './edit-modal';
import { translate } from '@components/i18n';

export const Header: React.FC = memo(() => {
    const dispatch = useDispatch();
    const modalContext = useContext(ModalContext);

    const filter = (filter: Partial<IFilterSelectItems>): void => {
        dispatch(filterCampaign(filter.filterValue));
    };

    const addUser = (): void => {
        modalContext.setIsOpen(true, <EditCampaignModal />, {
            title: translate('general.addNewCampain'),
            width: '70%',
        });
    };

    return (
        <Col>
            <Row style={{ marginTop: 20 }} align="middle">
                <Col span={24}>
                    <Row justify={'space-between'} align="middle">
                        <Col span={10}>
                            <SearchGroup
                                onBlur={(e) =>
                                    filter({ filterValue: e.target.value })
                                }
                                allowClear
                                placeholder={translate(
                                    'forms.placeholders.searchByName',
                                )}
                                onSearch={(e) => {
                                    filter({ filterValue: e });
                                }}
                                onChange={(e) => {
                                    filter({ filterValue: e.target.value });
                                }}
                            />
                        </Col>
                        <Col span={14}>
                            <Row justify={'end'}>
                                <Col span={12}></Col>
                                <Col span={5} style={{ marginRight: 10 }}></Col>
                                <Col span={5} style={{ marginRight: 10 }}>
                                    <ExportButton onClick={addUser}>
                                        {translate('forms.buttons.addNewF')}
                                    </ExportButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
});
