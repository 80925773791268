import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 14px;
  }

  .modal-comments .ant-modal-content {
      .ant-modal-body {
        padding: 0px;

        > div {
          box-shadow: none;
          margin-top: 0px !important;
          margin-left: 0px !important;
        }
      }
  }

  .ant-select-dropdown {
    border-radius: 4px !important;
  }

  .confirm-modal-styled {
    border-radius: 4px !important;
    width: 22% !important;

    .ant-modal-content {
      border-radius: 4px !important;
    }

    .ant-modal-confirm-title {
      font-size: 1.4em;
      font-weight: bold;
      display: inline-flex;
      margin-top: 10px;
    }

    .ant-modal-confirm-body > .anticon {
      font-size: 33px;
    }

    .ant-modal-confirm-btns {
      width: 100%;
      float: none;
      justify-content: space-between;
      align-items: center;
      display: flex;

      .ant-btn { 
        width: 48%;
        margin-left: 0 !important;
        border-radius: 8px;
        height: 40px;
      }

      .ant-btn-primary.green-btn:not([class^="ant-btn-dangerous"]) {
        border-color: #118F07;
        background-color: #118F07;
      }

      .ant-btn-primary.dark-blue-btn:not([class^="ant-btn-dangerous"]) {
        border-color: ${({ theme }) => theme.colors.darkBlue};
        background-color: ${({ theme }) => theme.colors.darkBlue};
      }
    }
  }

  .message-styled {
    .ant-message-notice-content {
      background-color: #D0EADB;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      width: 17%;
      min-height: 60px;
      display: inline-flex;
      justify-content: inherit;
      align-items: center;
      position: relative;
      
      ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0px;
        width: 6px;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: linear-gradient(180deg, #004212 0%, rgba(13, 66, 0, 0.62) 100%);;
      }

      .ant-message-custom-content {
        display: flex;
        align-items: center;
      }

      .ant-message-custom-content :last-child {
        font-size: 15px;
        font-weight: bold;
        color: #219653;
      }

      .ant-message-custom-content .anticon {
        margin-right: 15px;
        font-size: 2em;
      }

      .ant-message-success .anticon {
        color: #fff;
        background: linear-gradient(180deg, #004212 0%, rgba(13, 66, 0, 0.62) 100%);
        border-radius: 60%;
      }
    }
  }

  .message-styled-error {
    .ant-message-notice-content {
      background-color: #ffb9b9;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      width: 17%;
      min-height: 60px;
      display: inline-flex;
      justify-content: inherit;
      align-items: center;
      position: relative;
      
      ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0px;
        width: 6px;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: linear-gradient(180deg, #ff4d4f 0%, rgba(255, 77, 79, 0.62) 100%);
      }

      .ant-message-custom-content {
        display: flex;
        align-items: center;
      }

      .ant-message-custom-content :last-child {
        font-size: 15px;
        font-weight: bold;
        color: #ff4d4f;
      }

      .ant-message-custom-content .anticon {
        margin-right: 15px;
        font-size: 2em;
      }

      .ant-message-error .anticon {
        color: #fff;
        background: linear-gradient(180deg, #ff4d4f 0%, rgba(255, 77, 79, 0.62) 100%);
        border-radius: 60%;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .ant-picker-panels {
        justify-content: center !important;
        flex-wrap: wrap !important;
    }
  }
`;

export default GlobalStyle;
