import {
    canSaveReasonOrderSelector,
    orderItemsSelector,
} from '@redux/slices/order';
import { Col, Divider, Row } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SolidBackground } from '@styles/components/wrapper';
import { Title, Text } from '@styles/components/text';
import { Dot } from '@styles/components/button';
import { OrderRequest } from '@models/order/items/request';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

interface SelectItemsTableFooterProps {
    order?: Partial<OrderRequest>;
    marketing?: boolean;
}

export const SelectItemsTableFooter: React.FC<SelectItemsTableFooterProps> =
    memo(({ order, marketing }) => {
        const selectItemsState = useSelector(orderItemsSelector);
        const canSaveReason = useSelector(canSaveReasonOrderSelector);
        const [semaphore, setSemaphore] = useState<string>('');

        useEffect(() => {
            if (order) setSemaphore(order.semaforoPedido);
        }, [order]);

        return (
            <SolidBackground isWhite paddingBottom={10}>
                <Row style={{ width: '100%', marginTop: 10 }}>
                    <Divider type="horizontal" style={{ margin: '0px' }} />
                    <Row
                        justify="end"
                        style={{ width: '100%', marginTop: 10 }}
                        align="top"
                    >
                        <Title marginRight={'5px'} level={4} blue>
                            {translate('general.orderAmout')}:
                        </Title>
                        <Title
                            blue
                            marginTop={'0px !important'}
                            marginRight={'40px'}
                            level={4}
                        >
                            <Row align="middle" gutter={10}>
                                <Col>
                                    {order && (
                                        <>
                                            {formatMoney(
                                                selectItemsState.selectedMoeda?.simbolo,
                                                order.valorPedido ?? 0,
                                            )}
                                        </>
                                    )}
                                    {!order && (
                                        <>
                                            {formatMoney(
                                                selectItemsState.selectedMoeda?.simbolo,
                                                selectItemsState.order
                                                    .valorPedido ?? 0,
                                            )}
                                        </>
                                    )}
                                </Col>
                                <Col>
                                    {!marketing && (
                                        <>
                                            {!order && (
                                                <>
                                                    {selectItemsState.order
                                                        .semaforoPedido && (
                                                        <Dot
                                                            background={
                                                                selectItemsState
                                                                    .order
                                                                    .semaforoPedido
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {semaphore && (
                                                <Dot background={semaphore} />
                                            )}
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Title>
                    </Row>
                </Row>
                {!order && canSaveReason && (
                    <Row align="middle" justify={'end'} gutter={10}>
                        <Col style={{ marginRight: '2%' }}>
                            <Text
                                danger
                                fontWeight={'bold'}
                                style={{ whiteSpace: 'break-spaces' }}
                            >
                                {canSaveReason}
                            </Text>
                        </Col>
                    </Row>
                )}
            </SolidBackground>
        );
    });
