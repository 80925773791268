import { light } from '@definitions/styled-components';
import { InputProps, Typography } from 'antd';
import React, { useState, useEffect, useCallback, Ref } from 'react';
import {
    AutoCompleteWrapper,
    InputWrapper,
    MaskedInputWrapper,
} from './styled';
export * from './components/search-group';
export * from './components/password';
export * from './components/date';
export * from './components/area';
export * from './components/range-picker';

interface IAutoCompleteOptionProps {
    value: string;
}

interface IInputProps extends InputProps {
    mask?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref?: Ref<any>;
    error?: string;
    fromTheme?: boolean;
    allowAutoComplete?: boolean;
    autoCompleteOptions?: IAutoCompleteOptionProps[];
    onAutoCompleteChange?: (name: string, value: string) => void;
}

export const Input: React.FC<IInputProps> = (props) => {
    const {
        mask,
        allowAutoComplete,
        autoCompleteOptions,
        onAutoCompleteChange,
        ...rest
    } = props;
    const { onChange, name, maxLength, placeholder, error } = rest;
    const [options, setOptions] = useState(autoCompleteOptions);

    const setOptionsValue = useCallback(() => {
        setOptions(autoCompleteOptions);
    }, [autoCompleteOptions]);

    useEffect(() => {
        setOptionsValue();
    }, [autoCompleteOptions]);

    const onSearch = (searchText: string): void => {
        setOptions(
            !searchText
                ? autoCompleteOptions
                : autoCompleteOptions.filter((option) =>
                      option.value
                          .toLowerCase()
                          .includes(searchText.toLowerCase()),
                  ),
        );
    };

    const onSelect = (data: string): void => {
        onAutoCompleteChange(name, data);
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        onAutoCompleteChange(name, value);
    };

    if (allowAutoComplete) {
        return (
            <AutoCompleteWrapper
                options={options}
                onSelect={onSelect}
                onSearch={onSearch}
                onBlur={onBlur}
                placeholder={placeholder}
                style={rest.style}
            />
        );
    }

    if (mask)
        return (
            <MaskedInputWrapper
                mask={mask}
                onChange={onChange}
                name={name}
                maxLength={maxLength}
                placeholder={placeholder}
                prefix={rest.prefix}
                style={rest.style}
                addonBefore={rest.addonBefore}
                addonAfter={rest.addonAfter}
                suffix={rest.suffix}
                allowClear={rest.allowClear}
            />
        );

    return (
        <>
            <InputWrapper
                {...rest}
                error={error !== null && error?.length > 0}
            />
            {error && (
                <Typography
                    style={{ fontSize: 12, color: light.colors.danger }}
                >
                    {error}
                </Typography>
            )}
        </>
    );
};
