import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { UserResponse } from '@models/users';
import { approvalMarketingEditableFields } from '../constants';
import { FormBuilder } from '@components/form';
import { allUsers, fetchUsers } from '@redux/slices/users';
import { DelegationResponse } from '@models/delegation';
import {
    createDelegation,
    removeDelegation,
    updateDelegation,
} from '@redux/slices/delegation';
import moment from 'moment';
import { authSelector } from '@redux/slices/auth';
import { translate } from '@components/i18n';

interface EditDelegationModalProps {
    data?: DelegationResponse;
}

export const EditDelegationModal: React.FC<EditDelegationModalProps> = ({
    data,
}) => {
    const users = useSelector(allUsers);
    const { currentUser } = useSelector(authSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);

    const [form] = Form.useForm<UserResponse>();

    const [editDelegationState, setEditDelegationState] =
        useState<DelegationResponse>({
            ...data,
        });

    const modalContext = useContext(ModalContext);

    const onOk = (values): void => {
        if (!data) addNewGroup(values);
        else handleUpdateDelegation(values);
    };

    const handleUpdateDelegation = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingApprovalGroup'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateDelegation({
                data: {
                    id: data.id,
                    ...values,
                    aprovadorId: !currentUser.isAdmin
                        ? currentUser.id
                        : values.aprovadorId,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addNewGroup = (values): void => {
        const key = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringApprovalGroup'),
            key: key,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            createDelegation({
                data: {
                    ...values,
                    aprovadorId: !currentUser.isAdmin
                        ? currentUser.id
                        : values.aprovadorId,
                },
                notificationKey: key,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const onChangeValues = (
        changedValues: Partial<DelegationResponse>,
        allValues: DelegationResponse,
    ): void => {
        setEditDelegationState({
            ...allValues,
            ...changedValues,
        });
    };

    const handleDeleteDelegation = (): void => {
        dispatch(removeDelegation(data.id));

        modalContext.handleClose();
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{
                        ...editDelegationState,
                        dataInicio: !editDelegationState.dataInicio
                            ? undefined
                            : moment(editDelegationState.dataInicio),
                        dataTermino: !editDelegationState.dataTermino
                            ? undefined
                            : moment(editDelegationState.dataTermino),
                    }}
                    onFinish={onOk}
                    form={form}
                    fields={approvalMarketingEditableFields({
                        editionId: data?.id,
                        optionsUser: users.map((item) => ({
                            id: item.id,
                            name: item.login,
                        })),
                        showDescription:
                            editDelegationState.delegacaoMotivo === 'OUTROS',
                    }).filter((item) => {
                        if (item.name === 'aprovadorId' && !currentUser.isAdmin)
                            return false;
                        return true;
                    })}
                    withDelete={currentUser.isAdmin && !!data}
                    onDelete={
                        currentUser.isAdmin ? handleDeleteDelegation : null
                    }
                />
            </Col>
            <Col span={1} />
        </Row>
    );
};
