import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';

export const columns = [
    {
        title: translate('columns.solicitation'),
        dataIndex: 'id',
        sorter: false,
        width: '8%',
        orderMobile: 3,
    },
    {
        title: translate('columns.clientCode'),
        dataIndex: 'codigoCliente',
        sorter: false,
        width: '15%',
        orderMobile: 2,
    },
    {
        title: translate('columns.client'),
        dataIndex: 'nomeCliente',
        sorter: false,
        width: '15%',
        orderMobile: 1,
    },
    {
        title: translate('columns.representerCode'),
        dataIndex: 'usuarioId',
        sorter: false,
        width: '15%',
        orderMobile: 4,
    },
    {
        title: translate('columns.representer'),
        dataIndex: 'usuarioNome',
        sorter: false,
        width: '15%',
        orderMobile: 5,
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: false,
        width: '15%',
        orderMobile: 6,
    },
    {
        title: translate('columns.pandingWith'),
        dataIndex: 'pendenteCom',
        sorter: false,
        width: '15%',
        orderMobile: 7,
    },
    {
        title: translate('columns.status'),
        dataIndex: 'status',
        sorter: false,
        width: '15%',
        orderMobile: 8,
    },
    {
        title: translate('columns.discount'),
        dataIndex: 'desconto',
        sorter: false,
        width: '15%',
        orderMobile: 9,
        render: (text, _) => {
            return <span>{text}%</span>;
        },
    },
    {
        title: translate('columns.total'),
        dataIndex: 'total',
        sorter: false,
        width: '15%',
        orderMobile: 10,
        render: (_, record) => {
            return (
                <span>
                    {formatMoney(
                        undefined,
                        record.pedidos.reduce(
                            (acc, cur) => acc + cur.valorTotal,
                            0,
                        ),
                    )}
                </span>
            );
        },
    },
    {
        title: translate('columns.totalWithDiscount'),
        dataIndex: 'total',
        sorter: false,
        width: '15%',
        orderMobile: 11,
        render: (_, record) => {
            return (
                <span>
                    {formatMoney(
                        undefined,
                        record.pedidos.reduce(
                            (acc, cur) => acc + cur.valorTotal,
                            0,
                        ) -
                            record.pedidos.reduce(
                                (acc, cur) => acc + cur.valorTotal,
                                0,
                            ) *
                                (record.desconto / 100),
                    )}
                </span>
            );
        },
    },
];

export const filterTypesStatus = [
    {
        name: 'aprovado',
        description: translate('general.approved'),
    },
    {
        name: 'pendente',
        description: translate('general.pending'),
    },
    {
        name: 'reprovado',
        description: translate('general.reproved'),
    },
];
