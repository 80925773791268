export interface DelegationRequest {
    aprovadorId: number;
    dataInicio: string;
    dataTermino: string;
    delegacaoMotivo: string;
    delegadoId: number;
    descricao: string;
}

export interface ApproverModel {
    aprovadorId: number;
    divisao: string;
    limite: number;
    aprovadorNome?: string;
}

export class DelegationRequestAPI {
    constructor(data?: DelegationRequest) {
        if (data) {
            this.aprovadorId = data.aprovadorId;
            this.dataInicio = data.dataInicio;
            this.dataTermino = data.dataTermino;
            this.delegacaoMotivo = data.delegacaoMotivo;
            this.delegadoId = data.delegadoId;
            this.descricao = data.descricao;
        }
    }

    aprovadorId: number;
    dataInicio: string;
    dataTermino: string;
    delegacaoMotivo: string;
    delegadoId: number;
    descricao: string;
}
