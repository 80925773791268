import { Avatar, Col, Row, Typography } from 'antd';
import React, { memo } from 'react';
import { Badge, DashboardItemContainer } from './styled';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';

interface DashboardItemProps {
    title: string;
    description: string;
    icon: string;
    path: string;
    badge?: number;
}

export const DashboardItem: React.FC<DashboardItemProps> = memo(
    ({ title, description, icon, path, badge }) => {
        return (
            <>
                {path && (
                    <DashboardItemContainer
                        xs={24}
                        sm={24}
                        md={7}
                        lg={7}
                        xl={7}
                    >
                        <Link to={path}>
                            <Row gutter={20} align="middle">
                                <Col>
                                    <Avatar shape="circle" size={60}>
                                        <ReactSVG src={icon} />
                                    </Avatar>
                                </Col>
                                <Col>
                                    <Row>
                                        <Typography.Text
                                            style={{ opacity: 0.5 }}
                                        >
                                            {title}
                                        </Typography.Text>
                                    </Row>
                                    <Row>
                                        <Typography.Title
                                            style={{
                                                marginBottom: 0,
                                                fontWeight: 400,
                                                opacity: 0.8,
                                            }}
                                            level={
                                                description ===
                                                'Desconto financeiro'
                                                    ? 4
                                                    : 3
                                            }
                                        >
                                            {description}
                                        </Typography.Title>
                                    </Row>
                                </Col>
                            </Row>
                            {badge != null && badge !== 0 && (
                                <Badge>{badge}</Badge>
                            )}
                        </Link>
                    </DashboardItemContainer>
                )}
                {!path && (
                    <>
                        <Row gutter={20} align="middle">
                            <Col>
                                <Avatar shape="circle" size={60}>
                                    <ReactSVG src={icon} />
                                </Avatar>
                            </Col>
                            <Col>
                                <Row>
                                    <Typography.Text style={{ opacity: 0.5 }}>
                                        {title}
                                    </Typography.Text>
                                </Row>
                                <Row>
                                    <Typography.Title
                                        style={{
                                            marginBottom: 0,
                                            fontWeight: 400,
                                            opacity: 0.8,
                                        }}
                                        level={3}
                                    >
                                        {description}
                                    </Typography.Title>
                                </Row>
                            </Col>
                        </Row>
                        {badge != null && badge !== 0 && <Badge>{badge}</Badge>}
                    </>
                )}
            </>
        );
    },
);
