import { hexToRgb } from '@styles/utils';
import { Col } from 'antd';
import styled from 'styled-components';

export const DashboardItemContainer = styled(Col)`
    background-color: ${({ theme }) => theme.colors.white};
    border-left: 6px solid ${({ theme }) => theme.colors.darkBlue};
    border-radius: 5px;
    padding: 20px 15px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;

    &:hover {
        transform: scale(1.03);
    }

    &:active {
        transform: scale(0.95);
    }

    .ant-avatar {
        background: ${({ theme }) => hexToRgb(theme.colors.iconsBg, 0.7)};
    }

    .ant-avatar,
    .ant-avatar-string,
    .ant-avatar-string div {
        display: flex;
        align-items: center;
    }

    svg {
        padding: 5px;
    }

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 6px;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: ${({ theme }) => theme.colors.gradient};
    }

    @media only screen and (min-width: 921px) and (max-width: 1170px) {
        h3 {
            font-size: 1.2rem;
        }
    }

    @media only screen and (min-width: 600px) and (max-width: 920px) {
        h3 {
            font-size: 1rem;
        }
    }
`;

export const Badge = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f0eff0;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.darkBlue};
`;
