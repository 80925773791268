import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { ParameterResponse } from '@models/parameter/response';
import { parameterSelector } from '@redux/slices/config';
import { userValuesSelect } from '@redux/slices/users';
import { Col } from 'antd';
import React, { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { parametersColumns } from '../constants';
import { EditParameterModal } from './edit-modal';

export const Body: React.FC = memo(() => {
    const parameters = useSelector(parameterSelector);
    const modalContext = useContext(ModalContext);
    const userList = useSelector(userValuesSelect);

    const editParameter = (data: ParameterResponse): void => {
        modalContext.setIsOpen(
            true,
            <EditParameterModal
                data={data}
                key={`editing-parameter-${data.id}`}
            />,
            {
                title: `${translate('general.editParameter')} - ${data.id}`,
                width: '40%',
            },
        );
    };

    return (
        <Col style={{ width: '90vw' }}>
            <Table
                columns={parametersColumns(userList)}
                rowKey={(record: ParameterResponse) => `${record.id}`}
                dataSource={parameters.availableParameters}
                pagination={undefined}
                pointer
                isLoading={parameters.isFetching}
                onRowClick={editParameter}
            />
        </Col>
    );
});
