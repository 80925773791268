import styled, { css } from 'styled-components';
import { Collapse } from 'antd';

export const Collapsable = styled(Collapse)`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    border: none;
    position: relative;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);

    .ant-collapse-item {
        border-radius: 4px;
    }

    .ant-collapse-content,
    .ant-collapse > .ant-collapse-item,
    .ant-collapse-item {
        border: none !important;
    }

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 6px;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: ${({ theme }) => theme.colors.gradient};
    }
`;

export const SelectCustomer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px 5px;
    background: #f0eff0;
    border-radius: 20px;
    color: ${({ theme }) => theme.colors.darkBlue};
`;

interface ArrowButtonProps {
    danger?: boolean;
}

export const ArrowButton = styled.div<ArrowButtonProps>`
    color: #219653;
    transition: all 0.4s easy;
    cursor: pointer;
    align-items: flex-end;
    display: flex;

    ${({ danger }) =>
        danger &&
        css`
            color: #c10b0b;
        `}

    :hover {
        transform: scale(1.1);
    }

    :active {
        transform: scale(0.9);
    }
`;

export const EmptyItemMessage = styled.span`
    position: absolute;
    white-space: pre;
    width: 137%;
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    align-items: center;
    display: flex;
`;

export const CampaignCardWrapper = styled.div`
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 20px;
`;

interface CampaignIndicatorProps {
    color: string;
}

export const CampaignIndicator = styled.div<CampaignIndicatorProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 20px;
    width: 20px;
    border-radius: 8px;
    background-color: ${({ color }) => color};
    margin-right: 5px;
`;
