import moment, { Moment } from "moment";

export class TransferItemResponseApi {    
    id: number;
    cliente: number;
    descricao: string;
    divisao: string;
    enabled: true;
    filial: number;
    multiplo: number;
    numero: string;
    preco: number;
    vigencia: string;
    codigoMoeda: string

    public fromJSON(): TransferItemResponse {
        const data = {
            id: this.id,
            cliente: this.cliente,
            descricao: this.descricao,
            divisao: this.divisao,
            enabled: this.enabled,
            filial: this.filial,
            multiplo: this.multiplo,
            numero: this.numero,
            preco: this.preco,
            vigencia: this.vigencia ? moment(this.vigencia) : undefined,
            codigoMoeda: this.codigoMoeda,
        }

        return Object.assign(new TransferItemResponse(), data);
    }
}

export class TransferItemResponse {
    id: number;
    cliente: number;
    descricao: string;
    divisao: string;
    enabled: true;
    filial: number;
    multiplo: number;
    numero: string;
    preco: number;
    vigencia: Moment;
    codigoMoeda: string
}
