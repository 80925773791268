import React from 'react';

import { Page } from '@components';
import { Col, Row } from 'antd';
import { Title } from '@styles/components/text';
import { OrdersBody } from './components/orders-body';
import { translate } from '@components/i18n';

export const ApprovalOrders: React.FC = () => {
    return (
        <Page title={translate('pages.orders')} greyBackground withPadding>
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.orders')}
                        </Title>
                    </Row>
                    <OrdersBody />
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
