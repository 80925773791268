import React, { useEffect } from 'react';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Body } from './components/body';
import { Col } from 'antd';
import { CardTable } from '@components/table/styled';
import { fetchParameterValues } from '@redux/slices/config';
import { fetchUsers } from '@redux/slices/users';
import { translate } from '@components/i18n';

export const Config: React.FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchParameterValues());
        dispatch(fetchUsers());
    }, []);

    return (
        <Page
            title={translate('pages.settings')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Col span={22} style={{ alignSelf: 'center' }}>
                <h2 style={{ marginTop: '15px' }}>
                    {translate('titlePages.settings')}
                </h2>
                <CardTable>
                    <Body />
                </CardTable>
            </Col>
        </Page>
    );
};
