import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@hooks/use-query';
import { translate } from '@components/i18n';
import { AppDispatch } from '@redux/store';
import { fetchTransferOrder } from '@redux/slices/transfer-order/order';
import { TransferHistoryShowHeader } from '../components/history-show-header';
import { HistoryShowBody } from '../components/history-show-body';
import { Col, Row } from 'antd';
import { ShadowContainer } from '@styles/components/wrapper';
import { Title } from '@styles/components/text';
import { fetchMoneySymbols, fetchMoneyTypes, moneyTypeSelector } from '@redux/slices/money-type';

export const HistoryShow: React.FC = () => {
  const history = useHistory();
  const query = useQuery();

  const moneyType = useSelector(moneyTypeSelector)

  const ref = query.get('ref');

  const dispatch = useDispatch<AppDispatch>();

  const goBack = (): void => history.goBack();

  useEffect(() => {
    if (!moneyType?.isSuccess) {
      dispatch(fetchMoneyTypes())
    }

    if (!moneyType?.isSuccessSymbols) {
      dispatch(fetchMoneySymbols())
    }

    dispatch(fetchTransferOrder(ref))
  }, []);

  return (
    <Page
      title={translate('pages.historyOfTransferOrders')}
      greyBackground
      onMenuClick={goBack}
      menuIcon={<LeftOutlined />}
      loading={false}
    >
      <Row>
        <Col span={1} />
        <Col span={22}>
          <ShadowContainer style={{ padding: '1rem', marginTop: '2rem' }}>
            <Col span={24} style={{ marginBottom: '1rem' }}>
              <Row align={'middle'}>
                <Title
                  level={3}
                  blue
                  style={{ marginBottom: 0, marginRight: 5 }}
                >
                  {`${translate('titlePages.transferOrder')}`}
                </Title>
              </Row>
            </Col>
            <TransferHistoryShowHeader />
            <HistoryShowBody />
          </ShadowContainer>
        </Col>
        <Col span={1} />
      </Row>
    </Page>
  );
};
