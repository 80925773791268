/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApproverOrderDiscount } from '@models/approval/response';
import { CampaignResponse } from '@models/campaign';
import { CommentResponse } from '@models/comments/response';
import { OrderRequest } from '@models/order/items/request';
import { AxiosResponse } from 'axios';
import React from 'react';
import { UseMutateFunction } from 'react-query';
import { RouteState } from '..';

export interface IFinancialDiscountContext {
    isSavingComment?: boolean;
    readOnly?: boolean;
    currentState: RouteState;
    comments: CommentResponse[];
    addCommentFn: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        {
            text: any;
        },
        unknown
    >;
    removeCommentFn: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        {
            commentId?: any;
        },
        unknown
    >;
    setDiscount: (discount: string) => void;
    addComment: (comment: string) => void;
    removeComment: () => void;
    refuseOrder: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        {
            text: any;
        },
        unknown
    >;
    acceptOrder: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        unknown,
        unknown
    >;
    requestDiscount: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        unknown,
        unknown
    >;
    comment?: string;
    loading?: boolean;
    success?: boolean;
    orders: Partial<OrderRequest>[];
    campaigns?: CampaignResponse[];
    approvers?: ApproverOrderDiscount[];
}

export const FinancialDiscountContext =
    React.createContext<IFinancialDiscountContext | null>(null);
