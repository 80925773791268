import React, { useContext } from 'react';
import { Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { authSelector } from '@redux/slices/auth';
import { COUNTRY } from '@config/env';
import { Menu } from '@models/menu';
import { PageContext } from '../context/producer';
import meta from '../../../meta.json';
import { ItemMenuDrawer } from './item-menu-drawer';

export const PageDrawer: React.FC = () => {
    const pageContext = useContext(PageContext);
    const authState = useSelector(authSelector);

    return (
        <Drawer
            placement="left"
            closable={false}
            onClose={pageContext.toggleDrawer}
            visible={pageContext.drawerOpen}
            getContainer={false}
            style={{ position: 'absolute' }}
        >
            {meta.sideMenus
                ?.filter((m) => authState.currentUser.isAdmin || m.allowedRoles
                    ?.some((r) => authState.currentUser.roles.some((c) => c === r)))
                .filter(sb => (sb.onlyCountries?.includes("ALL") || sb.onlyCountries?.includes(COUNTRY)))
                .map((m) => (
                    <ItemMenuDrawer data={m as Menu.Item} key={m.title} />
                ))}
        </Drawer>
    );
};
