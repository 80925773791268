import styled, { css } from 'styled-components';
import { Button as ButtonAntd } from 'antd';
import {
    width,
    WidthProps,
    fontWeight,
    FontWeightProps,
    height,
    HeightProps,
} from 'styled-system';
import { hexToRgb } from '@styles/utils';

interface IButtonProps extends WidthProps, FontWeightProps, HeightProps {
    darkBlue?: boolean;
    success?: boolean;
    lightBlue?: boolean;
}

interface IDotProps {
    isWarning?: boolean;
    isDanger?: boolean;
    background: string;
}

export const Dot = styled.div<IDotProps>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.darkBlue};

    ${({ isWarning, theme }) =>
        isWarning &&
        css`
            background: ${theme.colors.warning};
        `}

    ${({ isDanger, theme }) =>
        isDanger &&
        css`
            background: ${theme.colors.danger};
        `}

    ${({ background }) =>
        background &&
        css`
            background: ${background};
        `}
`;

export const Button = styled(ButtonAntd)<IButtonProps>`
    ${width};
    ${fontWeight};
    justify-content: center;
    height: 45px;
    padding: 10px 24px;
    border-radius: 4px !important;
    display: flex;
    align-items: center;

    ${height}

    ${({ darkBlue }) =>
        darkBlue &&
        css`
            background: ${({ theme }) => theme.colors.darkBlue};
            color: white;
            border-color: ${({ theme }) => theme.colors.darkBlue} !important;

            :hover,
            :focus,
            :active {
                background: ${({ theme }) =>
                    hexToRgb(theme.colors.darkBlue, 0.9)} !important;
                color: white !important;
                border: none;
            }
        `}

        ${({ lightBlue }) =>
        lightBlue &&
        css`
            background: #00bcd4;
            color: white;
            border-color: #00bcd4 !important;

            :hover,
            :focus,
            :active {
                background: ${({ theme }) =>
                    hexToRgb('#00bcd4', 0.9)} !important;
                color: white !important;
                border: none;
            }
        `}

    ${({ success }) =>
        success &&
        css`
            background: #118f07;
            color: white;
            border-color: #118f07;

            :hover,
            :focus,
            :active {
                background: ${hexToRgb('#118F07', 0.9)};
                color: white;
                border: none;
            }

            &.ant-btn-background-ghost {
                border-color: #118f07;
                color: #118f07;
                background: transparent;

                :hover,
                :focus,
                :active {
                    border-color: #118f07;
                }
            }

            &.ant-btn-primary[disabled],
            &.ant-btn-primary[disabled]:hover,
            &.ant-btn-primary[disabled]:focus,
            &.ant-btn-primary[disabled]:active {
                color: rgba(0, 0, 0, 0.25) !important;
                border-color: #d9d9d9 !important;
                background: #f5f5f5 !important;
                text-shadow: none !important;
                box-shadow: none !important;
            }
        `}

    &.ant-btn-primary[disabled], &.ant-btn-primary[disabled]:hover, &.ant-btn-primary[disabled]:focus, &.ant-btn-primary[disabled]:active {
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: #d9d9d9 !important;
        background: #f5f5f5 !important;
        text-shadow: none !important;
        box-shadow: none !important;
    }
`;

export const ExportButton = styled(Button)`
    border-radius: 4px;
    margin-left: 10px;
    border: none;
    background: ${({ theme }) => theme.colors.darkBlue};
    color: white;
    align-self: end;

    :hover,
    :focus,
    :active {
        background: ${({ theme }) =>
            hexToRgb(theme.colors.darkBlue, 0.9)} !important;
        color: white !important;
        border: none;
    }
`;
