import React, { memo, useContext, useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    customerSelector,
    fetchAvailableItems,
    orderItemsSelector,
    orderTypeSelector,
    updateOrder,
} from '@redux/slices/order';
import { ModalContext } from 'src/contexts/modal-context';
import { Button } from '@styles/components/button';
import { userBonificationColumns } from '../constants';
import { Table } from '@components/table';
import { Title } from '@styles/components/text';
import axios from '@axios';
import { useQuery } from '@hooks/use-query';
import {
    ApproverResponse,
    ApproverResponseApi,
} from '@models/approval/response';
import { PaginatedResponseApi } from '@models/order/items/response';
import { authSelector } from '@redux/slices/auth';
import { translate } from '@components/i18n';

export const UserBonification: React.FC = memo(() => {
    const dispatch = useDispatch();
    const orderState = useSelector(orderItemsSelector);
    const modalContext = useContext(ModalContext);
    const { customer } = useSelector(customerSelector);
    const { approvalGroup } = useSelector(authSelector);
    const { orderType } = useSelector(orderTypeSelector);
    const query = useQuery();

    useEffect(() => {
        const isDraft = query.get('draft');
        if (!isDraft) {
            dispatch(updateOrder({ customer, orderType }));
        }
    }, []);

    useEffect(() => {
        const isDraft = query.get('draft');
        if (!isDraft)
            dispatch(
                fetchAvailableItems({
                    paymentConditionId: '100',
                    draft: true,
                    tempItems: [],
                }),
            );
        if (!approvalGroup) loadUsersToBonification();
        else if (
            approvalGroup.aprovadores.some((c) => c.tipo === 'ESCOLHA') &&
            !orderState.order.userBonification
        )
            loadUsersToBonification();
    }, []);

    const loadUsersToBonification = (): void => {
        axios
            .get<PaginatedResponseApi<ApproverResponseApi>>(
                '/aprovadormarketing/byusuario',
            )
            .then((res) => {
                if (
                    res.status === 200 &&
                    res.data &&
                    res.data.content.length > 0
                ) {
                    const result = res.data.content.map((u) =>
                        Object.assign(new ApproverResponseApi(), u),
                    );

                    if (result.length > 0) {
                        modalContext.setIsOpen(
                            true,
                            <UserBonificationModal
                                users={result.map((u) =>
                                    u.toApproverResponse(),
                                )}
                            />,
                            {
                                title: translate('general.chooseApprover'),
                                width: '60%',
                                closable: false,
                                maskClosable: false,
                                afterClose: () => {
                                    if (!orderState.order.userBonification)
                                        loadUsersToBonification();
                                },
                            },
                        );
                    }
                }
            });
    };

    return <></>;
});

interface UseBonificationProps {
    users: ApproverResponse[];
}

export const UserBonificationModal: React.FC<UseBonificationProps> = memo(
    ({ users = [] }) => {
        const dispatch = useDispatch();

        const [selectedRows, setSelectedRows] = useState<ApproverResponse[]>(
            [],
        );

        const modalContext = useContext(ModalContext);

        const onOk = (): void => {
            dispatch(updateOrder({ userBonification: selectedRows[0] }));
            modalContext.handleClose();
        };

        const rowSelection = {
            onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: ApproverResponse[],
            ) => {
                setSelectedRows(selectedRows);
            },
        };

        return (
            <Row align="middle">
                <Col span={24}>
                    <Col>
                        <Title as="h3" marginBottom={0}>
                            {translate('general.wantChooseApprover')}
                        </Title>
                        <Table
                            columns={userBonificationColumns}
                            rowKey={(record: ApproverResponse) =>
                                `${record.id}`
                            }
                            rowSelection={{
                                ...rowSelection,
                                columnWidth: '20px',
                                fixed: true,
                                type: 'radio',
                            }}
                            dataSource={users}
                            scroll={{ y: '40vh' }}
                        />
                        <Divider style={{ margin: '10px 0px' }} />
                        <Row justify="end">
                            <Button
                                type="primary"
                                disabled={selectedRows.length === 0}
                                onClick={onOk}
                            >
                                {translate('general.advance')}
                            </Button>
                        </Row>
                    </Col>
                </Col>
            </Row>
        );
    },
);
