import React, {
    memo,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Modal } from '@components/modal';
import { Col, Form, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { ChangeDiscountContext } from 'src/contexts/change-discount-modal-context';
import FormItem from 'antd/lib/form/FormItem';
import { Select } from '@styles/components/form';
import { generatePercentageArray } from '@utils/number';
import { updateItemApi } from '@redux/slices/order';
import { translate } from '@components/i18n';

const { Option } = Select;

export const ChangeDiscountModal: React.FC = memo(() => {
    const dispatch = useDispatch();
    const [currentDiscount, setCurrentDiscount] = useState<string>();
    const changeDiscountContext = useContext(ChangeDiscountContext);
    const [availableOptions, setAvailableOptions] = useState<number[]>([]);

    const onCancel = (): void => {
        changeDiscountContext.setIsOpen(false, undefined);
    };

    const onOk = (): void => {
        changeDiscountContext.setIsOpen(false, undefined);
        dispatch(
            updateItemApi({
                id: changeDiscountContext.currentRow.id,
                discount: Number(currentDiscount),
            }),
        );
    };

    const onChangeDiscount = (value: string): void => {
        setCurrentDiscount(value);
    };

    const loadOptions = useCallback(
        async (discount: string) => {
            const discountToUse = Number(Number(discount).toFixed(2));
            let options = generatePercentageArray();

            options = options.find(
                (c) => c.toFixed(2) === discountToUse.toFixed(2),
            )
                ? [...options, discountToUse]
                : options;

            setAvailableOptions(options.sort((a, b) => a - b));
            setCurrentDiscount(`${discountToUse}`);
        },
        [changeDiscountContext.currentRow],
    );

    useEffect(() => {
        if (changeDiscountContext.currentRow) {
            loadOptions(`${changeDiscountContext.currentRow.discount}`);
        }
    }, [changeDiscountContext.currentRow]);

    return (
        <Modal
            title={changeDiscountContext.currentRow?.description}
            visible={changeDiscountContext.isOpen}
            width={'25%'}
            onOk={onOk}
            onCancel={onCancel}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ className: 'full-btn auto-margin' }}
            okText={translate('forms.buttons.apply')}
        >
            <Row align="middle">
                <Col span={1} />
                <Col span={22}>
                    <Form
                        key={`select-discount-${currentDiscount}`}
                        onValuesChange={(changedValues) => {
                            if (changedValues.discount) {
                                onChangeDiscount(changedValues.discount);
                            }
                        }}
                        layout="vertical"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{
                            discount:
                                currentDiscount !== '0'
                                    ? currentDiscount
                                    : '0.00',
                        }}
                    >
                        <FormItem
                            label={translate(
                                'forms.labels.selectDiscountValue',
                            )}
                            name={'discount'}
                        >
                            <Select
                                placeholder={translate(
                                    'forms.placeholders.chooseOption',
                                )}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {availableOptions.map((discount) => {
                                    return (
                                        <Option value={discount.toFixed(2)}>
                                            {discount.toFixed(2)}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Form>
                </Col>
                <Col span={1} />
            </Row>
        </Modal>
    );
});
