const light = {
    colors: {
        transparent: 'transparent',
        darkGrey: '#282C34',
        blackGrey: '#20232A',
        grey: '#F2F2F2',
        white: '#FFFFFF',
        iconsBg: '#F0EFF0',
        iconsBgHover: '#d7d7d7',
        toggleBorder: '#ABB7C4',
        gradient: 'linear-gradient(99.21deg, #00ADEF -0.68%, #14347F 72.06%)',
        background: '#ffffff',
        headerBg: '#20232A',
        cardsBg: '#FFFFFF',
        textColor: '#000000',
        dodgerBlue: '#00ADEF',
        darkBlue: '#001B42',
        danger: '#FF0000',
        warning: '#FFA500',
    },
};

export { light };
