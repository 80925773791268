import { COUNTRIES } from "src/enums/countries";

export const APP_URL_API = process.env.REACT_APP_API_URL;
export const NODE_ENV = process.env.NODE_ENV;
export const PORT = process.env.PORT || 3000;
export const LANGUAGE = process.env.REACT_APP_LANGUAGE;
export const SHOW_PROTOCOL = process.env.REACT_APP_SHOW_PROTOCOL === 'true';
export const SHOW_DELIVERY_DATE = process.env.REACT_APP_SHOW_DELIVERY_DATE === 'true';
export const ENABLE_PAYMENT_CONDITION = process.env.REACT_APP_ENABLE_PAYMENT_CONDITION === 'true';
export const SHOW_CATEGORY = process.env.REACT_APP_SHOW_CATEGORY === 'true';
export const CATEGORY_WITH_VALUE_AR = process.env.REACT_APP_CATEGORY_WITH_VALUE_AR === 'true';
export const SHOW_COUNTRY = process.env.REACT_APP_SHOW_COUNTRY === 'true';
export const DO_NOT_SHOW_MONEY_SELECTION= process.env.REACT_APP_DO_NOT_SHOW_MONEY_SELECTION === 'true'

// based on language
export const IS_ARG = LANGUAGE === 'es-AR'
export const IS_BR = LANGUAGE === 'pt-BR'
export const IS_MX = LANGUAGE === 'es-MX'
export let COUNTRY = ''

if(IS_ARG){
  COUNTRY = COUNTRIES.ARG
}

if(IS_BR){
  COUNTRY = COUNTRIES.BR
}

if(IS_MX){
  COUNTRY = COUNTRIES.MX
}