import React, { useEffect, useState } from 'react';

import { Input, Page } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { Title } from '@styles/components/text';
import { columns, filterTypes } from './constants';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { toQueryParameters } from 'src/extensions/object';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
    fetchApprovedOrders,
    approvedOrderSelector,
    exportApprovedOrders,
} from '@redux/slices/reports';
import { ExportButton } from '@styles/components/button';
import { getLastYears } from '@utils/date';
import { getFilterOptions } from '@utils/filter';
import { filterMonths } from '@utils/constants';
import { ApprovedOrderResponse } from '@models/report';
import { translate } from '@components/i18n';
import { fetchMoedasSimbolos } from '@redux/slices/order';

export const ApprovedOrdersReport: React.FC = () => {
    const [queryState, setQueryState] = useState<string>('');
    const approvedOrderState = useSelector(approvedOrderSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [optionsComparisonFilter, setOptionsComparisonFilter] =
        useState(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        if (changedValues.filterBy) {
            setOptionsComparisonFilter(
                getFilterOptions(filterTypes, changedValues.filterBy),
            );
        }
        dispatch(
            fetchApprovedOrders({
                ...approvedOrderState.request,
                ...values,
                filterValue: queryState,
            }),
        );
    };

    const onSearch = (value: string): void => {
        setQueryState(value);
    };

    const onExport = (): void => {
        dispatch(
            exportApprovedOrders({
                ...approvedOrderState.request,
                filterValue: queryState,
            }),
        );
    };

    useEffect(() => {
        history.replace(
            '/reports/approved-orders?' +
                toQueryParameters(approvedOrderState.request),
        );
    }, [approvedOrderState.request]);

    useEffect(() => {
        dispatch(fetchMoedasSimbolos())
        dispatch(fetchApprovedOrders(approvedOrderState.request));
        setOptionsComparisonFilter(getFilterOptions(filterTypes, 'pedido'));
    }, []);

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchApprovedOrders({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    const search = (): void => {
        dispatch(
            fetchApprovedOrders({
                ...approvedOrderState.request,
                filterValue: queryState,
            }),
        );
    };

    useEffect(() => {
        const filters = Object.fromEntries(
            new URLSearchParams(location.search),
        );

        setTimeout(() => {
            form.setFieldsValue({
                ...filters,
                filterBy: filters.filterBy || undefined,
                comparisonType: filters.comparisonType || undefined,
                mes: filters.mes || undefined,
                ano: filters.ano || undefined,
            });
        }, 2000);
    }, []);

    const onSelect = (rowKey: ApprovedOrderResponse): void => {
        history.push(`/approval-order/${rowKey.order}?readOnly=true`);
    };

    return (
        <Page
            title={translate('pages.approvedOrders')}
            greyBackground
            withPadding
        >
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.approvedOrders')}
                        </Title>
                    </Row>
                    <ShadowContainer>
                        <Row justify="center">
                            <Col span={23}>
                                <Table
                                    customKey={`${approvedOrderState.request.current}-page`}
                                    columns={columns.filter(c => !!c)}
                                    form={form}
                                    pointer
                                    dataSource={
                                        approvedOrderState.approvedOrders
                                    }
                                    onRowClick={onSelect}
                                    pagination={approvedOrderState.request}
                                    isLoading={approvedOrderState.isFetching}
                                    onChange={loadData}
                                    onFilterChange={onFiltersChange}
                                    filters={[
                                        <FormItem
                                            label={translate(
                                                'forms.labels.searchFor',
                                            )}
                                            name={'filterBy'}
                                        >
                                            <Select
                                                placeholder={translate(
                                                    'general.all',
                                                )}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                            >
                                                {filterTypes
                                                .filter(c => !!c)
                                                .map((f) => {
                                                    return (
                                                        <Option
                                                            key={f.name}
                                                            value={f.name}
                                                        >
                                                            {f.description}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FormItem>,
                                        <FormItem
                                            label={translate(
                                                'forms.labels.searchType',
                                            )}
                                            name={'comparisonType'}
                                        >
                                            <Select
                                                placeholder={translate(
                                                    'general.all',
                                                )}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                            >
                                                {optionsComparisonFilter &&
                                                    optionsComparisonFilter.map(
                                                        (f) => {
                                                            return (
                                                                <Option
                                                                    key={f.name}
                                                                    value={
                                                                        f.name
                                                                    }
                                                                >
                                                                    {
                                                                        f.description
                                                                    }
                                                                </Option>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </FormItem>,
                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Input
                                                placeholder={translate(
                                                    'forms.placeholders.search',
                                                )}
                                                allowClear
                                                suffix={
                                                    <div
                                                        onClick={search}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <SearchOutlined />
                                                    </div>
                                                }
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.code === `Enter` ||
                                                        e.keyCode === 13
                                                    ) {
                                                        search();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    onSearch(e.target.value)
                                                }
                                            />
                                        </Form.Item>,
                                        <FormItem
                                            label={translate(
                                                'forms.labels.month',
                                            )}
                                            name={'mes'}
                                        >
                                            <Select
                                                placeholder={translate(
                                                    'general.all',
                                                )}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                            >
                                                {filterMonths.map((f) => {
                                                    return (
                                                        <Option
                                                            key={f.name}
                                                            value={f.name}
                                                        >
                                                            {f.description}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FormItem>,
                                        <FormItem
                                            label={translate(
                                                'forms.labels.year',
                                            )}
                                            name={'ano'}
                                        >
                                            <Select
                                                placeholder={translate(
                                                    'general.all',
                                                )}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                            >
                                                {getLastYears(10).map((f) => {
                                                    return (
                                                        <Option
                                                            key={f.name}
                                                            value={f.name}
                                                        >
                                                            {f.description}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FormItem>,
                                        <FormItem>
                                            <ExportButton
                                                onClick={() => {
                                                    search();
                                                }}
                                            >
                                                {translate(
                                                    'forms.buttons.search',
                                                )}{' '}
                                                <SearchOutlined />
                                            </ExportButton>
                                        </FormItem>,
                                    ]}
                                    actions={[
                                        <ExportButton
                                            onClick={() => {
                                                onExport();
                                            }}
                                        >
                                            {translate('general.export')}{' '}
                                            <DownloadOutlined />
                                        </ExportButton>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ShadowContainer>
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
