/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UserOutlined } from '@ant-design/icons';
import { EditableField } from '@models/editable-field';
import { authSelector, changeCurrentUser } from '@redux/slices/auth';
import { userSelector } from '@redux/slices/users';
import { Avatar, List, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@styles/components/button';
import { translate } from '@components/i18n';
import { REPORT_PERMISSION, ROLES } from 'src/enums/user';

export const userColumns = [
    {
        title: translate('columns.name'),
        dataIndex: 'name',
        sorter: false,
        width: '15%',
        render: (text, record): React.ReactNode => {
            return (
                <List.Item.Meta
                    style={{ alignItems: 'center' }}
                    avatar={
                        <Avatar src={record.photo} icon={<UserOutlined />} />
                    }
                    title={text}
                />
            );
        },
    },
    {
        title: translate('columns.jdeCode'),
        dataIndex: 'jdeCode',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.roles'),
        dataIndex: 'papeis',
        sorter: false,
        width: '15%',
        render: (_, record): React.ReactNode => {
            const roles = record?.roles.map((papel) => {
                const role = ROLES.find((role) => role.id === papel);
                if (role) return role.name;

                return papel;
            });

            return (
                <span>
                    {roles.map((papel, index) => (
                        <span key={index} style={{ marginRight: 5 }}>
                            {papel}
                        </span>
                    ))}
                </span>
            );
        },
    },
    {
        title: translate('columns.active'),
        dataIndex: 'active',
        sorter: false,
        width: '12%',
        render: (_, record): React.ReactNode => {
            return (
                <span>
                    {record.active
                        ? translate('general.yes')
                        : translate('general.no')}
                </span>
            );
        },
    },
    {
        title: translate('columns.login'),
        dataIndex: 'login',
        sorter: false,
        width: '12%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'division',
        sorter: true,
        width: '10%',
    },
];

export const loginWith = {
    title: translate('general.loginAs'),
    width: '8%',
    dataIndex: 'loginWith',
    sorter: true,
    render: (_, record): React.ReactNode => {
        const dispatch = useDispatch();

        const onClick = (): void => {
            dispatch(
                changeCurrentUser({
                    data: { email: record.login },
                    onSuccess: () => {
                        // window.location.reload();
                    },
                    notificationKey: ' ',
                }),
            );
        };

        return (
            <Popconfirm
                title={translate('general.logoutLogin')}
                okText={translate('general.yes')}
                cancelText={translate('general.no')}
                onConfirm={() => {
                    onClick();
                }}
            >
                <Button success type="primary" ghost height={35} width={35}>
                    <UserOutlined />
                </Button>
            </Popconfirm>
        );
    },
};

export function forgotPasswordFields(): EditableField[] {
    return [
        {
            name: 'login',
            label: translate('forms.labels.recoveryLogin'),
            type: 'text',
            placeholder: translate('forms.placeholders.login'),
            span: 24,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.login'),
                },
            ],
        },
    ];
}

export function selectorUserFields({ userOptions }): EditableField[] {
    return [
        {
            name: 'user',
            label: translate('forms.labels.chooseUserCopy'),
            type: 'select',
            span: 24,
            options: userOptions,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.selectUserCopy'),
                },
            ],
        },
    ];
}

export function userEditableFields({
    optionsApprovalGroups,
    optionsMarketingApprovalGroups,
    optionsDiscountGroups,
    optionsDivisions,
    userId,
    requiredPassword = true,
    optionsRow,
    withRow = false,
}): EditableField[] {
    const usersState = useSelector(userSelector);
    const loggedUser = useSelector(authSelector);
    return [
        {
            name: 'name',
            label: translate('forms.labels.fullName'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.name'),
                },
                {
                    min: 5,
                    message: translate('forms.rules.fiveCaracteres'),
                },
            ],
        },
        {
            name: 'division',
            label: translate('forms.labels.division'),
            type: 'select',
            span: 12,
            options: optionsDivisions,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.division'),
                },
            ],
        },
        {
            name: 'roles',
            label: translate('forms.labels.roles'),
            type: 'select',
            span: 24,
            multiple: true,
            options: ROLES,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.roles'),
                },
            ],
        },
        {
            name: 'email',
            label: translate('forms.labels.email'),
            type: 'text',
            span: 24,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.email'),
                },
                {
                    type: 'email',
                    message: translate('forms.rules.validEmail'),
                },
            ],
        },
        {
            name: 'emailCopia',
            label: translate('forms.labels.copyEmail'),
            type: 'text',
            span: 24,
            rules: [
                {
                    type: 'email',
                    message: translate('forms.rules.validEmail'),
                },
            ],
        },
        {
            name: 'limiteBonificacaoUsuario',
            label: translate('forms.labels.userLimitBonification'),
            type: 'number',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.limit'),
                },
            ],
        },
        {
            name: 'limiteUsuario',
            label: translate('forms.labels.userLimit'),
            type: 'number',
            span: 12,
        },
        {
            name: 'grupoAprovacaoVendaId',
            label: translate('forms.labels.approvalGroupOfSales'),
            type: 'select',
            span: 12,
            options: optionsApprovalGroups,
        },
        {
            name: 'grupoAprovacaoBonificacaoMarketingId',
            label: translate('forms.labels.marketingApprover'),
            type: 'select',
            span: 12,
            options: optionsMarketingApprovalGroups,
        },
        {
            name: 'grupoAprovacaoDescontosId',
            label: translate('forms.labels.approvalGroupOfDiscounts'),
            type: 'select',
            span: 12,
            options: optionsDiscountGroups,
        },
        {
            name: 'itensEspecificos',
            label: translate('forms.labels.specifyItems'),
            type: 'text',
            span: 12,
        },
        {
            name: 'permissaoRelatorio',
            label: translate('forms.labels.reportPermission'),
            type: 'select',
            span: 24,
            options: REPORT_PERMISSION,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'login',
            label: translate('forms.labels.login'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.login'),
                },
                {
                    validator: (rule, value: string) => {
                        if (
                            value &&
                            usersState.availableUsers.some(
                                ({ login, id }) =>
                                    login?.toLowerCase().trim() ===
                                        value.toLowerCase().trim() &&
                                    (!userId || userId !== id),
                            )
                        ) {
                            return Promise.reject(
                                translate('forms.rules.existLogin'),
                            );
                        }
                        return Promise.resolve();
                    },
                },
            ],
        },
        {
            name: 'password',
            label: translate('forms.labels.password'),
            type: 'password',
            span: 12,
            rules:
                (loggedUser.currentUser.isAdmin && userId) || !requiredPassword
                    ? []
                    : [
                          {
                              required: true,
                              message: translate('forms.rules.password'),
                          },
                      ],
        },
        {
            name: 'jdeCode',
            label: translate('forms.labels.jdeCode'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.division'),
                },
            ],
        },
        {
            name: 'dailyReport',
            label: translate('forms.labels.ignoreDailyEmailScheduledOrders'),
            type: 'boolean',
            span: 12,
            defaultValue: false,
        },
        {
            name: 'approvalsDailyReport',
            label: translate('forms.labels.ignoreDailyEmailApprovals'),
            type: 'boolean',
            span: 12,
            defaultValue: false,
        },
        {
            name: 'gerenteLinha',
            label: translate('forms.labels.lineManager'),
            type: 'boolean',
            span: 12,
            defaultValue: false,
        },
        {
            name: 'active',
            label: translate('forms.labels.active'),
            type: 'boolean',
            span: 12,
            defaultValue: true,
        },
        {
            name: 'codigoLinha',
            label: translate('forms.labels.line'),
            type: 'select',
            options: optionsRow,
            removed: !withRow,
            span: 12,
            placeholder: translate('forms.placeholders.line'),
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.selectOption'),
                },
            ],
        },
        {
            name: 'exibirClientesDivisao',
            label: translate('forms.labels.showDivisionClients'),
            type: 'boolean',
            span: 12,
            defaultValue: false,
        },
    ];
}
