import React, { memo, useContext, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { ModalContext } from 'src/contexts/modal-context';
import { InputArea } from '@components/input';
import { translate } from '@components/i18n';

interface RefuseModalProps {
    refuse: (message: string) => void;
}

export const RefuseModal: React.FC<RefuseModalProps> = memo(({ refuse }) => {
    const [message, setMessage] = useState<string>();
    const modalContext = useContext(ModalContext);

    const onOk = (): void => {
        refuse(message);
        modalContext.handleClose();
    };

    const onChangeMessage = (value: string): void => {
        setMessage(value);
    };

    return (
        <Col>
            <Row align="middle">
                <Col span={1} />
                <Col span={22}>
                    <Form
                        onValuesChange={(changedValues) => {
                            if (changedValues.reason) {
                                onChangeMessage(changedValues.reason);
                            }
                        }}
                        layout="vertical"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <FormItem
                            label={translate(
                                'forms.labels.whyYouAreRefusingThisOrder',
                            )}
                            name={'reason'}
                        >
                            <InputArea
                                placeholder={translate(
                                    'forms.placeholders.letReason',
                                )}
                            />
                        </FormItem>
                    </Form>
                </Col>
                <Col span={1} />
            </Row>
            <Row justify="end">
                <Button
                    type="primary"
                    disabled={!message || message.length <= 5}
                    onClick={onOk}
                >
                    {translate('forms.buttons.toRefuse')}
                </Button>
            </Row>
        </Col>
    );
});
