// only notation
export const getMoneyNotation = (symbol?: string): string => {
    if(symbol){
        return symbol
    }

    return '$';
};


// with notation
export const formatMoney = (symbol: string, money: number | string): string => {
    if (typeof money !== 'number' && !money) {
        return '';
    }

    if (isNaN(Number(money))) {
        return '';
    }

    const number = Number(money); // to number

    const tempSymbol = getMoneyNotation(symbol) as String // get symbol

    const value = number.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).replace("$", '') // money format without symbol
   
    return `${tempSymbol} ${value}` // symbol + money format
};

