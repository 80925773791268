import React, { useEffect } from 'react';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Header } from './components/header';
import { Body } from './components/body';
import { fetchDelegation } from '@redux/slices/delegation';
import { fetchUsers } from '@redux/slices/users';
import { Col } from 'antd';
import { CardTable } from '@components/table/styled';
import { translate } from '@components/i18n';

export const Delegation: React.FC = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchDelegation());
        dispatch(fetchUsers());
    }, []);

    return (
        <Page
            title={translate('pages.delegation')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Col span={22} style={{ alignSelf: 'center' }}>
                <h2 style={{ marginTop: '15px' }}>
                    {translate('titlePages.delegation')}
                </h2>
                <CardTable>
                    <Header />
                    <Body />
                </CardTable>
            </Col>
        </Page>
    );
};
