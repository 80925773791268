export interface ApprovalMarketingRequest {
    aprovadorId: number;
    divisao: string;
    limite: number;
    aprovadorNome?: string;
}

export interface ApproverModel {
    aprovadorId: number;
    divisao: string;
    limite: number;
    aprovadorNome?: string;
}

export class ApprovalMarketingRequestAPI {
    constructor(data?: ApprovalMarketingRequest) {
        if (data) {
            this.aprovadorId = data.aprovadorId;
            this.divisao = data.divisao;
            this.limite = data.limite;
            this.aprovadorNome = data.aprovadorNome;
        }
    }

    aprovadorId: number;
    divisao: string;
    limite: number;
    aprovadorNome?: string;
}
