/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useSelector } from 'react-redux';
import moment from 'moment';
import { EditableField } from '@models/editable-field';
import { translate } from '@components/i18n';
import { orderTypeSelector } from '@redux/slices/order';
import { transferClientsSelector } from '@redux/slices/transfer-clients';
import { formatMoney } from '@utils/money';
import { DO_NOT_SHOW_MONEY_SELECTION } from '@config/env';

export const columns = [
    {
        title: translate('columns.number'),
        dataIndex: 'numero',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.description'),
        dataIndex: 'descricao',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.multiple'),
        dataIndex: 'multiplo',
        sorter: false,
        width: '5%',
    },
    {
        title: translate('columns.branch'),
        dataIndex: 'filial',
        sorter: false,
        width: '10%',
        render: (_, record): React.ReactNode => {
            const { allAvailablePlatforms } = useSelector(orderTypeSelector);
            const filial = allAvailablePlatforms?.find(item => item.id === record.filial)

            if (!filial) {
                return <span>-</span>
            }

            return (
                <span>
                    {`${filial?.id || ""} - ${filial?.description || ''}`}
                </span>
            );
        },
    },
    {
        title: translate('columns.price'),
        dataIndex: 'preco',
        sorter: true,
        width: '10%',
        render: (_, record): React.ReactNode => {
            return (
                <span>{formatMoney('$', record.preco)}</span>
            )
        },
    },
    {
        title: translate('columns.vigency'),
        dataIndex: 'vigencia',
        sorter: true,
        width: '10%',
        render: (_, record): React.ReactNode => {
            return moment(record.vigencia).format('DD/MM/YYYY')
        },
    },
    {
        title: translate('columns.client'),
        dataIndex: 'client',
        sorter: false,
        width: '10%',
        render: (_, record): React.ReactNode => {
            const { available } = useSelector(transferClientsSelector);
            const client = available?.content?.find(client => client.id === record.cliente)
            return (
                <span>
                    {client?.razaoSocial || ''}
                </span>
            );
        },
    },
];

export function editableFields({
    canEdit = true,
    newData,
    optionsAllAvailablePlatforms,
    optionsClients,
    optionsAllItems,
    moneyOptions,
}): EditableField[] {

    const fields = [
        {
            name: 'filial',
            label: translate('forms.labels.branch'),
            type: 'select',
            disabled: !canEdit,
            placeholder: translate('forms.labels.branch'),
            options: optionsAllAvailablePlatforms,
            span: 24,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'numero',
            label: translate('forms.labels.number'),
            type: 'select',
            span: 24,
            options: optionsAllItems,
            disabled: !canEdit,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],

        },
        {
            name: 'preco',
            label: translate('forms.labels.price'),
            type: 'number',
            disabled: !canEdit,
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'vigencia',
            label: translate('forms.labels.vigency'),
            type: 'date',
            format: 'DD/MM/YYYY',
            disabled: !canEdit,
            span: 12,
            defaultValue: "",
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'cliente',
            label: translate('forms.labels.client'),
            type: 'select',
            placeholder: translate('forms.labels.client'),
            options: optionsClients,
            disabled: !canEdit,
            span: 12,
        },
        {
            name: 'codigoMoeda',
            label: translate('forms.labels.moneyType'),
            type: 'select',
            placeholder: translate('forms.labels.moneyType'),
            options: moneyOptions,
            disabled: !canEdit || moneyOptions?.length === 1,
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'enabled',
            label: translate('forms.labels.active'),
            type: 'boolean',
            span: 12,
            defaultValue: !!newData,
            disabled: !canEdit,
        },
    ];

    if (DO_NOT_SHOW_MONEY_SELECTION) {
        const index = fields.findIndex(item => item.name === 'codigoMoeda')
        fields.splice(index, 1)
    }

    return fields
}
