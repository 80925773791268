import { EditableField } from '@models/editable-field';
import { translate } from '@components/i18n';

export const columns = [
    {
        title: translate('columns.campainCode'),
        dataIndex: 'codigoCompanhia',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.branchCode'),
        dataIndex: 'codigoFilial',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.state'),
        dataIndex: 'estado',
        sorter: true,
        width: '10%',
    },
];

export function editableFields(): EditableField[] {
    return [
        {
            name: 'codigoCompanhia',
            label: translate('forms.labels.campainCode'),
            type: 'number',
            span: 12,
        },
        {
            name: 'codigoFilial',
            label: translate('forms.labels.branchCode'),
            type: 'number',
            span: 12,
        },
        {
            name: 'divisao',
            label: translate('forms.labels.division'),
            type: 'text',
            span: 12,
        },
        {
            name: 'estado',
            label: translate('forms.labels.state'),
            type: 'text',
            span: 12,
        },
    ];
}
