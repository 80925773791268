import {
    ApprovalMarketingRequest,
    ApprovalMarketingRequestAPI,
} from './request';

export interface ApprovalMarketingResponse extends ApprovalMarketingRequest {
    id: number;
    aprovadorId: number;
    aprovadorNome?: string;
    divisao: string;
    limite: number;
}

export class ApprovalMarketingResponseApi
    implements ApprovalMarketingRequestAPI
{
    id: number;
    aprovadorId: number;
    aprovadorNome?: string;
    divisao: string;
    limite: number;

    toMarketingApprovalResponse(): ApprovalMarketingResponse {
        return {
            id: this.id,
            limite: this.limite,
            divisao: this.divisao,
            aprovadorId: this.aprovadorId,
            aprovadorNome: this.aprovadorNome,
        };
    }
}
