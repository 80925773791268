import React from 'react';
import { InputAreaWrapper } from '../styled';

interface IInputArea {
    placeholder?: string;
    showCount?:
        | boolean
        | {
              formatter: (args: {
                  count: number;
                  maxLength?: number;
              }) => string;
          };
    maxLength?: number;
    allowClear?: boolean;
}

export const InputArea: React.FC<IInputArea> = (props) => {
    return <InputAreaWrapper {...props} bordered={false} />;
};
