import { Upload as UpladAntd } from 'antd';
import styled from 'styled-components';

export const Upload = styled(UpladAntd)`
    display: flex;
    justify-content: center;

    .ant-upload {
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
`;
