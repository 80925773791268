import { TransferClientRequest, TransferClientRequestParams } from './request';
import { TransferClientResponse } from './response';

export * from './response';
export * from './request';


export namespace TransferClient {
  export type Request = TransferClientRequest;
  export type Response = TransferClientResponse;
  export const Response = TransferClientResponse;
  export type Params = TransferClientRequestParams;
}
