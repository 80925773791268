import { Layout } from 'antd';
import styled from 'styled-components';
import { padding, PaddingProps } from 'styled-system';
const { Footer } = Layout;

export const FooterWrapper = styled(Footer)<PaddingProps>`
    ${padding};
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);

    height: 50px;
    position: fixed;
    bottom: 0px;
    width: 100%;
`;
