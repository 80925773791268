import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { RootState } from '@redux/reducers';
import '@extensions/string';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification } from '../notification';
import { ParameterRequest } from '@models/parameter/request';
import { ParameterResponse } from '@models/parameter/response';
import { parameterList } from './constants';
import { translate } from '@components/i18n';

export const updateParameter = createAsyncThunk<
    ParameterResponse,
    ThunkCallback<ParameterRequest>
>('parameters/updateParameter', async (request, thunkAPI) => {
    try {
        const response = await axios.put<ParameterResponse>(
            '/parametro/' + request.data.id,
            request.data,
        );

        const { data } = response;

        thunkAPI.dispatch(
            addNotification({
                type: 'success',
                message: translate('general.parameterUpdated'),
                title: translate('general.success'),
                notificationKey: request.notificationKey,
                callback: request.onSuccess,
            }),
        );

        return data;
    } catch (e) {
        console.debug('Error', e);

        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: translate('general.errorUpdatingParameter'),
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});

export const fetchParameterValues = createAsyncThunk<ParameterResponse[]>(
    'config/fetchParameterValues',
    async (_, thunkAPI) => {
        const parametersResult = [];
            for (const parameter of parameterList) {
                try {
                    const response = await axios.get<ParameterResponse[]>(
                        '/parametro/' + parameter,
                    );
    
                    const { data } = response;
                    parametersResult.push(data);
                } catch (error) {
                    thunkAPI.dispatch(addNotification({
                        type: 'error',
                        message: `${translate("general.setting")} ${parameter} ${translate('general.notFound')}`,
                        title: translate('general.error'),
                    }));
                }
            }

        return parametersResult;
    },
);

interface IParametersState {
    availableParameters: ParameterResponse[];
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: IParametersState = {
    availableParameters: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const parametersSlice = createSlice({
    name: 'parametersSlice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };

            return state;
        },
    },
    extraReducers: {
        [updateParameter.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [updateParameter.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },

        [updateParameter.fulfilled.toString()]: (
            state,
            action: PayloadAction<ParameterResponse>,
        ) => {
            state.availableParameters = state.availableParameters.map((u) =>
                u.id === action.payload.id ? action.payload : u,
            );
            return state;
        },

        [fetchParameterValues.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchParameterValues.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchParameterValues.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ParameterResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableParameters = payload;
            return state;
        },
    },
});

export const parameterSelector = (state: RootState): IParametersState =>
    state.parameters;

export const { clearState } = parametersSlice.actions;

export default parametersSlice.reducer;
