import { Typography } from 'antd';
import React from 'react';

interface IDescription {
    text: string;
    description: string;
}

export const Description: React.FC<IDescription> = ({ text, description }) => {
    return (
        <div>
            <Typography.Text
                style={{
                    fontWeight: 300,
                    fontSize: 16,
                }}
            >
                {text}
                <Typography.Text
                    strong
                    style={{
                        marginLeft: 5,
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {description}
                </Typography.Text>
            </Typography.Text>
        </div>
    );
};
