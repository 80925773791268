import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { RootState } from '@redux/reducers';
import { Paginated } from '@models/paginated';
import '@extensions/string';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification } from '../notification';
import { translate } from '@components/i18n';
import { toQueryParameters } from '@extensions/object';
import { PaginatedResponseApi } from '@models/order/items/response';
import { RoutePaginatedRequest, RouteResponse } from '@models/route';

export const fetchRoute = createAsyncThunk<
    Paginated<RouteResponse>,
    RoutePaginatedRequest
>('route/list', async (request, thunkAPI) => {
    const params = toQueryParameters({
        page: request.current - 1,
        size: request.pageSize < 10 ? 10 : request.pageSize,
        sort: request.sort || '',
        query: request.query || '',
    });
    try {
        const response = await axios.get<PaginatedResponseApi<RouteResponse>>(
            `/rota?${params}`,
        );

        const { data, status } = response;

        if (!data) {
            return {
                items: [],
                current: 0,
                total: 0,
                pageSize: 0,
            };
        } else {
            if (status === 200) {
                return {
                    items: data.content,
                    current: request.current,
                    total: response.data.totalElements,
                    pageSize: response.data.numberOfElements,
                    totalPages: response.data.totalPages,
                };
            } else {
                return thunkAPI.rejectWithValue('');
            }
        }
    } catch (e) {
        // TODO: here will be fetch's actions
        return thunkAPI.rejectWithValue(translate('general.erroListingItems'));
    }
});

export const createRoute = createAsyncThunk<
    RouteResponse,
    ThunkCallback<RouteResponse>
>('route/create', async (request, thunkAPI) => {
    try {
        const response = await axios.post<RouteResponse>('/rota', request.data);

        const { data, status } = response;

        if (status === 201 && data) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.registered'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        // TODO: here will be fetch's actions
        return thunkAPI.rejectWithValue(translate('general.erroListingItems'));
    }
});

export const updateRoute = createAsyncThunk<
    RouteResponse,
    ThunkCallback<RouteResponse>
>('route/update', async (request, thunkAPI) => {
    try {
        const response = await axios.put<RouteResponse>(
            '/rota/' + request.data.id,
            request.data,
        );

        const { data, status } = response;

        if (status === 200) {
            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.updated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const removeRoute = createAsyncThunk<number, number>(
    'route/remove',
    async (itemId, thunkAPI) => {
        try {
            const response = await axios.delete('/rota/' + itemId);
            if (response.status === 200) {
                thunkAPI.dispatch(removeRouteState(itemId));
                return Promise.resolve(itemId);
            }
            return 0;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;
            return thunkAPI.rejectWithValue(message);
        }
    },
);

interface IUIRouteState {
    list: RouteResponse[];
    isFetching: boolean;
    isSuccess: boolean;
    request: RoutePaginatedRequest;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: IUIRouteState = {
    list: [],
    isFetching: false,
    isSuccess: false,
    request: {
        pageSize: 10,
        current: 1,
        total: 0,
        query: '',
        sort: '',
        totalPages: 0,
    },
    isError: false,
    errorMessage: '',
    filter: '',
};

const itemsSlice = createSlice({
    name: 'routeSlice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };
            return state;
        },
        filter: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
            return state;
        },
        removeRouteState: (state, action: PayloadAction<number>) => {
            state.list = state.list.filter((u) => u.id !== action.payload);
            return state;
        },
    },
    extraReducers: {
        [updateRoute.fulfilled.toString()]: (
            state,
            action: PayloadAction<RouteResponse>,
        ) => {
            state.list = state.list.map((u) =>
                u.id === action.payload.id ? action.payload : u,
            );
            return state;
        },

        [fetchRoute.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchRoute.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchRoute.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<Paginated<RouteResponse>>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.request = {
                ...state.request,
                total: payload.total,
                pageSize: payload.pageSize,
                current: payload.current,
                totalPages: payload.totalPages,
            };
            state.list = payload.items;
            return state;
        },
    },
});

export const routeSelector = (state: RootState): IUIRouteState => state.route;

export const allRouteList = ({ route }: RootState): RouteResponse[] =>
    route.list;

export const { clearState, filter, removeRouteState } = itemsSlice.actions;

export default itemsSlice.reducer;
