
import { orderItemsSelector } from '@redux/slices/order';
import { Col, Row } from 'antd';
import React, { memo } from 'react';
import { CustomerCollapse } from './customer-collapse';
import { SelectItemsFilters } from './select-items-filters';
import { SolidBackground } from '@styles/components/wrapper';
import { useSelector } from 'react-redux';
import { UserInfoCollapse } from './user-info-collapse';
import '@extensions/number';
import { SelectItemsHeaderFooter } from './select-items-header-footer';

interface SelectItemsHeaderProps {
    readOnly?: boolean;
    marketing?: boolean;
    bonification?: boolean;
}

export const SelectItemsHeaderMarketingBonification: React.FC<SelectItemsHeaderProps> = memo(
    () => {
        const selectItemsState = useSelector(orderItemsSelector);

        return (
            <Col>
                <SolidBackground isWhite>
                    <Row style={{ padding: 30 }}>
                        <Col span={24}>
                            <Row justify="space-between" align="top">
                                <Col span={14}>
                                    <Row gutter={24}>
                                        <Col span={14}                                    >
                                            <CustomerCollapse
                                                customer={
                                                    selectItemsState.order
                                                        .customer
                                                }
                                            ></CustomerCollapse>
                                        </Col>
                                        {selectItemsState.order
                                            ?.userBonification &&
                                            selectItemsState.order
                                                ?.userBonification.name && (
                                                <Col span={6}>
                                                    <UserInfoCollapse
                                                        user={
                                                            selectItemsState
                                                                .order
                                                                ?.userBonification
                                                        }
                                                    />
                                                </Col>
                                            )}
                                    </Row>
                                </Col>
                                <Col span={10}>
                                    <SelectItemsFilters bonification marketing />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </SolidBackground>
                <SelectItemsHeaderFooter bonification />
            </Col>
        );
    },
);
