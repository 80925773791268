import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from '@config/env';

import translations from './locales';

const i18nConfig = {
    resources: translations,
    fallbackLng: LANGUAGE || 'pt-BR',
    defaultNS: 'translations',
};

i18n.use(initReactI18next).init(i18nConfig);

export const translate = (text: string): string => {
    return i18n.t(text);
};

export const isPtBr = (): boolean => {
    return (!LANGUAGE || LANGUAGE === 'pt-BR')
};

export const isEsAr = (): boolean => {
    return LANGUAGE === 'es-AR'
};

export const isEsMx = (): boolean => {
    return LANGUAGE === 'es-MX'
};

export default i18n;
