import { RouterPath } from '@definitions/routes';
import { Config } from '@pages/config';

const routes: RouterPath[] = [
    {
        path: '/config',
        exact: true,
        render: Config,
        auth: true,
        allowedRoles: [],
    },
];

export default routes;
