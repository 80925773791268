import React, { useContext } from 'react';

import { ShadowContainer } from '@styles/components/wrapper';
import { Col, Divider, Form, Row, Timeline } from 'antd';
import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import { FinancialDiscountContext } from '../context/context';
import { Text } from '@styles/components/text';
import '@extensions/number';
import moment from 'moment';
import { Input } from '@components';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { orderItemsSelector } from '@redux/slices/order';
import { useSelector } from 'react-redux';

export const OrderInfo: React.FC = () => {
    const context = useContext(FinancialDiscountContext);
    const { selectedMoeda } = useSelector(orderItemsSelector);

    const orderValues = !context.orders[0]
        ? []
        : [
              {
                  label: translate('columns.client'),
                  break: true,
                  value: `${context.orders[0].customer.code} - ${context.orders[0].customer.name}`,
              },
              {
                  label: translate('columns.customerCity'),
                  break: true,
                  value: `${context.orders[0].customer.city}`,
              },
              {
                  label: translate('columns.clientType'),
                  value: `${context.orders[0].customer.type} - ${context.orders[0].customer.typeId}`,
              },
          ];

    const orderStatus = [];

    const value =
        context.orders.reduce((acc, cur) => acc + cur.valorPedido, 0) +
        context.orders.reduce((acc, cur) => acc + cur.valorPedido, 0) *
            (-context.currentState?.desconto / 100);

    return (
        <ShadowContainer style={{ marginTop: 30, marginLeft: 20, padding: 20 }}>
            <Row gutter={10}>
                <Col span={24} lg={{span: 8}}>
                    {orderValues.map((c) => (
                        <Row style={{ marginTop: 10 }}>
                            <Text fontWeight={'bold'} blue marginRight={'4px'}>
                                {c.label}:
                            </Text>
                            <Text inline={!c.break} title={c.value}>
                                {c.value}
                            </Text>
                        </Row>
                    ))}
                </Col>
                <Col span={24} lg={{span: 6}}>
                    {orderStatus
                        .filter((c) => c)
                        .map((c) => (
                            <Row style={{ marginTop: 10 }}>
                                <Text
                                    fontWeight={'bold'}
                                    blue
                                    marginRight={'4px'}
                                >
                                    {c.label}:
                                </Text>
                                <Text inline={!c.break} title={c.value}>
                                    {c.value}
                                </Text>
                            </Row>
                        ))}
                </Col>
                <Col span={24} lg={{span: 10}}>
                    {context.approvers.length > 0 && (
                        <>
                            <Row>
                                <Text
                                    fontWeight={'bold'}
                                    blue
                                    fontSize={'1.2em'}
                                >
                                    {translate('general.approvers')}:
                                </Text>
                            </Row>
                            <Timeline style={{ marginTop: 10 }}>
                                {context.approvers.map((c) => (
                                    <Timeline.Item
                                        color={
                                            c.statusAprovacao === 'PENDENTE'
                                                ? 'gray'
                                                : c.statusAprovacao ===
                                                  'APROVADO'
                                                ? 'green'
                                                : 'red'
                                        }
                                        dot={
                                            c.statusAprovacao === 'PENDENTE' ? (
                                                <ClockCircleOutlined
                                                    style={{ fontSize: '16px' }}
                                                />
                                            ) : c.statusAprovacao ===
                                              'APROVADO' ? (
                                                <CheckOutlined
                                                    style={{ fontSize: '16px' }}
                                                />
                                            ) : (
                                                <CloseOutlined
                                                    style={{ fontSize: '16px' }}
                                                />
                                            )
                                        }
                                    >
                                        <Row
                                            align="middle"
                                            justify={'space-between'}
                                            wrap={false}
                                        >
                                            <span
                                                style={{
                                                    maxWidth: 150,
                                                    display: 'block',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                                title={
                                                    `${c.usuarioNome}` ??
                                                    `${c.role}` ??
                                                    translate(
                                                        'general.notInformed',
                                                    )
                                                }
                                            >
                                                {c.usuarioNome ??
                                                    `${c.role}` ??
                                                    translate(
                                                        'general.notInformed',
                                                    )}
                                            </span>{' '}
                                            <Col>
                                                <Row align={'middle'}>
                                                    <Divider type="vertical" />{' '}
                                                    <span
                                                        style={{
                                                            opacity: '0.7',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {c.dataHoraConfirmacao &&
                                                        c.statusAprovacao !==
                                                            'PENDENTE'
                                                            ? moment(
                                                                  c.dataHoraConfirmacao,
                                                              ).format(
                                                                  'HH:mm DD/MM/YYYY',
                                                              )
                                                            : translate(
                                                                  'general.wating',
                                                              )}
                                                    </span>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </>
                    )}
                </Col>
            </Row>
            <Divider style={{ margin: '10px 0px' }} />
            <Row
                style={{
                    marginBottom: 10,
                    justifyContent: 'space-between',
                }}
            >
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    layout="horizontal"
                    style={{
                        alignItems: 'flex-end',
                        display: 'inline-flex',
                        width: '100%',
                        columnGap: 5,
                        flexWrap: 'wrap'
                    }}
                >
                    <Form.Item
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                        }}
                        label={`${translate(
                            'forms.labels.totalOrdersValues',
                        )}:`}
                        labelCol={{
                            style: {
                                fontWeight: 'bold',
                                color: '#001B42 !important',
                            },
                        }}
                    >
                        <Input
                            placeholder={translate('forms.placeholders.search')}
                            disabled
                            style={{ minWidth: 120, color: '#000' }}
                            value={formatMoney(
                                selectedMoeda.simbolo,
                                context.orders.reduce(
                                    (acc, cur) => acc + cur.valorPedido,
                                    0,
                                ),
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                        }}
                        label={`${translate(
                            'forms.labels.financialDiscount',
                        )} (%):`}
                        labelCol={{
                            style: {
                                fontWeight: 'bold',
                                color: '#001B42 !important',
                            },
                        }}
                    >
                        <Input
                            placeholder="Ex: 5%"
                            allowClear
                            defaultValue={context.currentState?.desconto}
                            value={context.currentState?.desconto}
                            readOnly={context.readOnly}
                            type="number"
                            width={'100px'}
                            min={0}
                            max={100}
                            onChange={(e) => {
                                if (
                                    (e.target && Number(e.target.value) < 0) ||
                                    Number(e.target.value) > 100
                                )
                                    return;
                                context.setDiscount(e.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                        }}
                        label={translate('forms.labels.valueDiscount')}
                        labelCol={{
                            style: {
                                fontWeight: 'bold',
                                color: '#001B42 !important',
                            },
                        }}
                    >
                        <Input
                            placeholder={translate('forms.placeholders.search')}
                            disabled
                            style={{ minWidth: 120, color: '#000' }}
                            value={formatMoney(selectedMoeda?.simbolo, (context.orders.reduce(
                                (acc, cur) => acc + cur.valorPedido,
                                0,
                            )) - value)}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                        }}
                        label={translate('forms.labels.totalValueWithDiscount')}
                        labelCol={{
                            style: {
                                fontWeight: 'bold',
                                color: '#001B42 !important',
                            },
                        }}
                    >
                        <Input
                            placeholder={translate('forms.placeholders.search')}
                            disabled
                            style={{ minWidth: 120, color: '#000' }}
                            value={formatMoney(selectedMoeda?.simbolo, value)}
                        />
                    </Form.Item>
                </Form>
            </Row>
        </ShadowContainer>
    );
};
