import styled, { css } from 'styled-components';
import { Layout as LayoutAntd, Card as CardAntd } from 'antd';
import {
    borderRadius,
    BorderRadiusProps,
    margin,
    MarginProps,
    padding,
    PaddingProps,
} from 'styled-system';

export interface ICenterProps {
    direction?: 'row' | 'column';
}

export const Center = styled.div<ICenterProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: ${(props) => props.direction || 'column'};
    margin: auto;
`;

export const Layout = styled(LayoutAntd)`
    width: 100%;
    min-height: 94vh;
`;

export const DefaultBackground = styled(Layout)`
    position: relative;

    ::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        background: url('/icons/logo-icon.svg') no-repeat left center;
    }
`;

interface IContainerProps {
    greyBackground?: boolean;
    withPadding?: boolean;
}

export const Container = styled(DefaultBackground)<IContainerProps>`
    min-height: auto;

    ${({ withPadding }) =>
        withPadding &&
        css`
            padding-bottom: 100px;
        `}

    ${({ greyBackground }) =>
        greyBackground &&
        css`
            background: ${({ theme }) => theme.colors.grey};
            ::before {
                content: '';
                display: none;
            }
        `}
`;

export const ShadowContainer = styled.div`
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
`;

export const Card = styled(CardAntd)<PaddingProps>`
    min-width: 35%;
    border-radius: 8px;

    .ant-card-head {
        background-color: ${({ theme }) => theme.colors.darkBlue};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 5px;

        .ant-card-head-title {
            text-align: center;
            font-size: 1.3em;
        }

        .ant-typography {
            color: ${({ theme }) => theme.colors.white};
        }
    }

    .ant-card-body {
        padding: 24px 10%;
        ${padding};
    }
`;

export const DashboardItemsContainer = styled.div``;

interface ISolidBackground
    extends BorderRadiusProps,
        PaddingProps,
        MarginProps {
    isWhite?: boolean;
}

export const SolidBackground = styled.div<ISolidBackground>`
    ${borderRadius};
    ${padding};
    ${margin};
    width: 100%;

    ${({ isWhite }) =>
        isWhite &&
        css`
            background-color: ${({ theme }) => theme.colors.white};
        `}
`;
