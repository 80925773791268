import styled from 'styled-components';
import { Menu, Typography } from 'antd';

export const PageHeader = styled.header`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 2;
`;

export const HeaderMenu = styled.div`
    border-radius: 8px;
    padding: 10px 14px;
    cursor: pointer;
    transition: background 0.5s;
    background-color: ${({ theme }) => theme.colors.iconsBg};

    &:hover {
        background-color: ${({ theme }) => theme.colors.iconsBgHover};
    }

    span {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 15px;
    }
`;

export const HeaderMenuTitle = styled(Typography.Text)`
    color: ${({ theme }) => theme.colors.darkBlue};
`;

export const CustomMenu = styled(Menu)`
    margin-top: 20px;
    border-radius: 10px;
    padding: 10px;
`;
