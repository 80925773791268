import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { UserResponse } from '@models/users';
import {
    approvalGroupsEditableFields,
    approvalsType,
    approvalsTypeDiscount,
    rolesTypes,
} from '../constants';
import { FormBuilder } from '@components/form';
import {
    fetchUsersByRoles,
    userSelect,
} from '@redux/slices/users';
import { fetchAvailableTypes, orderTypeSelector } from '@redux/slices/order';
import {
    ApprovalGroupResponse,
    ApprovalGroupResponseApi,
} from '@models/approvalGroup';
import {
    approvalGroupsSelector,
    createApprovalGroup,
    fetchApprovalGroupsById,
    removeApprovalGroup,
    updateApprovalGroup,
} from '@redux/slices/approval-groups';
import { EditableField } from '@models/editable-field';
import { Center, Layout } from '@styles/components/wrapper';
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { divisionValues, fetchDivisions } from '@redux/slices/divisions';
import { CustomButton } from '../styled';
import { Button } from '@components/button';
import { translate } from '@components/i18n';

interface EditApprovalGroupModalProps {
    group?: ApprovalGroupResponse;
}

export const EditApprovalGroupModal: React.FC<EditApprovalGroupModalProps> = ({
    group,
}) => {
    const selectTypeState = useSelector(orderTypeSelector);
    const approvalGroupsState = useSelector(approvalGroupsSelector);
    const users = useSelector(userSelect);
    const divisions = useSelector(divisionValues);
    const dispatch = useDispatch();

    const typesValues = [
        {
            id: 'V2',
            name: translate('general.marketingBonification'),
        },
        {
            id: 'S1',
            name: translate('general.comercialBonificationSale'),
        },
        {
            id: 'DESCONTOS_FINANCEIROS',
            name: translate('general.financialDiscounts'),
        },
    ];

    const approverJsonModel = (
        indexFieldApprovers,
        marketing = false,
        discount = false,
        inactiveField = '',
    ): EditableField[] => {
        return [
            {
                name: `tipo.${indexFieldApprovers}`,
                label: `${translate('columns.type')} ${indexFieldApprovers}`,
                removed: !marketing && !discount,
                type: 'select',
                span: 4,
                options: marketing ? approvalsType : approvalsTypeDiscount,
                order: indexFieldApprovers,
                rules: [
                    {
                        required: true,
                        message: translate('forms.placeholders.selectType'),
                    },
                ],
            },
            {
                name: `aprovador.${indexFieldApprovers}`,
                label: `${translate(
                    'columns.approver',
                )} ${indexFieldApprovers}`,
                type: 'select',
                span:
                    marketing ||
                    editApprovalGroupState.codigoTipoPedido === 'V2'
                        ? 8
                        : 10,
                disabled: marketing || inactiveField === `aprovador`,
                options: users,
                order: indexFieldApprovers,
                rules: [
                    {
                        required: true,
                        message: translate('forms.placeholders.selectApprover'),
                    },
                ],
            },
            {
                name: `role.${indexFieldApprovers}`,
                label: `${translate('columns.profile')} ${indexFieldApprovers}`,
                removed: !discount,
                type: 'select',
                span: 10,
                options: rolesTypes,
                order: indexFieldApprovers,
                disabled: inactiveField === `role`,
                rules: [
                    {
                        required: true,
                        message: translate(
                            'forms.placeholders.selectPermission',
                        ),
                    },
                ],
            },
            {
                name: `limite.${indexFieldApprovers}`,
                label: `${translate('columns.limit')} ${indexFieldApprovers}`,
                type: 'number',
                span:
                    marketing ||
                    editApprovalGroupState.codigoTipoPedido === 'V2'
                        ? 6
                        : 7,
                order: indexFieldApprovers,
                removed: discount,
            },
            {
                name: `limiteBonificacao.${indexFieldApprovers}`,
                label: `${translate(
                    'columns.bonificationLimite',
                )} ${indexFieldApprovers}`,
                type: 'number',
                span:
                    marketing ||
                    editApprovalGroupState.codigoTipoPedido === 'V2'
                        ? 6
                        : 7,
                order: indexFieldApprovers,
                removed: discount,
            },
        ];
    };

    const handleAddField = (
        marketing = false,
        discount,
        inactiveField = '',
    ): void => {
        setFieldState((prev): EditableField[] => {
            const refItem = prev[prev.length - 2];
            const indexFieldApprovers = refItem.order + 1 || 1;
            return [
                ...prev.slice(0, prev.length - 1),
                ...approverJsonModel(
                    indexFieldApprovers,
                    marketing,
                    discount,
                    inactiveField,
                ),
                ...prev.slice(prev.length - 1, prev.length),
            ];
        });
    };

    const [fieldState, setFieldState] = useState<EditableField[]>(
        approvalGroupsEditableFields({
            optionsTypeOrder: typesValues,
            optionsDivisions: divisions.map((item) => ({
                id: item.nome,
                name: item.nome + ' - ' + item.descricao,
            })),
            handleAddField,
            group,
            approvalGroupsState
        }),
    );

    useEffect(() => {
        if (!fieldState.some((e) => e.name.includes('aprovador')) && !group)
            handleAddField(
                editApprovalGroupState.codigoTipoPedido === 'V2',
                editApprovalGroupState.codigoTipoPedido ===
                    'DESCONTOS_FINANCEIROS',
            );
    }, [fieldState]);

    const [form] = Form.useForm<UserResponse>();
    const [loading, setLoading] = useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const initialiseApprovers = (approversList) => {
        const approvers = {};
        approversList.aprovadores.forEach((element, i) => {
            (approvers[`aprovador.${i + 1}`] = element.usuarioId),
                (approvers[`tipo.${i + 1}`] = element.tipo),
                (approvers[`role.${i + 1}`] = element.role),
                (approvers[`limite.${i + 1}`] = element.limite),
                (approvers[`limiteBonificacao.${i + 1}`] =
                    element.limiteBonificacao);
        });

        return approvers;
    };

    const [editApprovalGroupState, setEditApprovalGroupState] =
        useState<ApprovalGroupResponse>({
            ...group,
        });

    const modalContext = useContext(ModalContext);

    const onOk = (values): void => {
        const refactorValues = {
            descricao: values.descricao,
            divisao: values.divisao,
            codigoTipoPedido: values.codigoTipoPedido,
            aprovadores: Object.keys(values)
                .filter((item) => item.includes('aprovador'))
                .map((item) => {
                    if (item.includes('aprovador')) {
                        const index = item.split('.')[1];
                        return {
                            usuarioId:
                                parseInt(values['aprovador.' + index]) ??
                                undefined,
                            tipo: values['tipo.' + index] || undefined,
                            role: values['role.' + index] || undefined,
                            ordem: parseInt(index),
                            limiteBonificacao: parseInt(
                                values['limiteBonificacao.' + index] || 0,
                            ),
                            limite: parseInt(values['limite.' + index] || 0),
                        };
                    }

                    return;
                }),
        };

        if (!group) addNewGroup(refactorValues);
        else handleUpdateApprovalGroup(refactorValues);
    };

    const handleUpdateApprovalGroup = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingApprovalGroup'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateApprovalGroup({
                data: {
                    id: group.id,
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addNewGroup = (values): void => {
        const key = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringApprovalGroup'),
            key: key,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            createApprovalGroup({
                data: {
                    ...values,
                },
                notificationKey: key,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    useEffect(() => {
        if (group?.id) {
            (async () => {
                setLoading(true);

                const result = await fetchApprovalGroupsById(group.id);

                const data = Object.assign(
                    new ApprovalGroupResponseApi(),
                    result,
                );

                setEditApprovalGroupState({
                    ...group,
                    ...initialiseApprovers(data.toGroupApprovalResponse()),
                });

                for (const iterator of data.toGroupApprovalResponse()
                    .aprovadores)
                    handleAddField(
                        data.codigoTipoPedido === 'V2',
                        data.codigoTipoPedido === 'DESCONTOS_FINANCEIROS',
                        data.codigoTipoPedido === 'DESCONTOS_FINANCEIROS' &&
                            iterator.tipo.toUpperCase() === 'USUARIO'
                            ? 'role'
                            : translate('general.approver'),
                    );

                setLoading(false);

                setFieldState(
                    approvalGroupsEditableFields({
                        optionsTypeOrder: typesValues,
                        optionsDivisions: divisions.map((item) => ({
                            id: item.nome,
                            name: item.nome + ' - ' + item.descricao,
                        })),
                        handleAddField: () =>
                            handleAddField(
                                data.codigoTipoPedido === 'V2',
                                data.codigoTipoPedido ===
                                    'DESCONTOS_FINANCEIROS',
                            ),
                        group,
                        approvalGroupsState
                    }),
                );

                data.toGroupApprovalResponse().aprovadores.forEach((c) =>
                    handleAddField(
                        data.codigoTipoPedido === 'V2',
                        data.codigoTipoPedido === 'DESCONTOS_FINANCEIROS',
                        data.codigoTipoPedido === 'DESCONTOS_FINANCEIROS' &&
                            c.tipo.toUpperCase() === 'USUARIO'
                            ? 'role'
                            : translate('general.approver'),
                    ),
                );
            })();
        }

        dispatch(fetchAvailableTypes());
        dispatch(fetchDivisions());
        dispatch(fetchUsersByRoles({
            data: {
                roles: ["CONTROLADORIA", "APROVADOR"]
            }
        }));
    }, [group]);

    const onChangeValues = (
        changedValues: Partial<ApprovalGroupResponse>,
        allValues: ApprovalGroupResponse,
    ): void => {
        console.debug({
            ...allValues,
            ...changedValues,
        });
        setEditApprovalGroupState({
            ...allValues,
            ...changedValues,
        });

        if (changedValues.codigoTipoPedido) {
            setFieldState(
                fieldState
                    .filter((v) => !v.name.includes('.'))
                    .map((r) => {
                        if (r.name.includes('codigoTipoPedidoX')) {
                            r.Render = () => (
                                <CustomButton>
                                    <Button
                                        onClick={() =>
                                            handleAddField(
                                                changedValues.codigoTipoPedido ===
                                                    'V2',
                                                changedValues.codigoTipoPedido ===
                                                    'DESCONTOS_FINANCEIROS',
                                            )
                                        }
                                        className="border-none"
                                    >
                                        <PlusCircleOutlined />
                                        <span className="ml-2">
                                            {translate(
                                                'forms.buttons.addApprover',
                                            )}
                                        </span>
                                    </Button>
                                </CustomButton>
                            );
                        }
                        return r;
                    }),
            );
        } else if (
            Object.keys(changedValues).some((c) => c.includes('tipo.'))
        ) {
            const index = Object.keys(changedValues)[0].split('.')[1];
            const defaultValueItem = fieldState.find(
                (c) => c.name === `aprovador.${index}`,
            );

            const defaultValueItemRole = fieldState.find(
                (c) => c.name === `role.${index}`,
            );

            console.debug(Object.values(changedValues)[0] !== 'USUARIO');

            if (Object.values(changedValues)[0] !== 'USUARIO') {
                defaultValueItem.disabled = true;
                if (defaultValueItemRole) defaultValueItemRole.disabled = false;
                form.setFieldsValue({ [defaultValueItem.name]: '' });
            } else {
                defaultValueItem.disabled = false;
                if (defaultValueItemRole) {
                    defaultValueItemRole.disabled = true;
                    form.setFieldsValue({ [defaultValueItemRole.name]: '' });
                }
            }

            const updatedFieldState = fieldState.map((item) => {
                if (item.name === defaultValueItem.name)
                    return defaultValueItem;
                if (
                    defaultValueItemRole &&
                    item.name === defaultValueItemRole.name
                )
                    return defaultValueItemRole;
                return item;
            });

            setFieldState(updatedFieldState);
        }
        // if(changedValues.)
        console.debug(changedValues);
    };

    const handleDeleteApprovalGroup = (): void => {
        dispatch(removeApprovalGroup(group.id));

        modalContext.handleClose();
    };

    const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

    if (loading)
        return (
            <Layout>
                <Center>
                    <Spin indicator={antIcon} />
                </Center>
            </Layout>
        );

    return (
        !loading && (
            <Row align="middle">
                <Col span={1} />
                <Col span={22}>
                    <FormBuilder
                        onValuesChange={onChangeValues}
                        layout="vertical"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={editApprovalGroupState}
                        onFinish={onOk}
                        form={form}
                        fields={fieldState}
                        withDelete={!!group}
                        onDelete={handleDeleteApprovalGroup}
                    />
                </Col>
                <Col span={1} />
            </Row>
        )
    );
};
