import { translate } from '@components/i18n';

export const columns = [
    {
        title: translate('columns.order'),
        dataIndex: 'id',
        sorter: false,
        width: '8%',
    },
    {
        title: translate('columns.client'),
        dataIndex: 'name',
        sorter: false,
        width: '25%',
    },
    {
        title: translate('columns.condition'),
        dataIndex: 'condition',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.scheduledDate'),
        dataIndex: 'draftRef',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'type',
        sorter: false,
        width: '20%',
    },
    {
        title: translate('columns.hasBonification'),
        dataIndex: 'hasBonification',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.total'),
        dataIndex: 'total',
        sorter: false,
        width: '20%',
    },
];

export const filterTypes = [
    {
        name: 'todos',
        description: translate('filters.all'),
    },
    {
        name: 'cnpj',
        description: translate('filters.cnpj'),
    },
    {
        name: 'nome',
        description: translate('filters.name'),
    },
    {
        name: 'codigo',
        description: translate('filters.clientCode'),
    },
    {
        name: 'cidade',
        description: translate('filters.city'),
    },
    {
        name: 'estado',
        description: translate('filters.state'),
    },
    {
        name: 'tipo',
        description: translate('filters.type'),
    },
];
