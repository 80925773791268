/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';
import { Table as STable } from '@styles/components/table';
import { tableLocale } from './locale';
import {
    FilterValue,
    GetRowKey,
    SorterResult,
    TablePaginationConfig,
    TableRowSelection,
} from 'antd/lib/table/interface';
import { RequestPaginated } from '@models/requestPaginated';
import { Form, FormInstance, Row, List as ListAntd } from 'antd';
import { ListFooter } from './components/footer';
import { translate } from '@components/i18n';
import { Card } from './components/card';
import * as S from './styled'

interface IColumn {
    title: string;
    dataIndex: string;
    sorter?: boolean | any;
    width?: string;
    render?: (index: number, item: any) => JSX.Element
}

interface IListProps {
    columns: IColumn[];
    width?: string;
    onChange?: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => void;
    isLoading?: boolean;
    pagination?: RequestPaginated;
    dataSource: any[];
    rowKey?: (record: any) => string;
    filters?: React.ReactNode[];
    actions?: React.ReactNode[];
    onFilterChange?: (changedValues: any, values: any) => void;
    footerObject?: React.ReactNode;
    form?: FormInstance;
    customKey?: string;
    onClick?: (
        record: any,
        index: number,
        event: React.MouseEvent<HTMLElement>,
    ) => void;
}

export const List: React.FC<IListProps> = memo(
    ({
        customKey,
        columns,
        onChange,
        isLoading = false,
        pagination,
        dataSource,
        rowKey,
        filters,
        actions,
        onFilterChange,
        onClick,
        footerObject,
        width,
        form,
    }) => {
        const orderMobile = columns.sort((a: any, b: any) => a.orderMobile - b.orderMobile)

        return (
            <S.Container>
                {((filters && filters.length > 0) ||
                    (actions && actions.length > 0)) && (
                    <Row
                        style={{
                            marginTop: 15,
                            justifyContent: 'space-between',
                        }}
                    >
                        {filters && filters.length > 0 && (
                            <Form
                                form={form}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 24 }}
                                layout="inline"
                                onValuesChange={onFilterChange}
                                style={{
                                    alignItems: 'flex-end',
                                    width: width ? width : 'inherit',
                                }}
                            >
                                {filters.map((v) => (
                                    <>{v}</>
                                ))}
                            </Form>
                        )}
                        {actions && actions.length > 0 && (
                            <>
                                {actions.map((v) => (
                                    <>{v}</>
                                ))}
                            </>
                        )}
                    </Row>
                )}
                <Row>
                    {isLoading && (
                        <div>Carregando</div>
                    )}
                    {!isLoading && (
                        <ListAntd 
                            dataSource={dataSource}
                            rowKey={rowKey}
                            loading={isLoading}
                            itemLayout="vertical"
                            style={{padding: '1em 0px', width: '100%'}}
                            renderItem={(item: any, index: number)=> (
                                <ListAntd.Item onClick={(event) => onClick && onClick(item, index, event)}>
                                    {orderMobile.map((column, columnIndex) => {
                                        if(columnIndex === 0){
                                            return (
                                                <S.CardHeader>
                                                    <S.CardValue>{item[column.dataIndex]}</S.CardValue>
                                                </S.CardHeader>
                                            )
                                        }

                                        return (
                                            <S.CardLine>
                                                <S.CardLabel>{column.title}</S.CardLabel>
                                                {column.render ? (
                                                    column.render(item[column.dataIndex], item)
                                                ): (
                                                    <S.CardValue>{item[column.dataIndex]}</S.CardValue>
                                                )}
                                            </S.CardLine>
                                        )
                                    })}
                                    
                                </ListAntd.Item>
                            )}
                            pagination={
                                !pagination
                                    ? false
                                    : {
                                          ...pagination,
                                          showSizeChanger: true,
                                          locale: {
                                              items_per_page:
                                                  translate('general.perPage'),
                                          },
                                      }
                            }
                            footer={
                                footerObject
                                ? footerObject
                                : <ListFooter
                                    totalItems={dataSource.length}
                                />
                            }
                        />
                        
                    )}
                </Row>
            </S.Container>
        );
    },
);

/*

dataSource.map((item => (
                            <Card data={item} columns={columns} onClick={onClick} rowKey={rowKey}/>
                        )))
*/