import moment from 'moment';
import { MinOrderRequest, MinOrderRequestAPI } from './request';

export interface Excecoes {
    id: number;
    pedidoMinimoId: number;
    tipocliente: string;
}

export interface Validacoes {
    codigoCliente: number;
    destino: string;
    id: number;
    origem: string;
    pedidoMinimoId: number;
    valorminimo: number;
}

export interface MinOrderResponse extends MinOrderRequest {
    codigoTipoTransporte: string;
    divisao: string;
    excecoes: Excecoes[];
    id: number;
    validacoes: Validacoes[];
    tiposCliente?: string[];
}

export class MinOrderResponseApi implements MinOrderRequestAPI {
    codigoTipoTransporte: string;
    divisao: string;
    excecoes: Excecoes[];
    id: number;
    validacoes: Validacoes[];

    toMinOrderResponse(): MinOrderResponse {
        return {
            ...this,
        };
    }
}

export interface TipoTransporteResponse {
    codigo: string;
    descricao: string;
}

export interface MinOrderResponseOrder {
    valorMinimo: number;
    codigoTipoTransporte: string;
}
