import { RouterPath } from '@definitions/routes';
import { Item } from '@pages/item';
import { Deadline } from '@pages/deadline';
import { ItemDeadline } from '@pages/item-deadline';
import { Cost } from '@pages/cost';
import { Route } from '@pages/route';
import { Semaphore } from '@pages/semaphore';

const routes: RouterPath[] = [
    {
        path: '/items',
        render: () => <Item />,
        auth: true,
        allowedRoles: [],
    },
    {
        path: '/prazos',
        render: () => <Deadline />,
        auth: true,
        allowedRoles: [],
    },
    {
        path: '/items-prazo',
        render: () => <ItemDeadline />,
        auth: true,
        allowedRoles: [],
    },
    {
        path: '/rotas',
        render: () => <Route />,
        auth: true,
        allowedRoles: [],
    },
    {
        path: '/custos',
        render: () => <Cost />,
        auth: true,
        allowedRoles: [],
    },
    {
        path: '/semaforos',
        render: () => <Semaphore />,
        auth: true,
        allowedRoles: [],
    },
];

export default routes;
