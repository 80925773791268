import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { Col, message } from 'antd';
import axios from '@axios';
import { MailOutlined } from '@ant-design/icons';
import { Button } from '@styles/components/button';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { useSelector } from 'react-redux';
import { orderItemsSelector } from '@redux/slices/order';

export const historyColumns = [
    {
        title: translate('columns.order'),
        dataIndex: 'id',
        sorter: false,
        width: '8%',
    },
    {
        title: translate('columns.client'),
        dataIndex: 'name',
        width: '25%',
    },
    {
        title: translate('columns.customerCity'),
        dataIndex: 'customerCity',
        width: '10%',
    },
    {
        title: translate('columns.condition'),
        dataIndex: 'condition',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.protocol'),
        dataIndex: 'protocol',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.date'),
        dataIndex: 'draftRef',
        sorter: false,
        width: '10%',
    },
    {
        title: translate('columns.orderType'),
        dataIndex: 'type',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.status'),
        dataIndex: 'status',
        sorter: false,
        width: '15%',
        render: (_, record) => {
            const status = filterTypesStatus.find(item => item.name === record.status.toLowerCase())

            return (
                <span>
                    {status.description}
                </span>
            )
        }
    },
    {
        title: translate('columns.hasBonification'),
        dataIndex: 'hasBonification',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.total'),
        dataIndex: 'total',
        sorter: false,
        width: '20%',
        render: (_, record): JSX.Element => {
            const {moedaSimbolos} = useSelector(orderItemsSelector)
            const moeda = moedaSimbolos.find(item => item.codigoMoeda === record?.codigoMoeda)
            return (
                <span>{formatMoney(moeda?.simbolo, record.total)}</span>
            )
        }
    },
    {
        title: translate('columns.resendEmail'),
        dataIndex: 'resendEmail',
        sorter: false,
        width: '20%',
        render: (_, record) => {
            const resendOrder = (): Promise<AxiosResponse<void>> => {
                return axios.get('/pedido/resendemail/' + record.id);
            };

            const { mutate: resendMutation } = useMutation(resendOrder, {
                onSuccess: (data) => {
                    console.debug(data);
                    message.success(translate('general.resendedEmail'));
                },
                onError: () => {
                    message.error(translate('general.resendedEmailError'));
                },
            });

            return (
                <span>
                    <Col>
                        <Button
                            success
                            type="primary"
                            ghost
                            disabled={
                                !(
                                    record.status === 'APROVADO' &&
                                    record.protocol
                                )
                            }
                            height={35}
                            width={35}
                            onClick={() => resendMutation()}
                        >
                            <MailOutlined />
                        </Button>
                    </Col>
                </span>
            );
        },
    },
    {
        title: translate('columns.forceSync'),
        dataIndex: 'forceSync',
        sorter: false,
        width: '20%',
        render: (_, record) => {
            const resendOrder = (): Promise<AxiosResponse<void>> => {
                return axios.post('/pedido/forcesync', [record.id]);
            };

            const { mutate: resendMutation } = useMutation(resendOrder, {
                onSuccess: (data) => {
                    console.debug(data);
                    message.success(translate('general.success'));
                },
                onError: () => {
                    message.error(translate('general.error'));
                },
            });

            return (
                <span>
                    <Col>
                        <Button
                            success
                            type="primary"
                            ghost
                            disabled={
                                !(
                                    (!record.protocol ||
                                        record.protocol ===
                                            translate('general.notInformed')) &&
                                    record.typeId === 'V2' &&
                                    record.status === 'APROVADO' &&
                                    record.envio
                                )
                            }
                            height={35}
                            width={35}
                            onClick={() => resendMutation()}
                        >
                            <MailOutlined />
                        </Button>
                    </Col>
                </span>
            );
        },
    },
];

export const filterTypes = [
    {
        name: 'todos',
        description: translate('filters.all'),
    },
    {
        name: 'cnpj',
        description: translate('filters.cnpj'),
    },
    {
        name: 'pedido',
        description: translate('filters.order'),
    },
    {
        name: 'nome',
        description: translate('filters.name'),
    },
    {
        name: 'codigo',
        description: translate('filters.clientCode'),
    },
    {
        name: 'cidade',
        description: translate('filters.city'),
    },
    {
        name: 'estado',
        description: translate('filters.state'),
    },
    {
        name: 'tipo',
        description: translate('filters.type'),
    },
];

export const filterTypesStatus = [
    {
        name: 'aprovado',
        description: translate('general.approved'),
    },
    {
        name: 'pendente',
        description: translate('general.pending'),
    },
    {
        name: 'reprovado',
        description: translate('general.reproved'),
    },
    {
        name: 'integrado',
        description: translate('general.integrated'),
    },
    {
        name: 'faturado',
        description: translate('general.billed'),
    },
    {
        name: 'entregue',
        description: translate('general.delivered'),
    },
];
