import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { ApprovalGroupResponse } from '@models/approvalGroup';
import { approvalGroupsValues } from '@redux/slices/approval-groups';
import { userSelect } from '@redux/slices/users';
import { Col } from 'antd';
import React, { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { userColumns } from '../constants';
import { EditApprovalGroupModal } from './edit-modal';

export const Body: React.FC = memo(() => {
    const approvalGroups = useSelector(approvalGroupsValues);
    const modalContext = useContext(ModalContext);
    const users = useSelector(userSelect);

    const editApprovalGroup = (group: ApprovalGroupResponse): void => {
        modalContext.setIsOpen(
            true,
            <EditApprovalGroupModal
                group={group}
                key={`editing-approval-group-${group.descricao}`}
            />,
            {
                title: `${translate('general.editApprovalGroup')} - ${
                    group.descricao
                }`,
                width: '50%',
            },
        );
    };

    return (
        <Col>
            <Table
                columns={userColumns}
                rowKey={(record: ApprovalGroupResponse) => `${record.id}`}
                dataSource={approvalGroups}
                pagination={undefined}
                pointer
                isLoading={!users || users.length === 0}
                onRowClick={editApprovalGroup}
                scroll={{ y: 'calc(100vh - 380px)' }}
            />
        </Col>
    );
});
