export const exportReport = (data: Blob, filename): void => {
    const url = window.URL.createObjectURL(data);
    const a: HTMLAnchorElement = document.createElement(
        'a',
    ) as HTMLAnchorElement;

    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};
