import { translate } from '@components/i18n';
import { ItemResponse } from '@models/order/items/response';
import { RootState } from '@redux/reducers';
export interface OrderValues {
    valorPedido?: number;
    valorPedidoIPI?: number;
    valorSuframa?: number;
    valorTrade?: number;
    valorSemPTAX?: number;
    somaTotalCusto?: number;
    semaforoPedido?: string;
    semaforoNomePedido?: string;
    semaforoCustoPedido?: number;
}

export const calculateOrderTotalValue = (state: RootState): OrderValues => {
    const { semaphores, availableItems, custos, order } = state.selectItems;
    const { customer } = order;
    const { selectedOrders } = state.bonification;

    let valorIterator = 0;
    let valorIPI = 0;
    let valorSemIPI = 0;
    let totalSuframa = 0;
    let totalTrade = 0;
    let somaCustos = 0;
    let somaValores = 0;
    let somaValoresSemPTAX = 0;

    if (selectedOrders && selectedOrders.length > 0) {
        console.debug('É bonificação comercial');
        selectedOrders.forEach((c) => {
            somaCustos += c.somaTotalCusto;
            somaValoresSemPTAX += c.valorSemPTAX;
        });

        let semaforoPedido = '';
        let semaforoNomePedido = '';
        let semaforoCustoPedido = 0;

        console.debug('CUSTO ANTES', somaCustos);

        somaValores = 0;
        availableItems.forEach((item) => {
            console.debug('CUSTO ITEM', item, item.custo * item.quantity);
            const custo = custos.find((c) => c.numeroItem === item.number);
            totalSuframa += item.suframa ?? 0;
            totalTrade += Number((item.valorTrade ?? 0).toFixed(2));
            valorIterator +=
                Number((item.subtotal ?? 0).toFixed(2)) - (item.suframa ?? 0);
            valorIPI +=
                Number((item.subtotalipi ?? 0).toFixed(2)) -
                (item.suframa ?? 0);
            valorSemIPI +=
                Number((item.subtotalsemipi ?? 0).toFixed(2)) -
                (item.suframa ?? 0);

            if (custo && item.quantity > 0) {
                console.debug('CUSTO ITEM', custo);
                somaCustos +=
                    (custo.valor ?? 0) *
                    item.quantity *
                    item.quantityPresentation;
            }
        });

        console.debug('CUSTO DEPOIS', somaCustos);

        if (somaCustos > 0) {
            const porcentagem =
                ((somaValoresSemPTAX - somaCustos) / somaValoresSemPTAX) * 100;
            console.debug(porcentagem, 'porcentagem');
            console.debug(somaValoresSemPTAX, somaCustos);
            const semaforo = semaphores
                .slice()
                .sort((a, b) => a.percentual - b.percentual);

            const semaforoCountry = semaforo.filter(s => s.pais === customer.country?.trim() && s.item == null && porcentagem <= s.percentual)
            const semaforoGeneric = semaforo.filter(
                (s) => s.pais == null && s.item == null && porcentagem <= s.percentual,
            );

            if (semaforoCountry.length > 0) {
                semaforoPedido = semaforoCountry[0].cor;
                semaforoNomePedido = semaforoCountry[0].nome;
                semaforoCustoPedido = somaCustos;
            } else if (semaforoGeneric.length > 0) {
                semaforoPedido = semaforoGeneric[0].cor;
                semaforoNomePedido = semaforoGeneric[0].nome;
                semaforoCustoPedido = somaCustos;
            } else {
                semaforoPedido = '';
            }
        }

        return {
            valorPedido: valorIterator,
            valorPedidoIPI: valorSemIPI,
            valorSuframa: totalSuframa,
            valorTrade: totalTrade,
            semaforoPedido: valorIterator <= 0.1 ? '' : semaforoPedido,
            semaforoNomePedido: semaforoNomePedido,
            semaforoCustoPedido: semaforoCustoPedido,
            valorSemPTAX: somaValoresSemPTAX,
            somaTotalCusto: somaCustos,
        };
    } else {
        availableItems.forEach((element) => {
            totalSuframa += element.suframa ?? 0;
            totalTrade += Number((element.valorTrade ?? 0).toFixed(2));
            valorIterator +=
                Number((element.subtotal ?? 0).toFixed(2)) -
                (element.suframa ?? 0);
            valorIPI +=
                Number((element.subtotalipi ?? 0).toFixed(2)) -
                (element.suframa ?? 0);
            valorSemIPI +=
                Number((element.subtotalsemipi ?? 0).toFixed(2)) -
                (element.suframa ?? 0);

            if (element.custo != -1 && element.quantity > 0) {
                console.debug('CUSTO ITEM', element);
                somaCustos +=
                    (element.custo ?? 0) *
                    element.quantity *
                    element.quantityPresentation;
                somaValores += Number(
                    (element.valor ?? 0) *
                    element.quantity *
                    element.quantityPresentation,
                );

                somaValoresSemPTAX += Number(
                    (element.valorSemPTAX ?? 0) *
                    element.quantity *
                    element.quantityPresentation,
                );
            }
        });

        // console.debug({
        //     valorIPI,
        //     valorSemIPI,
        //     valorIterator,
        //     totalTrade,
        //     totalSuframa,
        // });

        let semaforoPedido = '';
        let semaforoNomePedido = '';
        let semaforoCustoPedido = 0;

        if (somaCustos > 0) {
            const porcentagem =
                ((somaValoresSemPTAX - somaCustos) / somaValoresSemPTAX) * 100;
            const semaforo = semaphores
                .slice()
                .sort((a, b) => a.percentual - b.percentual);

            const semaforoCountry = semaforo.filter(s => s.pais === customer.country?.trim() && s.item == null && porcentagem <= s.percentual)
            const semaforoGeneric = semaforo.filter(
                (s) => s.pais == null && s.item == null && porcentagem <= s.percentual,
            );

            if (semaforoCountry.length > 0) {
                semaforoPedido = semaforoCountry[0].cor;
                semaforoNomePedido = semaforoCountry[0].nome;
                semaforoCustoPedido = somaCustos;
            } else if (semaforoGeneric.length > 0) {
                semaforoPedido = semaforoGeneric[0].cor;
                semaforoNomePedido = semaforoGeneric[0].nome;
                semaforoCustoPedido = somaCustos;
            } else {
                semaforoPedido = '';
            }
        }

        // console.debug({
        //     valorPedido: valorIterator,
        //     valorPedidoIPI: valorSemIPI,
        //     valorSuframa: totalSuframa,
        //     valorTrade: totalTrade,
        //     semaforoPedido: semaforoPedido,
        //     semaforoNomePedido: semaforoNomePedido,
        //     semaforoCustoPedido: semaforoCustoPedido,
        //     valorSemPTAX: somaValoresSemPTAX,
        //     somaTotalCusto: somaCustos,
        // });

        return {
            valorPedido: valorIterator,
            valorPedidoIPI: valorSemIPI,
            valorSuframa: totalSuframa,
            valorTrade: totalTrade,
            semaforoPedido: semaforoPedido,
            semaforoNomePedido: semaforoNomePedido,
            semaforoCustoPedido: semaforoCustoPedido,
            valorSemPTAX: somaValoresSemPTAX,
            somaTotalCusto: somaCustos,
        };
    }
};

export const calculateItemSemaphore = (
    item: Partial<ItemResponse>,
    is_: boolean,
    state: RootState,
): string => {
    const { semaphores, pdiscqt, convenios, custos, order } = state.selectItems;
    const { customer, orderType } = order;
    const { selectedOrders } = state.bonification;
    const { availablePlatforms } = state.selectType;

    const isBonification =
        selectedOrders.length > 0 || `${orderType.type.id}` !== 'S1';

    const tempItem = { ...item };
    let tempItemCost = 0;
    let tempTemIpi = false;

    const platform = availablePlatforms.find(
        (p) => p.id === orderType.platform.id,
    );
    let result = tempItem.precoBase;

    let resultadoSemPTRADE = result;
    let resultadoSemTradeePTAX = result;

    const PDISC = tempItem.pDISC ? tempItem.pDISC : 0;
    const PTAX = tempItem.pTAX ? tempItem.pTAX : 0;
    const PTRADE = tempItem.pTRADE ? tempItem.pTRADE : 0;
    const PANTECI = tempItem.pANTECI ? tempItem.pANTECI : 0;
    const PPROMO = tempItem.promotion ? tempItem.promotion : 0;
    const PESC = tempItem.pESC ? tempItem.pESC : 0;
    tempItem.totalQuantity = tempItem.quantity * tempItem.quantityPresentation;

    let valorTrade = 0;
    let valorsemdesconto = 0;

    let itemPDISCQT = 0;
    const filtroPDISCQT = pdiscqt.filter((i) => {
        return (
            tempItem.number == i.numeroItem &&
            tempItem.totalQuantity >= i.quantidadeInicial
        );
    });

    if (filtroPDISCQT.length > 0) itemPDISCQT = filtroPDISCQT[0].desconto;

    tempItem.promotion = PPROMO;

    result = result + (result * PDISC) / 100;

    if (!isBonification) {
        result = result + (result * itemPDISCQT) / 100;

        resultadoSemPTRADE = result;

        result = result + (result * PTRADE) / 100;

        valorTrade = resultadoSemPTRADE - result;

        result = result + (result * PANTECI) / 100;

        resultadoSemPTRADE =
            resultadoSemPTRADE + (resultadoSemPTRADE * PANTECI) / 100;

        tempItemCost = PANTECI;

        result = result + (result * Number(PPROMO)) / 100;
        resultadoSemPTRADE =
            resultadoSemPTRADE + (resultadoSemPTRADE * Number(PPROMO)) / 100;
        tempItem.promocao =
            PPROMO != 0
                ? Number(PPROMO).toFixed(2) + '%'
                : translate('general.no').toLocaleUpperCase();

        result = result + (result * PESC) / 100;
        resultadoSemPTRADE =
            resultadoSemPTRADE + (resultadoSemPTRADE * PESC) / 100;

        valorsemdesconto = result;
        result = result + (result * tempItem.discount) / 100;
        resultadoSemPTRADE =
            resultadoSemPTRADE + (resultadoSemPTRADE * tempItem.discount) / 100;
    } else {
        tempItem.promocao = translate('general.no').toLocaleUpperCase();
        tempItem.discount = 0;
        tempItem.promotion = 0;
    }

    const valorSemPTAX = result;
    resultadoSemTradeePTAX = round(resultadoSemPTRADE);

    result = result + (result * PTAX) / 100;

    resultadoSemPTRADE = resultadoSemPTRADE + (resultadoSemPTRADE * PTAX) / 100;

    tempItem.suframa = result - valorSemPTAX;
    tempItem.suframa =
        Number(tempItem.suframa.toFixed(2)) *
        tempItem.quantity *
        tempItem.quantityPresentation;
    if (tempItem.suframa < 0) {
        tempItem.suframa *= -1;
    }

    let isConv100 = false;
    let descConv100;

    if (
        customer.suframa == 'Y' ||
        customer.suframa == 'true'
        // !!customer.suframa #TODO: Verificar se é necessário
    ) {
        tempItem.valorTrade = Math.abs(
            resultadoSemTradeePTAX *
            (PTRADE / 100) *
            tempItem.quantity *
            tempItem.quantityPresentation,
        );
    } else if (
        tempItem.convenio100 == true &&
        platform.conven == 'true'
        // #TODO: Rever aqui tipo pedido completo
        // this.tipodepedidocompleto.Convenio100 == true &&
        // this.estadoOrigem != this.cliente.Zona_Cliente
    ) {
        let conv100 = 0;
        if (convenios) {
            convenios.forEach((c) => {
                if (c.codigo.trim() == customer.suframa.trim()) {
                    conv100 = c.tributacao;
                }
            });
        }

        tempItem.valorTrade = Math.abs(
            resultadoSemTradeePTAX *
            (PTRADE / 100) *
            tempItem.quantity *
            tempItem.quantityPresentation,
        );
        descConv100 = conv100;
        isConv100 = true;
    } else {
        tempItem.valorTrade = Math.abs(
            resultadoSemPTRADE *
            (PTRADE / 100) *
            tempItem.quantity *
            tempItem.quantityPresentation,
        );
        tempItem.suframa = 0;
    }

    if (isBonification) {
        tempItem.valorTrade = 0;
    }

    valorsemdesconto = valorsemdesconto + (valorsemdesconto * PTAX) / 100;

    result = round(result);

    tempItem.valorsemdesconto = Number(valorsemdesconto.toFixed(2));
    tempItem.valor = Number(result.toFixed(2));
    tempItem.valorexibicao = Number(result.toFixed(2));
    tempItem.costModifiable = Number(result.toFixed(2));
    tempItem.cost = Number(valorsemdesconto.toFixed(2));
    tempItem.subtotal =
        Number(tempItem.valor) *
        Number(tempItem.quantity) *
        Number(tempItem.quantityPresentation);
    tempItem.subtotalsemipi = tempItem.subtotal;

    tempTemIpi = calcularSeTemIPI(state);

    if (tempItem.ipi && customer.isentIpi) {
        const valorIpi = tempItem.valorIpi ? tempItem.valorIpi : 0;
        tempItem.valorIpi = valorIpi;
        tempItem.subtotalipi =
            tempItem.subtotal + (tempItem.subtotal * Number(valorIpi)) / 100;
        tempItem.subtotal = tempItem.subtotalipi;
    } else {
        tempItem.subtotalipi = tempItem.subtotal;
        tempItem.valorIpi = 0;
    }

    if (isConv100) {
        tempItem.suframa = Number((tempItem.subtotal * descConv100).toFixed(2));
    }

    tempItem.custo = undefined;

    if (`${orderType.type.id}` != 'V2') {
        const custoItem = custos.filter(
            (c) => c.numeroItem == tempItem.number,
        )[0];
        if (custoItem) {
            tempItem.custo = custoItem.valor;
        } else {
            tempItem.custo = -1;
        }

        if (tempItem.quantity > 0 && semaphores && semaphores.length > 0) {
            if (custoItem) {
                const porcentagem =
                    ((Number(tempItem.valor) *
                        tempItem.quantity *
                        tempItem.quantityPresentation -
                        custoItem.valor *
                        tempItem.quantity *
                        tempItem.quantityPresentation) /
                        (Number(tempItem.valor) *
                            tempItem.quantity *
                            tempItem.quantityPresentation)) *
                    100;

                const semaforo = semaphores
                    .slice()
                    .sort((a, b) => a.percentual - b.percentual);

                const semaforoItemCountry = semaforo.filter(s =>
                    s.item === tempItem.code &&
                    s.pais === customer.country?.trim() &&
                    porcentagem <= s.percentual
                )
                const semaforoCountry = semaforo.filter(s => s.pais === customer.country?.trim() && s.item == null && porcentagem <= s.percentual)
                const semaforoItem = semaforo.filter(s => s.item === tempItem.code && s.pais == null && porcentagem <= s.percentual)
                const semaforoGeneric = semaforo.filter(
                    (s) => s.pais == null && s.item == null && porcentagem <= s.percentual,
                );

                if (semaforoItemCountry.length > 0) {
                    tempItem.semaforo = semaforoItemCountry[0].cor;
                    tempItem.semaforoNome = semaforoItemCountry[0].nome;
                } else if (semaforoCountry.length > 0) {
                    tempItem.semaforo = semaforoCountry[0].cor;
                    tempItem.semaforoNome = semaforoCountry[0].nome;
                } else if (semaforoItem.length > 0) {
                    tempItem.semaforo = semaforoItem[0].cor;
                    tempItem.semaforoNome = semaforoItem[0].nome;
                } else if (semaforoGeneric.length > 0) {
                    tempItem.semaforo = semaforoGeneric[0].cor;
                    tempItem.semaforoNome = semaforoGeneric[0].nome;
                } else {
                    tempItem.semaforo = '';
                }

            } else {
                tempItem.semaforo = '';
            }
        } else {
            tempItem.semaforo = '';
        }
    } else {
        tempItem.custo = 0;
    }
    tempItem.total = tempItem.subtotal;

    return tempItem.semaforo;
};

export const calculateItemCost = (
    item: Partial<ItemResponse>,
    is_: boolean,
    state: RootState,
    search?: string,
): [ItemResponse, number, boolean] => {
    const { semaphores, pdiscqt, convenios, custos, order } = state.selectItems;
    const { customer, orderType } = order;
    const { selectedOrders } = state.bonification;
    const { availablePlatforms } = state.selectType;

    const isBonification =
        selectedOrders.length > 0 || `${orderType.type.id}` !== 'S1';

    const tempItem = { ...item };
    let tempItemCost = 0;
    let tempTemIpi = false;

    const platform = availablePlatforms.find(
        (p) => p.id === orderType.platform.id,
    );

    let result = tempItem.precoBase;

    tempItem.orderType = `${orderType.type.id}`;

    let resultadoSemPTRADE = result;
    let resultadoSemTradeePTAX = result;
    const PDISC = tempItem.pDISC ? tempItem.pDISC : 0;
    const PTAX = tempItem.pTAX ? tempItem.pTAX : 0;
    const PTRADE = tempItem.pTRADE ? tempItem.pTRADE : 0;
    const PANTECI = tempItem.pANTECI ? tempItem.pANTECI : 0;
    const PPROMO = tempItem.promotion ? tempItem.promotion : 0;
    const PESC = tempItem.pESC ? tempItem.pESC : 0;
    tempItem.totalQuantity = tempItem.quantity * tempItem.quantityPresentation;
    let valorTrade = 0;
    let valorsemdesconto = 0;

    let itemPDISCQT = 0;
    const filtroPDISCQT = pdiscqt.filter((i) => {
        return (
            tempItem.number == i.numeroItem &&
            tempItem.totalQuantity >= i.quantidadeInicial
        );
    });

    if (filtroPDISCQT.length > 0) itemPDISCQT = filtroPDISCQT[0].desconto;

    tempItem.promotion = PPROMO;

    result = result + (result * PDISC) / 100;

    if (!isBonification) {
        result = result + (result * itemPDISCQT) / 100;

        resultadoSemPTRADE = result;

        result = result + (result * PTRADE) / 100;

        valorTrade = resultadoSemPTRADE - result;

        result = result + (result * PANTECI) / 100;

        resultadoSemPTRADE =
            resultadoSemPTRADE + (resultadoSemPTRADE * PANTECI) / 100;

        tempItemCost = PANTECI;

        result = result + (result * Number(PPROMO)) / 100;
        resultadoSemPTRADE =
            resultadoSemPTRADE + (resultadoSemPTRADE * Number(PPROMO)) / 100;
        tempItem.promocao =
            PPROMO != 0
                ? Number(PPROMO).toFixed(2) + '%'
                : translate('general.no').toLocaleUpperCase();

        result = result + (result * PESC) / 100;
        resultadoSemPTRADE =
            resultadoSemPTRADE + (resultadoSemPTRADE * PESC) / 100;

        valorsemdesconto = result;
        result = result + (result * tempItem.discount) / 100;
        resultadoSemPTRADE =
            resultadoSemPTRADE + (resultadoSemPTRADE * tempItem.discount) / 100;
    } else {
        tempItem.promocao = translate('general.no').toLocaleUpperCase();
        tempItem.discount = 0;
        tempItem.promotion = 0;
    }

    const valorSemPTAX = result;
    resultadoSemTradeePTAX = round(resultadoSemPTRADE);

    result = result + (result * PTAX) / 100;

    resultadoSemPTRADE = resultadoSemPTRADE + (resultadoSemPTRADE * PTAX) / 100;

    tempItem.suframa = result - valorSemPTAX;
    tempItem.suframa =
        Number(tempItem.suframa.toFixed(2)) *
        tempItem.quantity *
        tempItem.quantityPresentation;
    if (tempItem.suframa < 0) {
        tempItem.suframa *= -1;
    }

    let isConv100 = false;
    let descConv100;

    if (
        customer.suframa == 'Y' ||
        customer.suframa == 'true'
        // !!customer.suframa #TODO: Verificar se é necessário
    ) {
        tempItem.valorTrade = Math.abs(
            resultadoSemTradeePTAX *
            (PTRADE / 100) *
            tempItem.quantity *
            tempItem.quantityPresentation,
        );
    } else if (
        tempItem.convenio100 == true &&
        platform.conven == 'true'
        // #TODO: Rever aqui tipo pedido completo
        // this.tipodepedidocompleto.Convenio100 == true &&
        // this.estadoOrigem != this.cliente.Zona_Cliente
    ) {
        let conv100 = 0;
        if (convenios) {
            convenios.forEach((c) => {
                if (c.codigo.trim() == customer.suframa.trim()) {
                    conv100 = c.tributacao;
                }
            });
        }

        tempItem.valorTrade = Math.abs(
            resultadoSemTradeePTAX *
            (PTRADE / 100) *
            tempItem.quantity *
            tempItem.quantityPresentation,
        );
        descConv100 = conv100;
        isConv100 = true;
    } else {
        tempItem.valorTrade = Math.abs(
            resultadoSemPTRADE *
            (PTRADE / 100) *
            tempItem.quantity *
            tempItem.quantityPresentation,
        );
        tempItem.suframa = 0;
    }

    if (isBonification) {
        tempItem.valorTrade = 0;
    }

    valorsemdesconto = valorsemdesconto + (valorsemdesconto * PTAX) / 100;

    result = round(result);

    tempItem.valorsemdesconto = Number(valorsemdesconto.toFixed(2));
    tempItem.valor = Number(result.toFixed(2));
    tempItem.valorexibicao = Number(result.toFixed(2));
    tempItem.valorSemPTAX = Number(valorSemPTAX.toFixed(2));
    tempItem.costModifiable = Number(result.toFixed(2));
    tempItem.cost = Number(valorsemdesconto.toFixed(2));
    tempItem.subtotal =
        Number(tempItem.valor) *
        Number(tempItem.quantity) *
        Number(tempItem.quantityPresentation);
    tempItem.subtotalsemipi = tempItem.subtotal;

    tempTemIpi = calcularSeTemIPI(state);

    if (tempItem.ipi && customer.isentIpi) {
        const valorIpi = tempItem.valorIpi ? tempItem.valorIpi : 0;
        tempItem.valorIpi = valorIpi;
        tempItem.subtotalipi =
            tempItem.subtotal + (tempItem.subtotal * Number(valorIpi)) / 100;
        tempItem.subtotal = tempItem.subtotalipi;
    } else {
        tempItem.subtotalipi = tempItem.subtotal;
        tempItem.valorIpi = 0;
    }

    if (isConv100) {
        tempItem.suframa = Number((tempItem.subtotal * descConv100).toFixed(2));
    }

    tempItem.custo = undefined;
    if (`${orderType.type.id}` != 'V2') {
        const custoItem = custos.filter(
            (c) => c.numeroItem == tempItem.number,
        )[0];

        if (custoItem) {
            tempItem.custo = custoItem.valor;
        } else {
            tempItem.custo = -1;
        }

        if (tempItem.quantity > 0 && semaphores && semaphores.length > 0) {
            if (custoItem) {
                const porcentagem =
                    ((Number(tempItem.valorSemPTAX) *
                        tempItem.quantity *
                        tempItem.quantityPresentation -
                        custoItem.valor *
                        tempItem.quantity *
                        tempItem.quantityPresentation) /
                        (Number(tempItem.valorSemPTAX) *
                            tempItem.quantity *
                            tempItem.quantityPresentation)) *
                    100;

                const semaforo = semaphores
                    .slice()
                    .sort((a, b) => a.percentual - b.percentual);

                const semaforoItemCountry = semaforo.filter(s =>
                    s.item === tempItem.code &&
                    s.pais === customer.country?.trim() &&
                    porcentagem <= s.percentual
                )
                const semaforoCountry = semaforo.filter(s => s.pais === customer.country?.trim() && s.item == null && porcentagem <= s.percentual)
                const semaforoItem = semaforo.filter(s => s.item === tempItem.code && s.pais == null && porcentagem <= s.percentual)
                const semaforoGeneric = semaforo.filter(
                    (s) => s.pais == null && s.item == null && porcentagem <= s.percentual,
                );

                if (semaforoItemCountry.length > 0) {
                    tempItem.semaforo = semaforoItemCountry[0].cor;
                    tempItem.semaforoNome = semaforoItemCountry[0].nome;
                } else if (semaforoCountry.length > 0) {
                    tempItem.semaforo = semaforoCountry[0].cor;
                    tempItem.semaforoNome = semaforoCountry[0].nome;
                } else if (semaforoItem.length > 0) {
                    tempItem.semaforo = semaforoItem[0].cor;
                    tempItem.semaforoNome = semaforoItem[0].nome;
                } else if (semaforoGeneric.length > 0) {
                    tempItem.semaforo = semaforoGeneric[0].cor;
                    tempItem.semaforoNome = semaforoGeneric[0].nome;
                } else {
                    tempItem.semaforo = '';
                }
            } else {
                tempItem.semaforo = '';
            }
        } else {
            tempItem.semaforo = '';
        }
    } else {
        const custoItem = custos.filter(
            (c) => c.numeroItem == tempItem.number,
        )[0];

        if (custoItem) {
            tempItem.custo = custoItem.valor;
        } else {
            tempItem.custo = -1;
        }
    }
    tempItem.total = tempItem.subtotal;
    return [tempItem as ItemResponse, tempItemCost, tempTemIpi];
};

const round = (num: number): number => {
    return Math.round(num * 100) / 100;
};

const calcularSeTemIPI = ({
    selectItems,
    selectCustomer,
}: RootState): boolean => {
    const { availableItems } = selectItems;
    const { customer } = selectCustomer;

    const itensIPI = availableItems.filter((i) => {
        return i.ipi && i.quantity > 0;
    });

    return itensIPI.length > 0 && !customer.isentIpi;
};
