import { light } from '@definitions/styled-components';
import { InputProps, Typography } from 'antd';
import React from 'react';
import { InputPasswordWrapper } from '../styled';

interface IInputPasswordProps extends InputProps {
    error?: string;
}

export const InputPassword: React.FC<IInputPasswordProps> = (props) => {
    const { ...rest } = props;
    const { error } = rest;

    return (
        <>
            <InputPasswordWrapper
                {...rest}
                error={error !== null && error?.length > 0}
            />
            {error && (
                <Typography
                    style={{ fontSize: 12, color: light.colors.danger }}
                >
                    {error}
                </Typography>
            )}
        </>
    );
};
