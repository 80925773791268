/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { translate } from '@components/i18n';
import { EditableField } from '@models/editable-field';

export const approvalMarketingColumns = [
    {
        title: translate('columns.name'),
        dataIndex: 'aprovadorNome',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'divisao',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.limit'),
        dataIndex: 'limite',
        sorter: false,
        width: '12%',
    },
];

export function approvalMarketingEditableFields({
    optionsUser,
    optionsDivision,
}): EditableField[] {
    const fields = [
        {
            name: 'aprovadorId',
            label: translate('columns.approver'),
            type: 'select',
            span: 12,
            options: optionsUser,
            rules: [{ required: true, message: translate('forms.rules.obg') }],
        },
        {
            name: 'divisao',
            label: translate('columns.division'),
            type: 'select',
            span: 6,
            options: optionsDivision,
            rules: [{ required: true, message: translate('forms.rules.obg') }],
        },
        {
            name: 'limite',
            label: translate('columns.limit'),
            type: 'text',
            span: 6,
            rules: [{ required: true, message: translate('forms.rules.obg') }],
        },
    ];

    return fields;
}
