import { EditableField } from '@models/editable-field';
import { FormItem } from '@styles/components/form';
import {
    Form,
    Switch,
    FormProps,
    Row,
    Col,
    Popconfirm,
    Divider,
    Radio,
} from 'antd';
import React from 'react';
import { Button, Input, InputPassword } from '..';
import { Option, Select } from '@styles/components/form';
import { InputDate } from '@components/input';
import { Title } from '@styles/components/text';
import { translate } from '@components/i18n';

interface FormBuilderProps extends FormProps {
    fields: EditableField[];
    withDelete?: boolean;
    withDeleteText?: string;
    onDelete?: () => void;
    customButtons?: React.ReactNode[];
    onlyEditable?: string[];
    internalKey?: string;
    widthOk?: boolean;
}

export const FormBuilder: React.FC<FormBuilderProps> = ({
    fields,
    form,
    withDelete,
    onDelete,
    onlyEditable = [],
    customButtons = [],
    withDeleteText = translate('general.delete'),
    internalKey,
    widthOk = true,
    ...rest
}) => {
    return (
        <Form {...rest} form={form} autoComplete="chrome-off" key={internalKey}>
            <Row gutter={[10, 0]}>
                {fields.map((f) => {
                    if (f.removed === true) return <></>;
                    else if (f.title)
                        return (
                            <Col
                                span={24}
                                style={{
                                    marginTop: 10,
                                    marginBottom: 15,
                                }}
                            >
                                {f.title !== '.' && (
                                    <>
                                        <Row
                                            align="middle"
                                            justify="space-between"
                                        >
                                            <Col span={6}>
                                                <Title
                                                    as={'h3'}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    {f.title}
                                                </Title>
                                            </Col>
                                            <Col span={17}>
                                                <Divider
                                                    style={{ margin: 0 }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                {f.rules && (
                                    <FormItem
                                        name={f.title}
                                        rules={f.rules}
                                        style={{ height: 1 }}
                                        onlyValidation
                                    >
                                        <Input
                                            style={{
                                                height: 1,
                                                visibility: 'hidden',
                                            }}
                                        />
                                    </FormItem>
                                )}
                            </Col>
                        );

                    if (f.type === 'boolean')
                        return (
                            <Col span={f.span}>
                                <FormItem
                                    label={f.label}
                                    name={f.name}
                                    rules={f.rules}
                                    key={`form-item-${f.name}`}
                                    valuePropName="checked"
                                    initialValue={f.defaultValue}
                                >
                                    <Switch
                                        disabled={f.disabled ||
                                            onlyEditable.length > 0 &&
                                            !onlyEditable.includes(f.name)
                                        }
                                    />
                                </FormItem>
                            </Col>
                        );
                    if (f.type === 'radio')
                        return (
                            <Col span={f.span}>
                                <FormItem
                                    label={f.label}
                                    name={f.name}
                                    rules={f.rules}
                                    key={`form-item-${f.name}`}
                                // initialValue={f.defaultValue}
                                >
                                    <Radio.Group>
                                        {f.options.map((o) => {
                                            return (
                                                <Radio value={o.id}>
                                                    {o.name}
                                                </Radio>
                                            );
                                        })}
                                    </Radio.Group>
                                </FormItem>
                            </Col>
                        );
                    else if (f.type === 'password')
                        return (
                            <Col span={f.span}>
                                <FormItem
                                    label={f.label}
                                    name={f.name}
                                    rules={f.rules}
                                    key={`form-item-${f.name}`}
                                >
                                    <InputPassword
                                        placeholder={
                                            f.placeholder
                                                ? f.placeholder
                                                : f.label
                                        }
                                        allowClear
                                        autoComplete="new-password"
                                        type={f.type}
                                        disabled={
                                            onlyEditable.length > 0 &&
                                            !onlyEditable.includes(f.name)
                                        }
                                    />
                                </FormItem>
                            </Col>
                        );
                    else if (f.type === 'select')
                        return (
                            <Col span={f.span}>
                                <FormItem
                                    label={f.label}
                                    name={f.name}
                                    rules={f.disabled ? undefined : f.rules}
                                    key={`form-item-${f.name}`}
                                >
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        adjustableHeight
                                        placeholder={f.placeholder || translate('general.all')}
                                        dropdownMatchSelectWidth={false}
                                        allowClear
                                        showSearch
                                        mode={f.multiple ? 'tags' : undefined}
                                        disabled={
                                            f.disabled ||
                                            (onlyEditable.length > 0 &&
                                                !onlyEditable.includes(f.name))
                                        }
                                        filterOption={(input, option) => {
                                            if (!option || !option.children)
                                                return true;
                                            return (
                                                option?.children
                                                    .toString()
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input
                                                            .toString()
                                                            .toLowerCase(),
                                                    ) >= 0
                                            );
                                        }}
                                    >
                                        {f.options
                                            ?.sort((a, b) =>
                                                a?.name?.localeCompare(b?.name),
                                            )
                                            .map((c) => (
                                                <Option
                                                    key={c.id + '' + c.name}
                                                    value={c.id}
                                                >
                                                    {c.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </FormItem>
                            </Col>
                        );
                    else if (f.type === 'component') {
                        if (!f.Render || onlyEditable.length > 0) return <></>;
                        return <Col span={f.span}>{<f.Render />}</Col>;
                    } else if (f.type === 'date') {
                        return (
                            <Col span={f.span}>
                                <FormItem
                                    label={f.label}
                                    name={f.name}
                                    rules={f.rules}
                                    key={`form-item-${f.name}`}
                                >
                                    <InputDate
                                        placeholder={
                                            f.placeholder
                                                ? f.placeholder
                                                : f.label
                                        }
                                        light
                                        format={f.format}
                                        allowClear
                                        disabled={f.disabled ||
                                            onlyEditable.length > 0 &&
                                            !onlyEditable.includes(f.name)
                                        }
                                    />
                                </FormItem>
                            </Col>
                        );
                    } else
                        return (
                            <Col span={f.span}>
                                <FormItem
                                    label={f.label}
                                    name={f.name}
                                    rules={f.rules}
                                    key={`form-item-${f.name}`}
                                >
                                    <Input
                                        placeholder={
                                            f.placeholder
                                                ? f.placeholder
                                                : f.label
                                        }
                                        min={
                                            f.type === 'number'
                                                ? f.min
                                                : undefined
                                        }
                                        max={
                                            f.type === 'number'
                                                ? f.max
                                                : undefined
                                        }
                                        allowClear
                                        autoComplete="new-password"
                                        type={f.type}
                                        disabled={
                                            f.disabled ||
                                            (onlyEditable.length > 0 &&
                                                !onlyEditable.includes(f.name))
                                        }
                                    />
                                </FormItem>
                            </Col>
                        );
                })}
            </Row>
            <Row justify={'space-between'}>
                {withDelete && (
                    <Popconfirm
                        title={translate('general.messageDelete')}
                        okText={translate('general.yes')}
                        cancelText={translate('general.no')}
                        onConfirm={onDelete}
                        disabled={
                            onlyEditable.length > 0 &&
                            onlyEditable.every((x) => !x)
                        }
                    >
                        <Button
                            type="primary"
                            className="half-btn"
                            danger
                            disabled={
                                onlyEditable.length > 0 &&
                                onlyEditable.every((x) => !x)
                            }
                        >
                            {withDeleteText}
                        </Button>
                    </Popconfirm>
                )}
                {customButtons && customButtons.length > 0 && (
                    <>{customButtons}</>
                )}
                {widthOk && (
                    <Button
                        type="primary"
                        className={
                            withDelete ||
                                (customButtons && customButtons.length > 0)
                                ? 'half-btn'
                                : 'full-btn'
                        }
                        disabled={
                            onlyEditable.length > 0 && onlyEditable.every((x) => !x)
                        }
                        htmlType="submit"
                    >
                        {translate('general.ok')}
                    </Button>
                )}
            </Row>
        </Form>
    );
};
