import { DivisionRequest, DivisionRequestAPI } from './request';

export interface DivisionResponse extends DivisionRequest {
    id: number;
    nome: string;
    descricao: string;
}

export class DivisionResponseApi implements DivisionRequestAPI {
    id: number;
    nome: string;
    descricao: string;

    toDivisionResponse(): DivisionResponse {
        return {
            id: this.id,
            nome: this.nome,
            descricao: this.descricao,
        };
    }
}

export interface RowResponse {
    codigo: number;
    descricao: string;
    divisao: string;
}
