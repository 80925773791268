import axios from '@axios';
import { Moeda, MoedaSimbolo } from "@models/order/moedas/response";
import { RootState } from '@redux/reducers';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const fetchMoneyTypes = createAsyncThunk<Moeda[]>(
  'money-types/all',
  async (_, thunkAPI) => {
      try {
          const response = await axios.get<Moeda[]>('/moeda/all');

          const { data: dataReponse } = response;

          return dataReponse;
      } catch (e) {
          const { response } = e;
          const { data } = response;
          const { error } = data;
          const { message } = error;

          return thunkAPI.rejectWithValue(message);
      }
  },
);

export const fetchMoneySymbols = createAsyncThunk<MoedaSimbolo[]>(
  'money-types/all-symbols',
  async (_, thunkAPI) => {
      try {
          const response = await axios.get<MoedaSimbolo[]>('/moeda/simbolo');

          const { data: dataReponse } = response;

          return dataReponse;
      } catch (e) {
          const { response } = e;
          const { data } = response;
          const { error } = data;
          const { message } = error;

          return thunkAPI.rejectWithValue(message);
      }
  },
);

export interface IMoneyType {
  available: Moeda[]
  availableSymbols: MoedaSimbolo[]
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
  isFetchingSymbols: boolean
  isSuccessSymbols: boolean
  isErrorSymbols: boolean
}

const initialState: IMoneyType = {
  available: undefined,
  availableSymbols: undefined,
  isFetching: false,
  isSuccess: false,
  isError: false,
  isFetchingSymbols: false,
  isSuccessSymbols: false,
  isErrorSymbols: false,
};


const moneyTypesSlice = createSlice({
  name: 'money-types-slice',
  initialState,
  reducers: {
      clearState: (state) => {
          state = { ...initialState };
          return state;
      },
  },
  extraReducers: {
      [fetchMoneyTypes.pending.toString()]: (state) => {
          state.isFetching = true;
          return state;
      },
      [fetchMoneyTypes.rejected.toString()]: (state, action) => {
          state.isFetching = false;
          state.isError = true;
          return state;
      },
      [fetchMoneyTypes.fulfilled.toString()]: (
          state,
          { payload }: PayloadAction<Moeda[]>,
      ) => {
          state.available = payload;
          state.isSuccess = true;
          state.isFetching = false;
          return state;
      },
      [fetchMoneySymbols.pending.toString()]: (state) => {
          state.isFetchingSymbols = true;
          return state;
      },
      [fetchMoneySymbols.rejected.toString()]: (state, action) => {
          state.isFetchingSymbols = false;
          state.isErrorSymbols = true;
          return state;
      },
      [fetchMoneySymbols.fulfilled.toString()]: (
          state,
          { payload }: PayloadAction<MoedaSimbolo[]>,
      ) => {
          state.availableSymbols = payload;
          state.isSuccessSymbols = true;
          state.isFetchingSymbols = false;
          return state;
      },
  },
});

export const { clearState } = moneyTypesSlice.actions;

export const moneyTypeSelector = (state: RootState): IMoneyType => state.moneyType;

export const moneySymbolsSelector = (state: RootState): MoedaSimbolo[] => state?.moneyType?.availableSymbols || [];

export default moneyTypesSlice.reducer;
