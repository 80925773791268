import { Radio } from 'antd';
import styled from 'styled-components';

export const SelectList = styled(Radio.Group)`
    width: 100%;

    .ant-radio-button-wrapper {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 4px;
        display: flex;

        > span:last-child {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }
`;
