import styled, { css } from 'styled-components';
import { Table as AntTable } from 'antd';
import { hexToRgb } from '@styles/utils';
import { margin, MarginProps } from 'styled-system';
import { TableProps } from 'antd';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ITableProps extends MarginProps, TableProps<any> {
    pointer?: boolean;
    selectedRowIndex?: number;
}

export const Table = styled(AntTable)<ITableProps>`
    width: 100%;
    ${margin};

    .ant-table-thead > tr > th {
        background-color: ${(props) => props.theme.colors.darkBlue};
        color: ${(props) => props.theme.colors.white};
    }

    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background-color: ${(props) =>
            hexToRgb(props.theme.colors.darkBlue, 0.9)};
    }

    .ant-table-thead tr:first-child th:first-child {
        border-top-left-radius: 8px;
    }

    .ant-table-thead tr:first-child th:last-child {
        border-top-right-radius: 8px;
    }

    ${({ selectedRowIndex, theme }) =>
        selectedRowIndex &&
        css`
            tr[data-row-key='${selectedRowIndex}'],
            tr[data-row-key='${selectedRowIndex}'] td {
                background: ${theme.colors.dodgerBlue} !important;
                color: ${theme.colors.white} !important;
            }
        `};

    tbody tr:nth-child(even),
    tbody tr:nth-child(even) td {
        background: #f2f2f2;
    }

    tbody tr:nth-child(even):hover,
    tbody tr:nth-child(even):hover td {
        background: rgba(231, 231, 231, 0.41);
    }

    tbody tr:hover {
        background: #fafafa !important;

        ${({ pointer }) =>
            pointer &&
            css`
                cursor: pointer;
            `}
    }

    .ant-table-footer {
        padding: 0px;
        margin-bottom: 10px;
    }
`;
