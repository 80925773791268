export const customerColumnsTranslations: { [key: string]: string } = {
    id: 'Id',
    name: 'nome',
    company: 'codigoCompanhia',
    type: 'tipo',
    code: 'codigo',
    city: 'cidade',
    state: 'zona',
    cnpj: 'cnpj',
};

export const pendingOrdersReportColumnsTranslations: { [key: string]: string } =
    {
        order: 'id',
        seller: 'usuario',
        date: 'dataPedido',
        division: 'divisao',
        customer: 'codigoCliente',
        customerName: 'nomeCliente',
        type: 'descricaoTipoPedido',
        deadline: 'descricaoPrazo',
        totalAmount: 'valorTotal',
        pendency: 'pendencia',
    };

export const approvedOrdersReportColumnsTranslations: {
    [key: string]: string;
} = {
    order: 'id',
    type: 'descricaoTipoPedido',
    protocol: 'protocolo',
    division: 'divisao',
    customer: 'codigoCliente',
    customerName: 'nomeCliente',
    totalAmount: 'valorTotal',
    deadline: 'descricaoPrazo',
    seller: 'usuario',
    date: 'dataPedido',
};

export const ordersHistoryReportColumnsTranslations: {
    [key: string]: string;
} = {
    order: 'id',
    type: 'descricaoTipoPedido',
    protocol: 'protocolo',
    division: 'divisao',
    customer: 'codigoCliente',
    customerName: 'nomeCliente',
    totalAmount: 'valorTotal',
    deadline: 'descricaoPrazo',
    status: 'status',
    date: 'dataPedido',
};

export const commercialBonusesSalesReportColumnsTranslations: {
    [key: string]: string;
} = {
    bonusOrder: 'pedidoBonificacaoId',
    bonusProtocol: 'pedidoBonificacaoProtocolo',
    customer: 'pedidoBonificacaoCodigoCliente',
    customerName: 'pedidoBonificacaoNomeCliente',
    date: 'pedidoBonificacaoDataPedido',
    bonusTotalAmount: 'pedidoBonificacaoValorTotal',
    user: 'pedidoBonificacaoUsuario',
    division: 'pedidoBonificacaoDivisao',
    saleOrder: 'pedidoVendaId',
    saleProtocol: 'pedidoVendaProtocolo',
    saleTotalAmount: 'pedidoVendaValorTotal',
    status: 'pedidoBonificacaoStatus',
};
