import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { Col, Row } from 'antd';
import React, { memo } from 'react';
import { SolidBackground } from '@styles/components/wrapper';
import { Text } from '@styles/components/text';
import { useSelector } from 'react-redux';
import { hasANTECSelector } from '@redux/slices/order';
import { translate, isEsAr, isEsMx } from '@components/i18n';

export const SelectItemsWarning: React.FC = memo(() => {
    const hasANTEC = useSelector(hasANTECSelector);
    const bonification = window.location.pathname.includes('bonifi');

    if (isEsAr() || isEsMx()) {
        if (hasANTEC && !bonification && `${hasANTEC}` !== `${-99999}`) {
            return (
                <SolidBackground
                    isWhite
                    borderRadius={4}
                    paddingY={'5px'}
                    paddingLeft={2}
                    style={{ height: '100%', display: 'flex', alignItems: 'center' }}
                >
                    <Row align="middle" justify="space-between">
                        <Col span={`${hasANTEC}` !== `${-99999}` ? 10 : 0}>
                            <Row align="middle">
                                <Col span={2} style={{ marginRight: 5 }}>
                                    <InfoCircleOutlined
                                        style={{
                                            color: '#F2994A',
                                            fontSize: '1.5em',
                                        }}
                                    />
                                </Col>
                                <Col span={20}>
                                    <Text>
                                        {translate(
                                            'general.activatedEarlyDiscount',
                                        )}{' '}
                                        ({hasANTEC}
                                        )%
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </SolidBackground>
            );
        }

        return null
    }

    return (
        <SolidBackground
            isWhite
            borderRadius={4}
            paddingY={'5px'}
            paddingLeft={2}
            style={{ height: '100%', display: 'flex', alignItems: 'center' }}
        >
            <Row align="middle" justify="space-between">
                {!isEsAr() && !isEsMx() && (
                    <Col
                        span={
                            `${hasANTEC}` !== `${-99999}` && !bonification ? 13 : 24
                        }
                    >
                        <Row align="middle" wrap={false}>
                            <Col style={{ marginRight: 5 }}>
                                <InfoCircleOutlined
                                    style={{ color: '#C10B0B', fontSize: '1.5em' }}
                                />
                            </Col>
                            <Col>
                                <Text fontSize={'1em'}>
                                    {translate('general.ipiTax')}
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                )
                }
                {hasANTEC && !bonification && (
                    <Col span={`${hasANTEC}` !== `${-99999}` ? 10 : 0}>
                        <Row align="middle">
                            <Col span={2} style={{ marginRight: 5 }}>
                                <InfoCircleOutlined
                                    style={{
                                        color: '#F2994A',
                                        fontSize: '1.5em',
                                    }}
                                />
                            </Col>
                            <Col span={20}>
                                <Text>
                                    {translate(
                                        'general.activatedEarlyDiscount',
                                    )}{' '}
                                    ({hasANTEC}
                                    )%
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </SolidBackground>
    );
});
