import React from 'react';

import { Page } from '@components';
import { Col, Row } from 'antd';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@hooks/use-query';
import { FinancialDiscountContextProvider } from './context/provider';
import { OrderDetails } from './components/order-details';
import { ApproverOrderDiscount } from '@models/approval/response';
import { translate } from '@components/i18n';

export interface RouteState {
    id?: string;
    pedidos?: string[];
    desconto?: string;
    aprovadores: ApproverOrderDiscount[];
    comentario?: string;
    codigoCliente?: string;
    history?: boolean;
}

export const RequestFinancialDiscounts: React.FC = () => {
    const { state } = useLocation<RouteState>();
    const query = useQuery();

    return (
        <Page
            title={
                query.get('readOnly')
                    ? translate('pages.discountSolicitations')
                    : translate('pages.approvalDiscount')
            }
            greyBackground
            withPadding
        >
            <FinancialDiscountContextProvider state={state}>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <OrderDetails />
                    </Col>
                    <Col span={1} />
                </Row>
            </FinancialDiscountContextProvider>
        </Page>
    );
};
