import React, { memo, useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { Roles, UserResponse } from '@models/users';
import { selectorUserFields, userEditableFields } from '../constants';
import { FormBuilder } from '@components/form';
import {
    removeUser,
    signUpUser,
    updateUser,
    userSelector,
} from '@redux/slices/users';
import { ImageUpload } from '@components/upload';
import {
    approvalGroupsValuesSelector,
    fetchApprovalGroups,
} from '@redux/slices/approval-groups';
import {
    divisionSelector,
    divisionValues,
    fetchLines,
} from '@redux/slices/divisions';
import { authSelector } from '@redux/slices/auth';
import { Button } from '@styles/components/button';
import { translate } from '@components/i18n';

interface EditUserModalProps {
    user?: UserResponse;
}

interface CopyUser {
    user?: number;
}

export const EditUserModal: React.FC<EditUserModalProps> = memo(({ user }) => {
    const dispatch = useDispatch();
    const approvalGroups = useSelector(approvalGroupsValuesSelector);
    const { availableUsers } = useSelector(userSelector);
    const { currentUser } = useSelector(authSelector);
    const divisions = useSelector(divisionValues);
    const divisionsSelector = useSelector(divisionSelector);
    const [form] = Form.useForm<UserResponse>();
    const [editUserState, setEditUserState] = useState<UserResponse>(user);
    const modalContext = useContext(ModalContext);
    const [copyng, setCopy] = useState<boolean>(false);

    const onOk = (values): void => {
        if (!user) addNewUser(values);
        else handleUpdateUser(values);
    };

    const handleUpdateUser = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingUser'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateUser({
                data: {
                    id: user.id,
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addNewUser = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringUser'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            signUpUser({
                data: {
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchApprovalGroups());
        dispatch(fetchLines());
    }, []);

    useEffect(() => {
        if (user) {
            setEditUserState(user);
            form.setFieldsValue(user);
        } else {
            form.resetFields();
        }
    }, [user]);

    const onChangeValues = (
        changedValues: Partial<UserResponse>,
        allValues: UserResponse,
    ): void => {
        setEditUserState({
            ...allValues,
            ...changedValues,
        });
    };

    const onOkUser = (values: CopyUser): void => {
        const user = availableUsers.find((u) => u.id === values.user);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { email, name, birthday, password, login, jdeCode, ...rest } =
            user;
        form.setFieldsValue(rest);
        handleCancelCopy();
    };

    const handleDeleteUser = (): void => {
        dispatch(removeUser(user.id));
        modalContext.handleClose();
    };

    const handleCopy = (): void => {
        setCopy(true);
    };

    const handleCancelCopy = (): void => {
        setCopy(false);
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22} style={{ display: copyng ? 'none' : 'block' }}>
                <Row justify="center">
                    <Col span={10}>
                        <ImageUpload
                            placeholder={translate('forms.placeholders.photo')}
                            defaultImage={user?.photo}
                            onChange={(str) => console.debug(str)}
                        />
                    </Col>
                </Row>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ ...editUserState }}
                    onFinish={onOk}
                    form={form}
                    fields={userEditableFields({
                        optionsApprovalGroups: approvalGroups
                            .filter((c) => c.codigoTipoPedido === 'S1')
                            .map((item) => ({
                                name: item.descricao,
                                id: item.id,
                            })),
                        optionsDiscountGroups: approvalGroups
                            .filter(
                                (c) =>
                                    c.codigoTipoPedido ===
                                    'DESCONTOS_FINANCEIROS',
                            )
                            .map((item) => ({
                                name: item.descricao,
                                id: item.id,
                            })),
                        optionsMarketingApprovalGroups: approvalGroups
                            .filter(
                                (c) =>
                                    c.codigoTipoPedido !== 'S1' &&
                                    c.codigoTipoPedido !==
                                        'DESCONTOS_FINANCEIROS',
                            )
                            .map((item) => ({
                                name: item.descricao,
                                id: item.id,
                            })),
                        optionsDivisions: divisions.map((item) => ({
                            id: item.nome,
                            name: item.nome + ' - ' + item.descricao,
                        })),
                        userId: user?.id,
                        optionsRow: divisionsSelector.availableRows?.map(
                            (r) => ({ id: r.codigo, name: r.descricao }),
                        ),
                        withRow: editUserState?.gerenteLinha,
                    }).filter(
                        (c) =>
                            currentUser.roles?.includes(Roles.admin) ||
                            (!!user && c.name !== 'password') ||
                            !user,
                    )}
                    withDelete={!!user}
                    onDelete={handleDeleteUser}
                    customButtons={
                        user
                            ? []
                            : [
                                  <Button
                                      type="ghost"
                                      className={'half-btn'}
                                      onClick={handleCopy}
                                  >
                                      {translate('forms.buttons.copyUser')}
                                  </Button>,
                              ]
                    }
                />
            </Col>
            {copyng && (
                <Col span={22}>
                    <FormBuilder
                        layout="vertical"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 24 }}
                        onFinish={onOkUser}
                        // form={form}
                        fields={selectorUserFields({
                            userOptions: availableUsers?.map((item) => ({
                                name: item.login,
                                id: item.id,
                            })),
                        })}
                        // withDelete
                        // withDeleteText="Cancelar"
                        // onDelete={handleCancelCopy}
                        customButtons={[
                            <Button
                                type="primary"
                                danger
                                className={'half-btn'}
                                onClick={handleCancelCopy}
                            >
                                {translate('general.cancel')}
                            </Button>,
                        ]}
                    />
                </Col>
            )}
            <Col span={1} />
        </Row>
    );
});
