import { translate } from '@components/i18n';

export const tableLocale = {
    filterTitle: translate('general.filterTitle'),
    filterConfirm: translate('general.filterConfirm'),
    filterReset: translate('general.filterReset'),
    filterEmptyText: translate('general.filterEmptyText'),
    selectAll: translate('general.selectAll'),
    selectInvert: translate('general.selectInvert'),
    selectionAll: translate('general.selectAllData'),
    sortTitle: translate('general.sort'),
    expand: translate('general.expandLine'),
    collapse: translate('general.colapseLine'),
    triggerDesc: translate('general.descSort'),
    triggerAsc: translate('general.ascSort'),
    cancelSort: translate('general.cancelSorting'),
    emptyText: translate('general.emptyList'),
};
