import React, { useEffect, useState } from 'react';

import { Input } from '@components';
import { SHOW_PROTOCOL } from '@config/env';
import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import {
    customerSelector,
    DraftsTable,
    fetchCompanies,
    fetchConvenio,
    fetchCustos,
    fetchMoedasSimbolos,
    fetchPdiscqt,
    fetchSemaphores,
} from '@redux/slices/order';
import { historyColumns, filterTypesStatus } from '../constants';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import {
    fetchHistory,
    historySelector,
    historyValues,
} from '@redux/slices/order/history';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { filterTypesOrderHistory } from '@pages/order/constants';
import { InputRange } from '@components/input';
import { translate } from '@components/i18n';

export const HistoryBody: React.FC = () => {
    const historyTableState = useSelector(historyValues);
    const historyState = useSelector(historySelector);
    const [queryState, setQueryState] = useState<string>('');
    const history = useHistory();
    const dispatch = useDispatch();
    const selectCustomerState = useSelector(customerSelector);
    const [form] = Form.useForm();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        let checkValues = { ...historyState.request };

        if (values['datas']) {
            const range = values['datas'];
            checkValues = {
                ...checkValues,
                dataInicio: range[0].format('DD/MM/YYYY'),
                dataFim: range[1].format('DD/MM/YYYY'),
            };
        } else {
            checkValues = {
                ...checkValues,
                dataInicio: undefined,
                dataFim: undefined,
            };
        }

        dispatch(
            fetchHistory({
                ...checkValues,
                ...values,
                filterValue: queryState,
            }),
        );
    };

    const search = (): void => {
        dispatch(
            fetchHistory({
                ...historyState.request,
                filterValue: queryState,
            }),
        );
    };

    const onSearch = (value: string): void => {
        setQueryState(value);
    };

    const onSelect = (rowKey: DraftsTable, y, x): void => {
        if (
            x.target.cellIndex === 9 ||
            x.target.outerHTML.includes('button') ||
            x.target.outerHTML.includes('svg')
        ) {
            console.debug('resend email');
        } else history.push(`/approval-order/${rowKey.id}?readOnly=true`);
    };

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchHistory({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    const start = (): void => {
        dispatch(
            fetchHistory({
                pageSize: 10,
                current: 1,
                filterValue: '',
                filterBy: '',
                company: '',
                categoria: ''
            }),
        );
        dispatch(fetchCompanies());
        dispatch(fetchCustos());
        dispatch(fetchConvenio());
        dispatch(fetchPdiscqt());
        dispatch(fetchSemaphores());
        dispatch(fetchMoedasSimbolos())
    };

    useEffect(() => {
        start();
    }, []);

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={23}>
                    <Table
                        columns={historyColumns
                            .filter(item => !(item.dataIndex === 'protocol' && !SHOW_PROTOCOL))}
                        rowKey={(record: DraftsTable) => `${record.id}`}
                        pointer
                        dataSource={historyTableState}
                        pagination={historyState.request}
                        isLoading={false}
                        onRowClick={onSelect}
                        onFilterChange={onFiltersChange}
                        onChange={loadData}
                        form={form}
                        filters={[
                            <FormItem
                                label={translate('forms.labels.searchFor')}
                                name={'filterBy'}
                            >
                                <Select
                                    placeholder={translate(
                                        'forms.placeholders.chooseFilter',
                                    )}
                                    allowClear
                                >
                                    {filterTypesOrderHistory.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.date')}
                                name={'datas'}
                            >
                                <InputRange
                                    format="DD-MM-YYYY"
                                    placeholder={[
                                        translate(
                                            'forms.placeholders.startDate',
                                        ),
                                        translate('forms.placeholders.endDate'),
                                    ]}
                                    light
                                />
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.cia')}
                                name={'company'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                >
                                    {selectCustomerState?.availableCompanies.map(
                                        (c) => (
                                            <Option key={c.id} value={c.id}>
                                                {c.id} - {c.name}
                                            </Option>
                                        ),
                                    )}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.status')}
                                name={'status'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {filterTypesStatus.map((c) => (
                                        <Option key={c.name} value={c.name}>
                                            {c.description}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={search}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                    onChange={(e) => onSearch(e.target.value)}
                                />
                            </Form.Item>,
                        ]}
                    />
                </Col>
            </Row>
        </ShadowContainer>
    );
};
