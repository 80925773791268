import React, { memo, useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { editableFields } from '../constants';
import { FormBuilder } from '@components/form';
import { translate } from '@components/i18n';
import {
    createDeadline,
    removeDeadline,
    updateDeadline,
} from '@redux/slices/deadline';
import { DeadlineResponse } from '@models/deadline';

interface EditModalProps {
    data?: DeadlineResponse;
}

export const EditModal: React.FC<EditModalProps> = memo(({ data }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm<DeadlineResponse>();
    const [editState, setEditState] = useState<DeadlineResponse>(data);
    const modalContext = useContext(ModalContext);

    const onOk = (values): void => {
        if (!data) addNew(values);
        else handleUpdate(values);
    };

    const handleUpdate = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingItem'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateDeadline({
                data: {
                    id: data?.id,
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addNew = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringItem'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            createDeadline({
                data: values,
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    useEffect(() => {
        if (data) {
            setEditState(data);
            form.setFieldsValue(data);
        } else {
            form.resetFields();
        }
    }, [data]);

    const onChangeValues = (
        changedValues: Partial<DeadlineResponse>,
        allValues: DeadlineResponse,
    ): void => {
        setEditState({
            ...allValues,
            ...changedValues,
        });
    };

    const handleDeleteUser = (): void => {
        dispatch(removeDeadline(data.id));
        modalContext.handleClose();
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ ...editState }}
                    onFinish={onOk}
                    form={form}
                    fields={editableFields()}
                    withDelete={!!data}
                    onDelete={handleDeleteUser}
                />
            </Col>
            <Col span={1} />
        </Row>
    );
});
