import React, { memo } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { FooterWrapper } from './styled';
import { authSelector, logoutUser } from '@redux/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import { ImportOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';

export const Footer: React.FC = memo(() => {
    const authState = useSelector(authSelector);
    const dispatch = useDispatch();

    const logout = (): void => {
        dispatch(logoutUser());
    };

    return (
        <FooterWrapper paddingTop={10} paddingBottom={10}>
            <Row justify={'space-between'}>
                <Col>
                    <Typography.Text>
                        {translate('general.copyright')}
                    </Typography.Text>
                </Col>
                {authState.currentUser && (
                    <Col>
                        <Button
                            onClick={logout}
                            type="link"
                            danger
                            icon={<ImportOutlined />}
                        >
                            {translate('general.logout')}
                        </Button>
                    </Col>
                )}
            </Row>
        </FooterWrapper>
    );
});
