import React, { memo, useContext, useEffect, useState } from 'react';
import { Col, Divider, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    addOrderToBonification,
    bonificationSelector,
    bonificationTableSelector,
    OrderBonificationTable,
    orderItemsSelector,
    setSelectedOrders,
} from '@redux/slices/order';
import { ModalContext } from 'src/contexts/modal-context';
import { Button } from '@styles/components/button';
import { LoadingOutlined } from '@ant-design/icons';
import { orderBonificationColumns } from '../constants';
import { Table } from '@components/table';
import { Title } from '@styles/components/text';
import { useHistory } from 'react-router-dom';
import { TextLoop } from 'react-text-loop-next';
import { OrderContext } from '../context/context';
import { translate } from '@components/i18n';

interface OrdersBonificationProps {
    draft: boolean;
}

export const OrdersBonification: React.FC<OrdersBonificationProps> = memo(
    ({ draft }) => {
        const dispatch = useDispatch();
        const history = useHistory();
        const bonificationState = useSelector(bonificationSelector);
        const ordersToBonification = useSelector(bonificationTableSelector);
        const selectItemsState = useSelector(orderItemsSelector);
        const { setCanLeave } = useContext(OrderContext);

        const [selectedRows, setSelectedRows] = useState<
            OrderBonificationTable[]
        >([]);

        const modalContext = useContext(ModalContext);

        const onOk = (): void => {
            dispatch(
                setSelectedOrders(
                    bonificationState.availableOrders.filter((c) =>
                        selectedRows.some((r) => `${r.key}` === `${c.key}`),
                    ),
                ),
            );

            dispatch(
                addOrderToBonification({
                    ...selectItemsState.order,
                    key: selectItemsState.draftRef,
                    availableItems: selectItemsState.availableItems.filter(
                        (i) => i.quantity > 0,
                    ),
                }),
            );

            modalContext.handleClose();
            setCanLeave(() =>
                history.push(
                    `/order/bonification?draftRef=${selectItemsState.draftRef}&draft=${draft}`,
                ),
            );
        };

        useEffect(() => {
            if (
                !bonificationState.isFetching &&
                bonificationState.isSuccess &&
                bonificationState.availableOrders.length === 0
            )
                onOk();
        }, [bonificationState.isFetching, bonificationState.isSuccess]);

        const rowSelection = {
            onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: OrderBonificationTable[],
            ) => {
                setSelectedRows(selectedRows);
            },
        };

        return (
            <Row align="middle">
                <Col span={24}>
                    {bonificationState.isFetching && (
                        <Row justify="center" align="middle" gutter={20}>
                            <Col>
                                <TextLoop mask>
                                    <Title as="h3" margin={0} blue>
                                        {translate(
                                            'general.verifyingOtherOrders',
                                        )}
                                    </Title>
                                    <Title margin={0} as="h3">
                                        {translate('general.maybeWantBonify')}
                                    </Title>
                                </TextLoop>
                                <div style={{ height: 10 }} />
                            </Col>
                            <Col>
                                <Spin
                                    spinning
                                    indicator={
                                        <LoadingOutlined
                                            style={{ fontSize: 24 }}
                                            spin
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                    )}
                    {!bonificationState.isFetching &&
                        bonificationState.isSuccess &&
                        ordersToBonification.length === 0 && (
                            <TextLoop mask>
                                <Title margin={0} as="h3">
                                    {translate('general.noOtherOrderFind')}
                                </Title>
                                <Title margin={0} as="h3">
                                    {translate('general.noOtherOrderFind')}
                                </Title>
                            </TextLoop>
                        )}
                    {!bonificationState.isFetching &&
                        ordersToBonification.length > 0 && (
                            <Col>
                                <Title as="h3" marginBottom={0}>
                                    {translate(
                                        'general.wantToAddOtherOrderToBonification',
                                    )}
                                </Title>
                                <Table
                                    columns={orderBonificationColumns}
                                    rowKey={(record: OrderBonificationTable) =>
                                        record.key
                                    }
                                    rowSelection={{
                                        ...rowSelection,
                                        columnWidth: '20px',
                                        fixed: true,
                                    }}
                                    dataSource={ordersToBonification}
                                />
                                <Divider style={{ margin: '10px 0px' }} />
                                <Row justify="end">
                                    <Button type="primary" onClick={onOk}>
                                        {translate('general.advance')}
                                    </Button>
                                </Row>
                            </Col>
                        )}
                </Col>
            </Row>
        );
    },
);
