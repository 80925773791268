export interface CustomerResponse {
    id?: number;
    name?: string;
    code?: number;
    company?: number;
    type?: string;
    typeId?: string;
    city?: string;
    state?: string;
    cnpj?: string;

    address?: string;
    alternativeAddress?: string;
    isentIpi?: boolean;
    suframa?: string;
    group?: string;
    trade?: string;
    country?: string;
}

export class CustomerResponseApi {
    codigo: number;
    nome: string;
    tipo: string;
    tipoDescricao: string;
    zona: string;
    trade?: unknown;
    endereco1: string;
    endereco2: string;
    bairro: string;
    cidade: string;
    condicaoPagto: string;
    companhia: number;
    cnpj: string;
    suframa: string;
    licenca?: unknown;
    grupo: string;
    isentaIpi: boolean;
    pais: string;

    toCustomerResponse(): CustomerResponse {
        return {
            city: this.cidade,
            cnpj: this.cnpj,
            code: this.codigo,
            company: this.companhia,
            id: this.codigo,
            name: this.nome,
            typeId: this.tipo,
            type: this.tipoDescricao,
            state: this.zona,
            address: this.endereco1,
            alternativeAddress: this.endereco2,
            suframa: this.suframa,
            isentIpi: this.isentaIpi,
            group: this.grupo,
            trade: `${this.trade}`,
            country: this.pais,
        };
    }
}

export interface CompanyResponse {
    id: number;
    name: string;
}

export class CompanyResponseApi {
    codigo: number;
    descricao: string;

    toCompanyResponse(): CompanyResponse {
        return {
            id: this.codigo,
            name: this.descricao,
        };
    }
}

export interface CompanyTypeResponse {
    tipo: string;
    classificacao: string;
}

export class CompanyTypeResponseAPI {
    tipo: string;
    classificacao: string;

    toCompanyResponse(): CompanyTypeResponse {
        return {
            tipo: this.tipo,
            classificacao: this.classificacao,
        };
    }
}
