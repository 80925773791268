import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import '@extensions/number';
import { ApprovalContext } from '../context/context';
import { Button } from '@styles/components/button';
import { ModalContext } from 'src/contexts/modal-context';
import { RefuseModal } from './refuse-modal';
import { cloneApiOrder } from '@redux/slices/order';
import { translate } from '@components/i18n';

export const OrderActions: React.FC = () => {
    const { refuseOrder, acceptOrder, readOnly, order } =
        useContext(ApprovalContext);
    const modalContext = useContext(ModalContext);
    const history = useHistory();
    const dispatch = useDispatch();

    const copyOrder = (): void => {
        message.loading({
            content: translate('general.copyingOrder'),
            key: order.key,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            cloneApiOrder({
                data: {
                    requestOrder: {
                        ...order,
                        availableItems: order.tempItems.filter(
                            (i) => i.quantity > 0,
                        ),
                        key: `${new Date().valueOf()}`,
                    },
                    save: false,
                },
                notificationKey: order.key,
                onSuccess: (id) => {
                    if (order.orderType.type.id === 'V2') {
                        history.push(
                            '/order/marketing-bonification?draft=true&marketing=true&ref=' +
                                id,
                        );
                    } else
                        history.push(
                            `/create-order/select-items?draft=true&ref=${id}`,
                        );
                },
            }),
        );
    };

    return (
        <div style={{ marginTop: 30, padding: 10 }}>
            <Button
                type="ghost"
                height={40}
                width={'100%'}
                onClick={() => {
                    history.goBack();
                }}
            >
                {translate('general.goBackList')}
            </Button>
            {readOnly && (
                <Button
                    type="primary"
                    height={40}
                    width={'100%'}
                    style={{ marginTop: 10 }}
                    onClick={copyOrder}
                >
                    {translate('forms.buttons.copyOrder')}
                </Button>
            )}
            {!readOnly && (
                <>
                    <Button
                        danger
                        type="primary"
                        height={40}
                        width={'100%'}
                        style={{ marginTop: 20 }}
                        onClick={() => {
                            modalContext.setIsOpen(
                                true,
                                <RefuseModal
                                    refuse={(reason) =>
                                        refuseOrder({ text: reason })
                                    }
                                />,
                                {
                                    title: translate('general.whatRefuse'),
                                    width: '30%',
                                },
                            );
                        }}
                    >
                        {translate('forms.buttons.toRepprove')}
                    </Button>
                    <Button
                        type="primary"
                        height={40}
                        width={'100%'}
                        style={{ marginTop: 20 }}
                        success
                        onClick={acceptOrder}
                    >
                        {translate('forms.buttons.toApprove')}
                    </Button>
                </>
            )}
        </div>
    );
};
