import React, { useContext } from 'react';

import { ShadowContainer } from '@styles/components/wrapper';
import { Col, Row, Tabs, Table as AntTable, Tag } from 'antd';
import { approvalSelector, orderItemsSelector } from '@redux/slices/order';
import { itemsColumns } from '../constants';
import { Table } from '@components/table';
import { useHistory } from 'react-router-dom';
import { FinancialDiscountContext } from '../context/context';
import { Title } from '@styles/components/text';
import { OrderInfo } from './order-info';
import { Comments } from './comments';
import { OrderActions } from './order-actions';
import { HeaderMenu } from '@components/header/styled';
import { RightOutlined } from '@ant-design/icons';
import { ItemResponse } from '@models/order/items/response';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrderDetailsTableFooter } from './order-details-table-footer';
import { Semaphore } from '@components/semaphore';
import { colors } from '@utils/constants';
import { translate } from '@components/i18n';
import { List } from '@components/list';

const { TabPane } = Tabs;

interface ParamProps {
    id?: string;
}

export const OrderDetails: React.FC = () => {
    const context = useContext(FinancialDiscountContext);
    const { availableApprovals } = useSelector(approvalSelector);
    const { selectedMoeda } = useSelector(orderItemsSelector);
    const history = useHistory();
    const { id } = useParams<ParamProps>();

    return (
        <ShadowContainer style={{ marginTop: 30 }}>
            <Row
                style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
                align={'middle'}
                gutter={10}
                justify="space-between"
            >
                <Col span={24} lg={{span: 10}}>
                    <Row align={'middle'}>
                        <Title
                            level={3}
                            blue
                            style={{ marginBottom: 0, marginRight: 5 }}
                        >
                            {`${translate('general.ordersT')} >`}
                        </Title>
                        <Title
                            level={5}
                            blue
                            style={{ marginBottom: 0, marginTop: 0 }}
                        >
                            {translate('general.discount')}
                        </Title>
                    </Row>
                </Col>
                <Col>
                    {!context.readOnly && (
                        <Row
                            align="middle"
                            gutter={10}
                            style={{
                                opacity: availableApprovals.length > 1 ? 1 : 0,
                            }}
                        >
                            <Col>
                                <HeaderMenu
                                    onClick={() => {
                                        const next = availableApprovals.filter(
                                            (i) => `${i.draftRef}` !== `${id}`,
                                        );

                                        history.push(
                                            '/approval-order/' +
                                                next[0].draftRef,
                                        );
                                    }}
                                >
                                    <RightOutlined />
                                </HeaderMenu>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Row>
                <Col span={23} lg={{span: 12}}>
                    <OrderInfo />
                </Col>
                <Col span={23} lg={{span: 8}}>
                    <Comments comments={context.comment ?? ''} />
                </Col>
                <Col span={23} lg={{span: 4}}>
                    <OrderActions />
                </Col>
            </Row>
            <Row justify="center">
                <Col span={0} lg={{span: 23}}>
                    <Tabs style={{ marginTop: 20 }}>
                        {!!context.orders && context.orders.length > 0 && (
                            <>
                                {context.orders.map((or) => (
                                    <TabPane
                                        tab={
                                            <Row gutter={10}>
                                                <Col>
                                                    {translate('columns.order')}{' '}
                                                    - {or.key}{' '}
                                                </Col>
                                                <Semaphore 
                                                    order={or}
                                                    moeda={selectedMoeda}
                                                />
                                            </Row>
                                        }
                                        key={'order-key-' + or.key}
                                    >
                                        <Table
                                            columns={itemsColumns}
                                            rowKey={(record: ItemResponse) =>
                                                `${record.id}-${or.key}`
                                            }
                                            pointer
                                            dataSource={or?.tempItems ?? []}
                                            pagination={undefined}
                                            isLoading={context.loading}
                                            summary={
                                                or.campanhaIds.length > 0
                                                    ? () => (
                                                          <AntTable.Summary
                                                              fixed={true}
                                                          >
                                                              <AntTable.Summary.Row>
                                                                  <AntTable.Summary.Cell
                                                                      index={2}
                                                                      colSpan={
                                                                          16
                                                                      }
                                                                  >
                                                                      <Row
                                                                          wrap={
                                                                              false
                                                                          }
                                                                      >
                                                                          <b
                                                                              style={{
                                                                                  marginRight: 5,
                                                                                  opacity: 0.5,
                                                                              }}
                                                                          >
                                                                              {translate(
                                                                                  'general.activeCampain',
                                                                              )}
                                                                              :
                                                                          </b>
                                                                          {or.campanhaIds
                                                                              .reverse()
                                                                              .map(
                                                                                  (
                                                                                      c,
                                                                                  ) => (
                                                                                      <Tag
                                                                                          color={
                                                                                              colors[
                                                                                                  context.campaigns?.findIndex(
                                                                                                      (
                                                                                                          d,
                                                                                                      ) =>
                                                                                                          d.id ===
                                                                                                          c,
                                                                                                  )
                                                                                              ]
                                                                                          }
                                                                                      >
                                                                                          {
                                                                                              context.campaigns?.find(
                                                                                                  (
                                                                                                      d,
                                                                                                  ) =>
                                                                                                      d.id ===
                                                                                                      c,
                                                                                              )
                                                                                                  ?.nome
                                                                                          }
                                                                                      </Tag>
                                                                                  ),
                                                                              )}
                                                                      </Row>
                                                                  </AntTable.Summary.Cell>
                                                              </AntTable.Summary.Row>
                                                          </AntTable.Summary>
                                                      )
                                                    : undefined
                                            }
                                            footerObject={
                                                <OrderDetailsTableFooter
                                                    order={or}
                                                    moeda={selectedMoeda}
                                                />
                                            }
                                        />
                                    </TabPane>
                                ))}
                            </>
                        )}
                    </Tabs>
                </Col>
                <Col span={23} lg={{span: 0}}>
                    <Tabs style={{ marginTop: 20 }}>
                        {!!context.orders && context.orders.length > 0 && (
                            <>
                                {context.orders.map((or) => (
                                    <TabPane
                                        tab={
                                            <Row gutter={10}>
                                                <Col>
                                                    {translate('columns.order')}{' '}
                                                    - {or.key}{' '}
                                                </Col>
                                                <Semaphore 
                                                    order={or}
                                                    moeda={selectedMoeda}
                                                />
                                            </Row>
                                        }
                                        key={'order-key-' + or.key}
                                    >
                                        <List
                                            columns={itemsColumns}
                                            rowKey={(record: ItemResponse) =>
                                                `${record.id}-${or.key}`
                                            }
                                            dataSource={or?.tempItems ?? []}
                                            pagination={undefined}
                                            isLoading={context.loading}
                                            footerObject={
                                                <OrderDetailsTableFooter
                                                    order={or}
                                                    moeda={selectedMoeda}
                                                />
                                            }
                                        />
                                    </TabPane>
                                ))}
                            </>
                        )}
                    </Tabs>
                </Col>
            </Row>
        </ShadowContainer>
    );
};
