import {
    canSaveOrderSelector,
    fetchDrafts,
    orderItemsSelector,
    saveApiOrder,
    saveBonificationOrder,
    sendOrder,
    updateOrder,
    addOrderToBonification,
    addTempItems,
    fetchApproversForOrder,
    ApproverOrder,
} from '@redux/slices/order';

import { Checkbox, Col, Form, Popover, Row } from 'antd';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SolidBackground } from '@styles/components/wrapper';
import { Button } from '@styles/components/button';
import { ModalContext } from 'src/contexts/modal-context';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { OrdersBonification } from './orders-bonification-modal';
import { SendCopyRequest } from '@models/order/items/request';
import { FormItem } from '@styles/components/form';
import { Input } from '@components/input';
import { useQuery } from '@hooks/use-query';
import { Prompt } from 'react-router';
import { OrderContext } from '../context/context';
import { selectedCampaignsSelector } from '@redux/slices/campaign';
import { RootState } from '@redux/reducers';
import { Loading } from '@styles/components/loader';
import { ApproversListTimeline } from './approvers-list';
import { translate } from '@components/i18n';

const { confirm } = Modal;

interface SelectItemsActionsProps {
    bonification?: boolean;
    marketing?: boolean;
    send?: number;
}

interface Approvers {
    loading: boolean;
    approvers: ApproverOrder[];
}

export const SelectItemsActions: React.FC<SelectItemsActionsProps> = memo(
    ({ bonification = false, marketing = false, send = 0 }) => {
        const selectItemsState = useSelector(orderItemsSelector);
        const query = useQuery();
        const canSave = useSelector(canSaveOrderSelector);
        const rootState = useSelector((c) => c) as RootState;
        const modalContext = useContext(ModalContext);
        const history = useHistory();
        const [form] = Form.useForm<SendCopyRequest>();
        const selectedCampaigns = useSelector(selectedCampaignsSelector);
        const dispatch = useDispatch();
        const [sendCopyState, setSendCopyState] = useState<SendCopyRequest>({
            email: '',
            note: '',
            envioProximoPedidoVenda: true,
        });
        const { canLeave, setCanLeave } = useContext(OrderContext);
        const [approvers, setApprovers] = useState<Approvers>({
            loading: true,
            approvers: [],
        });

        const onOk = (): void => {
            dispatch(
                updateOrder({
                    sendCopy: sendCopyState,
                }),
            );
        };

        useEffect(() => {
            if (selectItemsState.order?.sendCopy) {
                setSendCopyState(selectItemsState.order.sendCopy);
                form.setFieldsValue(selectItemsState.order.sendCopy);
            } else onOk();
            dispatch(fetchDrafts());
        }, []);

        const onChangeValues = (
            changedValues: Partial<SendCopyRequest>,
            allValues: SendCopyRequest,
        ): void => {
            setSendCopyState({
                ...allValues,
                ...changedValues,
            });

            if (changedValues.envioProximoPedidoVenda !== undefined) {
                dispatch(
                    updateOrder({
                        sendCopy: {
                            ...allValues,
                            ...changedValues,
                        },
                    }),
                );
            }
        };

        const cancelOrder = (): void => {
            confirm({
                title:
                    bonification && !marketing
                        ? translate('general.wantToCancelBonificationAndRetorn')
                        : selectItemsState.order.orderType.date
                        ? `${translate('general.wantToCancel')}${
                              selectItemsState.draftRef
                                  ? translate('general.edition')
                                  : translate('general.creation')
                          } ${translate('general.toprogramming')}`
                        : `${translate('general.wantToCancel')}${
                              selectItemsState.draftRef
                                  ? translate('general.edition')
                                  : translate('general.creation')
                          } ${translate('general.toOrder')}`,
                icon: <ExclamationCircleOutlined />,
                className: 'confirm-modal-styled',
                okText: translate('general.no'),
                okType: 'danger',
                cancelText: translate('general.yes'),
                okButtonProps: { type: 'primary' },
                cancelButtonProps: { type: 'primary', className: 'green-btn' },
                onOk() {
                    console.debug('OK');
                },
                onCancel() {
                    if (bonification && !marketing)
                        setCanLeave(() =>
                            history.replace(
                                `/create-order/select-items?draft=true&ref=${query.get(
                                    'draftRef',
                                )}`,
                            ),
                        );
                    else setCanLeave(() => history.push('/'));
                },
            });
        };

        const saveOrder = (save = false, draft = false): void => {
            if (bonification && !marketing) {
                dispatch(
                    saveBonificationOrder({
                        notificationKey: 'bonification',
                        data: {
                            bonificationId: query.get('bonificationId')
                                ? Number(query.get('bonificationId'))
                                : undefined,
                        },
                        onSuccess: () => {
                            if (send !== 0)
                                dispatch(
                                    sendOrder({
                                        data: [send],
                                        notificationKey: 'orderSend',
                                    }),
                                );
                            setCanLeave(() => history.push('/'));
                        },
                    }),
                );
            } else if (marketing) {
                const orderKey = `${new Date().valueOf()}`;
                message.loading({
                    content: translate('general.sendingMarketingBonitication'),
                    key: orderKey,
                    duration: 10,
                    className: 'message-styled',
                });
                dispatch(
                    saveApiOrder({
                        data: {
                            requestOrder: {
                                ...selectItemsState.order,
                                codigoMoeda: selectItemsState.selectedMoeda.codigo,
                                availableItems:
                                    selectItemsState.availableItems.filter(
                                        (i) => i.quantity > 0,
                                    ),
                                key: `${new Date().valueOf()}`,
                            },
                            save: selectItemsState.order.orderType.date
                                ? false
                                : true,
                        },
                        notificationKey: orderKey,
                        onSuccess: () => {
                            setCanLeave(() => history.push('/'));
                        },
                    }),
                );
            } else {
                if (!selectItemsState.order.bonificationId) {
                    const bonification = selectedCampaigns
                        ? selectedCampaigns.some((c) =>
                              c.tipo.includes('BONIFICACAO'),
                          )
                        : false;

                    if (!bonification)
                        confirm({
                            title: translate(
                                'general.wantCreateBonificationForThisOrder',
                            ),
                            icon: <CheckCircleOutlined />,
                            className: 'confirm-modal-styled',
                            okText: translate('general.yes'),
                            cancelText: translate('general.no'),
                            cancelButtonProps: {
                                type: 'primary',
                                className: 'dark-blue-btn',
                            },
                            okButtonProps: {
                                type: 'primary',
                                className: 'green-btn',
                            },
                            closable: true,
                            onOk() {
                                saveDraft(true);

                                modalContext.setIsOpen(
                                    true,
                                    <OrdersBonification draft={draft} />,
                                    {
                                        title: translate(
                                            'general.comercialBonification',
                                        ),
                                        width: '50%',
                                    },
                                );
                            },
                            onCancel(e) {
                                if (e.triggerCancel) return;
                                const orderKey = `${new Date().valueOf()}`;
                                message.loading({
                                    content: !save
                                        ? translate('general.sendingOrder')
                                        : translate('general.savingDraft'),
                                    key: orderKey,
                                    duration: 10,
                                    className: 'message-styled',
                                });

                                dispatch(
                                    saveApiOrder({
                                        data: {
                                            requestOrder: {
                                                ...selectItemsState.order,
                                                codigoMoeda: selectItemsState.selectedMoeda.codigo,
                                                availableItems:
                                                    selectItemsState.availableItems.filter(
                                                        (i) => i.quantity > 0,
                                                    ),
                                                key: `${new Date().valueOf()}`,
                                            },
                                            save: !save
                                                ? selectItemsState.order
                                                      .orderType.date
                                                    ? false
                                                    : true
                                                : false,
                                        },
                                        notificationKey: orderKey,
                                        onSuccess: () => {
                                            setCanLeave(() =>
                                                history.push('/'),
                                            );
                                        },
                                    }),
                                );

                                e();
                            },
                        });
                    else {
                        saveDraft(true);
                        modalContext.setIsOpen(
                            true,
                            <OrdersBonification draft={draft} />,
                            {
                                title: translate(
                                    'general.comercialBonification',
                                ),
                                width: '50%',
                            },
                        );
                    }
                } else if (selectItemsState.order.bonificationId) {
                    saveDraft(true);
                    dispatch(
                        addOrderToBonification({
                            ...selectItemsState.order,
                            key: selectItemsState.draftRef,
                            availableItems:
                                selectItemsState.availableItems.filter(
                                    (i) => i.quantity > 0,
                                ),
                        }),
                    );

                    dispatch(
                        addTempItems(selectItemsState.order.bonificationItems),
                    );

                    modalContext.handleClose();

                    setCanLeave(() =>
                        history.push(
                            `/order/bonification?draftRef=${selectItemsState.draftRef}&bonificationId=${selectItemsState.order.bonificationId}&draft=false`,
                        ),
                    );
                }
            }
        };

        const saveDraft = (bonificate = false): string => {
            const draftRef = selectItemsState.draftRef
                ? selectItemsState.draftRef
                : `${new Date().valueOf()}`;

            if (bonification && !marketing) {
                if (!bonificate)
                    message.loading({
                        content: translate('general.savingDraft'),
                        key: draftRef,
                        duration: 10,
                        className: 'message-styled',
                    });

                dispatch(
                    saveBonificationOrder({
                        notificationKey: draftRef,
                        data: {
                            bonificationId: query.get('bonificationId')
                                ? Number(query.get('bonificationId'))
                                : undefined,
                        },
                        onSuccess: () => {
                            setCanLeave(() => history.push('/'));
                        },
                    }),
                );
            } else {
                dispatch(
                    saveApiOrder({
                        data: {
                            requestOrder: {
                                ...selectItemsState.order,
                                codigoMoeda: selectItemsState.selectedMoeda.codigo,
                                availableItems:
                                    selectItemsState.availableItems.filter(
                                        (i) => i.quantity > 0,
                                    ),
                                key: `${new Date().valueOf()}`,
                            },
                            save: false,
                            bonificate,
                        },
                        notificationKey: draftRef,
                        onSuccess: () => {
                            if (!bonificate) {
                                setCanLeave(() => history.push('/'));
                            }
                        },
                    }),
                );
            }

            return draftRef;
        };

        const checkApprovers = async () => {
            setApprovers({ loading: true, approvers: [] });
            const approver = await fetchApproversForOrder(
                rootState,
                query.get('draftRef'),
            );
            setApprovers({ loading: false, approvers: approver });
        };

        const draft = (): void => {
            if (marketing || bonification) {
                saveDraft();
            } else {
                saveOrder(true, true);
            }
        };

        return (
            <SolidBackground
                marginTop={20}
                marginBottom={80}
                paddingBottom={10}
                paddingX={'40px'}
            >
                <Prompt
                    when={!query.get('readOnly') && !canLeave}
                    message={`${translate('general.wantExitOf')} ${
                        query.get('draft')
                            ? translate('general.edition')
                            : translate('general.creation')
                    } ${translate('general.toOrder')}`}
                />
                <Row justify="space-between" align="middle">
                    <Col span={10}>
                        {(marketing || !bonification) && (
                            <Form<SendCopyRequest>
                                onValuesChange={onChangeValues}
                                layout={!marketing ? 'inline' : 'vertical'}
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 24 }}
                                initialValues={{ ...sendCopyState }}
                                form={form}
                                style={{ width: '100%' }}
                            >
                                {marketing && (
                                    <>
                                        <FormItem
                                            label={translate(
                                                'forms.labels.sendOrderWithNextSale',
                                            )}
                                            name={'envioProximoPedidoVenda'}
                                            valuePropName="checked"
                                        >
                                            <Checkbox />
                                        </FormItem>
                                        <Row wrap={false}>
                                            <FormItem
                                                label={translate(
                                                    'forms.labels.sendCopyFor',
                                                )}
                                                vertical
                                                style={{
                                                    width: '40%',
                                                    marginRight: 10,
                                                    flexWrap: 'unset',
                                                }}
                                                name={'email'}
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: translate(
                                                            'forms.rules.validEmail',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={translate(
                                                        'forms.placeholders.email',
                                                    )}
                                                    onBlur={onOk}
                                                />
                                            </FormItem>
                                            <FormItem
                                                label={`${translate(
                                                    'forms.labels.observation',
                                                )}:`}
                                                name={'note'}
                                                vertical
                                                style={{
                                                    width: '50%',
                                                    flexWrap: 'unset',
                                                }}
                                            >
                                                <Input
                                                    placeholder={translate(
                                                        'forms.labels.observation',
                                                    )}
                                                    onBlur={onOk}
                                                />
                                            </FormItem>
                                        </Row>
                                    </>
                                )}
                                {!marketing && (
                                    <>
                                        <FormItem
                                            label={translate(
                                                'forms.labels.sendCopyFor',
                                            )}
                                            name={'email'}
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: translate(
                                                        'forms.rules.validEmail',
                                                    ),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={translate(
                                                    'forms.placeholders.email',
                                                )}
                                                onBlur={onOk}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label={`${translate(
                                                'forms.labels.observation',
                                            )}:`}
                                            name={'note'}
                                            style={{ width: '50%' }}
                                        >
                                            <Input
                                                placeholder={translate(
                                                    'forms.labels.observation',
                                                )}
                                                onBlur={onOk}
                                            />
                                        </FormItem>
                                    </>
                                )}
                            </Form>
                        )}
                    </Col>
                    <Row gutter={10}>
                        <Col>
                            <Button
                                danger
                                type="primary"
                                height={40}
                                onClick={cancelOrder}
                            >
                                {translate('general.cancel')}
                            </Button>
                        </Col>
                        <Col>
                            <Popover
                                content={() => {
                                    return (
                                        <>
                                            {approvers.loading && <Loading />}
                                            {!approvers.loading &&
                                                approvers.approvers.length ===
                                                    0 && (
                                                    <span>
                                                        {translate(
                                                            'general.noDetectedApprover',
                                                        )}
                                                    </span>
                                                )}
                                            {!approvers.loading &&
                                                approvers.approvers.length >
                                                    0 && (
                                                    <ApproversListTimeline
                                                        approvers={
                                                            approvers.approvers
                                                        }
                                                    />
                                                )}
                                        </>
                                    );
                                }}
                                trigger="click"
                                onVisibleChange={(visible) => {
                                    if (visible) checkApprovers();
                                }}
                            >
                                <Button
                                    type="primary"
                                    height={40}
                                    disabled={
                                        !selectItemsState.availableItems.some(
                                            (i) => i.quantity > 0,
                                        )
                                    }
                                    lightBlue
                                >
                                    {translate('general.approvers')}
                                </Button>
                            </Popover>
                        </Col>
                        {((marketing &&
                            !selectItemsState.order.orderType?.date) ||
                            (bonification &&
                                query.get('draft') === 'true' &&
                                !selectItemsState.order.orderType?.date) ||
                            (!bonification &&
                                !selectItemsState.order.orderType?.date)) && (
                            <Col>
                                <Button
                                    type="primary"
                                    darkBlue
                                    height={40}
                                    onClick={draft}
                                    disabled={
                                        !selectItemsState.availableItems.some(
                                            (i) => i.quantity > 0,
                                        )
                                    }
                                >
                                    {(!selectItemsState.order.orderType?.date ||
                                        bonification) &&
                                        translate('general.saveDraft')}
                                </Button>
                            </Col>
                        )}
                        {(marketing ||
                            !bonification ||
                            (bonification &&
                                query.get('draft') === 'false')) && (
                            <Col>
                                <Button
                                    type="primary"
                                    height={40}
                                    disabled={!canSave}
                                    onClick={() => saveOrder(false)}
                                >
                                    {(!selectItemsState.order.bonificationId ||
                                        (bonification &&
                                            !selectItemsState.order.orderType
                                                ?.date)) &&
                                        translate('general.send')}
                                    {selectItemsState.order.bonificationId &&
                                        selectItemsState.order.orderType
                                            ?.date &&
                                        translate('general.editBonitication')}
                                    {selectItemsState.order.bonificationId &&
                                        !selectItemsState.order.orderType
                                            ?.date &&
                                        window.location.pathname.includes(
                                            'create-order',
                                        ) &&
                                        translate('general.editBonitication')}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Row>
            </SolidBackground>
        );
    },
);
