import { RouterPath } from '@definitions/routes';
import { MinOrder } from '@pages/min-order';

const routes: RouterPath[] = [
    {
        path: '/min-order',
        auth: true,
        render: () => <MinOrder />,
        allowedRoles: ['FATURAMENTO'],
    },
];

export default routes;
