import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useSelector } from "react-redux";
import { CustomMenu } from "@components/header/styled";
import { authSelector, IAuthState } from "@redux/slices/auth";
import { COUNTRY } from '@config/env';
import { translate } from '@components/i18n';
import { Text } from '@styles/components/text';
import meta from '../../meta.json'

export const TopMenu = (authState: IAuthState) => {

  return (
    <CustomMenu key="rel-menu" style={{ marginTop: 5 }}>
      {meta.sideMenus
        .find((m) => m.title === 'sideMenu.reports.self')
        ?.subMenus?.filter(
          (m) =>
            authState.currentUser.isAdmin ||
            m.allowedRoles?.some((r) =>
              authState.currentUser.roles.some((c) => c === r),
            ),
        )
        .filter(sb => (sb.onlyCountries.includes("ALL") || sb.onlyCountries.includes(COUNTRY)))
        .map((m, i) => {
          return (
            <CustomMenu.Item key={i}>
              <Link
                to={m.path}
                key={`side-menu-${translate(m.title)}-${i}-link`}
              >
                <Row gutter={12}>
                  <Col>
                    <Text
                      blue
                      fontWeight={400}
                      fontSize={15}
                    >
                      {translate(m.title)}
                    </Text>
                  </Col>
                </Row>
              </Link>
            </CustomMenu.Item>
          );
        })}
    </CustomMenu>
  )
}