import {
    CaretDownOutlined,
    ImportOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { translate } from '@components/i18n';
import { authSelector, logoutUser } from '@redux/slices/auth';
import { Text } from '@styles/components/text';
import { Avatar, Col, Dropdown, Row } from 'antd';
import React, { memo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { CustomMenu, HeaderMenuTitle } from '../styled';
import { EditUserModal } from './edit-user-modal';

export const ActionsUser: React.FC = memo(() => {
    const authState = useSelector(authSelector);
    const dispatch = useDispatch();
    const modalContext = useContext(ModalContext);

    const logout = (): void => {
        dispatch(logoutUser());
    };

    const editUser = (): void => {
        modalContext.setIsOpen(true, <EditUserModal />, {
            title: translate('general.editPersonalData'),
            width: '45%',
        });
    };

    const menu = (
        <CustomMenu>
            <CustomMenu.Item key="0" onClick={editUser}>
                <Row gutter={20}>
                    <Col span={3}>
                        <UserOutlined
                            style={{ fontSize: 16, color: '#00ADEF' }}
                        />
                    </Col>
                    <Col
                        span={21}
                        style={{ flexDirection: 'column', display: 'flex' }}
                    >
                        <Text blue fontWeight={400} fontSize={17}>
                            {translate('general.myData')}
                        </Text>
                        <Text fontWeight={400} opacity={0.6}>
                            {authState.currentUser.email}
                        </Text>
                        <Text>
                            <span>{translate('general.login')}: </span>
                            <Text opacity={0.6}>
                                {authState.currentUser.login}
                            </Text>
                        </Text>
                        <Text>
                            <span>{translate('general.division')}: </span>
                            <Text opacity={0.6}>
                                {authState.currentUser.division}
                            </Text>
                        </Text>
                    </Col>
                </Row>
            </CustomMenu.Item>
            <CustomMenu.Divider />
            <CustomMenu.Item key="3" onClick={logout}>
                <Row gutter={12}>
                    <Col span={3}>
                        <ImportOutlined
                            style={{ fontSize: 16, color: '#EB5757' }}
                        />
                    </Col>
                    <Col>
                        <Text blue fontWeight={400} fontSize={17}>
                            {translate('general.logout')}
                        </Text>
                    </Col>
                </Row>
            </CustomMenu.Item>
        </CustomMenu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Row align="middle" gutter={10} style={{ cursor: 'pointer' }}>
                <Col>
                    <Avatar
                        shape="circle"
                        size="large"
                        src={authState.currentUser.photo}
                    >
                        <UserOutlined style={{ fontSize: 20 }} />
                    </Avatar>
                </Col>
                <Col>
                    <HeaderMenuTitle>
                        {authState.currentUser.name} <CaretDownOutlined />
                    </HeaderMenuTitle>
                </Col>
            </Row>
        </Dropdown>
    );
});
