import React, { useState } from 'react';
import { PageContext } from './producer';

export const PageProvider: React.FC = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (): void => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <PageContext.Provider
            value={{
                drawerOpen,
                toggleDrawer,
            }}
        >
            {children}
        </PageContext.Provider>
    );
};
