import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { UserResponse } from '@models/users';
import { approvalMarketingEditableFields } from '../constants';
import { FormBuilder } from '@components/form';
import { fetchUsers, userValues } from '@redux/slices/users';
import { ApprovalMarketingResponse } from '@models/approvalMarketing';
import {
    createApprovalMarketing,
    removeApprovalMarketing,
    updateApprovalMarketing,
} from '@redux/slices/approval-marketing';
import { divisionValues, fetchDivisions } from '@redux/slices/divisions';
import { translate } from '@components/i18n';

interface EditApprovalMarketingModalProps {
    data?: ApprovalMarketingResponse;
}

export const EditApprovalMarketingModal: React.FC<
    EditApprovalMarketingModalProps
> = ({ data }) => {
    const users = useSelector(userValues);
    const divisions = useSelector(divisionValues);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(fetchDivisions());
    }, []);

    const [form] = Form.useForm<UserResponse>();

    const [editApprovalMarketingState, setEditApprovalMarketingState] =
        useState<ApprovalMarketingResponse>({
            ...data,
        });
    const modalContext = useContext(ModalContext);

    const onOk = (values): void => {
        if (!data) addNewGroup(values);
        else handleUpdateApprovalMarketing(values);
    };

    const handleUpdateApprovalMarketing = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingApprovalGroup'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateApprovalMarketing({
                data: {
                    id: data.id,
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addNewGroup = (values): void => {
        const key = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringApprovalGroup'),
            key: key,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            createApprovalMarketing({
                data: {
                    ...values,
                },
                notificationKey: key,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const onChangeValues = (
        changedValues: Partial<ApprovalMarketingResponse>,
        allValues: ApprovalMarketingResponse,
    ): void => {
        setEditApprovalMarketingState({
            ...allValues,
            ...changedValues,
        });
    };

    const handleDeleteApprovalMarketing = (): void => {
        dispatch(removeApprovalMarketing(data.id));

        modalContext.handleClose();
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={editApprovalMarketingState}
                    onFinish={onOk}
                    form={form}
                    fields={approvalMarketingEditableFields({
                        optionsUser: users.map((item) => ({
                            id: item.id,
                            name: item.login,
                        })),
                        optionsDivision: divisions.map((item) => ({
                            id: item.nome,
                            name: item.nome + ' - ' + item.descricao,
                        })),
                    })}
                    withDelete={!!data}
                    onDelete={handleDeleteApprovalMarketing}
                />
            </Col>
            <Col span={1} />
        </Row>
    );
};
