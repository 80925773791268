import { hexToRgb } from '@styles/utils';
import { Input, AutoComplete, DatePicker } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import styled, { css } from 'styled-components';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { Moment } from 'moment';

type InputDateProps = RangePickerProps<Moment> & { light?: boolean };

interface IInputWrapperProps extends IInputProps {
    error: boolean;
}

interface IInputProps {
    focused?: boolean;
}

export const InputAreaWrapper = styled(Input.TextArea)`
    border: 1px solid #868686 !important;
    color: #000;
    border-radius: 4px;
    position: relative;

    :after {
        margin-bottom: -21px;
        position: absolute;
        right: 10px;
        bottom: 22px;
        font-size: 0.8em;
    }
`;

interface InputDateWrapperProps {
    light?: boolean;
}

export const InputDateWrapper = styled(DatePicker)<InputDateWrapperProps>`
    background-color: ${({ theme }) => theme.colors.darkBlue};
    height: 40px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid;

    ${({ light }) =>
        light &&
        css`
            background-color: ${({ theme }) => theme.colors.white};
        `}

    input,
    .ant-picker-suffix,
    .ant-picker-clear {
        color: ${({ theme }) => theme.colors.white};

        ${({ light }) =>
            light &&
            css`
                color: ${({ theme }) => hexToRgb(theme.colors.textColor, 0.8)};
            `}
    }

    .ant-picker-clear {
        background-color: ${({ theme }) => theme.colors.darkBlue};

        ${({ light }) =>
            light &&
            css`
                background-color: ${({ theme }) => theme.colors.white};
            `}
    }

    input {
        ::placeholder,
        :-ms-input-placeholder,
        ::-ms-input-placeholder {
            color: ${({ theme }) => theme.colors.white};
            opacity: 1;

            ${({ light }) =>
                light &&
                css`
                    color: ${({ theme }) =>
                        hexToRgb(theme.colors.textColor, 1)};
                `}
        }
    }

    &.ant-picker-disabled {
        background-color: #d9d9d9 !important;
        border: 1px #666666 solid;

        .ant-picker-selector {
            background-color: #d9d9d9 !important;
        }
    }
`;

export const InputRangeDateWrapper = styled(
    DatePicker.RangePicker,
)<InputDateProps>`
    background-color: ${({ theme }) => theme.colors.darkBlue};
    height: 40px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid;

    ${({ light }) =>
        light &&
        css`
            background-color: ${({ theme }) => theme.colors.white};
        `}

    input,
    .ant-picker-suffix,
    .ant-picker-clear {
        color: ${({ theme }) => theme.colors.white};

        ${({ light }) =>
            light &&
            css`
                color: ${({ theme }) => hexToRgb(theme.colors.textColor, 0.8)};
            `}
    }

    .ant-picker-clear {
        background-color: ${({ theme }) => theme.colors.darkBlue};

        ${({ light }) =>
            light &&
            css`
                background-color: ${({ theme }) => theme.colors.white};
            `}
    }

    input {
        ::placeholder,
        :-ms-input-placeholder,
        ::-ms-input-placeholder {
            color: ${({ theme }) => theme.colors.white};
            opacity: 1;

            ${({ light }) =>
                light &&
                css`
                    color: ${({ theme }) =>
                        hexToRgb(theme.colors.textColor, 1)};
                `}
        }
    }
`;

export const InputPasswordWrapper = styled(Input.Password)<IInputWrapperProps>`
    border-radius: 4px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.textColor};
    border: 1px solid #868686 !important;

    > input {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.textColor};

        ${({ error }) =>
            error &&
            css`
                border-color: ${({ theme }) => theme.colors.danger} !important;
            `}
    }

    span.ant-input-wrapper.ant-input-group {
        height: 40px !important;

        > input {
            height: 40px !important;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            background-color: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.textColor};

            ${({ error }) =>
                error &&
                css`
                    border-color: ${({ theme }) =>
                        theme.colors.danger} !important;
                `}
        }

        > span {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            ${({ error }) =>
                error &&
                css`
                    border-color: ${({ theme }) =>
                        theme.colors.danger} !important;
                `}
        }
    }

    &.ant-input-affix-wrapper-disabled {
        background-color: #d9d9d9 !important;

        .ant-select-selector {
            background-color: #d9d9d9 !important;
        }
    }
`;

export const InputWrapper = styled(Input)<IInputWrapperProps>`
    border-radius: 4px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.textColor};
    border: 1px solid #868686 !important;

    ${({ width }) =>
        width &&
        css`
            width: ${width};
        `}

    > input {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.textColor};

        input {
            ::placeholder,
            :-ms-input-placeholder,
            ::-ms-input-placeholder {
                color: ${({ theme }) => theme.colors.textColor};
                opacity: 1;
            }
        }

        ${({ error }) =>
            error &&
            css`
                border-color: ${({ theme }) => theme.colors.danger} !important;
            `}
    }

    span.ant-input-wrapper.ant-input-group {
        height: 38px !important;

        > input {
            height: 38px !important;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            background-color: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.textColor};

            ${({ error }) =>
                error &&
                css`
                    border-color: ${({ theme }) =>
                        theme.colors.danger} !important;
                `}
        }

        > span {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            ${({ error }) =>
                error &&
                css`
                    border-color: ${({ theme }) =>
                        theme.colors.danger} !important;
                `}
        }
    }

    .ant-input-group-addon,
    .ant-input {
        background: transparent;
        border: none;
    }

    .ant-input:focus,
    .ant-input-focused {
        box-shadow: none;
    }

    &.ant-input-affix-wrapper-disabled {
        background-color: #d9d9d9 !important;

        .ant-select-selector {
            background-color: #d9d9d9 !important;
        }
    }
`;

export const MaskedInputWrapper = styled(MaskedInput)<IInputProps>`
    border-radius: 4px;
    height: 40px;
    border: 1px solid #868686 !important;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.textColor};
`;

export const AutoCompleteWrapper = styled(AutoComplete)<IInputProps>`
    border-radius: 4px;
    height: 40px;
    width: -webkit-fill-available;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.textColor};

    > div {
        height: 40px !important;
        border-radius: 4px;

        > span {
            height: 40px !important;

            > input {
                height: 40px !important;
                background-color: ${({ theme }) => theme.colors.white};
                color: ${({ theme }) => theme.colors.textColor};
            }
        }

        span.ant-select-selection-placeholder {
            display: flex !important;
            align-items: center !important;
        }
    }
`;

export const SearchGroupWrapper = styled(Input.Group)`
    height: 40px;

    .ant-input-group-wrapper.ant-input-search,
    .ant-input-wrapper.ant-input-group,
    .ant-input-affix-wrapper,
    .ant-input-group-addon,
    button {
        height: 40px;
    }

    input {
        height: 30px;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        box-shadow: none !important;
        border-color: #d9d9d9 !important;
    }

    .ant-input-affix-wrapper,
    .ant-input-group-addon {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }

    .ant-select-selector {
        border-radius: 4px !important;
        background-color: ${(props) => props.theme.colors.white} !important;
        border: 1px solid #d9d9d9 !important;
        border-right: none !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
        height: 40px !important;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
        display: flex;
        align-items: center;
    }

    .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    &.ant-select-disabled {
        background-color: #d9d9d9 !important;
        border: 1px solid #d9d9d9 !important;

        .ant-select-selector {
            background-color: #d9d9d9 !important;
        }
    }
`;
