/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { translate } from '@components/i18n';
import { CustomerResponse } from '@models/customer';

export const columns = [
    { title: 'Cliente', dataIndex: 'name', sorter: true, width: '25%' },
    {
        title: translate('columns.code'),
        dataIndex: 'code',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.cia'),
        dataIndex: 'company',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.type'),
        dataIndex: 'type',
        sorter: true,
        width: '15%',
        render: (_, record: CustomerResponse): React.ReactNode => {
            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {record.typeId} - {record.type}
                </span>
            );
        },
    },
    {
        title: translate('columns.city'),
        dataIndex: 'city',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.state'),
        dataIndex: 'state',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.cnpj'),
        dataIndex: 'cnpj',
        sorter: true,
        width: '10%',
    },
];

export const filterTypes = [
    {
        name: 'todos',
        description: translate('filters.all'),
    },
    {
        name: 'cnpj',
        description: translate('filters.cnpj'),
    },
    {
        name: 'nome',
        description: translate('filters.name'),
    },
    {
        name: 'codigo',
        description: translate('filters.clientCode'),
    },
    {
        name: 'cidade',
        description: translate('filters.city'),
    },
    {
        name: 'estado',
        description: translate('filters.state'),
    },
    {
        name: 'tipo',
        description: translate('filters.type'),
    },
];

export const filterTypesOrder = [
    {
        name: 'CNPJ_CLIENTE',
        description: translate('filters.cnpj'),
    },
    {
        name: 'NOME_CLIENTE',
        description: translate('filters.name'),
    },
    {
        name: 'CODIGO_CLIENTE',
        description: translate('filters.clientCode'),
    },
    {
        name: 'CIDADE_CLIENTE',
        description: translate('filters.city'),
    },
    {
        name: 'ZONA_CLIENTE',
        description: translate('filters.state'),
    },
    {
        name: 'TIPO_PEDIDO',
        description: translate('filters.orderType'),
    },
];

export const filterTypesOrderHistory = [
    {
        name: 'CNPJ_CLIENTE',
        description: translate('filters.cnpj'),
    },
    {
        name: 'NOME_CLIENTE',
        description: translate('filters.name'),
    },
    {
        name: 'PEDIDO',
        description: translate('filters.order'),
    },
    {
        name: 'CODIGO_CLIENTE',
        description: translate('filters.clientCode'),
    },
    {
        name: 'CIDADE_CLIENTE',
        description: translate('filters.city'),
    },
    {
        name: 'ZONA_CLIENTE',
        description: translate('filters.state'),
    },
    {
        name: 'TIPO_PEDIDO',
        description: translate('filters.orderType'),
    },
];

export const filterOptions = [
    { value: 'all', label: translate('filters.all') },
    { value: 'name', label: translate('filters.name') },
    { value: 'code', label: translate('filters.code') },
];

export const filterOptionsTranspo = [
    { value: 'all', label: translate('filters.all') },
    { value: 'name', label: translate('filters.name') },
    { value: 'codigoTipoTransporte', label: translate('filters.transport') },
];

export const filterModalSelectOptions = [
    { value: 'todos', label: translate('filters.all') },
    { value: 'nome', label: translate('filters.name') },
    { value: 'codigo', label: translate('filters.code') },
];
