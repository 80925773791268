import { Typography } from 'antd';
import React from 'react';

interface IDiscountWarning {
    description: string;
}

export const DiscountWarning: React.FC<IDiscountWarning> = ({
    description,
}) => {
    return (
        <div>
            <Typography.Text
                strong
                style={{
                    marginLeft: 5,
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                }}
            >
                {description}
            </Typography.Text>
        </div>
    );
};
