import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { ItemHistory } from '@models/item';
import { Layout, Popover, Spin, List, Col, Row } from 'antd';
import axios from '@axios';
import React, { useState, memo } from 'react';
import { toQueryParameters } from '@extensions/object';
import '@extensions/number';
import { Center } from '@styles/components/wrapper';
import moment from 'moment';
import { formatMoney } from '@utils/money';
import { SelectedMoeda } from '@models/order/moedas/response';

interface ItemHistoryProps {
    itemNumber: string;
    clientCode: number;
    platformId: number | string;
    moeda?: SelectedMoeda;
}

export const HistoryItem: React.FC<ItemHistoryProps> = memo(
    ({ itemNumber, clientCode, platformId, moeda }) => {
        const [history, setHistory] = useState<ItemHistory[]>([]);
        const [loading, setLoading] = useState<boolean>(true);
        const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const loadHistory = async () => {
            if (history.length > 0 || alreadyLoaded) return;
            setLoading(true);

            const params = toQueryParameters({
                codigoFilial: platformId,
                codigoCliente: clientCode,
                numeroItem: itemNumber,
            });

            try {
                const response = await axios.get<ItemHistory[]>(
                    `/item/historico/?${params}`,
                );
                setHistory(response.data);
                setLoading(false);
                setAlreadyLoaded(true);
            } catch (e) {
                console.error(e);
            }
        };

        const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

        return (
            <div>
                <Popover
                    content={
                        <>
                            {!loading ? (
                                <>
                                    {alreadyLoaded && history.length === 0 && (
                                        <p>
                                            Nenhum histórico pra este item
                                            encontrado
                                        </p>
                                    )}
                                    {alreadyLoaded && history.length > 0 && (
                                        <List
                                            split={true}
                                            size={'small'}
                                            itemLayout="horizontal"
                                            style={{
                                                maxHeight: '300px',
                                                overflow: 'auto',
                                            }}
                                            dataSource={history}
                                            // pagination={{
                                            //     pageSize: 3,
                                            //     responsive: true,
                                            // }}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={
                                                            <span>
                                                                <b>Pedido:</b>{' '}
                                                                {item.pedidoId}
                                                            </span>
                                                        }
                                                        description={
                                                            <Col span={24}>
                                                                <Row>
                                                                    <span>
                                                                        <b>
                                                                            Preço:
                                                                        </b>{' '}
                                                                        {formatMoney(
                                                                            moeda?.simbolo,
                                                                            item.precoUnitario,
                                                                        )}
                                                                    </span>
                                                                </Row>
                                                                <Row>
                                                                    <span>
                                                                        <b>
                                                                            Quantidade:
                                                                        </b>{' '}
                                                                        {
                                                                            item.quantidade
                                                                        }
                                                                    </span>
                                                                </Row>
                                                                <Row>
                                                                    <span>
                                                                        <b>
                                                                            Data:
                                                                        </b>{' '}
                                                                        {moment(item.dataPedido).format('DD/MM/YYYY')}
                                                                    </span>
                                                                </Row>
                                                            </Col>
                                                        }
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    )}
                                </>
                            ) : (
                                <Layout>
                                    <Center>
                                        <Spin indicator={antIcon} />
                                    </Center>
                                </Layout>
                            )}
                        </>
                    }
                    title="Últimos pedidos deste item"
                    onVisibleChange={loadHistory}
                >
                    <SearchOutlined
                        style={{
                            marginLeft: 5,
                            cursor: 'pointer',
                        }}
                    />
                </Popover>
            </div>
        );
    },
);
