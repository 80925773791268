import { Dropdown } from "antd";
import { useSelector } from "react-redux";
import { DashboardItem } from "@components/dashboard-item"
import { DashboardItemContainer } from "@components/dashboard-item/styled";
import { translate } from "@components/i18n"
import { authSelector } from "@redux/slices/auth";
import { approvalSelector, draftsSelector } from "@redux/slices/order";
import { scheduledValues } from "@redux/slices/order/scheduled";
import { transferDraftOrderSelector } from "@redux/slices/transfer-order/draft";
import meta from '../../meta.json';
import { TopMenu } from "./top-menu";

type TCardHome = {
  data: typeof meta.menus[0]
}

export const CardHome: React.FC<TCardHome> = ({ data }) => {
  const authState = useSelector(authSelector);
  const draftsState = useSelector(draftsSelector);
  const scheduledState = useSelector(scheduledValues);
  const approvalState = useSelector(approvalSelector);
  const transferDraftState = useSelector(transferDraftOrderSelector);

  const getBadget = (description: string): number => {
    switch (description) {
      case 'home.cards.scheduled':
        return scheduledState?.length || 0;
      case 'home.cards.approvals':
        return approvalState.availableApprovals?.length || 0;
      case 'home.cards.draft':
        return draftsState.drafts.length || 0;
      case 'home.cards.transfeDraft':
        return transferDraftState?.available?.totalElements || 0;
      default: 0
    }
  }

  if (data.description === 'home.cards.reports') {
    return (
      <Dropdown
        overlay={TopMenu(authState)}
        trigger={['click']}
        key={'rel'}
        placement="topCenter"
      >
        <DashboardItemContainer
          xs={24}
          sm={24}
          md={7}
          lg={7}
          xl={7}
        >
          <DashboardItem
            key={`dashboard-item-${translate(data.title)}-${translate(data.description)}`}
            title={translate(data.title)}
            description={translate(data.description)}
            icon={data.icon}
            path={''}
          />
        </DashboardItemContainer>
      </Dropdown>
    )
  }

  return (
    <DashboardItem
      key={`dashboard-item-${translate(data.title)}-${translate(data.description)}`}
      {...data}
      badge={data.badge ? getBadget(data.description) : null}
      title={translate(data.title)}
      description={translate(data.description)}
    />
  )
}

