import { translate } from '@components/i18n';
import { IS_ARG } from '@config/env';

export enum ROLES_ENUM  {
    ADMINISTRADOR = "ADMINISTRADOR",
    APROVADOR = "APROVADOR",
    REPRESENTANTE = "REPRESENTANTE",
    FATURAMENTO = "FATURAMENTO",
    CONTROLADORIA = "CONTROLADORIA",
    FINANCEIRO = "FINANCEIRO",
    PROMOTOR = "PROMOTOR", //  only ARGENTINA
}

export const ROLES = [
    { id: ROLES_ENUM.ADMINISTRADOR, name: translate('general.adm') },
    { id: ROLES_ENUM.APROVADOR, name: translate('general.approver') },
    { id: ROLES_ENUM.REPRESENTANTE, name: translate('general.representer') },
    { id: ROLES_ENUM.FATURAMENTO, name: translate('general.billing') },
    {
        id: ROLES_ENUM.CONTROLADORIA,
        name: translate('general.controllership'),
    },
    { id: ROLES_ENUM.FINANCEIRO, name: translate('general.financial') },
];

if(IS_ARG){
    ROLES.push({ id: ROLES_ENUM.PROMOTOR, name: translate('general.promoter') }) // ONLY ARGENTINA
}

export const REPORT_PERMISSION = [
    {
        id: "PEDIDOS_USUARIO",
        name: translate("general.onlyMyOrders")
    },
    {
        id: "PEDIDOS_DIVISAO",
        name: translate("general.onlyMyDivision")
    },
    {
        id: "PEDIDOS_TODOS",
        name: translate("general.totalAccess")
    },
]