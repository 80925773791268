import { UserRequest, UserRequestAPI } from '.';

export interface UserResponse extends UserRequest {
    createdAt?: string;
    id: number;
    active: boolean;
    isAdmin?: boolean;
}

export enum Roles {
    admin = 'ADMINISTRADOR',
    approver = 'APROVADOR',
    controlling = 'CONTROLADORIA',
}

export class UserResponseApi implements UserRequestAPI {
    id: number;
    codigoJDE: number;
    divisao: string;
    email: string;
    exibirClientesDivisao: boolean;
    habilitado: boolean;
    login: string;
    nome: string;
    senha: string;
    limiteUsuario: number;
    limiteBonificacaoUsuario: number;
    grupoAprovacaoVendaId: number;
    grupoAprovacaoBonificacaoMarketingId: number;
    grupoAprovacaoDescontosId: number;
    itensEspecificos: string;
    emailCopia?: string;
    papeisEnum?: string[];
    ignorarEmailDiarioProgramados?: boolean;
    ignorarEmailDiarioAprovacao?: boolean;
    gerenteLinha?: boolean;
    codigoLinha?: number;
    codigoTipoTransporte?: string;
    permissaoRelatorio?: string;

    toUserResponse(roles?: string[]): UserResponse {
        return {
            active: this.habilitado,
            division: this.divisao,
            email: this.email,
            id: this.id,
            jdeCode: this.codigoJDE,
            login: this.login,
            name: this.nome,
            photo: '',
            limiteUsuario: this.limiteUsuario,
            limiteBonificacaoUsuario: this.limiteBonificacaoUsuario,
            grupoAprovacaoVendaId: this.grupoAprovacaoVendaId,
            emailCopia: this.emailCopia,
            roles: roles ?? this.papeisEnum,
            isAdmin: (roles ?? this.papeisEnum)?.includes(Roles.admin),
            grupoAprovacaoBonificacaoMarketingId:
                this.grupoAprovacaoBonificacaoMarketingId,
            grupoAprovacaoDescontosId: this.grupoAprovacaoDescontosId,
            itensEspecificos: this.itensEspecificos,
            dailyReport: this.ignorarEmailDiarioProgramados,
            approvalsDailyReport: this.ignorarEmailDiarioAprovacao,
            gerenteLinha: this.gerenteLinha,
            exibirClientesDivisao: this.exibirClientesDivisao,
            codigoLinha: this.codigoLinha,
            codigoTipoTransporte: this.codigoTipoTransporte,
            permissaoRelatorio: this.permissaoRelatorio
        };
    }
}
