import React from 'react';

interface IPageContext {
    drawerOpen: boolean;
    toggleDrawer?: () => void;
}

const defaultState = {
    drawerOpen: false,
};

export const PageContext = React.createContext<IPageContext>(defaultState);
