import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { UserResponse } from '@models/users';
import { authSelector } from '@redux/slices/auth';
import { userValues } from '@redux/slices/users';
import { Col } from 'antd';
import React, { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { loginWith, userColumns } from '../constants';
import { EditUserModal } from './edit-modal';

export const Body: React.FC = memo(() => {
    const users = useSelector(userValues);
    const modalContext = useContext(ModalContext);
    const { currentUser } = useSelector(authSelector);

    const editUser = (
        user: UserResponse,
        index: number,
        event: React.MouseEvent<HTMLElement, MouseEvent>,
    ): void => {
        if (
            !`${event.target}`.includes('SVG') &&
            !`${event.target}`.includes('Button') &&
            !`${event.target}`.includes('Span')
        )
            modalContext.setIsOpen(
                true,
                <EditUserModal
                    user={user}
                    key={`editing-user-${user?.name?.split(' ')[0]}`}
                />,
                {
                    title: `${translate('general.editUser')} - ${user?.name?.split(' ')[0]
                        }`,
                    width: '40%',
                },
            );
    };

    return (
        <Col>
            <Table
                columns={
                    currentUser.isAdmin
                        ? [...userColumns, loginWith]
                        : userColumns
                }
                rowKey={(record: UserResponse) => `${record.id}`}
                dataSource={users}
                pagination={undefined}
                pointer
                onRowClick={editUser}
                scroll={{ y: 'calc(100vh - 380px)' }}
            />
        </Col>
    );
});
