import { ItemResponse } from '@models/order/items/response';
import * as React from 'react';
import { useState } from 'react';

interface ChangeDiscountContextInterface {
    isOpen?: boolean;
    currentRow?: ItemResponse;
    setIsOpen: (open: boolean, currentRow: ItemResponse) => void;
}

export const ChangeDiscountContext =
    React.createContext<ChangeDiscountContextInterface | null>(null);

interface ChangeDiscountContextProviderProps {
    children: React.ReactNode;
}

export const ChangeDiscountContextProvider: React.FC<
    ChangeDiscountContextProviderProps
> = ({ children }) => {
    const [cstate, setState] = useState<
        Partial<ChangeDiscountContextInterface>
    >({
        isOpen: false,
        currentRow: null,
    });

    const setIsOpen = (isOpen: boolean, currentRow: ItemResponse): void => {
        setState({
            isOpen,
            currentRow,
        });
    };

    return (
        <ChangeDiscountContext.Provider
            value={{ ...cstate, setIsOpen: setIsOpen }}
        >
            {children}
        </ChangeDiscountContext.Provider>
    );
};
