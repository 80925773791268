import React, { memo, useContext, useEffect } from 'react';
import { Button, Checkbox, Form, Row } from 'antd';
import { LoginRequest } from '@models/auth/login';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, signInUser } from '@redux/slices/auth';
import { Input, InputPassword } from '@components';
import { Center } from '@styles/components/wrapper';
import { useHistory } from 'react-router-dom';
import { ModalContext } from 'src/contexts/modal-context';
import { ForgotPasswordModal } from './forgot-password-modal';
import { translate } from '@components/i18n';

export const LoginForm: React.FC = memo(() => {
    const [form] = Form.useForm<LoginRequest>();
    const history = useHistory();
    const { isFetching, isError, isSuccess, errorMessage, currentUser } =
        useSelector(authSelector);
    const dispatch = useDispatch();
    const modalContext = useContext(ModalContext);

    const onFinish = (data: LoginRequest): void => {
        dispatch(signInUser(data));
    };

    useEffect(() => {
        if (isSuccess && currentUser) {
            form.resetFields();
            history.push('/');
        }
    }, [isSuccess]);

    const forgetPassword = () => {
        modalContext.setIsOpen(true, <ForgotPasswordModal />, {
            title: translate('general.recoveryAccess'),
            width: '30%',
        });
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
        >
            <Form.Item
                name="email"
                label={translate('forms.labels.login')}
                requiredMark={true}
                rules={[
                    {
                        required: true,
                        message: translate('forms.rules.login'),
                    },
                ]}
            >
                <Input
                    name={'email'}
                    placeholder={translate('forms.placeholders.login')}
                />
            </Form.Item>
            <Form.Item
                label={translate('forms.labels.password')}
                name={'password'}
                rules={[
                    {
                        required: true,
                        message: translate('forms.rules.password'),
                    },
                ]}
            >
                <Row>
                    <InputPassword
                        placeholder={translate('forms.placeholders.login')}
                        type="password"
                    />
                </Row>
            </Form.Item>
            <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>
                        {translate('forms.labels.rememberAccess')}
                    </Checkbox>
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <Center>
                    <Button
                        htmlType="submit"
                        type="primary"
                        danger={isError}
                        loading={isFetching}
                        style={{
                            justifyContent: 'center',
                            width: '100%',
                            height: 40,
                            fontWeight: 'bold',
                        }}
                    >
                        {isError && errorMessage}
                        {!isError && translate('forms.buttons.access')}
                    </Button>
                </Center>
            </Form.Item>
            <Form.Item>
                <Row justify="end">
                    {/* <Button
                        type="link"
                        style={{ paddingRight: 0, textDecoration: 'underline' }}
                    >
                        Alterar Senha
                    </Button> */}
                    <Button
                        type="link"
                        style={{ paddingRight: 0, textDecoration: 'underline' }}
                        onClick={forgetPassword}
                    >
                        {translate('forms.buttons.forgotPasswordQuestion')}
                    </Button>
                </Row>
            </Form.Item>
        </Form>
    );
});
