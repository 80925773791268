import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Typography } from 'antd';
import { Page } from '@components';
import { authSelector } from '@redux/slices/auth';
import { translate } from '@components/i18n';
import { ListCardsHome } from '@components/list-cards-home';

const Home: React.FC = () => {
    const authState = useSelector(authSelector);

    return (
        <Page title={translate('pages.home')}>
            <Col span={24} style={{ marginTop: '50px' }}>
                <Row justify="center">
                    <Col span={2} />
                    <Col span={18}>
                        <Row justify="start">
                            <Typography.Title
                                level={3}
                                style={{ marginBottom: 0 }}
                            >
                                {translate('home.hello')}{' '}
                                {authState.currentUser?.name?.split(' ')[0]}
                            </Typography.Title>
                        </Row>
                        <Col>
                            <Row justify="start">
                                <Typography.Title
                                    level={3}
                                    style={{
                                        fontWeight: 400,
                                        marginBottom: 40,
                                    }}
                                >
                                    {translate('home.welcome')}
                                </Typography.Title>
                            </Row>
                        </Col>
                        <Col>
                            <ListCardsHome />
                        </Col>
                    </Col>
                </Row>
            </Col>
        </Page>
    );
};

export default Home;
