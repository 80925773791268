import React, { useState } from 'react';

import { Input } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { DraftsTable, sendedOrdersValues } from '@redux/slices/order';
import { sendedOrdersColumns, filterTypes } from '../constants';
import { CustomerRequest } from '@models/customer';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from '@styles/components/button';
import { translate } from '@components/i18n';

export const SendedOrdersBody: React.FC = () => {
    const sendedOrdersState = useSelector(sendedOrdersValues);
    const [filters, setFilters] = useState<CustomerRequest>();
    const [selectedRows, setSelectedRows] = useState<DraftsTable[]>([]);
    const history = useHistory();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        setFilters(values);
    };

    const onSearch = (value: string): void => {
        setFilters({ ...filters, filterValue: value });
    };

    const rowSelection = {
        onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: DraftsTable[],
        ) => {
            setSelectedRows(selectedRows);
        },
    };

    const sendOrders = (): void => {
        //alert('Por enquanto, nada acontece');
        history.push('/');
    };

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={23}>
                    <Table
                        columns={sendedOrdersColumns}
                        rowKey={(record: DraftsTable) => `${record.id}`}
                        rowSelection={{
                            ...rowSelection,
                            columnWidth: '20px',
                            fixed: true,
                        }}
                        dataSource={sendedOrdersState}
                        pagination={undefined}
                        isLoading={false}
                        onFilterChange={onFiltersChange}
                        filters={[
                            <FormItem
                                label={translate('forms.labels.searchFor')}
                                name={'filterBy'}
                            >
                                <Select placeholder={translate('general.all')}>
                                    {filterTypes.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.cia')}
                                name={'company'}
                            >
                                <Select placeholder="Todas">
                                    <Option value="client1">Cia 1</Option>
                                    <Option value="client2">Cia 2</Option>
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    mask={
                                        filters?.filterBy === 'cnpj'
                                            ? '111.111.111/1111-11'
                                            : ''
                                    }
                                    onChange={(e) => onSearch(e.target.value)}
                                />
                            </Form.Item>,
                        ]}
                    />
                </Col>
            </Row>
            {selectedRows.length > 0 && (
                <Row justify="center" style={{ paddingBottom: 20 }}>
                    <Col span={23}>
                        <Row justify={'end'} gutter={10}>
                            <Col>
                                <Button
                                    type="primary"
                                    height={40}
                                    onClick={sendOrders}
                                >
                                    {translate('general.send')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </ShadowContainer>
    );
};
