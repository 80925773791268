import { Row } from "antd"
import { useSelector } from "react-redux";
import { authSelector } from "@redux/slices/auth";
import { COUNTRY } from "@config/env";
import { CardHome } from "../card-home"
import meta from '../../../meta.json'

export const AdminListCardsHome: React.FC = () => {
  const authState = useSelector(authSelector);

  const filtered = meta.menus
    .filter(
      (m) =>
        authState.currentUser.isAdmin ||
        m.allowedRoles?.some((r) =>
          authState.currentUser.roles.some(
            (c) => c === r,
          ),
        ),
    )
    .filter(sb => (sb.onlyCountries?.includes("ALL") || sb.onlyCountries?.includes(COUNTRY)))

  // items that we shows in individual lines. we need to find the lines that exist
  const lines = filtered.reduce((prev, curr) => {
    if (curr.line && !prev.includes(curr.line)) {
      return [...prev, curr.line];
    }
    return prev;
  }, [])

  const disorderedItems = filtered.filter(item => !item.line)

  return (
    <>
      <Row style={{ rowGap: '2rem', columnGap: '2rem' }} wrap>
        {disorderedItems.map((m, index) => (
          <CardHome data={m} key={index} />
        ))}
      </Row>
      {lines.sort().map(line => (
        <Row style={{ marginTop: '2rem', rowGap: '2rem', columnGap: '2rem' }} wrap key={line}>
          {filtered.filter(item => item.line && item.line === line).map((m, index) => (
            <CardHome data={m} key={index} />
          ))}
        </Row>
      ))}
    </>
  )
}