import React, { memo, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Col, message, Popconfirm, Row } from 'antd';
import { Table } from '@components/table';
import { CampaignResponse } from '@models/campaign';
import { campaignValues, fetchCampaigns } from '@redux/slices/campaign';
import { ModalContext } from 'src/contexts/modal-context';
import { campaignColumns } from '../constants';
import { EditCampaignModal } from './edit-modal';
import { Button } from '@styles/components/button';
import axios from '@axios';
import { AxiosResponse } from 'axios';
import { translate } from '@components/i18n';

export const Body: React.FC = memo(() => {
    const campaigns = useSelector(campaignValues);
    const modalContext = useContext(ModalContext);
    const [selectedRows, setSelectedRows] = useState<CampaignResponse[]>([]);
    const dispatch = useDispatch();

    const editUser = (user: CampaignResponse): void => {
        modalContext.setIsOpen(
            true,
            <EditCampaignModal
                campaign={user}
                key={`editing-user-${user.nome.split(' ')[0]}`}
            />,
            {
                title: `${translate('general.editCampain')} - ${
                    user.nome.split(' ')[0]
                }`,
                width: '70%',
            },
        );
    };

    const finishCampaigns = (): void => {
        setSelectedRows([]);
        resendMutation(selectedRows.map((c) => c.id));
    };

    const rowSelection = {
        onChange: (_: React.Key[], selectedRows: CampaignResponse[]) => {
            setSelectedRows(selectedRows);
        },
        getCheckboxProps: (record: CampaignResponse) => ({
            disabled: record.finalizado,
            name: record.nome,
        }),
    };

    const closeCampaign = (ids: number[]): Promise<AxiosResponse<void>> => {
        return axios.post('/campanha/finalizacao', ids);
    };

    const { mutate: resendMutation } = useMutation(closeCampaign, {
        onSuccess: (data) => {
            console.debug(data);
            dispatch(fetchCampaigns());
            message.success(translate('general.endCampain'));
        },
        onError: (e) => {
            message.error(translate('general.errorOnEndCampain'));
        },
    });

    return (
        <Col>
            <Table
                columns={campaignColumns}
                rowKey={(record: CampaignResponse) => `${record.id}`}
                dataSource={campaigns}
                pagination={undefined}
                pointer
                rowSelection={{
                    ...rowSelection,
                    columnWidth: '20px',
                    selectedRowKeys: selectedRows.map((c) => `${c.id}`),
                    fixed: true,
                }}
                onRowClick={editUser}
                scroll={{ y: 'calc(100vh - 450px)' }}
            />
            {selectedRows.length > 0 && (
                <Row justify="center" style={{ paddingBottom: 20 }}>
                    <Col span={23}>
                        <Row justify={'end'} gutter={10}>
                            <Col>
                                <Popconfirm
                                    title={
                                        <span>
                                            {translate(
                                                'general.activeCampainsSelected',
                                            )}
                                            <br />{' '}
                                            {translate(
                                                'general.activeCampainsSelected2Part',
                                            )}
                                        </span>
                                    }
                                    style={{ whiteSpace: 'break-spaces' }}
                                    okText={translate('general.yes')}
                                    cancelText={translate('general.no')}
                                    onConfirm={finishCampaigns}
                                >
                                    <Button danger type="primary" height={40}>
                                        {translate('forms.buttons.endCampain')}
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Col>
    );
});
