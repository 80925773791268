export const toQueryParameters = (obj: unknown): string => {
    const urlParameters = Object.entries(obj)
        .map((e) => e.join('='))
        .join('&');
    return urlParameters;
};

export const toQueryParametersWithArray = (obj: unknown): string => {
   
    const urlParameters =  Object.entries(obj)
    .map(e => {
        if(typeof e[1] === 'object' && e[1].length > 0){
            return e[1]
                .map(item => e[0]+ '=' + item )
                .join("&")
        }

        return e.join('=')
    }).join("&")
    return urlParameters;
};
