import { Divider, Row } from 'antd';
import React, { memo } from 'react';
import { SolidBackground } from '@styles/components/wrapper';
import { Text } from '@styles/components/text';
import { translate } from '@components/i18n';

interface SelectItemsTableFooterProps {
    totalItems: number;
}

export const TableFooter: React.FC<SelectItemsTableFooterProps> = memo(
    ({ totalItems }) => {
        return (
            <SolidBackground isWhite paddingBottom={10}>
                <Row>
                    <Divider type="horizontal" style={{ margin: '0px' }} />
                    <Row justify="end" style={{ width: '100%', marginTop: 10 }}>
                        <Text strong marginRight={'5px'}>
                            {translate('general.totalItems')}
                        </Text>
                        <Text marginRight={'40px'}>{totalItems}</Text>
                    </Row>
                </Row>
            </SolidBackground>
        );
    },
);
