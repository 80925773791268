import { TransferItemRequest, TransferItemRequestApi, TransferItemRequestParams, TransferItemUpdateRequest } from './request';
import { TransferItemResponse, TransferItemResponseApi } from './response';

export * from './response';
export * from './request';


export namespace TransferItem {
  export type Request = TransferItemRequest;
  export const Request = TransferItemRequest;
  export const RequestApi = TransferItemRequestApi;

  export type RequestUpdate = TransferItemUpdateRequest;
  export const RequestUpdate = TransferItemUpdateRequest;

  export type Response = TransferItemResponse;
  export const Response = TransferItemResponse;
  
  export type ResposeApi = TransferItemResponseApi
  export const ResposeApi = TransferItemResponseApi

  export type Params = TransferItemRequestParams;
}