import React, { useContext, useEffect, useRef } from 'react';
import moment from 'moment';
import { Avatar, Row, Comment, Tooltip, Divider, Popconfirm } from 'antd';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';

import { ShadowContainer } from '@styles/components/wrapper';
import { Text } from '@styles/components/text';
import '@extensions/number';
import { MessageOutlined } from '@ant-design/icons';
import { CommentResponse } from '@models/comments/response';
import Search from 'antd/lib/input/Search';
import { CommentBackground } from '../styled';
import { authSelector } from '@redux/slices/auth';
import { ApprovalContext } from '../context/context';
import { UseMutateFunction } from 'react-query';
import { ModalContext } from 'src/contexts/modal-context';
import { translate } from '@components/i18n';

interface OrderInfoProps {
    comments: CommentResponse[];
    item?: string;
    orderId?: number;
    addComment?: (c: any) => void;
    removeComment?: UseMutateFunction<
        AxiosResponse<void>,
        unknown,
        {
            commentId?: any;
            orderId?: any;
        },
        unknown
    >;
    readOnly?: boolean;
}

export const Comments: React.FC<OrderInfoProps> = ({
    comments,
    item,
    addComment,
    readOnly,
    orderId,
    removeComment,
}) => {
    const addCommentFn = addComment
        ? addComment
        : useContext(ApprovalContext).addComment;

    const removeCommentFn = removeComment
        ? removeComment
        : useContext(ApprovalContext).removeComment;

    const isReadOnly =
        readOnly !== undefined
            ? readOnly
            : useContext(ApprovalContext).readOnly;

    const { currentUser } = useSelector(authSelector);
    const messagesEndRef = useRef(null);
    const modalContext = useContext(ModalContext);

    const scrollToBottom = (): void => {
        messagesEndRef.current?.scrollTo({
            top: messagesEndRef.current?.scrollHeight,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollToBottom();
    }, [comments]);

    return (
        <ShadowContainer style={{ marginTop: 30, marginLeft: 20, padding: 20 }}>
            <Row align={'middle'}>
                <Avatar
                    size="large"
                    style={{
                        backgroundColor: '#001B42',
                        verticalAlign: 'middle',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        marginRight: 10,
                    }}
                    icon={
                        <MessageOutlined
                            style={{ color: '#fff', fontSize: 16 }}
                        />
                    }
                />
                <Text fontWeight={'bold'} blue fontSize={'1.2em'}>
                    {translate('general.commentHistory')}
                </Text>
            </Row>
            <div
                ref={messagesEndRef}
                style={{
                    maxHeight: 180,
                    minHeight: 130,
                    marginTop: 20,
                    overflowY: 'auto',
                }}
            >
                {comments.length === 0 && (
                    <div>{translate('general.noComment')}</div>
                )}
                {comments.map((c) => (
                    <CommentBackground sender={currentUser.id === c.userId}>
                        <Comment
                            author={
                                <a>
                                    <b>
                                        {c.userName ||
                                            translate('general.notInformed')}
                                    </b>
                                </a>
                            }
                            actions={
                                (currentUser.id === c.userId && !isReadOnly) ||
                                c.itemNumber
                                    ? [
                                          <span
                                              style={{
                                                  display: c.itemNumber
                                                      ? 'block'
                                                      : 'none',
                                              }}
                                              onClick={() =>
                                                  modalContext.setIsOpen(
                                                      true,
                                                      <Comments
                                                          comments={comments?.filter(
                                                              (commment) =>
                                                                  `${commment.itemNumber}` ===
                                                                  `${c.itemNumber}`,
                                                          )}
                                                          readOnly={isReadOnly}
                                                          addComment={
                                                              addCommentFn
                                                          }
                                                          removeComment={
                                                              removeCommentFn
                                                          }
                                                          item={`${c.itemNumber}`}
                                                          orderId={orderId}
                                                      />,
                                                      {
                                                          title: `${translate(
                                                              'general.commentOfItem',
                                                          )} ${c.itemNumber}`,
                                                          className:
                                                              'modal-comments',
                                                      },
                                                  )
                                              }
                                          >
                                              <b>
                                                  {translate('columns.item')}:
                                              </b>{' '}
                                              {c.itemNumber}
                                          </span>,
                                          <Popconfirm
                                              title={translate(
                                                  'general.lostCommentYouSure',
                                              )}
                                              okText={translate('general.yes')}
                                              cancelText={translate(
                                                  'general.no',
                                              )}
                                              onConfirm={() => {
                                                  removeCommentFn({
                                                      commentId: c.id,
                                                      orderId: c.orderId,
                                                  });
                                                  if (modalContext.isOpen)
                                                      modalContext.handleClose();
                                              }}
                                          >
                                              <span
                                                  style={{
                                                      color: '#FF0000',
                                                      display:
                                                          currentUser.id ===
                                                              c.userId &&
                                                          !isReadOnly
                                                              ? 'block'
                                                              : 'none',
                                                  }}
                                              >
                                                  {translate(
                                                      'general.removeComment',
                                                  )}
                                              </span>
                                          </Popconfirm>,
                                      ]
                                    : []
                            }
                            content={<p>{c.comment}</p>}
                            datetime={
                                <Tooltip
                                    title={moment(c.date).format(
                                        'DD-MM-YYYY hh:MM:ss',
                                    )}
                                >
                                    <span>{moment(c.date).fromNow()}</span>
                                </Tooltip>
                            }
                        />
                        <div />
                    </CommentBackground>
                ))}
            </div>
            <Divider style={{ margin: 0, marginBottom: 5 }} />
            <Search
                key={`search-${comments.length}`}
                placeholder={translate('forms.placeholders.comment')}
                disabled={isReadOnly}
                onSearch={(c) => {
                    addCommentFn({ text: c, item: item });
                    if (modalContext.isOpen) modalContext.handleClose();
                }}
                enterButton="Enviar"
            />
        </ShadowContainer>
    );
};
