import { Link } from 'react-router-dom'
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FlexBox, MenuItem } from '../styled';
import { Menu } from '@models/menu';

type TActionItemMenuDrawer = {
  data: Menu.ActionItem
}

export const ActionItemMenuDrawer: React.FC<TActionItemMenuDrawer> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={data.path}
      key={`side-menu-${t(data.title)}-link`}
    >
      <MenuItem
        className="has-hover"
        key={`side-menu-${t(data.title)}`}
      >
        <FlexBox>
          <Typography.Text>
            {t(data.title)}
          </Typography.Text>
        </FlexBox>
      </MenuItem>
    </Link>
  )
}