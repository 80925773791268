import React, { memo } from 'react';
import { Col, Row } from 'antd';

export const Header: React.FC = memo(() => {
    return (
        <Col>
            <Row style={{ marginTop: 20 }} align="middle">
                <Col span={24}>
                    {/* <Row justify={'space-between'} align="middle">
                        <Col span={10}></Col>
                        <Col span={14}>
                            <Row justify={'end'}>
                                <Col span={12}></Col>
                                <Col span={5} style={{ marginRight: 10 }}></Col>
                                <Col span={5} style={{ marginRight: 10 }}>
                                    <ExportButton onClick={addUser}>
                                        Adicionar Novo
                                    </ExportButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                </Col>
            </Row>
        </Col>
    );
});
