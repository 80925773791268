export interface TypeResponse {
    id: string;
    description: string;
    conven: string;
}

export class TypeResponseApi {
    codigo: string;
    descricao: string;
    convenio100: string;

    toTypeResponse(): TypeResponse {
        return {
            id: this.codigo,
            description: this.descricao,
            conven: this.convenio100,
        };
    }
}
