import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import '@extensions/string';
import {
    CampaignRequest,
    CampaignRequestAPI,
    CampaignRequestOrder,
    CampaignResponse,
    CampaignResponseApi,
    ItemResponse,
} from '@models/campaign';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification } from '../notification';
import { RootState } from '@redux/reducers';
import { PaginatedResponseApi } from '@models/order/items/response';
import moment from 'moment';
import { translate } from '@components/i18n';

export const fetchAllItems = createAsyncThunk<ItemResponse[]>(
    'campanha/items',
    async () => {
        const response = await axios.get<ItemResponse[]>('/item/all');

        return response.data;
    },
);
export const fetchEnabledItems = createAsyncThunk<ItemResponse[]>(
    'campanha/items-enabled',
    async () => {
        const response = await axios.get<ItemResponse[]>('/item/enabled');

        return response.data;
    },
);

export const fetchCampaignsForOrder = createAsyncThunk<
    CampaignResponse[],
    CampaignRequestOrder
>('campanhas/fetchCampaignsForOrder', async (request, thunkAPI) => {
    try {
        if (request.paymentConditionId === '100') return [];
        else {
            const state = thunkAPI.getState() as RootState;

            const params = JSON.parse(
                JSON.stringify({
                    filialCodigo: state.selectItems.order.orderType.platform.id,
                    condicaoCodigo: request.paymentConditionId,
                    tipoCliente: state.selectItems.order.customer.typeId,
                    zonaCliente: state.selectItems.order.customer.state,
                    dataProgramada: request.date
                        ? moment(request.date).format('YYYY-MM-DD')
                        : undefined,
                }),
            );

            const response = await axios.post<CampaignResponseApi[]>(
                '/campanha/bypedido',
                params,
            );

            const { data, status } = response;

            if (status === 200) {
                const result = data.map((u) =>
                    Object.assign(new CampaignResponseApi(), u),
                );

                return result.map((u) => u.toCampaignResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(
            translate('general.errorListApprovalGroup'),
        );
    }
});

export const fetchCampaigns = createAsyncThunk<CampaignResponse[]>(
    'campanhas',
    async (_, thunkAPI) => {
        try {
            const response = await axios.get<
                PaginatedResponseApi<CampaignResponseApi>
            >('/campanha', {
                params: {
                    page: 0,
                    size: 999,
                },
            });

            const { data, status } = response;

            if (status === 200) {
                const result = data.content.map((u) =>
                    Object.assign(new CampaignResponseApi(), u),
                );

                return result.map((u) => u.toCampaignResponse());
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(
                translate('general.errorListApprovalGroup'),
            );
        }
    },
);

export const fetchCampaignById = async (
    groupId,
): Promise<CampaignRequestAPI> => {
    try {
        const response = await axios.get<CampaignResponseApi>(
            '/campanha/' + groupId,
        );
        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        const { response } = e;
    }
};

export const createCampaign = createAsyncThunk<
    CampaignResponse,
    ThunkCallback<CampaignRequest>
>('campanhas/createCampaign', async (request, thunkAPI) => {
    try {
        const response = await axios.post<CampaignResponseApi>(
            '/campanha',
            new CampaignRequestAPI(request.data),
        );

        const { data, status } = response;
        if (status === 201 && data) {
            const instance: CampaignResponseApi = Object.assign(
                new CampaignResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.campainRegistered'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );
            thunkAPI.dispatch(fetchCampaigns());
            return instance.toCampaignResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        console.debug('Error', e);

        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: translate('general.errorRegisteringCampain'),
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});

export const removeCampaign = createAsyncThunk<number, number>(
    'campanhas/removeCampaign',
    async (groupId, thunkAPI) => {
        try {
            const response = await axios.delete('/campanha/' + groupId);
            if (response.status === 200) {
                thunkAPI.dispatch(
                    addNotification({
                        type: 'success',
                        message: translate('general.campainRemoved'),
                        title: translate('general.success'),
                    }),
                );
                thunkAPI.dispatch(removeCampaignState(groupId));
                return Promise.resolve(groupId);
            }
            return 0;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;
            return thunkAPI.rejectWithValue(message);
        }
    },
);

interface ICampaignState {
    availableCampaign: CampaignResponse[];
    availableCampaignForOrder: CampaignResponse[];
    allItems: ItemResponse[];
    selectedCampaigns: number[];
    oldSeletedCampain?: number;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: ICampaignState = {
    availableCampaign: [],
    availableCampaignForOrder: [],
    allItems: [],
    selectedCampaigns: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const campaignSlice = createSlice({
    name: 'campaignSlice',
    initialState,
    reducers: {
        clearStateCampaign: (state) => {
            state = { ...initialState };

            return state;
        },
        filterCampaign: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
            return state;
        },
        selectCampaign: (state, action: PayloadAction<number[]>) => {
            state.selectedCampaigns = action.payload;
            return state;
        },
        oldSeletedCampain: (state, action: PayloadAction<number>) => {
            state.oldSeletedCampain = action.payload;
            return state;
        },
        updateAvailableCampaignForOrder: (
            state,
            action: PayloadAction<CampaignResponse>,
        ) => {
            state.availableCampaignForOrder =
                state.availableCampaignForOrder.map((c) =>
                    c.id === action.payload.id ? action.payload : c,
                );
            return state;
        },
        removeCampaignState: (state, action: PayloadAction<number>) => {
            state.availableCampaign = state.availableCampaign.filter(
                (u) => u.id !== action.payload,
            );
            return state;
        },
    },
    extraReducers: {
        [createCampaign.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<CampaignResponse>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableCampaign = [...state.availableCampaign, payload];
            return state;
        },
        [createCampaign.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [createCampaign.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchCampaigns.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchCampaigns.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchCampaigns.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<CampaignResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableCampaign = payload;
            return state;
        },
        [fetchCampaignsForOrder.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchCampaignsForOrder.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchCampaignsForOrder.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<CampaignResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableCampaignForOrder = payload;
            return state;
        },

        [fetchAllItems.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ItemResponse[]>,
        ) => {
            state.allItems = payload;
            return state;
        },
        [fetchEnabledItems.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ItemResponse[]>,
        ) => {
            state.allItems = payload;
            return state;
        },
    },
});

export const updateCampaign = createAsyncThunk<
    CampaignResponse,
    ThunkCallback<CampaignResponse>
>('campaignSlice/createCampaign', async (request, thunkAPI) => {
    try {
        const response = await axios.put<CampaignResponseApi>(
            '/campanha/' + request.data.id,
            new CampaignRequestAPI(request.data),
        );

        const { data, status } = response;

        if (status === 200) {
            const instance: CampaignResponseApi = Object.assign(
                new CampaignResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.campainUpdated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            thunkAPI.dispatch(fetchCampaigns());

            return instance.toCampaignResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: translate('general.errorUpdatingCampain'),
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});

export const campaignSelector = (state: RootState): ICampaignState =>
    state.campaign;

interface OptionItem {
    id: string | number;
    name: string;
}

export const campaignItemSelector = (state: RootState): OptionItem[] =>
    state.campaign.allItems.map((c) => ({
        id: c.numero,
        name: `${c.numero} - ${c.descricao}`,
    }));

export const campaignValues = ({ campaign }: RootState): CampaignResponse[] =>
    campaign.availableCampaign.filter((u) => u.nome?.compare(campaign.filter));

export const selectedCampaignsSelector = ({
    campaign,
}: RootState): CampaignResponse[] =>
    campaign.availableCampaignForOrder.filter((c) =>
        campaign.selectedCampaigns.includes(c.id),
    );

export const {
    clearStateCampaign,
    filterCampaign,
    removeCampaignState,
    selectCampaign,
    updateAvailableCampaignForOrder,
    oldSeletedCampain,
} = campaignSlice.actions;

export default campaignSlice.reducer;
