import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Spin } from 'antd';
import { Center } from './wrapper';

export const Loading: React.FC = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

    return (
        <Layout>
            <Center>
                <Spin indicator={antIcon} />
            </Center>
        </Layout>
    );
};
