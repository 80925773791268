import React, { useContext } from 'react';

import '@extensions/number';
import { FinancialDiscountContext } from '../context/context';
import { Button } from '@styles/components/button';
import { ModalContext } from 'src/contexts/modal-context';
import { RefuseModal } from './refuse-modal';
import { useHistory } from 'react-router-dom';
import { AcceptModal } from './accept-modal';
import { translate } from '@components/i18n';

export const OrderActions: React.FC = () => {
    const {
        refuseOrder,
        acceptOrder,
        requestDiscount,
        readOnly,
        currentState,
    } = useContext(FinancialDiscountContext);
    const modalContext = useContext(ModalContext);
    const history = useHistory();

    return (
        <div style={{ marginTop: 30, padding: 10 }}>
            <Button
                type="ghost"
                height={40}
                width={'100%'}
                onClick={() => {
                    history.goBack();
                }}
            >
                {translate('general.goBackList')}
            </Button>
            {!readOnly && (
                <div>
                    <Button
                        type="primary"
                        height={40}
                        width={'100%'}
                        style={{ marginTop: 20 }}
                        success
                        disabled={
                            !currentState.desconto ||
                            `${currentState.desconto}` === '0'
                        }
                        onClick={requestDiscount}
                    >
                        {translate('forms.buttons.requestDiscount')}
                    </Button>
                </div>
            )}
            {readOnly && !currentState.history && (
                <>
                    <Button
                        danger
                        type="primary"
                        height={40}
                        width={'100%'}
                        style={{ marginTop: 20 }}
                        onClick={() => {
                            modalContext.setIsOpen(
                                true,
                                <RefuseModal
                                    refuse={(reason) =>
                                        refuseOrder({ text: reason })
                                    }
                                />,
                                {
                                    title: translate('general.whatRefuse'),
                                    width: '30%',
                                },
                            );
                        }}
                    >
                        {translate('general.discountRepprove')}
                    </Button>
                    <Button
                        type="primary"
                        height={40}
                        width={'100%'}
                        style={{ marginTop: 20 }}
                        success
                        onClick={() => {
                            modalContext.setIsOpen(
                                true,
                                <AcceptModal
                                    accept={(reason) =>
                                        acceptOrder({ text: reason })
                                    }
                                />,
                                {
                                    title: translate('general.letObservation'),
                                    width: '30%',
                                },
                            );
                        }}
                    >
                        {translate('general.discountApprove')}
                    </Button>
                </>
            )}
        </div>
    );
};
