import 'antd/dist/antd.less';
import { StyledThemeProvider } from '@definitions/styled-components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import store from '@redux/store';
import { routes } from '@routes';
import RoutePrivate from '@routes/private';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Center, Layout } from '@styles/components/wrapper';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './components/i18n';

const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

function App(): JSX.Element {
    const queryClient = new QueryClient();
    const persistor = persistStore(store);

    return (
        <StyledThemeProvider>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <PersistGate
                        loading={
                            <Layout>
                                <Center>
                                    <Spin indicator={antIcon} />
                                </Center>
                            </Layout>
                        }
                        persistor={persistor}
                    >
                        <div className="App">
                            <BrowserRouter>
                                <Switch>
                                    {Object.values(routes).map((route, index) =>
                                        route.auth ? (
                                            <RoutePrivate
                                                {...route}
                                                key={index}
                                                role={route.allowedRoles}
                                            />
                                        ) : (
                                            <Route {...route} key={index} />
                                        ),
                                    )}
                                    <Route
                                        component={() => (
                                            <div>Página não encontrada</div>
                                        )}
                                    />
                                </Switch>
                            </BrowserRouter>
                        </div>
                    </PersistGate>
                </Provider>
            </QueryClientProvider>
        </StyledThemeProvider>
    );
}

export default App;
