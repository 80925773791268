import { translate } from '@components/i18n';
import { Description } from '@components/texts';
import { UserResponse } from '@models/users';
import { Col } from 'antd';
import React, { memo } from 'react';
import { Collapsable } from './styled';

const { Panel } = Collapsable;

interface UserInfoCollapseProps {
    user: Partial<UserResponse>;
}

export const UserInfoCollapse: React.FC<UserInfoCollapseProps> = memo(
    ({ user }) => {
        return (
            <Collapsable collapsible="disabled" expandIcon={(c) => <div></div>}>
                <Panel
                    header={
                        <>
                            <Col>
                                <Description
                                    text={translate('general.approver')}
                                    description={user.name}
                                />
                                <Description
                                    text={`${translate('columns.division')}:`}
                                    description={user.division}
                                />
                            </Col>
                        </>
                    }
                    key={'header'}
                ></Panel>
            </Collapsable>
        );
    },
);
