import React, { memo, useContext } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-use';
import { ModalContext } from 'src/contexts/modal-context';
import { IFilterSelectItems } from '@redux/slices/order';
import { SearchGroup } from '@components/input';
import { ExportButton } from '@styles/components/button';
import { translate } from '@components/i18n';
import { fetchTransferItems, fetchTransferItemsByUser } from '@redux/slices/transfer-item';
import { EditModal } from './edit-modal';
import { authSelector } from '@redux/slices/auth';

export const Header: React.FC = memo(() => {
    const { pathname } = useLocation()
    const isRegister = pathname.includes("/register")

    const dispatch = useDispatch();
    const modalContext = useContext(ModalContext);
    const authState = useSelector(authSelector)

    const filter = (filter: Partial<IFilterSelectItems>): void => {
        if (authState.isAdmin) {
            dispatch(
                fetchTransferItems({
                    page: 0,
                    size: 10,
                    query: filter.filterValue,
                    sort: 'numero,asc'
                }),
            );
        } else {
            dispatch(
                fetchTransferItemsByUser({
                    page: 0,
                    size: 10,
                    query: filter.filterValue,
                    sort: 'numero,asc'
                }),
            );
        }
    };

    const add = (): void => {
        modalContext.setIsOpen(true, <EditModal />, {
            title: translate('general.registerItem'),
            width: '40%',
        });
    };

    return (
        <Col>
            <Row style={{ marginTop: 20 }} align="middle">
                <Col span={24}>
                    <Row justify={'space-between'} align="middle">
                        <Col span={10}>
                            <SearchGroup
                                allowClear
                                placeholder={translate(
                                    'forms.placeholders.search',
                                )}
                                onSearch={(e) => {
                                    filter({ filterValue: e });
                                }}
                            />
                        </Col>
                        {isRegister && (
                            <Col span={14}>
                                <Row justify="end">
                                    <ExportButton onClick={add}>
                                        {translate('forms.buttons.addNew')}
                                    </ExportButton>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Col>
    );
});
