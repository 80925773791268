import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import '@extensions/string';
import {
    ApprovalMarketingRequest,
    ApprovalMarketingRequestAPI,
    ApprovalMarketingResponse,
    ApprovalMarketingResponseApi,
} from '@models/approvalMarketing';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification } from '../notification';
import { RootState } from '@redux/reducers';
import { translate } from '@components/i18n';

export const fetchApprovalMarketing = createAsyncThunk<
    ApprovalMarketingResponse[]
>('approvalMarketing/fetchApprovalMarketing', async (_, thunkAPI) => {
    try {
        const response = await axios.get<ApprovalMarketingResponseApi[]>(
            '/aprovadormarketing/all',
            {
                params: {
                    query: '',
                },
            },
        );
        const { data, status } = response;

        if (status === 200) {
            const result = data.map((u) =>
                Object.assign(new ApprovalMarketingResponseApi(), u),
            );

            return result.map((u) => u.toMarketingApprovalResponse());
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        // TODO: here will be fetch's actions
        return thunkAPI.rejectWithValue(
            translate('general.errorListApprovalGroup'),
        );
    }
});

export const fetchApprovalMarketingById = async (
    groupId,
): Promise<ApprovalMarketingRequestAPI> => {
    try {
        const response = await axios.get<ApprovalMarketingResponseApi>(
            '/aprovadormarketing/' + groupId,
        );
        if (response.status === 200) {
            return response.data;
        }
    } catch (e) {
        //const { response } = e;
    }
};

export const createApprovalMarketing = createAsyncThunk<
    ApprovalMarketingResponse,
    ThunkCallback<ApprovalMarketingRequest>
>('approvalMarketing/createApprovalMarketing', async (request, thunkAPI) => {
    try {
        const response = await axios.post<ApprovalMarketingResponseApi>(
            '/aprovadormarketing',
            new ApprovalMarketingRequestAPI(request.data),
        );

        const { data, status } = response;
        if (status === 201 && data) {
            const instance: ApprovalMarketingResponseApi = Object.assign(
                new ApprovalMarketingResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.approvalGroupRegistered'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            thunkAPI.dispatch(fetchApprovalMarketing());

            return instance.toMarketingApprovalResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        console.debug('Error', e);

        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: translate('general.errorRegisteringUser'),
                title: translate('general.errorT'),
                notificationKey: request.notificationKey,
            }),
        );

        return thunkAPI.rejectWithValue('');
    }
});

export const removeApprovalMarketing = createAsyncThunk<number, number>(
    'approvalMarketing/removeApprovalMarketing',
    async (groupId, thunkAPI) => {
        try {
            const response = await axios.delete(
                '/aprovadormarketing/' + groupId,
            );
            if (response.status === 200) {
                thunkAPI.dispatch(removeApprovalMarketingState(groupId));
                return Promise.resolve(groupId);
            }
            return 0;
        } catch (e) {
            const { response } = e;
            const { data } = response;
            const { error } = data;
            const { message } = error;
            return thunkAPI.rejectWithValue(message);
        }
    },
);

interface IApprovalMarketingState {
    availableApprovalMarketing: ApprovalMarketingResponse[];
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: IApprovalMarketingState = {
    availableApprovalMarketing: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const approvalMarketingSlice = createSlice({
    name: 'approvalMarketingSlice',
    initialState,
    reducers: {
        clearStateApprovalMarketing: (state) => {
            state = { ...initialState };

            return state;
        },
        filterApprovalMarketing: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
            return state;
        },
        removeApprovalMarketingState: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.availableApprovalMarketing =
                state.availableApprovalMarketing.filter(
                    (u) => u.id !== action.payload,
                );
            return state;
        },
    },
    extraReducers: {
        [createApprovalMarketing.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ApprovalMarketingResponse>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableApprovalMarketing = [
                ...state.availableApprovalMarketing,
                payload,
            ];
            return state;
        },
        [createApprovalMarketing.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [createApprovalMarketing.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchApprovalMarketing.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchApprovalMarketing.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
            return state;
        },
        [fetchApprovalMarketing.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ApprovalMarketingResponse[]>,
        ) => {
            state.isFetching = false;
            state.isSuccess = true;
            state.availableApprovalMarketing = payload;
            return state;
        },
    },
});

export const updateApprovalMarketing = createAsyncThunk<
    ApprovalMarketingResponse,
    ThunkCallback<ApprovalMarketingResponse>
>('approvalMarketing/createApprovalMarketing', async (request, thunkAPI) => {
    try {
        const response = await axios.put<ApprovalMarketingResponseApi>(
            '/aprovadormarketing/' + request.data.id,
            new ApprovalMarketingRequestAPI(request.data),
        );

        const { data, status } = response;

        if (status === 200) {
            const instance: ApprovalMarketingResponseApi = Object.assign(
                new ApprovalMarketingResponseApi(),
                data,
            );

            thunkAPI.dispatch(
                addNotification({
                    type: 'success',
                    message: translate('general.approvalGroupUpdated'),
                    title: translate('general.success'),
                    notificationKey: request.notificationKey,
                    callback: request.onSuccess,
                }),
            );

            thunkAPI.dispatch(fetchApprovalMarketing());

            return instance.toMarketingApprovalResponse();
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        const { response } = e;
        const { data } = response;
        const { error } = data;
        const { message } = error;

        return thunkAPI.rejectWithValue(message);
    }
});

export const approvalMarketingSelector = (
    state: RootState,
): IApprovalMarketingState => state.approvalMarketing;

export const approvalMarketingValues = ({
    approvalMarketing,
}: RootState): ApprovalMarketingResponse[] =>
    approvalMarketing.availableApprovalMarketing.filter(
        (u) =>
            u.divisao?.compare(approvalMarketing.filter) ||
            u.aprovadorNome?.compare(approvalMarketing.filter),
    );

export const {
    clearStateApprovalMarketing,
    filterApprovalMarketing,
    removeApprovalMarketingState,
} = approvalMarketingSlice.actions;

export default approvalMarketingSlice.reducer;
