import React from 'react';
import ImageFadeIn from 'react-image-fade-in';

interface ILogoProps {
    size?: number | 's' | 'm' | 'l';
}

export const Logo: React.FC<ILogoProps> = ({ size }) => {
    return (
        <ImageFadeIn
            loadAsBackgroundImage={false}
            opacityTransition={3}
            src="/images/logo.png"
            width={
                size
                    ? typeof size === 'number'
                        ? size
                        : size === 's'
                        ? '150px'
                        : size === 'm'
                        ? '200px'
                        : '250px'
                    : '250px'
            }
        />
    );
};
