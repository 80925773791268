import { RouterPath } from '@definitions/routes';
import { ApprovalGroups } from '@pages/approval-groups';
import { ApprovalMarketing } from '@pages/approval-marketing';
import { Users } from '@pages/users';
import { Delegation } from '@pages/delegation';

const routes: RouterPath[] = [
    {
        path: '/users',
        render: () => <Users />,
        auth: true,
        allowedRoles: [],
    },
    {
        path: '/approval-groups',
        render: () => <ApprovalGroups />,
        auth: true,
        allowedRoles: [],
    },
    {
        path: '/approval-marketing',
        render: () => <ApprovalMarketing />,
        auth: true,
        allowedRoles: [],
    },
    {
        path: '/delegation',
        render: () => <Delegation />,
        auth: true,
        allowedRoles: ['APROVADOR'],
    },
];

export default routes;
