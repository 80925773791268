import { BellOutlined } from '@ant-design/icons';
import { Avatar, Badge, Col, Divider, Dropdown, Row } from 'antd';
import React, { memo } from 'react';
import { CustomMenu } from '../styled';
import { ActionsUser } from './actions-user';
import { Text } from '@styles/components/text';
import { translate } from '@components/i18n';

export const Actions: React.FC = memo(() => {
    const menu = (
        <CustomMenu>
            <CustomMenu.Item key="0">
                <Row gutter={20} align="middle">
                    <Col span={3} style={{ marginRight: 10 }}>
                        <Avatar
                            size={30}
                            shape="circle"
                            style={{ backgroundColor: '#1890ff' }}
                            icon={<Text white>0</Text>}
                        />
                    </Col>
                    <Col
                        span={20}
                        style={{ flexDirection: 'column', display: 'flex' }}
                    >
                        <Text blue fontWeight={400} fontSize={15}>
                            {translate('general.newOrder')}
                        </Text>
                        <Text fontWeight={400} fontSize={11} opacity={0.6}>
                            {translate('general.yourOrdersZero')}
                        </Text>
                    </Col>
                </Row>
            </CustomMenu.Item>
            <CustomMenu.Item key="0">
                <Row gutter={20} align="middle">
                    <Col span={3} style={{ marginRight: 10 }}>
                        <Avatar
                            size={30}
                            shape="circle"
                            style={{ backgroundColor: '#EB5757' }}
                            icon={<Text white>20</Text>}
                        />
                    </Col>
                    <Col
                        span={20}
                        style={{ flexDirection: 'column', display: 'flex' }}
                    >
                        <Text blue fontWeight={400} fontSize={15}>
                            {translate('general.orderOnHold')}
                        </Text>
                        <Text fontWeight={400} fontSize={11} opacity={0.6}>
                            {translate('general.yourOrdersTwenty')}
                        </Text>
                    </Col>
                </Row>
            </CustomMenu.Item>
        </CustomMenu>
    );

    return (
        <Col span={6}>
            <Row align="middle" gutter={5} style={{ cursor: 'pointer' }}>
                <Col style={{ visibility: 'hidden' }}>
                    <Dropdown
                        overlay={menu}
                        trigger={['click']}
                        placement="bottomRight"
                    >
                        <Badge count={5}>
                            <Avatar shape="circle" size="large">
                                <BellOutlined style={{ fontSize: 20 }} />
                            </Avatar>
                        </Badge>
                    </Dropdown>
                </Col>
                <Col>
                    <Divider type="vertical" />
                </Col>
                <Col>
                    <ActionsUser />
                </Col>
            </Row>
        </Col>
    );
});
