import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { UserResponse } from '@models/users';
import { userEditableFields } from '@pages/users/constants';
import { FormBuilder } from '@components/form';
import { updateUser } from '@redux/slices/users';
import {
    approvalGroupsValuesSelector,
    fetchApprovalGroups,
} from '@redux/slices/approval-groups';
import {
    divisionSelector,
    divisionValues,
    fetchLines,
} from '@redux/slices/divisions';
import { authSelector } from '@redux/slices/auth';
import { translate } from '@components/i18n';

export const EditUserModal: React.FC = () => {
    const dispatch = useDispatch();
    const approvalGroups = useSelector(approvalGroupsValuesSelector);
    const { currentUser } = useSelector(authSelector);
    const divisionsSelector = useSelector(divisionSelector);
    const divisions = useSelector(divisionValues);
    const [form] = Form.useForm<UserResponse>();
    const [editUserState, setEditUserState] =
        useState<UserResponse>(currentUser);
    const modalContext = useContext(ModalContext);

    const onOk = (values): void => {
        handleUpdateUser(values);
    };

    const handleUpdateUser = (values): void => {
        console.debug(values);
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingUser'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateUser({
                data: {
                    id: currentUser.id,
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchApprovalGroups());
        dispatch(fetchLines());
    }, []);

    useEffect(() => {
        if (currentUser) {
            setEditUserState(currentUser);
            form.setFieldsValue(currentUser);
        } else {
            form.resetFields();
        }
    }, [currentUser]);

    const onChangeValues = (
        changedValues: Partial<UserResponse>,
        allValues: UserResponse,
    ): void => {
        setEditUserState({
            ...allValues,
            ...changedValues,
        });
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ ...editUserState }}
                    onFinish={onOk}
                    form={form}
                    onlyEditable={!currentUser.isAdmin ? ['password'] : []}
                    fields={userEditableFields({
                        optionsApprovalGroups: approvalGroups
                            .filter((c) => c.codigoTipoPedido === 'S1')
                            .map((item) => ({
                                name: item.descricao,
                                id: item.id,
                            })),
                        optionsDiscountGroups: approvalGroups
                            .filter(
                                (c) =>
                                    c.codigoTipoPedido ===
                                    'DESCONTOS_FINANCEIROS',
                            )
                            .map((item) => ({
                                name: item.descricao,
                                id: item.id,
                            })),
                        optionsMarketingApprovalGroups: approvalGroups
                            .filter(
                                (c) =>
                                    c.codigoTipoPedido !== 'S1' &&
                                    c.codigoTipoPedido !==
                                        'DESCONTOS_FINANCEIROS',
                            )
                            .map((item) => ({
                                name: item.descricao,
                                id: item.id,
                            })),
                        optionsDivisions: divisions.map((item) => ({
                            id: item.nome,
                            name: item.nome + ' - ' + item.descricao,
                        })),
                        userId: currentUser?.id,
                        requiredPassword: false,
                        optionsRow: divisionsSelector.availableRows?.map(
                            (r) => ({ id: r.codigo, name: r.descricao }),
                        ),
                        withRow: editUserState?.gerenteLinha,
                    })}
                    withDelete={false}
                />
            </Col>
            <Col span={1} />
        </Row>
    );
};
