import GlobalStyle from '@styles/globalStyles';
import React from 'react';
import { ThemeProvider } from 'styled-components/macro';

import { light } from './light';

export const StyledThemeProvider: React.FC = ({ children }) => {
    return (
        <ThemeProvider theme={light}>
            <GlobalStyle /> {children}
        </ThemeProvider>
    );
};

export * from './light';
