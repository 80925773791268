import React, { useEffect, useState } from 'react';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { SelectItemsHeader } from './components/select-items-header';
import {
    SelectItemsBody,
    SelectItemsBodyBonification,
} from './components/select-items-body';
import { SelectItemsActions } from './components/select-items-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    bonificationSelector,
    fetchAvailableItems,
    fetchAvailableItemsBonification,
    fetchPaymentCondition,
    orderItemsSelector,
    updateCampaignItems,
} from '@redux/slices/order';
import { Tabs } from '@components/tabs/styled';
import { useQuery as useQueryHooks } from '@hooks/use-query';
import { Col, Row, message } from 'antd';
import { Semaphore } from '@components/semaphore';
import { OrderContextProvider } from './context/provider';
import { campaignSelector, selectCampaign } from '@redux/slices/campaign';
import { clearMinOrderState } from '@redux/slices/min-order';
import { translate } from '@components/i18n';
import { AppDispatch } from '@redux/store';

const { TabPane } = Tabs;

export const Bonification: React.FC = () => {
    const { selectedOrders } = useSelector(bonificationSelector);
    const campaignState = useSelector(campaignSelector);
    const { order, paymentCondition, selectedMoeda, ...selectItemsState } = useSelector(orderItemsSelector);
    const dispatch = useDispatch<AppDispatch>();
    const query = useQueryHooks();
    const [loading, setLoading] = useState<boolean>(true)

    const fetchPreData = async (): Promise<void> => {
        await Promise.all([
            dispatch(clearMinOrderState()),
        ])
    }

    useEffect(() => {
        if (order?.customer?.code) {
            fetchPreData().then(res => {
                getItems()
            })
        }
    }, [order?.customer?.code])

    useEffect(() => {
        const ref = query.get('ref');
        if (ref && ref !== undefined)
            dispatch(fetchAvailableItemsBonification(Number(ref)))
                .unwrap()
                .then(() => {
                    setTimeout(() => setLoading(false), 1000)
                })
        else
            dispatch(
                fetchAvailableItems({
                    paymentConditionId: '100',
                    draft: true,
                    tempItems: selectedOrders
                        .filter(
                            (o) =>
                                o.bonificationItems &&
                                o.bonificationItems.length > 0,
                        )
                        .map((o) => o.bonificationItems)
                        .flat(1),
                }),
            ).unwrap().then(() => {
                setTimeout(() => setLoading(false), 1000)
            })

        if (campaignState.availableCampaignForOrder.length > 0) {
            const campaigns = campaignState.selectedCampaigns
                .filter((c) => c !== null)
                .map((c) =>
                    campaignState.availableCampaignForOrder.find(
                        (ii) => ii.id === c,
                    ),
                );

            dispatch(
                selectCampaign(
                    campaigns
                        .filter((c) => c.tipo.includes('BONIFICACAO'))
                        .map((c) => c.id),
                ),
            );

            setTimeout(() => dispatch(updateCampaignItems()), 4000);
        }
    }, []);

    const getItems = async (): Promise<void> => {
        const isDraft = query.get('draft');

        if (
            paymentCondition && paymentCondition.id &&
            selectItemsState.availablePaymentConditions.length > 0 &&
            !isDraft
        ) {

            if (!selectItemsState.draftRef) {
                dispatch(
                    fetchAvailableItems({
                        paymentConditionId: `${paymentCondition.id}`,
                    }),
                );
            }
        } else if (
            paymentCondition && paymentCondition.id &&
            !selectItemsState.request.paymentConditionId &&
            !isDraft
        ) {
            message.info({
                content: translate('general.suggestionNotFound'),
                key: 'suggestion-not-found',
            });
            dispatch(
                fetchAvailableItems({
                    paymentConditionId: `${paymentCondition.id}`,
                }),
            );
        }
    }

    return (
        <OrderContextProvider>
            <Page
                title={translate('pages.newOrderBonification')}
                greyBackground
                onClickGoBack
                menuIcon={<LeftOutlined />}
            >
                <SelectItemsHeader readOnly bonification />
                <Tabs
                    defaultActiveKey="bonification"
                    style={{ marginLeft: 20 }}
                >
                    <TabPane
                        tab={
                            <Row gutter={10}>
                                <Col>{translate('columns.bonification')}</Col>
                                (<Semaphore order={order} moeda={selectedMoeda} loading={loading} />)
                            </Row>
                        }
                        key={'bonification'}
                    >
                        <SelectItemsBody bonification />
                    </TabPane>
                    {selectedOrders?.map((order, index) => {
                        return (
                            <TabPane
                                tab={
                                    <Row gutter={10}>
                                        <Col>
                                            {query.get('draftRef') ===
                                                `${order.key}`
                                                ? translate(
                                                    'general.actualOrder',
                                                )
                                                : `${translate(
                                                    'columns.order',
                                                )} ${order.key}`}
                                        </Col>
                                        
                                        <Semaphore 
                                            order={order} 
                                            moeda={selectedMoeda}
                                        />
                                    </Row>
                                }
                                key={`tab-${index + 1}`}
                            >
                                <SelectItemsBodyBonification order={order} />
                            </TabPane>
                        );
                    })}
                </Tabs>
                <SelectItemsActions
                    bonification
                    send={
                        selectedOrders?.find(
                            (w) => `${w.key}` === query.get('draftRef'),
                        )?.orderType?.date
                            ? 0
                            : Number(query.get('draftRef'))
                    }
                />
            </Page>
        </OrderContextProvider>
    );
};
